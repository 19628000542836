import React from 'react';
import { Grid } from 'antd';
import PrettyPieChart from '@client/components/charts/PrettyPieChart';
import ChartWidget, { ChartWidgetProps } from '../components/ChartWidget';
import { FetchDataParams } from '../components/DashboardWidgetWrapper';
import PieChartLabel from './helpers/PieChartLabel';

type PieChartWidgetProps<Data, Params extends FetchDataParams> = {
	entries: Array<{
		key: string;
		label: string;
		fill?: string;
	}>;
	roundTotal?: boolean;
	textProps?: object;
	unit: string;
} & Omit<ChartWidgetProps<Data, Params>, 'legendItems' | 'renderChart'>;

type LabelProps = {
	cx: number;
	cy: number;
	midAngle: number;
	outerRadius: number;
	payload?: object;
}

const PieChartWidget = <Data extends { total: number }, Params extends FetchDataParams>({
	entries,
	unit,
	textProps,
	roundTotal,
	...props
}: PieChartWidgetProps<Data, Params>) => {
	const screens = Grid.useBreakpoint();

	return (
		<ChartWidget
			showPeriodLabel
			height={235}
			legendItems={entries}
			slim={!screens.xl}
			renderChart={(data) => {
				const pieChartData = entries.map((entry) => ({ ...entry, value: data[entry.key] }));

				return (
					<PrettyPieChart
						roundTotal={roundTotal}
						data={pieChartData}
						textProps={textProps}
						unit={unit}
						label={(label: LabelProps) => PieChartLabel({
							cx: label.cx,
							cy: label.cy,
							midAngle: label.midAngle,
							outerRadius: label.outerRadius,
							payload: label.payload,
							slim: screens.xl,
						})}
						height={235}
						total={data.total}
					/>
				);
			}}
			{...props}
		/>
	);
};

export default PieChartWidget;
