import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	Card,
	Row,
	Col,
	notification,
} from 'antd';
import {
	useParams,
	useHistory,
} from 'react-router-dom';
import Table from '@client/components/Table/Table';
import {
	getCounterpartyDetails,
	updateCounterparty,
	deleteCounterparty,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import showErrorNotification from '@client/utils/showErrorNotification';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import getFixtureTableColumns from '@client/utils/getFixtureTableColumns';
import getPointOfContactItems from '@client/utils/getPointOfContactItems';
import Button from '@client/components/Button';
import { Links } from '@client/utils/links';
import getItems from './helpers/getItems';

const ChartererDetailsScreen = () => {
	const { id } = useParams();
	const history = useHistory();

	const [
		charterer,
		refreshCharterer,
		error,
		loading,
	] = useFetchedState(
		() => getCounterpartyDetails(id),
		[],
		{ showNotification: false },
	);

	const items = charterer != null ? getItems(charterer) : [];
	const pointOfContactItems = charterer != null ? getPointOfContactItems(charterer) : [];

	const fixtureColumns = useMemo(() => getFixtureTableColumns(), []);

	const saveDetails = useCallback(async (values) => {
		await updateCounterparty(id, values);
		await refreshCharterer();
	}, [id, refreshCharterer]);

	const onDeleteCharterer = useCallback(async () => {
		try {
			await deleteCounterparty(id);

			notification.success({
				message: 'Charterer was deleted',
				description: `${charterer?.name} has successfully been deleted.`,
			});

			history.goBack();
		} catch (e) {
			showErrorNotification('Could not delete charterer', e);
		}
	}, [id, history, charterer?.name]);

	return (
		<SimpleScreen
			error={error}
			canGoBack
			breadcrumbs={[['Charterers', Links.Charterers.get()]]}
			title={charterer?.name}
			rootPageTitle="Charterers"
			loading={loading}
			headerActions={[(
				<Button
					disabled={loading}
					onClick={onDeleteCharterer}
					confirmTitle={`Are you sure you want to delete ${charterer?.name}?`}
					key="delete"
					type="primary"
					danger
				>
					Delete charterer
				</Button>
			)]}
		>
			<Row gutter={16}>
				<Col span={12}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Card bordered>
								<EditableDetails
									data-cy="details"
									items={items}
									onSave={saveDetails}
								/>
							</Card>
						</Col>
						<Col span={24}>
							<Card bordered>
								<EditableDetails
									data-cy="pointOfContact"
									items={pointOfContactItems}
									onSave={saveDetails}
									title="Point of contact"
								/>
							</Card>
						</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Card title="Estimates">
						<Table
							data-cy="fixtures"
							rowKey="id"
							dataSource={charterer?.fixtures ?? []}
							columns={fixtureColumns}
						/>
					</Card>
				</Col>
			</Row>
		</SimpleScreen>
	);
};

export default ChartererDetailsScreen;
