import React, { useMemo } from 'react';
import {
	Card,
	Col,
	Empty,
	Row,
	Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/pro-thin-svg-icons';
import { formatCurrency } from '@shared/utils/currency';
import {
	getReceivableLabel,
	longReceivableRanges,
	shortReceivableRanges,
} from '@shared/utils/receivablesHelpers';
import { DashboardPeriods } from '@shared/utils/constants';
import { useAuth } from '@client/lib/auth';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getReceivablesAgingData } from '@client/lib/api';
import Table from '@client/components/Table/Table';
import { useDashboardPeriod } from '../helpers/dashboardInfo';
import styles from './ReceivablesAgingDashboard.module.css';

const ReceivablesAgingDashboard = () => {
	const { userInfo } = useAuth();
	const period = useDashboardPeriod();

	const [dashboardData, _refresh, _error, loading] = useFetchedState(
		() => getReceivablesAgingData(period ?? DashboardPeriods.SIXTY_DAYS),
		[period],
	);
	const ranges = period === DashboardPeriods.SIXTY_DAYS ?
		shortReceivableRanges :
		longReceivableRanges;

	const tableData = useMemo(() => {
		if (dashboardData == null) {
			return [];
		}

		const totalByRange = ranges.map((_range, i) => {
			let total = 0;
			dashboardData.forEach((cp) => {
				total += cp.receivable[i].receivable;
			});

			return total;
		});

		const grandTotal = totalByRange.reduce((t, r) => t + r, 0);

		const totalRow = {
			isTotal: true,
			counterparty: (<b>Total</b>),
			totals: totalByRange,
			total: grandTotal,
		};

		return [...dashboardData, totalRow];
	}, [dashboardData, ranges]);

	if (!loading && (dashboardData == null || dashboardData.length === 0)) {
		return (
			<div className={styles.emptyWrapper}>
				<Card>
					<Empty
						image={(
							<FontAwesomeIcon
								color="crimson"
								size="4x"
								icon={faChartPie}
							/>
						)}
						description={(
							<>
								<Typography.Title level={3}>
									This dashboard needs more data
								</Typography.Title>
								<Typography.Text type="secondary">
									Create a contract with hire invoices to get started with this dashboard
								</Typography.Text>
							</>
						)}
					/>
				</Card>
			</div>
		);
	}

	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<Table
					pagination={false}
					dataSource={tableData}
					loading={loading}
					columns={[
						{ dataIndex: 'counterparty', title: 'Counterparty' },
						...ranges.map((_r, i) => ({
							title: getReceivableLabel(i, period),
							render: (_value: any, row: any) => (row?.isTotal ?
								(<b>{formatCurrency(row.totals[i], userInfo.baseCurrency)}</b>) :
								formatCurrency(row.receivable[i].receivable, userInfo.baseCurrency)),
						})),
						{
							title: 'Total',
							dataIndex: 'total',
							// @ts-ignore
							render: (total, row) => (row.isTotal ? (
								<b>{formatCurrency(total, userInfo.baseCurrency)}</b>
							) : formatCurrency(total, userInfo.baseCurrency)),
						},
					]}
				/>
			</Col>
		</Row>
	);
};

export default ReceivablesAgingDashboard;
