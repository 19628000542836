import React from 'react';
import {
	Currencies,
	PnlRenderTypes,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import { formatPercentage } from '@shared/utils/formatPercentage';
import styleColorPicker from './styleColorPicker';

const PnlItem = ({ value, renderType, currency, bold, className, reverseColor = false } : {
	value: number;
	renderType: Values<typeof PnlRenderTypes> | null;
	currency: Values<typeof Currencies>;
	bold: boolean | undefined;
	className?: string | null;
	reverseColor?: boolean;
}) : React.ReactElement => {
	let rendered;

	if (value == null) {
		return (<>-</>);
	}

	if (renderType === PnlRenderTypes.CURRENCY) {
		rendered = (<>{formatCurrency(value, currency)}</>);
	} else if (renderType === PnlRenderTypes.PERCENTAGE) {
		rendered = (
			<span style={styleColorPicker(value, reverseColor)}>
				{formatPercentage(value)}
			</span>
		);
	} else {
		rendered = (<>{value}</>);
	}

	if (bold) {
		rendered = (<b>{rendered}</b>);
	}

	if (className != null) {
		rendered = (<span className={className}>{rendered}</span>);
	}

	return rendered;
};

export default PnlItem;
