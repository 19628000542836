import type { Moment } from 'moment';
import { toMoment } from '@shared/utils/date';
import HIIHireAddressCommission from '@shared/hireInvoice/HIIHireAddressCommission';
import HIIBallastBonus from '@shared/hireInvoice/HIIBallastBonus';
import HIIBallastBonusAddressCommission from '@shared/hireInvoice/HIIBallastBonusAddressCommission';
import HIIBallastBonusBrokerCommission from '@shared/hireInvoice/HIIBallastBonusBrokerCommission';
import HIIHireBrokerCommission from '@shared/hireInvoice/HIIHireBrokerCommission';
import HIIHirePeriod from '@shared/hireInvoice/HIIHirePeriod';
import HIIExpenseSubjectToHireDays from '@shared/hireInvoice/HIIExpenseSubjectToHireDays';
import HIIRevenueSubjectToDays from '@shared/hireInvoice/HIIRevenueSubjectToDays';
import HIIHirePeriodModel from '@api/models/hii-hire-period';
import type { ExpenseSubjectToHireDaysProps } from '@api/models/expense-subject-to-hire-days';
import type { BankAccountProps } from '@api/models/bank-account';
import type { VoyageOffHirePeriodProps } from '@api/models/voyage-off-hire-period';
import type { VoyageExpenseReceivableProps } from '@api/models/voyage-expense-receivable';
import type { VoyageBunkerProps } from '@api/models/voyage-bunker';
import type { BunkerProps } from '@api/models/bunker';
import type { VoyageOffHireBunkerProps } from '@api/models/voyage-off-hire-bunker';
import { RevenueSubjectToDaysProps } from '@api/models/revenue-subject-to-days';
import { OffHirePeriodProps } from '@api/models/off-hire-period';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { Values } from './objectEnums';
import filterByState from './filterHireInvoiceItemsByState';
import {
	AccountTypes,
	CalculationMethodForExpensesSubjectToHireDays,
	FixtureTypes,
	HireInvoiceItemStates,
	HireTypes,
	HireUnit,
} from './constants';
import {
	getExpenseHireInvoiceItem,
	getBunkerHireInvoiceItem,
	getOffHireHireInvoiceItem,
} from './hireInvoice';

let _id = -1;

const getId = () => {
	const newId = _id;

	_id -= 1;

	return newId;
};

export type WithHIIState<T> = T & { state: Values<typeof HireInvoiceItemStates> };

export type TcParams = {
	isHireInvoice: boolean;
	commencementDate: Moment | string | null;
	completionDate: Moment | string;
	hireInvoices: GetVoyageDetailsResponse['hireInvoices'];
	daysPerHirePeriod: number;
	invoicePerCalenderMonth: boolean | null;
	hireRate: number;
	hireDaysDecimals: number;
	hireDescription: string | null;
	hireType: Values<typeof HireTypes>;
	expensesSubjectToHireDays: ExpenseSubjectToHireDaysProps[];
	revenuesSubjectToDays: RevenueSubjectToDaysProps[];
	calculationMethodForExpensesSubjectToHireDays: Values<typeof CalculationMethodForExpensesSubjectToHireDays>;
	addressCommission: number;
	brokers: Array<{
		id: number;
		name: string;
		commission: number;
		paidBy: Values<typeof AccountTypes>;
	}>;
	grossBallastBonus: number | null;
	ballastBonusAvailable: boolean;
	readyForCumulative: boolean;
	voyageBunkers: Array<WithHIIState<VoyageBunkerProps & BunkerProps>>;
	voyageExpenseReceivables: WithHIIState<VoyageExpenseReceivableProps>[];
	voyageOffHirePeriods: Array<WithHIIState<VoyageOffHirePeriodProps & OffHirePeriodProps> & {
		bunkers: Array<VoyageOffHireBunkerProps & BunkerProps>;
	}>;
	bankAccount: BankAccountProps;
	type: typeof FixtureTypes.TC_OUT | typeof FixtureTypes.TC_IN;
	useUTC: boolean;
	hireUnit: HireUnit;
};

const getAvailableHireInvoiceItems = (params: TcParams) => {
	const {
		isHireInvoice,
		brokers: allBrokers,
		hireInvoices,
		bankAccount,
		addressCommission,
		commencementDate,
		completionDate,
		daysPerHirePeriod,
		invoicePerCalenderMonth,
		hireRate: fixtureHireRate,
		hireDescription: fixtureHireDescription,
		hireType: fixtureHireType,
		expensesSubjectToHireDays,
		revenuesSubjectToDays,
		calculationMethodForExpensesSubjectToHireDays,
		grossBallastBonus,
		ballastBonusAvailable,
		readyForCumulative,
		voyageBunkers,
		voyageExpenseReceivables,
		voyageOffHirePeriods,
		useUTC,
		hireDaysDecimals,
		hireUnit,
	} = params;

	const items = [];

	const brokers = allBrokers.filter((b) => b.paidBy === AccountTypes.CHARTERER);

	if (commencementDate == null) {
		return [];
	}

	let fromDate = commencementDate;

	let hireRate = fixtureHireRate;
	let hireDescription = fixtureHireDescription;
	let hireType = fixtureHireType;

	const hireInvoiceItems = hireInvoices.flatMap((i) => i.HireInvoiceItems);

	const previousHireItems = hireInvoiceItems.reduce((arr: HIIHirePeriodModel[], item) => {
		if (item.HIIHirePeriod == null) {
			return arr;
		}

		const period = item.HIIHirePeriod;

		return [...arr, period];
	}, []).sort((a, b) => toMoment(a.from).diff(b.from));

	if (previousHireItems.length > 0 && !readyForCumulative) {
		const latestHireItem = previousHireItems[previousHireItems.length - 1];

		fromDate = toMoment(latestHireItem.to);
		hireRate = latestHireItem.hireRate;
		hireDescription = (latestHireItem?.hireDescription ?? '');
		hireType = latestHireItem?.hireType as Values<typeof HireTypes>;
	}

	fromDate = toMoment(fromDate).utc();

	let daysToAdd = daysPerHirePeriod;

	if (invoicePerCalenderMonth) {
		daysToAdd = fromDate.daysInMonth();
	}

	let toDate = toMoment(fromDate).add(daysToAdd, 'days');

	if (completionDate != null && toMoment(completionDate).isBefore(toDate)) {
		toDate = toMoment(completionDate);
	}

	toDate = toMoment(toDate).utc();

	if (!readyForCumulative) {
		const hireItem = new HIIHirePeriod(getId(), true, {
			from: fromDate,
			to: toDate,
			hireRate,
			hireDescription: hireDescription ?? '',
			hireType,
			fixtureCurrency: bankAccount.currency,
			useUTC,
			hireDaysDecimals,
			hireUnit,
		});

		hireItem.children.push(
			...(addressCommission ? [new HIIHireAddressCommission(getId(), true, {
				percentage: addressCommission,
				fixtureCurrency: bankAccount.currency,
			})] : []),
			...brokers.map((c) => new HIIHireBrokerCommission(getId(), true, {
				brokerId: c.id,
				brokerName: c.name,
				percentage: c.commission,
				fixtureCurrency: bankAccount.currency,
			})),
			...expensesSubjectToHireDays.map((e) => new HIIExpenseSubjectToHireDays(getId(), true, {
				name: e.name,
				amount: e.amount,
				interval: e.interval,
				formulaType: calculationMethodForExpensesSubjectToHireDays,
				fixtureCurrency: bankAccount.currency,
			})),
		);

		items.push(hireItem);
	}

	if (revenuesSubjectToDays != null && revenuesSubjectToDays.length > 0) {
		revenuesSubjectToDays.forEach((r) => {
			items.push(
				new HIIRevenueSubjectToDays(getId(), true, {
					name: r.name,
					amount: r.amount,
					fixtureCurrency: bankAccount.currency,
					startDate: isHireInvoice ? toMoment(fromDate) : undefined,
					endDate: isHireInvoice ? toMoment(toDate) : undefined,
					useUTC,
				}),
			);
		});
	}

	if (grossBallastBonus != null && ballastBonusAvailable) {
		const ballastBonus = new HIIBallastBonus(getId(), true, {
			amount: grossBallastBonus,
			fixtureCurrency: bankAccount.currency,
		});

		ballastBonus.children.push(
			...(addressCommission ? [new HIIBallastBonusAddressCommission(getId(), true, {
				fixtureCurrency: bankAccount.currency,
			})] : []),
			...brokers.map((c) => new HIIBallastBonusBrokerCommission(getId(), true, {
				brokerId: c.id,
				brokerName: c.name,
				percentage: c.commission,
				fixtureCurrency: bankAccount.currency,
			})),
		);

		items.push(ballastBonus);
	}

	filterByState(voyageBunkers, HireInvoiceItemStates.PENDING).forEach((bunker) => {
		items.push(getBunkerHireInvoiceItem(getId, bunker, bankAccount.currency));
	});

	filterByState(voyageExpenseReceivables, HireInvoiceItemStates.PENDING)
		.forEach((expense) => items.push(getExpenseHireInvoiceItem(
			getId,
			expense,
			bankAccount.currency,
		)));

	filterByState(voyageOffHirePeriods, HireInvoiceItemStates.PENDING).forEach((offHire) => {
		items.push(getOffHireHireInvoiceItem(getId, offHire, bankAccount.currency, {
			addressCommission,
			brokers,
			expensesSubjectToHireDays,
			calculationMethodForExpensesSubjectToHireDays,
			hireDaysDecimals,
		}));
	});

	// Make sure parent id is set on all children
	items.forEach((item) => {
		item.children.forEach((child) => {
			child.parentId = item.id;
		});
	});

	return items;
};

export default getAvailableHireInvoiceItems;
