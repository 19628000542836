import React from 'react';
import classNames from 'classnames';
import { nowMoment } from '@shared/utils/date';
import { FixtureTypes } from '@shared/utils/constants';
import { ChartColors } from '@client/utils/constants';
import Timeline from '@client/components/Timeline';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getFullFleetTimelineData } from '@client/lib/api';
import styles from '../widgets/styles/TcInTimeline.module.css';

const NewFleetTimeline = () => {
	const [data, _refresh, _error, loading] = useFetchedState(getFullFleetTimelineData);

	const legendItems = [
		{
			key: 'ownedPeriod',
			label: 'Owned Period',
			fill: ChartColors.GREY,
		},
		{
			key: 'fixedPeriod',
			label: 'Fixed Period',
			fill: ChartColors.BLUE,
		},
		{
			key: 'redeliveryPeriod',
			label: 'Redelivery Period',
			fill: ChartColors.LIGHTBLUE,
		},
		{
			key: 'offHire',
			label: 'Off-Hire',
			fill: ChartColors.RED,
		},
	];

	if (loading) {
		return (<div>Loading...</div>);
	}

	return (
		<Timeline
			legend={legendItems}
			legendPosition="top"
			className={styles.fleetTimeline}
			sections={data ?? []}
			defaultTimeStart={nowMoment().subtract(15, 'days')}
			defaultTimeEnd={nowMoment().add(3, 'months')}
			showTodayMarker
			// @ts-ignore
			groupRenderer={({ group }) => (group.type == null ?
				group.title :
				(
					<div
						className={classNames(styles.subGroup, {
							[styles.last]: group.type === FixtureTypes.SPOT,
						})}
					>
						{group.title}
					</div>
				))}
		/>
	);
};

export default NewFleetTimeline;
