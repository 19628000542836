import React from 'react';
import { Moment } from 'moment';
import {
	AlertTypeLabels,
	AlertTypes,
	DATE_AND_TIME,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { VoyageProps } from '@api/models/voyage';
import type { VesselProps } from '@api/models/vessel';
import { formatDate } from '@client/utils/formatDate';

type Warning = {
	type: Values<typeof AlertTypes>;
	deliveryPortName?: string;
	arrivalDate?: Moment;
	laycanTo?: Moment;
	voyage: VoyageProps & {
		Vessel: VesselProps;
	};
	latestHirePeriod: {
		from: Moment;
		to: Moment;
	};
};

const getWarningDescription = (warning: Warning) => {
	let result;

	switch (warning.type) {
		case AlertTypes.ETA_SLIPPAGE:
			if (warning.arrivalDate == null || warning.laycanTo == null) {
				break;
			}

			result = (
				<p>
					ETA for this vessel at the delivery port
					<b>{` ${warning?.deliveryPortName} `}</b>
					is
					<b>{` ${formatDate(warning.arrivalDate, DATE_AND_TIME)} `}</b>
					but the laycan ends at
					<b>{` ${formatDate(warning.laycanTo, DATE_AND_TIME)}`}</b>
				</p>
			);
			break;
		case AlertTypes.LATE_HIRE_INVOICING:
			result = (
				<p>
					A new hire invoice can be raised for
					<b>{` ${warning.voyage.Vessel.name}. `}</b>
					<br />
					The previous hire invoice spanned until
					<b>{` ${formatDate(warning.latestHirePeriod.to, DATE_AND_TIME)} `}</b>
				</p>
			);
			break;
		default:
			result = AlertTypeLabels[warning.type];
			break;
	}

	return result;
};

export default getWarningDescription;
