import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Empty,
	Row,
	Space,
	Tabs,
	Tag,
	Typography,
} from 'antd';
import isEqual from 'lodash.isequal';
import {
	CloseOutlined,
	EditOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import { splitActionKey } from '@shared/utils/splitActionKey';
import { capitalize } from '@shared/utils/string';
import { LaytimeTerms } from '@shared/utils/constants';
import type { CargoPortWithEstimatedValues } from '@api/features/cargos/transformCargos';
import type { GetCargoDetailsResponse } from '@api/features/cargos/getCargoDetails';
import type { Port } from '@api/utils/ports';
import Card from '@client/components/Card/Card';
import CountryFlag from '@client/components/CountryFlag';
import Button from '@client/components/Button';
import Select from '@client/components/Select';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getPorts } from '@client/lib/api';
import CargoPortForm from '../list/CargoPortForm';
import EstimatedCargoPortForm from '../list/EstimatedCargoPortForm';
import styles from './CargoDetailsScreen.module.css';

type CargoPortsDetailsProps = {
	cargoPorts: CargoPortWithEstimatedValues[];
	sortedCargoPorts: CargoPortWithEstimatedValues[];
	cargoDetails: GetCargoDetailsResponse | null | undefined;
	handleChange: (values: { cargoPortId: number; cargoId: number; attributes: any }) => void;
	laytimeTerms: LaytimeTerms;
	quantitiesRemaining: {
		loadedRemaining: number;
		dischargeRemaining: number;
	} | null;
	refreshDetails: () => void;
}

export const CargoPortsDetails = ({
	cargoPorts,
	sortedCargoPorts,
	handleChange,
	cargoDetails,
	laytimeTerms,
	quantitiesRemaining,
	refreshDetails,
}: CargoPortsDetailsProps) => {
	const [editingPort, setEditingPort] = useState(false);
	const [portToEdit, setPortToEdit] = useState<number>();
	const [selectedCargoPort, setSelectedCargoPort] = useState<
		GetCargoDetailsResponse['cargoPorts'][number] | null
	>(null);

	const [ports] = useFetchedState(getPorts);

	const portOptions = useMemo(() => (ports != null ? ports?.map((p) => {
		return {
			label: (
				<div className={styles.portSelector}>
					<CountryFlag
						countryCode={typeof p?.countryCode === 'string' ? p.countryCode : undefined}
					/>
					<p>{p.name}</p>
				</div>
			),
			value: p.id,
			searchValue: p.name,
		};
	}).sort((a, b) => a.searchValue.localeCompare(b.searchValue)) : []), [ports]);

	useEffect(() => {
		// Update the selected cargo-port with updated values from endpoint. Not very optimal.
		if (cargoDetails != null && selectedCargoPort != null) {
			const { id: cpId } = selectedCargoPort;
			const updated = cargoPorts.find((cp) => cp.id === cpId);

			if (updated == null) {
				return;
			}

			if (!isEqual(updated, selectedCargoPort)) {
				setSelectedCargoPort(updated);
			}
		}
	}, [cargoDetails, cargoPorts, selectedCargoPort]);

	useEffect(() => {
		if (cargoPorts != null && cargoPorts.length > 0 && selectedCargoPort == null) {
			setSelectedCargoPort(cargoPorts[0]);
		}
	}, [cargoPorts, selectedCargoPort]);

	const tabs = useMemo(() => sortedCargoPorts.map((cp) => ({
		key: cp.id,
		tabKey: `${cp.id}`,
		details: cp,
	})), [sortedCargoPorts]);

	const updateEntry = async ({ port, cargoPort }: {
		port: Port;
		cargoPort: CargoPortWithEstimatedValues;
	}) => {
		handleChange({
			cargoPortId: cargoPort.id,
			cargoId: cargoPort.cargoId,
			attributes: { port },
		});
	};

	const handlePortSelect = async (
		portId: number | null,
		cargoPort: CargoPortWithEstimatedValues,
	) => {
		if (portId == null || cargoPort == null) {
			return;
		}

		const newPort = ports?.find((p) => p.id === portId);

		if (newPort == null) {
			return;
		}

		await updateEntry({
			port: newPort,
			cargoPort,
		});

		setEditingPort(false);
	};

	return (
		<Card>
			{(tabs == null || tabs.length === 0) && (
				<Empty description="No cargo ports. Please add loading or discharge ports above." />
			)}
			<Tabs
				onChange={
					(id) => setSelectedCargoPort(sortedCargoPorts.find((cp) => cp.id === Number(id)) ?? null)
				}
				defaultActiveKey={`${selectedCargoPort?.id}`}
			>
				{tabs.map((t) => {
					const { action } = splitActionKey(t.details.portAndActionKey);

					return (
						<Tabs.TabPane
							key={t.key}
							tabKey={t.tabKey}
							tab={
								(
									<>
										<Space>
											<Tag color={action === 'loading' ? 'geekblue' : 'red'}>
												{capitalize(action)}
											</Tag>
											<CountryFlag countryCode={t.details.port.countryCode} />
											{t.details.port.name}
										</Space>
									</>
								)
							}
						>
							<Row gutter={[16, 16]}>
								{cargoPorts.length === 0 ? (
									<Col span={24}>
										<Empty description="Please add a cargo port to get started" />
									</Col>
								) : (
									<>
										<Col className={styles.cardPadding} span={24}>
											<Typography.Title level={4}>
												<Space direction="horizontal">
													{`${capitalize(action)} in `}
													<div
														className={classNames(styles.flagAndNameRow)}
													>
														{editingPort && portToEdit === t.details.id ? (
															<Select
																showSearch
																className={styles.selectPort}
																value={t.details.port.id}
																key={t.details.port.id}
																optionFilterProp="searchValue"
																options={portOptions}
																onSelect={
																	async (portId) => await handlePortSelect(portId, t.details)
																}
																onBlur={() => {
																	setEditingPort(false);
																	setPortToEdit(undefined);
																}}
															/>
														) : (
															<>
																<CountryFlag
																	countryCode={t.details.port.countryCode}
																	className={styles.portFlag}
																	size={25}
																/>
																<Typography.Text className={styles.portName}>
																	{t.details.port.name}
																</Typography.Text>
															</>
														)}
													</div>
													<div className={styles.topButtonRow}>
														{editingPort && portToEdit === t.details.id ? (
															<Button
																type="link"
																className={styles.closeIcon}
																onClick={() => {
																	setEditingPort(false);
																	setPortToEdit(undefined);
																}}
																icon={(
																	<CloseOutlined />
																)}
															/>
														) : (
															<div className={styles.innerBtnContainer}>
																<Button
																	type="link"
																	onClick={() => {
																		setEditingPort(true);
																		setPortToEdit(t.details.id);
																	}}
																	icon={(
																		<EditOutlined />
																	)}
																/>
															</div>
														)}
													</div>
												</Space>
											</Typography.Title>
											<Row gutter={[24, 24]}>
												<Col span={24}>
													<Typography.Title level={5}>
														Charter party
													</Typography.Title>
													{(cargoDetails != null) && (
														<CargoPortForm
															key={t.details.id}
															handleCargoPortChange={handleChange}
															refreshCargoDetails={refreshDetails}
															cargoPort={t.details}
															cargo={cargoDetails}
															enableDemDes={laytimeTerms === LaytimeTerms.NON_REVERSIBLE}
															type={splitActionKey(t.details.portAndActionKey).action === 'loading' ? 'loadingPort' : 'dischargePort'}
															quantitiesRemaining={quantitiesRemaining}
														/>
													)}
												</Col>
												<Col span={24}>
													<Typography.Title level={5}>
														Estimated calculation
													</Typography.Title>
													{(cargoDetails != null && t.details != null) && (
														<EstimatedCargoPortForm
															key={t.details.id}
															handleCargoPortChange={handleChange}
															cargoPort={t.details}
															cargo={cargoDetails}
															refreshCargoDetails={refreshDetails}
														/>
													)}
												</Col>
											</Row>
										</Col>
									</>
								)}
							</Row>
						</Tabs.TabPane>
					);
				})}
			</Tabs>
		</Card>
	);
};
