import React, { useState } from 'react';
import {
	Typography,
	Popover,
} from 'antd';
import { Link } from 'react-router-dom';
import { VesselOwnershipTypes } from '@shared/utils/constants';
import {
	markFixtureAsFixed,
	markFixtureAsUnfixed,
} from '@client/lib/api';
import dynamicDelay from '@client/utils/dynamicDelay';
import APIError from '@client/utils/APIError';
import TooltipIcon from '@client/components/TooltipIcon';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import Button from '@client/components/Button';
import { Links } from '@client/utils/links';
import styles from './styles/FixButton.module.css';

const FixButton = ({
	fixtureDetails,
	refreshFixtureDetails,
	disabled,
	onFix,
}) => {
	const [missingFixtureData, setMissingFixtureData] = useState(null);
	const [fixLoading, setFixLoading] = useState(false);

	const fix = async () => {
		setFixLoading(true);

		try {
			const result = await dynamicDelay(() => markFixtureAsFixed(fixtureDetails.id), 1500);
			await refreshFixtureDetails();

			onFix(result);
			showSuccessNotification('Successfully marked as fixed', 'The estimate was successfully marked as fixed');
		} catch (e) {
			if (e instanceof APIError && typeof e.rawMessage === 'object') {
				setMissingFixtureData(e.rawMessage);
			} else {
				showErrorNotification('Could not mark as fixed', e);
			}
		}

		setFixLoading(false);
	};

	const unfix = async () => {
		setFixLoading(true);

		try {
			await dynamicDelay(() => markFixtureAsUnfixed(fixtureDetails.id), 1500);
			await refreshFixtureDetails();

			showSuccessNotification('Successfully marked as unfixed', 'The estimate was successfully marked as unfixed');
		} catch (e) {
			showErrorNotification('Could not mark as unfixed', e);
		}

		setFixLoading(false);
	};

	if (fixtureDetails.fixed) {
		let disabledTooltip;

		if (fixtureDetails.hasHireInvoices) {
			disabledTooltip = 'Cannot mark a estimate as unfixed while it has hire invoices';
		} else if (fixtureDetails.voyageExpenses.length > 0) {
			disabledTooltip = 'Cannot mark a estimate as unfixed while it has vendor expenses';
		}

		return (
			<Button
				confirmTitle="Are you sure you want to mark as unfixed?"
				disabledTooltip={disabledTooltip}
				disabled={fixtureDetails.hasHireInvoices || fixtureDetails.voyageExpenses.length > 0}
				loading={fixLoading}
				onClick={unfix}
			>
				Mark as Unfixed
			</Button>
		);
	}

	return (
		<Popover
			key="fix"
			trigger="click"
			placement="bottomLeft"
			visible={missingFixtureData != null}
			onVisibleChange={(visible) => !visible && setMissingFixtureData(null)}
			title={(
				<>
					Missing data
					{' '}
					<TooltipIcon placement="left">
						All the necessary information needs to be filled out
						before you can mark the fixture as fixed.
					</TooltipIcon>
				</>
			)}
			content={missingFixtureData != null && (
				Object.entries(missingFixtureData).map(([sectionName, missingFields]) => (
					<div className={styles.missingSection}>
						<Typography.Title level={4} className={styles.missingTitle}>
							{sectionName}
						</Typography.Title>
						{sectionName === 'Vessel' && (
							<Link className={styles.missingLink} to={Links.Vessel.get(fixtureDetails.vesselId)}>
								Go to
								{' '}
								{fixtureDetails.vesselName || 'vessel'}
							</Link>
						)}
						<ul>
							{missingFields.map((field) => (
								<li key={field}>{field}</li>
							))}
						</ul>
					</div>
				))
			)}
		>
			<Button
				disabled={disabled}
				disabledTooltip={fixtureDetails.vessel && fixtureDetails.vessel.ownershipType === VesselOwnershipTypes.MARKET ? 'Cannot fix estimate with a market vessel ' : 'Some required fields are still missing'}
				confirmTitle="Are you sure you want to mark as fixed?"
				onClick={fix}
				loading={fixLoading}
				type="primary"
			>
				Mark as Fixed
			</Button>
		</Popover>
	);
};

export default FixButton;
