import React, { useState } from 'react';
import {
	Card,
	Divider,
	Space,
	Row,
	Col,
	Grid,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faMinus,
	faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { Values } from '@shared/utils/objectEnums';
import {
	FixtureTypes,
	PnlItemTypes,
	PnlRenderTypes,
} from '@shared/utils/constants';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import Table from '@client/components/Table/Table';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getVesselDetails } from '@client/lib/api';
import { useAuth } from '@client/lib/auth';
import { getEstimateData } from '@client/utils/getEstimateData';
import getPnlColumns
	from '@client/screens/fleet/VoyageDetailsScreen/components/ProfitAndLossTab/utils/getPnlColumns';
import TcInCostAllocation from '../TcInCostAllocation';
import styles from './ProfitAndLossTab.module.css';
import PnlItem from './utils/PnlItem';
import { getPnlData } from './utils/getPnlData';

export type Render = (
	value: number,
	i: RenderItem
) => string | number | React.ReactElement;

export type RenderItem = {
	render?: Render;
	renderType: Values<typeof PnlRenderTypes> | null;
	bold?: boolean | undefined;
	type?: Values<typeof PnlItemTypes>;
}

type Props = {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps>;
}

const ProfitAndLossTab = ({
	voyageDetails,
	fixtureDetails,
}: Props) => {
	const { userInfo } = useAuth();
	const [activeTab, setActiveTab] = useState('pnl');
	const screens = Grid.useBreakpoint();

	const [vessel, _refresh, error, loadingVesselDetails] = useFetchedState(
		() => getVesselDetails(voyageDetails.vesselId),
		[voyageDetails.vesselId],
	);

	const [estimate, _estimateRefresh, estimateError, loadingEstimate] = useFetchedState(
		() => getEstimateData(fixtureDetails, voyageDetails, userInfo.baseCurrency, styles),
		[fixtureDetails],
	);

	if (
		error ||
		estimateError ||
		loadingVesselDetails ||
		loadingEstimate ||
		vessel == null ||
		estimate == null
	) {
		return null;
	}

	const {
		revenue,
		expenses,
		tce,
		summary,
	} = getPnlData(
		{
			pnl: voyageDetails?.stats.pnl,
			fixtureDetails: fixtureDetails as GetFixtureDetailsResponse<TcFixtureProps>,
			vessel,
			estimate,
		},
	);

	const currency = fixtureDetails?.bankAccount?.currency || userInfo.baseCurrency;

	const render = (value: number, i: RenderItem) => {
		if (typeof i.render === 'function') {
			return i.render(value, i);
		}

		return (
			<PnlItem
				value={value ?? 0}
				renderType={i.renderType}
				currency={currency}
				bold={i?.bold}
			/>
		);
	};

	const pnlColumns = getPnlColumns({ render, currency });

	return (
		<Row gutter={[10, 10]}>
			<Col span={24}>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Card
						className={screens.xs ? styles.fullWidth : undefined}
						title={(
							<>
								P&L Sheet
								{!fixtureDetails.completed && fixtureDetails.type !== FixtureTypes.SPOT && (
									<div className={styles.pnlTooltip}>
										To accurately calculate actual profit per day,
										actuals are prorated across the estimated contract duration.
									</div>
								)}
							</>
						)}
						tabList={[
							{ label: 'P&L', key: 'pnl' },
							{ label: 'TC-In Allocation', key: 'tcIn' },
						]}
						onTabChange={(key) => setActiveTab(key)}

					>
						{activeTab === 'tcIn' ? (
							<TcInCostAllocation voyageId={voyageDetails.id} />
						) : (
							<>
								<Divider>
									<Space>
										<FontAwesomeIcon icon={faPlus as Icon} />
										Revenue
									</Space>
								</Divider>
								<Table
									className={styles.table}
									pagination={false}
									columns={pnlColumns}
									dataSource={revenue}
									useCards={screens.xs}
								/>
								<Divider>
									<Space>
										<FontAwesomeIcon
											icon={faMinus as Icon}
										/>
										Voyage Expenses
									</Space>
								</Divider>
								<Table
									className={styles.table}
									pagination={false}
									columns={pnlColumns}
									dataSource={expenses}
									useCards={screens.xs}
								/>
								{
									fixtureDetails.type === FixtureTypes.SPOT && (
										<>
											<Divider>TCE</Divider>
											<Table
												className={styles.table}
												pagination={false}
												columns={pnlColumns}
												dataSource={tce}
											/>
										</>
									)
								}
								<Divider>Summary</Divider>
								<Table
									className={styles.table}
									pagination={false}
									columns={pnlColumns}
									useCards={screens.xs}
									dataSource={summary}
								/>
							</>
						)}
					</Card>
				</div>
			</Col>
		</Row>
	);
};

export default ProfitAndLossTab;
