const normalize = (days: number | null | undefined) => {
	if (days == null) {
		return 0;
	}

	if (Number.isNaN(days)) {
		return 0;
	}

	if (!Number.isFinite(days)) {
		return 0;
	}

	return days;
};

export default normalize;
