import React, { SetStateAction } from 'react';
import {
	Grid,
	Modal,
} from 'antd';
import CargoDetailsScreen from '@client/screens/cargos/details/CargoDetailsScreen';
import { useCentralStore } from '@client/screens/estimates/details/context/CentralStoreContext';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import styles from './CargoDetailsModal.module.css';

type Props = {
	cargoId: number | null;
	visible: boolean;
	refresh: () => Promise<void>;
	setEditingCargoId: React.Dispatch<SetStateAction<number | null>>;
	isTceLocked?: boolean;
	updateInProgress?: boolean;
}

const CargoDetailsModal = ({
	cargoId,
	visible,
	refresh,
	setEditingCargoId,
	isTceLocked,
}: Props) => {
	const [blockClose, setBlockClose] = React.useState(false);
	const screens = Grid.useBreakpoint();

	const onClose = async () => {
		await refresh();
		setEditingCargoId(null);
	};

	const {
		selectedEstimateId,
	} = useCentralStore();

	const {
		useBlocker,
		makeBlockable,
	} = useNavigationBlock();

	useBlocker(blockClose);

	const onCloseModal = makeBlockable(onClose);

	return (
		<Modal
			closable={false}
			open={visible}
			onCancel={onCloseModal}
			destroyOnClose
			width={`calc(100vw - ${screens.lg ? '15%' : '5%'})`}
			className={styles.modal}
			okText="Close"
			okButtonProps={{
				onClick: () => onCloseModal(),
			}}
			bodyStyle={{
				padding: screens.lg ? 5 : 0,
			}}
			cancelButtonProps={{
				style: {
					visibility: 'hidden',
				},
			}}
		>
			{cargoId != null && (
				<CargoDetailsScreen
					inEstimate
					cargoId={cargoId}
					isTceLocked={isTceLocked}
					selectedEstimateId={selectedEstimateId ?? undefined}
					blockClose={blockClose}
					setBlockClose={setBlockClose}
				/>
			)}
		</Modal>
	);
};

export default CargoDetailsModal;
