import React, {
	useEffect,
	useState,
} from 'react';
import {
	Alert,
	Checkbox,
	Form,
	Input,
	Skeleton,
	Space,
	Tag,
} from 'antd';
import { useHistory } from 'react-router-dom';
import {
	ExpenseTypes,
	FixtureCounterpartyTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import isItemPortCall from '@shared/utils/isItemPortCall';
import type { SupplierProps } from '@api/models/supplier';
import type { GetVoyagesResponse } from '@api/features/voyages/getVoyages';
import type { ItineraryPortCallDto } from '@api/features/ops/getVesselItinerary';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getCounterparties,
	getVoyageItinerary,
} from '@client/lib/api';
import { rules } from '@client/utils/form';
import { useAuth } from '@client/lib/auth';
import { Links } from '@client/utils/links';
import TooltipIcon from '@client/components/TooltipIcon';
import Select from '../Select';
import MultiCurrencyInput from '../MultiCurrencyInput';
import Button from '../Button';
import CountryFlag from '../CountryFlag';
import DatePicker from '../DatePicker';
import styles from './CreateExpenseForm.module.css';

const VcFormItems = ({
	expenseType,
	suppliers,
	voyages,
	voyageId,
	onCloseForm,
	isPortDA,
	isEditing,
	extra,
	creatingNewSupplier,
	setCreatingNewSupplier,
}: {
	voyages: GetVoyagesResponse | undefined;
	expenseType: Values<typeof ExpenseTypes>;
	suppliers: SupplierProps[] | undefined;
	voyageId: number;
	isPortDA: boolean;
	isEditing: boolean;
	extra?: React.ReactElement;
	onCloseForm: () => void;
	creatingNewSupplier: boolean;
	setCreatingNewSupplier: (state: boolean) => void;
}) => {
	const [selectedType, setSelectedType] = useState<Values<typeof ExpenseTypes> | null>(null);
	const [isAmountNegative, setIsAmountNegative] = useState(false);
	const [portCalls, setPortCalls] = useState<ItineraryPortCallDto[]>([]);
	const history = useHistory();
	const [charterers, _reloadCharterers, _error, _loading] = useFetchedState(
		() => getCounterparties(FixtureCounterpartyTypes.CHARTERER),
	);

	const selectedVoyage = (voyages ?? []).find((v) => v.id === voyageId);

	useEffect(() => {
		const fetchData = async () => {
			if (isPortDA && voyageId) {
				const result = await getVoyageItinerary(voyageId);
				const filtered = result.filter((e) => isItemPortCall(e)) as ItineraryPortCallDto[];
				setPortCalls(filtered);
			}
		};

		fetchData();
	}, [isPortDA, voyageId]);

	const { userInfo } = useAuth();

	const goToItin = () => {
		onCloseForm();
		history.push(`${Links.Voyage.get(voyageId)}/#/itinerary`);
	};

	return (
		<>
			<Form.Item
				name="type"
				label="Type"
			>
				<Select<Values<typeof ExpenseTypes>>
					placeholder="Select type"
					disabled={isEditing}
					showSearch
					allowClear
					onChange={(v) => setSelectedType(v)}
					options={[
						{ label: 'Payable', value: ExpenseTypes.PAYABLE },
						{ label: 'Payable & Receivable', value: ExpenseTypes.BOTH },
					]}
				/>
			</Form.Item>
			{expenseType == null ? (
				<>
					<p>Please select a type</p>
					<Skeleton />
				</>
			) : (
				<>
					{expenseType === ExpenseTypes.BOTH && (
						<Form.Item
							name="charterer"
							label="Charterer"
							rules={[rules.required]}
						>
							<Select
								options={charterers?.map((c) => ({
									label: c.name, value: c.id,
								}))}
								disabled={isEditing && expenseType === ExpenseTypes.BOTH}
							/>
						</Form.Item>
					)}
					<Form.Item
						name="isPortDA"
						label="Is this a port DA?"
						valuePropName="checked"
					>
						<Checkbox />
					</Form.Item>
					{isPortDA && (
						<Form.Item
							name="portCallId"
							rules={[rules.required]}
							label="Select port call"
							extra={(
								<>
									{'Don\'t see the port call you\'re looking for? Check the '}
									<Button className={styles.noPaddingButton} onClick={goToItin} type="link">
										Itinerary
									</Button>
								</>
							)}
						>
							<Select
								showSearch
								options={portCalls.map((pc) => ({
									value: pc.id,
									label: (
										<Space>
											<Tag className={styles.tag} color={pc.estimated ? 'cyan' : 'geekblue'}>
												{pc.estimated ? 'Estimated' : 'Actual'}
											</Tag>
											<CountryFlag countryCode={pc.port.countryCode} />
											{pc.port.name}
										</Space>),
								}))}
								placeholder="Select Port"
							/>
						</Form.Item>
					)}
					<Form.Item
						name="estimated"
						valuePropName="checked"
						initialValue={false}
						label={(
							<Space>
								Estimated
								<TooltipIcon>
									Estimated items are used for estimating P&L items,
									and cannot be invoiced or put through
									an approvals process.
									If you wish to invoice the item,
									convert the item to an actual by
									deselecting the &quot;estimated&quot; checkbox
								</TooltipIcon>
							</Space>
						)}
					>
						<Checkbox />
					</Form.Item>
					<Form.Item
						name="supplierId"
						label="Supplier"
					>
						<Select
							placeholder="Select Supplier"
							showSearch
							allowClear
							options={[
								{
									label: (<i>Create new...</i>),
									value: 'new',
								},
								...(suppliers ?? []).map((s) => ({
									label: s.name,
									value: s.id,
								}))]}
							onChange={(counterpartyId) => {
								setCreatingNewSupplier(counterpartyId === 'new');
							}}
						/>
					</Form.Item>
					<Form.Item
						name="supplierName"
						label="Supplier Name"
						hidden={!creatingNewSupplier}
						rules={[{ required: creatingNewSupplier, message: 'Field is missing' }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="customInvoiceId"
						label="Invoice ID"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="invoiceDate"
						label="Invoice Date"
					>
						<DatePicker
							className={styles.datepicker}
							range={false}
						/>
					</Form.Item>
					<Form.Item
						name="dueDate"
						label="Due Date"
					>
						<DatePicker
							className={styles.datepicker}
							range={false}
						/>
					</Form.Item>
					<Form.Item
						name="itemDescription"
						label="Item description"
						rules={[rules.required]}
					>
						<Input />
					</Form.Item>
					<Form.Item>
						<Form.Item
							name="amount"
							label="Invoice Amount"
							rules={[rules.required]}
						>
							<MultiCurrencyInput
								allowNegative={selectedType === ExpenseTypes.PAYABLE}
								baseCurrency={selectedVoyage?.currency || userInfo.baseCurrency}
								placeholder="Amount"
								onChange={(v) => setIsAmountNegative(v.value != null ? v.value < 0 : false)}
							/>
						</Form.Item>
						{isAmountNegative && (
							<Alert
								message={(
									<>
										<b>NOTE:</b>
										{' '}
										The amount being entered should only be negative for e.g. rebates.
									</>
								)}
							/>
						)}
					</Form.Item>
					<Form.Item
						name="note"
						label="Internal note"
					>
						<Input.TextArea />
					</Form.Item>
					{extra}
					<Button
						htmlType="submit"
						type="primary"
					>
						Save Expense
					</Button>
				</>
			)}
		</>
	);
};

export default VcFormItems;
