import React from 'react';
import { Menu } from 'antd';
import { useLocation } from 'react-router';
import { FeatureFlags } from '@shared/utils/constants';
import useFeatureFlag from '@client/utils/hooks/useFeatureFlag';
import { getDepartmentMenuItems } from '../helpers/departmentMenuItems';
import buildMenuItemComponents from '../helpers/buildMenuItemComponents';
import getMenuItems from '../helpers/getMenuItems';
import styles from './NavbarMenu.module.css';

const NavbarMenu = ({
	activeDepartment,
	isAdmin,
}) => {
	const location = useLocation();
	const showPerformance = useFeatureFlag(FeatureFlags.PERFORMANCE);

	const menuItems = getMenuItems({ showPerformance });
	const departmentMenuItemKeys = getDepartmentMenuItems(activeDepartment);
	const departmentMenuItems = departmentMenuItemKeys.map((k) => menuItems[k]);

	const availableMenuItems = departmentMenuItems.filter((item) => !item.admin || isAdmin);

	const flattenedItems = availableMenuItems.reduce((arr, i) => [
		...arr,
		i,
		...(i.children || []),
	], []);

	const activeKeys = flattenedItems
		.filter((i) => (
			(i.link === '/' && location.pathname === '/') ||
			(i.link !== '/' && location.pathname.startsWith(i.link))
		))
		.map((i) => i.link);

	return (
		<Menu
			mode="inline"
			theme="dark"
			selectedKeys={activeKeys}
			className={styles.menu}
		>
			{buildMenuItemComponents(availableMenuItems)}
		</Menu>
	);
};

export default NavbarMenu;
