import React, { useState } from 'react';
import {
	Row,
	Col,
	Grid,
	Empty,
} from 'antd';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import { formatCurrency } from '@shared/utils/currency';
import { round } from '@shared/utils/math';
import { YearQuarterDashboardPeriods } from '@shared/utils/constants';
import { formatPercentage } from '@shared/utils/formatPercentage';
import {
	getFleetPnl,
	getForwardFloatingDays,
	getHistoricalTcPerformance,
	getHistoricalUtilisation,
	getOperatingUtilisation,
} from '@client/lib/api';
import { ChartColors } from '@client/utils/constants';
import { useAuth } from '@client/lib/auth';
import Select from '@client/components/Select';
import QuickBar from '@client/components/QuickBar/QuickBar';
import FleetTimelineWidget from '../widgets/FleetTimelineWidget';
import ForwardCoverageWidget from '../widgets/ForwardCoverageWidget';
import ForwardHireNumberWidget from '../widgets/ForwardHireNumberWidget';
import ForwardHireRateNumberWidget from '../widgets/ForwardHireRateNumberWidget';
import PieChartWidget from '../widgets/PieChartWidget';
import NumberWidget from '../components/NumberWidget';
import { DashboardComponent } from '../helpers/dashboardInfo';
import ChartWidget from '../components/ChartWidget';
import styles from './TcOutDashboard.module.css';

const TcOutDashboard: DashboardComponent<YearQuarterDashboardPeriods> = ({ period }) => {
	const { userInfo } = useAuth();
	const screens = Grid.useBreakpoint();

	const [historicalDataKey, setHistoricalDataKey] = useState('revenue');

	const HistoricalKeyOptions = {
		revenue: 'TC Revenue',
		profit: 'TC Profit',
		tcProfitPerDay: 'TC Profit per day',
	};

	const HistoricalUtilisationLegend = {
		unfixedDays: 'Unfixed days',
		fixedDays: 'Fixed days',
		offhireDays: 'Off-hire days',
	};

	return (
		<>
			<Row gutter={[16, 16]} data-tour="dashboardTop">
				<Col span={24}>
					<QuickBar />
				</Col>
				<Col md={12} xl={6} xs={24}>
					<NumberWidget
						title="TC Profit"
						headerTooltip={(
							<div className={styles.formulaTooltip}>
								+ Revenue
								{'\n'}
								- Voyage Expenses
								{'\n'}
								- CAPEX
								{'\n'}
								- OPEX
								{'\n'}
								- TC-In costs
								{'\n'}
								= Profit
							</div>
						)}
						badge="NET"
						transformData={(data) => formatCurrency(
							round(data.profit ?? 0, 0),
							userInfo.baseCurrency,
						)}
						fetchData={getFleetPnl}
						fetchDataParams={[period]}
					/>
				</Col>
				<Col md={12} xl={6} xs={24}>
					<NumberWidget
						title="Avg. TC Profit per day"
						badge="NET"
						headerTooltip={(data) => (data != null ? (
							`Profit of ${formatCurrency(round(data.profit ?? 0, 0), userInfo.baseCurrency)} divided by ${round(data.totalVesselDays)} total vessel days`
						) : undefined)}
						transformData={(data) => {
							if (
								typeof data.profit === 'number' &&
								data.profit > 0 &&
								typeof data?.totalVesselDays === 'number'
							) {
								return formatCurrency(
									round((data?.profit) / (data?.totalVesselDays), 0),
									userInfo?.baseCurrency!,
								);
							}

							return '0';
						}}
						fetchData={getFleetPnl}
						fetchDataParams={[period]}
					/>
				</Col>
				<Col md={12} xl={6} xs={24}>
					<ForwardHireNumberWidget />
				</Col>
				<Col md={12} xl={6} xs={24}>
					<ForwardHireRateNumberWidget />
				</Col>
				<Col md={12} xl={8} xs={24}>
					<PieChartWidget
						entries={[
							{
								label: 'Fixed days',
								key: 'fixedDays',
								fill: ChartColors.BLUE,
							},
							{
								label: 'Unfixed days',
								key: 'unfixedDays',
								fill: ChartColors.CYAN,
							},
							{
								label: 'Off-hire days',
								key: 'offHireDays',
								fill: ChartColors.RED,
							},
						]}
						fetchData={getOperatingUtilisation}
						fetchDataParams={[period]}
						unit="days"
						roundTotal
						header="Fleet Utilisation"
					/>
				</Col>
				<Col md={12} xl={8} xs={24}>
					<ForwardCoverageWidget />
				</Col>
				<Col md={24} xl={8} xs={24}>
					<PieChartWidget
						header="Exposure to Floating Hire"
						headerTooltip="The number of forward days currently on floating hire versus fixed hire"
						fetchData={getForwardFloatingDays}
						fetchDataParams={[period]}
						unit="days"
						roundTotal
						forward
						entries={[
							{
								label: 'Fixed hire',
								key: 'fixedHireDays',
								fill: ChartColors.BLUE,
							},
							{
								label: 'Floating hire',
								key: 'floatingHireDays',
								fill: ChartColors.LIGHTBLUE,
							},
						]}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]} className={styles.marginTop}>
				{screens.md && (
					<Col span={24}>
						<FleetTimelineWidget />
					</Col>
				)}
				<Col xl={24} xxl={12}>
					<ChartWidget
						header="Historical Performance"
						fetchData={getHistoricalTcPerformance}
						extra={(
							<Select
								placeholder="Select Metric"
								className={styles.selector}
								allowClear={false}
								value={historicalDataKey}
								onChange={(key) => key != null && setHistoricalDataKey(key)}
								options={Object.keys(HistoricalKeyOptions).map((key) => ({
									label: HistoricalKeyOptions[key],
									value: key,
								}))}
							/>
						)}
						fetchDataParams={[period]}
						renderChart={(data) => {
							if (data == null) {
								return (<Empty />);
							}

							return (
								<BarChart
									data={data}
									margin={{
										top: 20,
										right: 30,
										left: 75,
										bottom: 5,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="label" />
									<YAxis tickFormatter={(tick) => (formatCurrency(tick, userInfo.baseCurrency))} />
									<Tooltip
										formatter={(value: number, key: string) => [
											formatCurrency(value, userInfo.baseCurrency),
											HistoricalKeyOptions[key],
										]}
										cursor={false}
									/>
									<Legend formatter={(tick) => HistoricalKeyOptions[tick]} />
									<Bar dataKey={historicalDataKey} stackId="a" fill={ChartColors.BLUE} />
								</BarChart>
							);
						}}
					/>
				</Col>
				<Col xl={24} xxl={12}>
					<ChartWidget
						header="Historical Utilisation"
						fetchData={getHistoricalUtilisation}
						fetchDataParams={[period]}
						renderChart={(data) => {
							if (data == null) {
								return (<Empty />);
							}

							return (
								<BarChart
									data={data}
									margin={{
										top: 20,
										right: 30,
										left: 75,
										bottom: 5,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="label" />
									<YAxis
										domain={[0, 100]}
										tickFormatter={(tick) => formatPercentage(round(tick, 0)) ?? '0%'}
									/>
									<Tooltip
										formatter={(value: number, key: string) => [
											formatPercentage(round(value, 2)),
											HistoricalUtilisationLegend[key],
										]}
										cursor={false}
									/>
									<Legend formatter={(key) => HistoricalUtilisationLegend[key]} />
									<Bar dataKey="unfixedDays" stackId="a" fill={ChartColors.LIGHTBLUE} />
									<Bar dataKey="fixedDays" stackId="a" fill={ChartColors.BLUE} />
									<Bar dataKey="offhireDays" stackId="a" fill={ChartColors.RED} />
								</BarChart>
							);
						}}
					/>
				</Col>
			</Row>
		</>
	);
};

export default TcOutDashboard;

