import { nowMoment } from '@shared/utils/date';
import { SofActions } from '@shared/utils/constants';
import type { SofItemProps } from '@api/models/sof-item';
import type { LaytimeCalculationProps } from '@api/models/laytime-calculation';

export const getEmptyLaytimeCalc = (
	cargoPortId: number,
	tzOffset: number,
	toCount: boolean,
): Omit<
	LaytimeCalculationProps, 'createdAt' | 'updatedAt' | 'deletedAt'
> & {
	SofItems: SofItemProps[];
	createdAt: Date;
	updatedAt: Date;
	deletedAt: null;
} => {
	return {
		id: -1,
		SofItems: [{
			laytimeCalculationId: -2,
			date: nowMoment().utcOffset(tzOffset),
			action: SofActions.ANCHORED,
			toCount,
			percentageToCount: 0,
			timeUsedInMinutes: 0,
		}],
		cargoPortId,
		timeUsedInMinutes: 0,
		createdAt: new Date(),
		updatedAt: new Date(),
		deletedAt: null,
	};
};
