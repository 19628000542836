import React from 'react';
import {
	PnlItemTypes,
	PnlRenderTypes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import type{ TcFixtureProps } from '@api/models/tc-fixture';
import type {
	TcPnl,
	VoyagePnl,
} from '@api/utils/sequelize/getVoyagePnl';
import { getEstimateData } from '@client/utils/getEstimateData';
import { combinePnlActualEstimate } from './combinePnlActualEstimate';

type Props = {
	pnl: VoyagePnl | null;
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps>;
	vessel: GetVesselDetailsResponse;
	// eslint-disable-next-line no-undef
	estimate: Awaited<ReturnType<typeof getEstimateData>>;
}

export const getPnlData = ({
	pnl,
	fixtureDetails,
	vessel,
	estimate,
}: Props) => {
	let revenue;
	let expenses;
	let tce;
	let summary;

	const {
		profit,
		totalRevenue,
		totalExpenses,
		tcInCost,
		capexCost,
		opexCost,
		grossHire,
		grossBallastBonus,
		addressCommissions,
		brokerCommissions,
		numberOfDays,
		bunkerBalance,
		expensesSubjectToHireDays,
		revenueSubjectToDays,
		offHire,
		ownerExpenses,
		chartererExpenses,
		tce: tceResult,
		netProfit,
		netTce,
		totalRepositioningExpenditure,
	} = pnl as TcPnl;

	revenue = [
		{
			type: PnlItemTypes.GROSS_HIRE,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Gross Hire',
			amount: grossHire,
		},
		{
			type: PnlItemTypes.GROSS_BALLAST_BONUS,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Gross Ballast Bonus',
			amount: Number(grossBallastBonus),
		},
		{
			type: PnlItemTypes.ADDRESS_COMMISSIONS,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Add. Commissions',
			amount: addressCommissions,
		},
		{
			type: PnlItemTypes.BROKER_COMMISSIONS,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Broker Commissions',
			amount: brokerCommissions,
		},
		{
			type: PnlItemTypes.EXPENSES_SUBJECT_TO_HIRE_DAYS,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Expenses Subject to Hire Days',
			amount: expensesSubjectToHireDays,
		},
		{
			type: PnlItemTypes.REVENUE_SUBJECT_TO_DAYS,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Revenue Subject To Days',
			amount: revenueSubjectToDays,
		},
		{
			type: PnlItemTypes.REIMBURSABLE_EXPENSES,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Charterer\'s Expenses',
			amount: chartererExpenses,
		},
		{
			type: PnlItemTypes.BUNKER_LOSS_GAIN,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Bunker Gain/(Loss)',
			amount: bunkerBalance,
		},
		{
			type: PnlItemTypes.TOTAL_REVENUE,
			renderType: PnlRenderTypes.CURRENCY,
			desc: (<b>Total Revenue</b>),
			amount: totalRevenue,
			bold: true,
		},
	];

	expenses = [
		{
			type: PnlItemTypes.PAYABLE_EXPENSES,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Owner\'s Expenses',
			amount: ownerExpenses,
		},
		{
			type: PnlItemTypes.OFF_HIRE,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Off-Hire',
			amount: offHire,
		},
		{
			type: PnlItemTypes.REPOSITIONING_BUNKERS,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Repositioning Bunkers',
			amount: totalRepositioningExpenditure,
		},
		{
			type: PnlItemTypes.TOTAL_EXPENSES,
			renderType: PnlRenderTypes.CURRENCY,
			desc: (<b>Total Expenses</b>),
			amount: totalExpenses,
			bold: true,
		},
	];

	summary = [
		{
			type: PnlItemTypes.DAYS,
			renderType: null,
			desc: 'Total Days',
			amount: round(numberOfDays),
		},
		{
			type: PnlItemTypes.FINAL_VOYAGE_PROFIT,
			renderType: PnlRenderTypes.CURRENCY,
			desc: (<b>TC Earnings</b>),
			amount: profit,
			bold: true,
		},
		{
			type: PnlItemTypes.FINAL_VOYAGE_PROFIT_PER_DAY,
			renderType: PnlRenderTypes.CURRENCY,
			desc: (<b>TC Earnings Per Day</b>),
			amount: tceResult,
			bold: true,
		},
		...(vessel.ownershipType === VesselOwnershipTypes.OWNED ? [
			{
				type: PnlItemTypes.CAPEX,
				renderType: PnlRenderTypes.CURRENCY,
				desc: 'CAPEX',
				amount: capexCost,
			},
			{
				type: PnlItemTypes.OPEX,
				renderType: PnlRenderTypes.CURRENCY,
				desc: 'OPEX',
				amount: opexCost,
			},
		] : []),
		{
			type: PnlItemTypes.TC_IN_COSTS,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Net Hire Cost',
			amount: tcInCost,
		},
		{
			type: PnlItemTypes.NET_RESULT,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Net Result',
			amount: netProfit,
		},
		{
			type: PnlItemTypes.NET_RESULT_PER_DAY,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Net Result Per Day',
			amount: netTce,
		},
	];

	const elapsedDays = summary[0].amount;
	const estimatedDays = (estimate?.summary[0]?.amount) ?? 0;

	revenue = combinePnlActualEstimate({
		actualData: revenue,
		estimateData: estimate.revenue,
		elapsedDays,
		estimatedDays,
		currency: fixtureDetails.bankAccount.currency,
	});

	expenses = combinePnlActualEstimate({
		actualData: expenses,
		estimateData: estimate.expenses,
		elapsedDays,
		estimatedDays,
		currency: fixtureDetails.bankAccount.currency,
	});

	if (tce != null && 'tce' in estimate) {
		tce = combinePnlActualEstimate({
			actualData: tce,
			// @ts-ignore
			estimateData: estimate.tce,
			elapsedDays,
			estimatedDays,
			currency: fixtureDetails.bankAccount.currency,
		});
	}

	summary = combinePnlActualEstimate({
		actualData: summary,
		// @ts-ignore
		estimateData: estimate.summary,
		elapsedDays,
		estimatedDays,
		currency: fixtureDetails.bankAccount.currency,
	});

	return {
		revenue,
		expenses,
		tce: tce ?? [],
		summary,
	};
};
