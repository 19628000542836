import React, {
	useState,
	useMemo,
	useEffect,
	useCallback,
	useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
/* eslint-disable no-restricted-imports */
import {
	Empty,
	Table as AntTable,
	Space,
	Card,
	Row as AntRow,
	Col,
	Input,
} from 'antd';
import classNames from 'classnames';
import { TableProps as AntTableProps } from 'antd/lib/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	ExportOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import useSearch from 'react-hook-search';
import { CSVLink } from 'react-csv';
import { ColumnType } from 'antd/es/table';
import { ObjectKey } from '@shared/utils/ObjectKey';
import { formatDate } from '@shared/utils/date';
import Select, { SelectOption } from '@client/components/Select';
/* eslint-enable no-restricted-imports */
import Button from '../Button';
import styles from './Table.module.css';
import useFixedRows from './private/useFixedRows';

// I don't know what type Value should be :/
type Filter<Row, Value = unknown> = {
	key: string;
	label: string;
	allLabel: string;
	multiple: boolean;
	onFilter: (value: Value, row: Row, index: number) => boolean;
	options: SelectOption[];
}

export type TableProps<Row> = {
	onRowClick?: (row: Row) => void;
	getRowHref?: (row: Row) => string;
	filters?: Filter<Row>[];
	onChangeFilteredData?: (newFilteredData: Row[]) => void;
	emptyText?: string | React.ReactNode;
	fixedRows?: Row[];
	fixRowsFrom?: 'bottom';
	extraLeft?: React.ReactNode;
	extraRight?: React.ReactNode;
	useCards?: boolean;
	showSearch?: boolean;
	searchAttributes?: string[];
	extraArea?: React.ReactNode;
	showExportToCsv?: boolean;
	customFixedRowEventListeners?: {
		key: string;
		callback: () => void;
	};
} & AntTableProps<Row>;

const Table = <Row extends object>({
	onRow: onRowProp,
	onRowClick,
	getRowHref,
	rowClassName: rowClassNameProp,
	locale,
	filters = [],
	onChangeFilteredData,
	emptyText,
	className,
	dataSource = [],
	fixedRows = [],
	fixRowsFrom = 'bottom',
	extraLeft = null,
	extraRight = null,
	columns,
	useCards = false,
	searchAttributes = [],
	showSearch = false,
	showExportToCsv = false,
	extraArea,
	customFixedRowEventListeners,
	...props
}: TableProps<Row>) => {
	if (fixRowsFrom !== 'bottom') {
		throw new Error('Can only fix rows from bottom (to do?)');
	}

	const history = useHistory();

	const [filterValues, setFilterValues] = useState({});
	const [tableFilters, setTableFilters] = useState({});

	const previousFilteredData = useRef<Row[]>();

	const {
		tableClassName,
		fixedRowClassName,
	} = useFixedRows(fixedRows, customFixedRowEventListeners);

	const [shownItems, searchValue, setSearchValue] = useSearch(dataSource as [], searchAttributes);

	const filterHasValue = useCallback((filterValue: any): boolean => (
		(!Array.isArray(filterValue) && filterValue != null) ||
		(Array.isArray(filterValue) && !filterValue.includes(null))
	), []);

	const getRowClasses = (row: Row, _index: number) => ({
		[styles.clickableRow]: typeof getRowHref === 'function' || typeof onRowClick === 'function',
		[fixedRowClassName]: fixedRows.includes(row),
	});

	useEffect(() => {
		if (columns == null) {
			return;
		}

		columns.forEach((c) => {
			if (c.filteredValue != null) {
				setTableFilters({ ...tableFilters, [c.key as ObjectKey]: c.filteredValue });
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columns]);

	const rowClassName = (row: Row, index: number) => {
		const rowClasses = getRowClasses(row, index);

		if (typeof rowClassNameProp === 'function') {
			return classNames(rowClasses, rowClassNameProp(row, index, 0));
		}

		return classNames(rowClasses);
	};

	const filteredData = useMemo(() => {
		if (shownItems == null) {
			return [];
		}

		const activeFilterKeys = Object.entries(filterValues)
			.filter(([_key, value]) => filterHasValue(value))
			.map(([key]) => key);

		if (activeFilterKeys.length === 0) {
			return shownItems;
		}

		const activeFilters = filters.filter((f) => activeFilterKeys.includes(f.key));

		return shownItems.filter((row, index) => (
			activeFilters.every((filter) => filter.onFilter(filterValues[filter.key], row, index))
		));
	}, [shownItems, filterValues, filters, filterHasValue]);

	const dataToExport = useMemo(() => {
		if (filteredData == null) {
			return [];
		}

		return filteredData.map((row) => {
			const newRow = {};

			if (columns != null) {
				columns.forEach((c: ColumnType<Row>) => {
					if (c.dataIndex != null) {
						if (Array.isArray(c.dataIndex)) {
							if (c.dataIndex.length === 0) {
								return;
							}

							if (c.dataIndex.length === 1) {
								newRow[c.dataIndex[0]] = row[c.dataIndex[0]];
							} else {
								const firstLayer = row[c.dataIndex[0]];
								const nextLayer = firstLayer != null ? firstLayer[c.dataIndex[1]] : null;
								newRow[`${c.dataIndex[0]}_${c.dataIndex[1]}`] = nextLayer;
							}
						} else {
							// @ts-ignore
							newRow[c.dataIndex] = row[c.dataIndex];
						}
					}
				});
			}

			return newRow;
		});
	}, [filteredData, columns]);

	useEffect(() => {
		if (
			previousFilteredData.current == null &&
			filteredData === shownItems
		) {
			return;
		}

		previousFilteredData.current = filteredData as Row[];

		if (typeof onChangeFilteredData === 'function') {
			onChangeFilteredData(filteredData as Row[]);
		}
	}, [shownItems, filteredData, filterValues, onChangeFilteredData]);

	const onRow = useCallback((record: Row) => {
		const rowProps = (onRowProp ? onRowProp(record) : {});

		return {
			...rowProps,
			onClick: () => {
				if (typeof getRowHref === 'function') {
					history.push(getRowHref(record));
				}

				if (typeof onRowClick === 'function') {
					onRowClick(record);
				}
			},
		};
	}, [getRowHref, history, onRowClick, onRowProp]);

	const changeFilterValue = (key: string, value: any) => {
		let newValue = value;

		if (Array.isArray(value)) {
			const newlyAdded = value[value.length - 1];

			if (newlyAdded === null || value.length === 0) {
				newValue = [null];
			} else {
				newValue = value.filter((v) => v != null);
			}
		}

		setFilterValues((v) => ({
			...v,
			[key]: newValue,
		}));
	};

	let emptyContent;

	if (typeof emptyText === 'string') {
		emptyContent = (
			<>
				<Empty
					image={Empty.PRESENTED_IMAGE_SIMPLE}
					description={emptyText}
				/>
			</>
		);
	} else if (emptyText != null) {
		emptyContent = (<div>{emptyText}</div>);
	}

	if (useCards) {
		const hasLink = getRowHref != null && typeof getRowHref === 'function';

		return (
			<>
				{shownItems == null || columns == null || shownItems.length === 0 ? (
					<Empty description={emptyText} />
				) : (
					<AntRow gutter={[16, 16]}>
						{shownItems.map((entry, index) => (
							<Col span={24}>
								<Card
									className={hasLink ? styles.noBottomPadding : ''}
									size="small"
								>
									<AntRow className={hasLink ? styles.rowWithPadding : ''} gutter={[2, 4]}>
										{columns.map((column) => {
											let value;

											// @ts-ignore
											const dataIndex = column?.dataIndex;

											value = dataIndex == null ? null : entry[dataIndex];

											if (
												column.render != null &&
												typeof column.render === 'function' &&
												entry != null
											) {
												value = column.render(value, entry, index);
											}

											const { title } = column;

											return (
												<>
													<Col span={10}>
														{title != null && title !== '' && (
															<b>{`${title}:`}</b>
														)}
													</Col>
													<Col span={14}>
														{/* @ts-ignore */}
														{value ?? 'N/A'}
													</Col>
												</>
											);
										})}
									</AntRow>
									{hasLink && (
										<Button
											onClick={() => history.push(getRowHref(entry))}
											className={styles.bottomButton}
										>
											<Space>
												Go to
												<FontAwesomeIcon icon={faArrowRight as IconProp} />
											</Space>
										</Button>
									)}
								</Card>
							</Col>
						))}
					</AntRow>
				)}
			</>
		);
	}

	return (
		<div>
			<div className={styles.tablePropArea}>
				{showSearch && (
					<Col span={8} className={styles.searchBar}>
						<Input
							onChange={setSearchValue}
							value={searchValue}
							prefix={(
								<SearchOutlined className={styles.searchIcon} />
							)}
							placeholder="Search..."
							className={styles.searchBar}
							allowClear
						/>
					</Col>
				)}
				<div>
					{showExportToCsv && (
						<CSVLink
							key="export-csv"
							data={dataToExport}
							filename={`ClearVoyage export ${formatDate(new Date())}.csv`}
						>
							<Button
								icon={(<ExportOutlined />)}
							>
								Export to CSV
							</Button>
						</CSVLink>

					)}
					{extraArea}
				</div>
			</div>
			{filters != null && filters.length > 0 && (
				<div className={styles.top}>
					<Space className={styles.filters}>
						{filters.map((f) => (
							<div key={f.key} className={styles.filterWrapper}>
								<span className={styles.filterLabel}>{f.label}</span>
								<Select
									key={f.key}
									value={filterValues[f.key] || (
										f.multiple ?
											[null] :
											null
									)}
									onChange={(v) => {
										changeFilterValue(f.key, v);
									}}
									dropdownMatchSelectWidth={false}
									mode={f.multiple ? 'multiple' : undefined}
									showArrow
									maxTagCount={0}
									showSearch={false}
									className={filterHasValue(filterValues[f.key]) ? styles.bold : undefined}
									maxTagPlaceholder={(values) => (
										<span className={styles.filterTag}>
											{filterHasValue(filterValues[f.key]) ? (
												<strong>{`${values.length} selected`}</strong>
											) : (
												f.allLabel || 'All values'
											)}
										</span>
									)}
									options={[
										{
											label: f.allLabel || 'All values',
											value: null,
										},
										...(f.options ?? []).map((o) => ({
											label: o.label,
											value: o.value,
										})),
									]}
								/>
							</div>
						))}
						<div>{extraLeft}</div>
					</Space>
					<div>{extraRight}</div>
				</div>
			)}
			<AntTable<Row>
				className={classNames(
					styles.table,
					tableClassName,
					className,
				)}
				showSorterTooltip={false}
				dataSource={[...filteredData, ...fixedRows]}
				columns={(columns ?? []).map((c) => ({
					...c,
					filteredValue: (c.key != null && tableFilters[c.key.toString()] != null) ?
						tableFilters[c.key.toString()] :
						[],
				}))}
				{...props}
				onChange={(
					pagination,
					incomingFilters,
					sorter,
					extra,
				) => {
					if (typeof props.onChange === 'function') {
						props.onChange(pagination, incomingFilters, sorter, extra);
					}

					setTableFilters(incomingFilters);
				}}
				locale={{
					emptyText: emptyContent,
					...(locale || {}),
				}}
				rowClassName={rowClassName}
				onRow={onRow}
			/>
		</div>
	);
};

export default Table;
