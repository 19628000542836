import React, {
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	faBooks,
	faBoxesStacked,
	faChartPieAlt,
	faDollar,
	faDonate,
	faFileInvoiceDollar,
	faGasPump,
	faList,
	faListTimeline,
	faStopwatch,
	faUserTie,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Typography } from 'antd';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import {
	AccountTypes,
	AttachmentTypes,
	Currencies,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import { sortByDates } from '@shared/utils/sortByDates';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getCargosAndLaytimes,
	getVoyageExpenses,
	getVoyageInvoices,
	getVoyageItinerary,
} from '@client/lib/api';
import AttachmentLibrary from '@client/components/AttachmentLibrary';
import DetailsTabScreen from '@client/components/screens/DetailsTabScreen';
import { Links } from '@client/utils/links';
import VesselDescription from '@client/components/VesselDescription';
import showErrorNotification from '@client/utils/showErrorNotification';
import VcSummaryTab from './components/tabs/VcSummaryTab';
import VcExpensesTab from './components/VcExpensesTab';
import ItineraryTab from './components/ItineraryTab/ItineraryTab';
import FreightTab from './tabs/FreightTab';
import ClaimsTab from './tabs/ClaimsTab';
import FreightInvoices from './components/tabs/FreightInvoices';
import CommissionsTab from './components/CommissionsTab/CommissionsTab';
import VcPnlTab from './components/ProfitAndLossTab/VcPnlTab';
import BunkerExpenditureTab from './tabs/BunkerExpenditureTab/BunkerExpenditureTab';
import HeaderAction from './components/HeaderAction';
import VcStatementOfAccountsTab from './components/VcStatementOfAccountsTab';

const VcContractDetailsScreen = ({
	voyageDetails,
	fixtureDetails,
	refreshVoyageDetails,
	refreshFixtureDetails,
	currency,
}: {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<SpotFixtureProps>;
	refreshVoyageDetails: () => void;
	refreshFixtureDetails: () => void;
	currency: Values<typeof Currencies>;

}) => {
	const tabRef = useRef<HTMLDivElement>(null);
	const [startDate, setStartDate] = useState<string | null>(
		toMoment(voyageDetails?.commencementDate ?? nowMoment()).subtract(1, 'week').toISOString() || null,
	);
	const [endDate, setEndDate] = useState<string | null>(
		toMoment(voyageDetails?.completionDate ?? nowMoment()).toISOString() || null,
	);

	const [
		voyageInvoices,
		refreshVoyageInvoices,
		_voyageInvoiceError,
		voyageInvoicesLoading,
	] = useFetchedState(async () => {
		try {
			const invoices = await getVoyageInvoices(voyageDetails.id);

			return (invoices ?? []).map((hireInvoice) => ({
				...hireInvoice,
				items: hireInvoice.items.map((i) => HireInvoiceItem.fromJSON(i)),
			}));
		} catch (e) {
			showErrorNotification('Could not load voyage invoices', e as Error);

			return [];
		}
	});

	const [
		itinerary,
		refreshItinerary,
		itineraryError,
		itineraryLoading,
	] = useFetchedState(async () => (voyageDetails == null ? [] : getVoyageItinerary(
		Number(voyageDetails.id),
	)), [startDate, endDate, voyageDetails]);

	const [cargos, refreshCargos] = useFetchedState(
		() => getCargosAndLaytimes(fixtureDetails.id),
		[],
		{ autoRefresh: false },
	);

	const [vendorExpenses, refreshVendorExpenses, _error, _loading] = useFetchedState(
		getVoyageExpenses,
	);

	const expenses = useMemo(() => {
		if (!voyageDetails?.id) {
			return [];
		}

		const sortedByDate = sortByDates(voyageDetails.voyageExpenseReceivables, 'createdAt');

		return sortedByDate.sort((a, b) => a.account.localeCompare(b.account));
	}, [voyageDetails]);

	const refreshDetails = async () => {
		refreshVoyageDetails();
		refreshFixtureDetails();
		refreshVendorExpenses();
		refreshVoyageInvoices();
		refreshItinerary();
		refreshCargos();
	};

	const spotShowCommissionsTab = useMemo(() => {
		if (fixtureDetails?.type === 'spot') {
			return fixtureDetails.cargos
				.some((cargo) => cargo.Brokers
					.some((b) => b.BrokerInCargo.paidBy === AccountTypes.OWNER));
		}

		return false;
	}, [fixtureDetails?.cargos, fixtureDetails?.type]);

	const tabs = voyageDetails == null || fixtureDetails == null ? [] : [
		{
			key: 'summary',
			title: 'Summary',
			icon: faChartPieAlt as IconProp,
			render: () => (
				<VcSummaryTab
					itinerary={itinerary}
					itineraryLoading={itineraryLoading}
					voyageDetails={voyageDetails}
					fixtureDetails={fixtureDetails as typeof fixtureDetails & { estimateId: number }}
					refreshDetails={refreshDetails}
				/>
			),
		},
		{
			key: 'expenses',
			title: 'Expenses',
			forceRender: true,
			icon: faDonate as IconProp,
			render: () => (
				<VcExpensesTab
					fixtureDetails={fixtureDetails}
					voyageDetails={voyageDetails}
					fixtureCurrency={currency}
					refreshDetails={refreshDetails}
					expenses={expenses}
					vendorExpenses={vendorExpenses}
					refreshVendorExpenses={refreshVendorExpenses}
				/>
			),
		},
		{
			key: 'itinerary',
			title: 'Itinerary',
			icon: faListTimeline as IconProp,
			render: () => (
				<ItineraryTab
					startDate={startDate}
					setStartDate={setStartDate}
					itineraryError={itineraryError}
					itineraryLoading={itineraryLoading}
					endDate={endDate}
					setEndDate={setEndDate}
					itinerary={itinerary}
					voyageDetails={voyageDetails}
					refreshDetails={refreshDetails}
				/>
			),
		},
		{
			key: 'freight',
			title: 'Freight',
			icon: faBoxesStacked as IconProp,
			render: () => (
				<FreightTab cargos={cargos} refreshDetails={refreshDetails} />
			),
		},
		{
			key: 'claims',
			title: 'Claims',
			icon: faStopwatch as IconProp,
			render: () => (
				<ClaimsTab
					fixtureId={fixtureDetails.id}
					voyageId={voyageDetails.id}
					itinerary={itinerary ?? []}
					refreshDetails={refreshDetails}
					cargos={cargos}
				/>
			),
		},
		{
			key: 'invoices',
			title: 'Invoices',
			icon: faFileInvoiceDollar as IconProp,
			render: () => (
				<FreightInvoices
					voyageDetails={voyageDetails}
					refreshDetails={refreshDetails}
					fixtureDetails={
						fixtureDetails as
						GetFixtureDetailsResponse<SpotFixtureProps>
					}
					voyageInvoices={voyageInvoices ?? []}
					voyageInvoicesLoading={voyageInvoicesLoading}
				/>
			),
		},
		...(spotShowCommissionsTab ? [{
			key: 'commissions',
			title: 'Commissions',
			icon: faUserTie as IconProp,
			render: () => (
				<CommissionsTab
					fixtureDetails={fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>}
					voyageDetails={voyageDetails}
					refreshDetails={refreshDetails}
				/>
			),
		}] : []),
		{
			key: 'pnl',
			title: 'P&L',
			icon: faDollar as IconProp,
			render: () => (
				<VcPnlTab
					currency={currency}
					id={voyageDetails.id}
				/>
			),
		},
		{
			key: 'bunkers',
			title: 'Bunkers',
			icon: faGasPump as IconProp,
			render: () => (
				<BunkerExpenditureTab
					vessel={voyageDetails.vessel}
					voyageDetails={voyageDetails}
					refreshVoyageDetails={refreshDetails}
					fixtureDetails={fixtureDetails}
				/>
			),
		},
		{
			key: 'documents',
			title: 'Documents',
			icon: faBooks as IconProp,
			render: () => (
				<AttachmentLibrary
					attachments={voyageDetails.attachments}
					refreshAttachments={refreshDetails}
					type={AttachmentTypes.VOYAGE}
					attachToId={voyageDetails.id}
				/>
			),
		},
		{
			key: 'soa',
			title: 'SoA',
			icon: faList as IconProp,
			render: ({ active }: {active: boolean}) => (
				<VcStatementOfAccountsTab
					voyageDetails={voyageDetails}
					focused={active}
					fixtureDetails={
						fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>
					}
				/>
			),
		},
	];

	return (
		<DetailsTabScreen
			rootPageTitle="Contract - TC"
			tabContainerRef={tabRef}
			defaultTabKey="summary"
			canGoBack
			tabsProps={{ destroyInactiveTabPane: true }}
			tabs={tabs}
			breadcrumbs={
				[[
					'Contracts - VC-Out',
					Links.Voyages.get(),
				]]
			}
			title={(
				<Typography.Title level={4}>
					<VesselDescription
						name={voyageDetails?.vesselName}
						flag={voyageDetails?.vesselFlag}
					/>
					{' | '}
					{voyageDetails?.identifier}
				</Typography.Title>
			)}
			extra={(
				<HeaderAction
					fixtureDetails={fixtureDetails}
					details={voyageDetails}
					refreshDetails={refreshDetails}
				/>
			)}
		/>
	);
};

export default VcContractDetailsScreen;
