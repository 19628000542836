import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { faInbox } from '@fortawesome/pro-light-svg-icons';
import { Moment } from 'moment';
import {
	Checkbox,
	Tag,
	Grid,
} from 'antd';
import { Link } from 'react-router-dom';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Currencies,
	DATE_AND_TIME,
	EstimateStatus,
	FixtureTypes,
} from '@shared/utils/constants';
import { toMoment } from '@shared/utils/date';
import { Values } from '@shared/utils/objectEnums';
import { standardSort } from '@shared/utils/standardSort';
import { round } from '@shared/utils/math';
import { formatCurrency } from '@shared/utils/currency';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	copyEstimate,
	createEstimate,
	getEstimateGroups,
	getVessels,
} from '@client/lib/api';
import { renderDate } from '@client/utils/table';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import { Links } from '@client/utils/links';
import { FormPopoverProps } from '@client/components/FormPopover';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import Button from '@client/components/Button';
import VesselDescription from '@client/components/VesselDescription';
import getVesselOptions from '@client/utils/getVesselOptions';
import styles from './EstimatesListPage.module.css';

type EstimateOverviewTableRow = {
	id: number;
	estimateGroupId: number;
	firstEstimateId: number;
	name: string;
	vesselName: string | null;
	vesselFlag: string | null;
	createdAt: Moment;
	tce: number | null;
	currency: Values<typeof Currencies>;
};

type CreateEstimateFormData = {
	name: string;
	currency: Values<typeof Currencies>;
	vesselId?: number;
}

const EstimatesListPage: React.FC = () => {
	const [estimateGroups, refresh, error, loading] = useFetchedState(getEstimateGroups);
	const [toCompare, setToCompare] = useState<Array<number>>([]);

	const [vessels] = useFetchedState(getVessels);

	const screens = Grid.useBreakpoint();

	const estimateRows: EstimateOverviewTableRow[] = useMemo(() => (
		estimateGroups?.map((e) => ({
			id: e.id,
			firstEstimateId: e.firstEstimateId,
			estimateGroupId: e.id,
			name: e.groupName,
			vesselName: e.vesselName ?? null,
			vesselFlag: e.vesselFlag,
			createdAt: toMoment(e.createdAt),
			tce: e?.tce ?? null,
			currency: e.currency ?? null,
			status: e.status ?? null,
		})) ?? []
	), [estimateGroups]);

	const getStatusColor = (status: EstimateStatus) => {
		if (status === EstimateStatus.Available) {
			return 'green';
		}

		if (status === EstimateStatus.Working) {
			return 'blue';
		}

		if (status === EstimateStatus.Subjects) {
			return 'orange';
		}

		return 'red';
	};

	const columns: ColumnsType<EstimateOverviewTableRow> = useMemo(() => [
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status) => (
				<Tag color={getStatusColor(status)}>
					{EstimateStatus[status]}
				</Tag>
			),
		},
		// Name
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: '30%',
		},
		// Vessel
		{
			title: 'Vessel',
			dataIndex: 'vesselName',
			key: 'vesselName',
			sorter: standardSort('vesselName'),
			render: (_, est) => (
				<VesselDescription
					name={est.vesselName ?? '—'}
					flag={est.vesselFlag}
				/>
			),
			width: '25%',
		},
		// Created at
		{
			title: 'Creation Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			sortOrder: 'descend',
			sorter: standardSort('createdAt'),
			render: renderDate(DATE_AND_TIME),
			width: '20%',

		},
		// TCE
		{
			title: 'TCE (per day)',
			dataIndex: 'tce',
			key: 'tce',
			sorter: standardSort('tce'),
			width: '25%',
			render: (value, row) => {
				if (value == null) {
					return '—';
				}

				const roundedValue = round(value, 0);

				if (row.currency == null) {
					return roundedValue;
				}

				return formatCurrency(roundedValue, row.currency);
			},
		},
		{
			title: 'Action',
			render: (_, row) => (
				<Button
					onClick={async (e: any) => {
						e.stopPropagation();
						await copyEstimate(row.id);
						await refresh();
					}}
					icon={(
						<FontAwesomeIcon
							className={styles.icon}
							icon={faCopy as IconProp}
						/>
					)}
				>
					<b>Copy</b>
				</Button>
			),
		},
		{
			title: 'Compare',
			onCell: () => {
				return {
					onClick: (e: any) => e.stopPropagation(),
				};
			},
			render: (_entry, record) => (
				<Checkbox
					checked={toCompare.find((e) => e === record.id) != null}
					onClick={(e) => {
						e.stopPropagation();
					}}
					onChange={(e) => {
						if (e.target.checked) {
							setToCompare([...toCompare, record.id]);
						} else {
							setToCompare(toCompare.filter((entry) => entry !== record.id));
						}
					}}
				/>
			),
			align: 'center',
		},
	], [toCompare, refresh]);

	const submitCreateEstimateForm = useCallback(async (values: CreateEstimateFormData) => {
		const estimate = await createEstimate(values);
		showSuccessNotification(
			'Estimate created',
			'The estimate was successfully created',
		);

		return estimate;
	}, []);

	const formFields: FormPopoverProps<CreateEstimateFormData>['fields'] = useMemo(() => [
		{
			label: 'Name',
			name: 'name',
			type: 'text',
			required: true,
		},
		{
			label: 'Vessel',
			name: 'vesselId',
			type: 'select',
			required: false,
			options: (vessels != null ? [
				{
					label: 'No vessel',
					value: -1,
				},
				{
					label: 'My vessels',
					options: getVesselOptions(vessels, 'myVessels'),
				},
				{
					label: 'Market vessels',
					options: getVesselOptions(vessels, 'marketVessels'),
				},
			] : []),
			inputProps: {
				showSearch: true,
			},
		},
		{
			label: 'Currency',
			name: 'currency',
			type: 'select',
			required: true,
			options: Object.values(Currencies).map((c) => ({
				label: c,
				value: c,
			})).sort(standardSort('label')),
			inputProps: {
				showSearch: true,
			},
		},
	], [vessels]);

	return (
		<ListTableScreen<EstimateOverviewTableRow, CreateEstimateFormData>
			title="VC-Out Estimates"
			rootPageTitle="Estimate - VC"
			columns={columns}
			loading={loading}
			emptyTitle="No estimates found"
			emptyIcon={faInbox}
			error={error}
			data={estimateRows}
			tableProps={{ useCards: screens.xs }}
			formFields={formFields}
			formProps={{
				initialValues: {
					currency: Currencies.USD,
				},
			}}
			formButtonText="Create estimate"
			onFormSubmit={submitCreateEstimateForm}
			getRowHref={
				(row) => Links.Fixtures[FixtureTypes.SPOT].Details.get(
					row.estimateGroupId,
					row.firstEstimateId ?? row.id,
				)
			}
			extraButtonContent={(
				<Link to={Links.Fixtures[FixtureTypes.SPOT].CompareEstimates.get(toCompare)}>
					<Button
						disabled={toCompare.length === 0}
						type="default"
					>
						{toCompare.length === 0 ? (
							<>Select entries to compare</>
						) : (
							<>
								Compare selected
								(
								{toCompare.length}
								)
							</>
						)}
					</Button>
				</Link>
			)}
			redirectOnSubmit
			showEmptyArrow
		/>
	);
};

export default EstimatesListPage;
