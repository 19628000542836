import
React, {
	useMemo,
	useState,
} from 'react';
import { Grid } from 'antd';
import {
	Card,
	Col,
	Row,
} from 'antd/lib';
import type { TransformedExpenses } from '@api/utils/getTransformedVoyageExpenses';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getVoyageExpenses } from '@client/lib/api';
import { useAuth } from '@client/lib/auth';
import VendorExpensesTable from '../ExpenseApprovalsScreen/VendorExpensesTable';
import styles from './components/styles/ExpenseScreen.module.css';
import ExpenseDetailSection from './ExpenseDetailSection';

const ExpensesScreen = () => {
	const [allExpenses, refreshExpenses, _error] = useFetchedState(getVoyageExpenses);
	const { userInfo } = useAuth();
	const [showAllExpenses] = useState(!userInfo.filterVessels);
	const [itemId, setItemId] = useState<number | undefined>(undefined);

	const screens = Grid.useBreakpoint();

	const expenses = useMemo(() => {
		if (allExpenses === undefined) {
			return [];
		}

		if (showAllExpenses) {
			return allExpenses;
		}

		return allExpenses.filter((e: TransformedExpenses) => (
			userInfo.relevantVessels.some((v: {id: number}) => v.id === e.voyage?.vesselId)
		));
	}, [allExpenses, userInfo, showAllExpenses]);

	const selectedExpense = useMemo(() => {
		if (expenses === undefined || itemId === undefined) {
			return undefined;
		}

		return expenses.find((e: TransformedExpenses) => e.id === itemId);
	},
	[expenses, itemId]);

	return (
		<>
			<Row gutter={[16, 16]} className={styles.expensesScreenContent}>
				<Col xxl={14} sm={24} className={screens.xs ? styles.mobileColumn : styles.column}>
					<Card>
						<VendorExpensesTable
							expenses={expenses}
							setItemId={setItemId}
							refreshExpenses={refreshExpenses}
							item={selectedExpense}
						/>
					</Card>
				</Col>
				<Col
					xxl={10}
					sm={24}
					className={screens.xs ? styles.mobileColumn : styles.column}
				>
					<ExpenseDetailSection item={selectedExpense} refreshExpenses={refreshExpenses} />
				</Col>
			</Row>
		</>
	);
};

export default ExpensesScreen;
