import React from 'react';
import {
	DATE_AND_TIME,
	FixtureTypeLabels,
	VesselOwnershipTypeLabels,
} from '@shared/utils/constants';
import formatTcFixtureDuration from '@shared/utils/formatTcFixtureDuration';
import { formatDate } from '@client/utils/formatDate';
import VesselDescription from '@client/components/VesselDescription';
import styles from '@client/screens/fixtures/shared/styles/FixtureDetailsScreen.module.css';

export const useTcSummaryItemGroups = ({
	fixture,
}) => {
	const vessel = fixture?.vessel || {};
	const counterParty = fixture?.FixtureCounterparty || {};

	if (fixture == null) {
		return [];
	}

	return [
		{
			header: 'Estimate',
			items: [
				{
					key: 'fixtureType',
					label: 'Estimate Type',
					value: FixtureTypeLabels[fixture.type],
				},
				{
					key: 'identifier',
					label: 'Identifier',
					value: fixture.identifier,
				},
				{
					key: 'cpDate',
					label: 'CP Date',
					value: formatDate(fixture.cpDate),
				},
				{
					key: 'laycanFrom',
					label: 'Laycan From',
					value: formatDate(fixture.laycanFrom, DATE_AND_TIME, true),
				},
				{
					key: 'laycanTo',
					label: 'Laycan To',
					value: formatDate(fixture.laycanTo, DATE_AND_TIME, true),
				},
				{
					key: 'deliveryPortsOrRanges',
					label: 'Delivery Port(s) / Range(s)',
					value: fixture.deliveryPortsOrRangesLabel,
				},
				{
					key: 'redeliveryPortsOrRanges',
					label: 'Redelivery Port(s) / Range(s)',
					value: fixture.redeliveryPortsOrRangesLabel,
				},
				{
					key: 'minDuration',
					label: 'Min. Duration',
					value: (formatTcFixtureDuration(fixture) != null ? (
						<>
							{formatTcFixtureDuration(fixture)
								.split(' - ')[0]}
							<br />
							<span className={styles.smallDate}>
								{formatDate(fixture.minimumDurationExact ?? fixture.estimatedRedeliveryDate)}
							</span>
						</>
					) : null),
				},
				{
					key: 'maxDuration',
					label: 'Max. Duration',
					value: (formatTcFixtureDuration(fixture) != null ? (
						<>
							{formatTcFixtureDuration(fixture)
								.split(' - ')[1]}
							<br />
							<span className={styles.smallDate}>
								{formatDate(
									fixture.maximumDurationExact ?? fixture.maxRedeliveryDate,
								)}
							</span>
						</>
					) : null),
				},
			],
		},
		{
			header: 'Vessel',
			items: [
				{
					key: 'vesselName',
					label: 'Name',
					value: vessel?.name == null ? null : (
						<VesselDescription
							name={vessel.name}
							flag={vessel.flag}
						/>
					),
				},
				{
					key: 'vesselOwnership',
					label: 'Ownership',
					value: VesselOwnershipTypeLabels[vessel.ownershipType],
				},
				{
					key: 'vesselClass',
					label: 'Class',
					value: vessel.vesselClass,
				},
			],
		},
		{
			header: 'Counterparty',
			items: [
				{
					key: 'counterpartyName',
					label: 'Name',
					value: counterParty.name,
				},
			],
		},
	];
};
