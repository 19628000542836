import { DATE_AND_TIME } from '@shared/utils/constants';
import { formatDate } from '@client/utils/formatDate';

const getItems = ({
	name,
	createdAt,
	updatedAt,
}) => [
	{
		key: 'name',
		label: 'Name',
		value: name,
		editable: true,
		inputProps: {
			'data-cy': 'detailsNameInput',
		},
	},
	{
		key: 'createdAt',
		label: 'Creation date',
		value: formatDate(createdAt),
	},
	{
		key: 'updatedAt',
		label: 'Last updated',
		value: formatDate(updatedAt, DATE_AND_TIME),
	},
];

export default getItems;
