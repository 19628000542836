import React, { useCallback } from 'react';
import {
	Grid,
	notification,
} from 'antd';
import { faAddressBook } from '@fortawesome/pro-duotone-svg-icons';
import { standardSort } from '@shared/utils/standardSort';
import {
	getSuppliers,
	createSupplier,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { renderDate } from '@client/utils/table';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import { Links } from '@client/utils/links';

const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		defaultSortOrder: 'ascend',
		sorter: standardSort('name'),
	},
	{
		title: 'Address',
		dataIndex: 'address',
		key: 'address',
		sorter: standardSort('address'),
	},
	{
		title: 'Last updated',
		dataIndex: 'updatedAt',
		key: 'updatedAt',
		sorter: standardSort('updatedAt'),
		render: renderDate(),
	},
];

const SuppliersListScreen = () => {
	const [suppliers, reloadSuppliers, error, loading] = useFetchedState(getSuppliers);
	const screens = Grid.useBreakpoint();

	const createNewSupplier = useCallback(async ({ name }) => {
		const supplier = await createSupplier(name);

		await reloadSuppliers();

		notification.success({
			message: 'Supplier created',
			description: `${name} has been created.`,
		});

		return supplier;
	}, [reloadSuppliers]);

	return (
		<ListTableScreen
			title="Suppliers"
			rootPageTitle="Suppliers"
			columns={columns}
			loading={loading}
			tableProps={{ useCards: screens.xs }}
			error={error}
			data={suppliers}
			formFields={[
				{
					label: 'Name',
					name: 'name',
					type: 'text',
					required: true,
				},
			]}
			formButtonText="Create supplier"
			onFormSubmit={createNewSupplier}
			getRowHref={(row) => Links.Supplier.get(row.id)}
			showEmptyArrow
			emptyTitle="You have not registered any suppliers yet"
			emptyDescription="Click the button to create one"
			emptyIcon={faAddressBook}
		/>
	);
};

export default SuppliersListScreen;
