import React from 'react';
import {
	Card,
	Checkbox,
	Space,
	Tag,
	Tooltip,
} from 'antd';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAdd } from '@fortawesome/pro-light-svg-icons';
import {
	DeleteOutlined,
	EditOutlined,
} from '@ant-design/icons';
import { formatCurrency } from '@shared/utils/currency';
import {
	capitalize,
	formatQuantity,
} from '@shared/utils/string';
import { LaytimeTerms } from '@shared/utils/constants';
import { getCargoPortLaytimeStats } from '@shared/utils/getCargoPortLaytimeStats';
import { getCargoPortLaytimeEstimatedValues } from '@shared/utils/getCargoPortLaytimeEstimatedValues';
import { round } from '@shared/utils/math';
import { formatLaytimeDuration } from '@shared/utils/date';
import { asyncForEach } from '@shared/utils/array';
import type { CargoProps } from '@api/models/cargo';
import type { CargoPortProps } from '@api/models/cargo-port';
import type { ItineraryPortCallDto } from '@api/features/ops/getVesselItinerary';
import type { GetCargosAndLaytimesResponse } from '@api/features/laytime/getCargosAndLaytimes';
import Button from '@client/components/Button';
import CountryFlag from '@client/components/CountryFlag';
import { IncomingCargoPort } from '@client/screens/fleet/VoyageDetailsScreen/components/LaytimeCalculatorTab/LaytimeCalculationDrawer';
import Details from '@client/components/Details';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import {
	deleteLaytimeCalculation,
	updateCargoPort,
} from '@client/lib/api';
import styles from '../FreightDemurrageDespatchTab.module.css';

export type CargoPort = {
	port: {name: string; countryCode: string};
	portAndActionKey: string;
	loadingRate: number;
	demurrage: number;
	despatch: number;
	timeAllowed: number;
	calculation?: {
		laytimeUsed: string;
		actualQuantity: number;
		timeLostGained: string;
		result: number;
	};
}

const CargoPortEntry = ({
	cargo,
	cargoPort,
	onAddNewLaytimeCalc,
	portCall,
	refreshDetails,
}: {
	cargo: GetCargosAndLaytimesResponse[number];
	cargoPort: IncomingCargoPort;
	onAddNewLaytimeCalc: (cargoId: number, id: number) => void;
	portCall: ItineraryPortCallDto | undefined;
	refreshDetails: () => void;
}) => {
	const newestLaytimeCalculation = cargoPort.LaytimeCalculations[0];
	const {
		action,
		isDem,
		timeGainedOrLost,
		totalDemurrage,
		totalDespatch,
	} = getCargoPortLaytimeStats(cargoPort, { ...cargo, CargoPorts: [cargoPort] });

	const onDeleteCalculation = async () => {
		try {
			await asyncForEach(cargoPort.LaytimeCalculations, async (calc) => {
				await deleteLaytimeCalculation(calc.id);
			});

			await refreshDetails();
			showSuccessNotification('Laytime calculation deleted');
		} catch (e) {
			showErrorNotification('Could not delete laytime calculation', e as Error);
		}
	};

	const {
		estimatedDemurrage,
		estimatedDespatch,
	} = getCargoPortLaytimeEstimatedValues(
		cargo as CargoProps,
		cargoPort as CargoPortProps,
		portCall?.estimatedWorkingDays,
	);

	const isTermsNonReversible = cargo.laytimeTerms === LaytimeTerms.NON_REVERSIBLE;

	const includeDeadfreight = async () => {
		try {
			await updateCargoPort({
				cargoPortId: cargoPort.id,
				cargoId: cargo.id,
				attributes: {
					includeDeadfreight: !cargoPort.includeDeadfreight,
				},
			});
			await refreshDetails();
			await refreshDetails();
		} catch (e) {
			showErrorNotification('Could not include deadfreight in laytime calculation', e as Error);
		}
	};

	const blWithDeadfreight = (cargoPort?.actualQuantity ?? 0) + (cargo?.totalDeadfreightAmount ?? 0);

	const minQuantityAllowed = (
		(100 - (cargo?.quantityTolerance ?? 0)) / 100
	) * cargo?.quantity;

	const hasDeadFreight = cargoPort.actualQuantity !== null &&
		cargoPort.actualQuantity < minQuantityAllowed;

	return (
		<div className={styles.bigContainer}>
			<Card className={styles.card}>
				<div className={styles.header}>
					<Space>
						<Tag color={action === 'loading' ? 'geekblue' : 'red'}>
							{capitalize(action)}
						</Tag>
						<CountryFlag countryCode={cargoPort.port.countryCode} />
						{cargoPort.port.name}
					</Space>
				</div>
				<div className={styles.content}>
					<div
						className={styles.detailContainer}
					>
						<Details
							// @ts-ignore
							column={isTermsNonReversible ? 4 : 1}
							className={styles.details}
							title=""
							items={[
								...(isTermsNonReversible ? [
									{
										key: 'demurrageRate',
										label: 'Demurrage Rate',
										value: `${formatCurrency(cargoPort.demurrage ?? 0, cargo.currency)} / ${cargoPort.laytimeInterval}`,
									},
									{
										key: 'estimatedDemurrage',
										label: 'Estimated Demurrage',
										value: `${formatCurrency(Math.abs(estimatedDemurrage ?? 0), cargo.currency)}`,
									},
									{
										key: 'despatchRate',
										label: 'Despatch Rate',
										value: `${formatCurrency(cargoPort.despatch ?? 0, cargo.currency)} / ${cargoPort.laytimeInterval}`,
									},
									{
										key: 'estimatedDespatch',
										label: 'Estimated Despatch',
										value: `${formatCurrency(Math.abs(estimatedDespatch ?? 0), cargo.currency)}`,
									},
								] : []),
								{
									key: 'cpQuantity',
									label: 'CP Quantity',
									value: formatQuantity(cargoPort.estimatedQuantity ?? 0, cargo.unit),
								},
								{
									key: 'blQuantity',
									label: (
										<div style={{ display: 'flex', gap: '10px', width: '170px' }}>
											{cargoPort.includeDeadfreight ?
												'Freight & Deadfreight' : 'BL Quantity'}
											<span>
												{hasDeadFreight && (
													<Tooltip title={cargoPort.includeDeadfreight ? 'Exclude deadfreight from laytime calculation' :
														'Include deadfreight in laytime calculation'}
													>
														<Checkbox
															checked={cargoPort.includeDeadfreight}
															onChange={includeDeadfreight}
														/>
													</Tooltip>
												)}
											</span>
										</div>
									),
									value: formatQuantity(cargoPort.includeDeadfreight ? blWithDeadfreight :
										cargoPort.actualQuantity ?? 0, cargo.unit),
								},
								{
									key: 'cpLoadRate',
									label: 'CP L/D Rate',
									value: `${formatQuantity(cargoPort.loadingRate ?? 0, cargo.unit)} / ${cargoPort.laytimeInterval}`,
								},
								{
									key: 'timeAllowed',
									label: 'Laytime allowed',
									value: `${round(cargoPort.timeAllowed ?? 0, 2)} ${cargoPort.laytimeInterval}${(cargoPort.timeAllowed ?? 0) > 1 ? 's' : ''}`,
								},
							]}
						/>
					</div>
				</div>
			</Card>
			<div
				className={classNames(styles.section, styles.calculatedSection)}
			>
				<div className={styles.calculatedHeader}>
					<Space>
						<b>Actual</b>
					</Space>
					<Space>
						<EditOutlined
							className={styles.editBtn}
							onClick={() => onAddNewLaytimeCalc(cargoPort.cargoId, cargoPort.id)}
						/>
						{newestLaytimeCalculation != null && (
							<Button
								type="link"
								danger
								icon={(<DeleteOutlined />)}
								confirmTitle="Are you sure you want to delete this laytime calculation?"
								className={styles.deleteBtn}
								onClick={onDeleteCalculation}
							/>
						)}
					</Space>
				</div>
				<div className={styles.calculatedContent}>
					{newestLaytimeCalculation != null ? (
						<Details
							title=""
							className={styles.actualDetails}
							items={[
								{
									key: 'timeUsed',
									label: 'Laytime Used',
									value: newestLaytimeCalculation.timeUsedInMinutes != null &&
									newestLaytimeCalculation.timeUsedInMinutes > 0 ?
										`${formatLaytimeDuration(
											newestLaytimeCalculation.timeUsedInMinutes,
											cargoPort.laytimeInterval,
											3,
										)}` :
										'N/A',
								},
								{
									key: 'lostOrGained',
									label: `Time ${isDem ? 'Lost' : 'Gained'}`,
									value: timeGainedOrLost !== 0 ?
										`${formatLaytimeDuration(
											Math.abs(timeGainedOrLost),
											cargoPort.laytimeInterval,
											3,
										)}` :
										'N/A',
								},
								{
									key: 'total',
									label: `Total ${isDem ? 'Demurrage' : 'Despatch'}`,
									value: formatCurrency(
										Math.abs(isDem ? totalDemurrage : totalDespatch),
										cargo.currency,
									),
								},
							]}
						/>
					) : (
						<>
							<b>No laytime calculation found</b>
							<Button
								icon={(
									<FontAwesomeIcon
										className={styles.icon}
										icon={faAdd as IconProp}
									/>
								)}
								type="primary"
								onClick={() => onAddNewLaytimeCalc(cargoPort.cargoId, cargoPort.id)}
							>
								Create new calculation
							</Button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default CargoPortEntry;
