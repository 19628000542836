import React, {
	useCallback,
	useMemo,
} from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { faInbox } from '@fortawesome/pro-thin-svg-icons';
import { Moment } from 'moment';
import {
	Space,
	Tag,
} from 'antd';
import {
	CargoUnitLabels,
	DATE_AND_TIME,
	CargoUnitTypes,
	Currencies,
	LaytimeCalculationMethods,
	QuantityOptions,
	FreightRateType,
	LaytimeTerms,
	LaytimeIntervals,
	EstimateStatus,
	FixtureCounterpartyTypes,
} from '@shared/utils/constants';
import { standardSort } from '@shared/utils/standardSort';
import { Values } from '@shared/utils/objectEnums';
import { formatCurrency } from '@shared/utils/currency';
import type { Port } from '@api/utils/ports';
import type { GetCargosResponse } from '@api/features/cargos/getCargos';
import type {
	EstimatedExpenseItem,
	EstimatedRevenueItem,
} from '@api/models/cargo';
import { formatDate } from '@client/utils/formatDate';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	createCargo,
	getCargos,
	getCounterparties,
} from '@client/lib/api';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import showErrorNotification from '@client/utils/showErrorNotification';
import { Links } from '@client/utils/links';
import { FormPopoverProps } from '@client/components/FormPopover';
import { getFilterProps } from '@client/utils/table';

export type CargoPort = {
	id?: number;
	portAndActionKey: string;
	amountLoaded: number;
	loadingRate: number;
	laycanFrom: Moment;
	laycanTo: Moment;
	demurrage: number;
	despatch: number;
	timeAllowed: number;
	calculationMethod: LaytimeCalculationMethods;
	laytimeInterval: LaytimeIntervals;
}

export type CargoFormValues = {
	charterer: number |null;
	estimatedItems: Array<EstimatedRevenueItem | EstimatedExpenseItem>;
	deadfreight: number | null;
	type: string;
	dischargePorts: number[];
	freightRate: {
		currency: Values<typeof Currencies>;
		value: number;
		exchangeRate: number;
	};
	freightType: Values<typeof FreightRateType>;
	loadingPorts: number[];
	overage: number | null;
	quantity: {
		quantity: number;
		unit: Values<typeof CargoUnitTypes>;
	};
	quantityOption: QuantityOptions | null;
	tolerance: number | null;
	laytimeTerms: LaytimeTerms | null;
	status: EstimateStatus | null;
	demurrage: number | null;
	despatch: number | null;
	demurrageLoading: number | null;
	despatchLoading: number | null;
	demurrageDischarge: number | null;
	despatchDischarge: number | null;
}

export type CargoRow = GetCargosResponse[number]

type CreateCargoFormData = {
	type: string;
	charterer?: number;
	estimateId?: number;
}

const CargosListScreen: React.FC = () => {
	const [cargos, _refreshCargoList, error, loading] = useFetchedState(getCargos);

	const sortedCargos = useMemo(() => {
		if (cargos == null) {
			return [];
		}

		return cargos.filter((c) => !c.ghost).sort((a, b) => a.id - b.id);
	}, [cargos]);

	const [charterers, _reloadCharterers, _error, _loading] = useFetchedState(
		() => getCounterparties(FixtureCounterpartyTypes.CHARTERER),
	);

	const chartererOptions = useMemo(() => {
		if (charterers == null) {
			return [];
		}

		return charterers.map((c) => ({
			chartererName: c.name,
		}));
	}, [charterers]);

	const getStatusColor = (status: EstimateStatus) => {
		if (status === EstimateStatus.Available) {
			return 'green';
		}

		if (status === EstimateStatus.Working) {
			return 'blue';
		}

		if (status === EstimateStatus.Subjects) {
			return 'orange';
		}

		return 'red';
	};

	const columns: ColumnsType<CargoRow> = useMemo(() => [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status) => (status == null ? ' - ' : (
				<Tag color={getStatusColor(status)}>
					{EstimateStatus[status]}
				</Tag>
			)),
		},
		{
			title: 'Created/Modified',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			sorter: standardSort('updatedAt'),
			defaultSortOrder: 'descend',
			render: (date) => (date != null ? formatDate(date, DATE_AND_TIME) : 'N/A'),
		},
		{
			title: 'Laydays',
			dataIndex: 'firstLaycan',
			key: 'firstLaycan',
			sorter: standardSort('firstLaycan'),
			render: (date) => (date != null ? formatDate(date, DATE_AND_TIME) : 'Not specified'),
		},
		{
			title: 'Cancelling',
			dataIndex: 'lastLaycan',
			key: 'lastLaycan',
			sorter: standardSort('lastLaycan'),
			render: (date) => (date != null ? formatDate(date, DATE_AND_TIME) : 'Not specified'),
		},
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
			sorter: standardSort('type'),
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			key: 'quantity',
			sorter: standardSort('quantity'),
			render: (quantity, row) => (quantity != null ? `${quantity} ${CargoUnitLabels[row.unit]}` : 'N/A'),
		},
		{
			title: 'Freight',
			dataIndex: 'freightType',
			key: 'freightType',
			render: (freightType, row) => {
				if (freightType == null || row.freightRate == null || row.currency == null) {
					return 'N/A';
				}

				return freightType === FreightRateType.LUMPSUM ?
					formatCurrency(row.freightRate, row.currency) :
					`${formatCurrency(row.freightRate, row.currency)} /${row.unit}`;
			},
		},
		{
			title: 'Charterer',
			dataIndex: 'chartererName',
			key: 'chartererName',
			render: (chartererName) => chartererName ?? 'N/A',
			...getFilterProps(chartererOptions, 'select', 'chartererName', 'desc'),
		},
		{
			title: 'Loadport(s)',
			dataIndex: 'loadingPorts',
			key: 'loadingPorts',
			render: (loadPorts: Port[]) => (loadPorts.length > 0 ?
				(
					<Space>
						{(loadPorts ?? []).map((port: Port) => (
							<Tag color="geekblue">{port.name}</Tag>
						))}
					</Space>
				) : 'N/A'),
		},
		{
			title: 'Disports(s)',
			dataIndex: 'dischargePorts',
			key: 'dischargePorts',
			render: (disports: Port[]) => (disports.length > 0 ?
				(
					<Space>
						{(disports ?? []).map((port: Port) => (
							<Tag color="red">{port.name}</Tag>
						))}
					</Space>
				) : 'N/A'),
		},
	], [chartererOptions]);

	const createNewCargo = useCallback(async (values: {
		type: string;
		charterer?: number;
	}) => {
		try {
			return await createCargo(values);
		} catch (e) {
			showErrorNotification('Could not create cargo', e as Error);

			return null;
		}
	}, []);

	const formFields: FormPopoverProps<CreateCargoFormData>['fields'] = useMemo(() => [
		{
			label: 'Description',
			name: 'type',
			type: 'text',
			required: true,
		},
		{
			label: 'Charterer',
			name: 'charterer',
			type: 'select',
			required: false,
			options: charterers?.map((c) => ({
				label: c.name,
				value: c.id,
			})),
		},
	], [charterers]);

	return (
		<ListTableScreen<CargoRow, CreateCargoFormData>
			title="Cargos"
			tabs={[{
				tab: 'Active',
				key: 'active',
				showEmptyTabArrow: true,
				filter: (_cargo) => true,
				emptyTabTitle: 'You do not have any cargos yet',
				emptyTabDescription: 'Cargos created either here, or from an estimate, will show in this list', // TODO
			},
			{
				tab: 'Expired',
				key: 'expired',
				showEmptyTabArrow: true,
				filter: (_cargo) => false,
				emptyTabTitle: 'You do not have any expired cargos yet',
				emptyTabDescription: 'Once the laycan has slipped and the cargo is not being worked, it will show here',
			}]}
			rootPageTitle="Cargos"
			columns={columns}
			loading={loading}
			emptyTitle="No cargos found"
			emptyIcon={faInbox}
			tableProps={{ scroll: { x: 1350 } }}
			error={error}
			data={sortedCargos}
			formFields={formFields}
			formButtonText="Create cargo"
			onFormSubmit={createNewCargo}
			showEmptyArrow
			getRowHref={(row) => Links.Cargo.get(row.id)}
		/>
	);
};

export default CargosListScreen;
