import React, {
} from 'react';
import {
	Card,
	Col,
	Row,
	Skeleton,
	Tabs,
} from 'antd';
import styles from '../styles/DetailsTabScreen.module.css';
import RootScreen, { RootScreenProps } from '../RootScreen';

export type LoadingDetailsTabScreenProps = RootScreenProps;

const LoadingDetailsTabScreen = ({
	...props
}: LoadingDetailsTabScreenProps) => (
	<RootScreen {...props}>
		<Card className={styles.headerCard}>
			<div className={styles.headerTitle}>
				<Skeleton.Button
					className={styles.titleSkeleton}
					active
					shape="round"
					size="default"
				/>
			</div>
			<Tabs
				className={styles.cardTabs}
			>
				{[0, 1, 2, 3].map((i) => (
					<Tabs.TabPane
						key={i}
						disabled
						tab={(
							<span>
								<Skeleton.Button active size="small" shape="circle" />
								<Skeleton.Input active size="small" className={styles.tabNameSkeleton} />
							</span>
						)}
					/>
				))}
			</Tabs>
		</Card>
		<Row gutter={[16, 16]}>
			<Col span={24} />
			<Col span={12}>
				<Card title={' '}>
					<Row gutter={[64, 64]}>
						<Col span={12}>
							<Skeleton active />
						</Col>
						<Col span={12}>
							<Skeleton active />
						</Col>
						<Col span={12}>
							<Skeleton active />
						</Col>
						<Col span={12}>
							<Skeleton active />
						</Col>
					</Row>
				</Card>
			</Col>
			<Col span={12}>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Card title={(<Skeleton.Button active shape="round" size="default" />)}>
							<Skeleton.Button
								className={styles.cardBodySkeleton}
								active
								shape="round"
								size="default"
							/>
						</Card>
					</Col>
					<Col span={12}>
						<Card title={(<Skeleton.Button active shape="round" size="default" />)}>
							<Skeleton.Button
								className={styles.cardBodySkeleton}
								active
								shape="round"
								size="default"
							/>
						</Card>
					</Col>
					<Col span={12}>
						<Card title={(<Skeleton.Button active shape="round" size="default" />)}>
							<Skeleton.Button
								className={styles.cardBodySkeleton}
								active
								shape="round"
								size="default"
							/>
						</Card>
					</Col>
					<Col span={12}>
						<Card title={(<Skeleton.Button active shape="round" size="default" />)}>
							<Skeleton.Button
								className={styles.cardBodySkeleton}
								active
								shape="round"
								size="default"
							/>
						</Card>
					</Col>
					<Col span={24}>
						<Card title={(<Skeleton.Button active shape="round" size="default" />)}>
							<Skeleton active />
						</Card>
					</Col>
				</Row>
			</Col>
		</Row>
	</RootScreen>
);

export default LoadingDetailsTabScreen;
