import React from 'react';
import {
	Col,
	Row,
} from 'antd';
import QuickBar from '@client/components/QuickBar/QuickBar';
import VesselActivityWidget from '../widgets/VesselActivityWidget';

const HomeDashboard = () => {
	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<QuickBar />
			</Col>
			<Col span={24}>
				<VesselActivityWidget />
			</Col>
		</Row>
	);
};

export default HomeDashboard;
