import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	DeleteOutlined,
	DownloadOutlined,
	ExclamationCircleOutlined,
	InfoCircleOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Table from 'antd/lib/table';
import {
	Col,
	Divider,
	Input,
	Row,
	Space,
	Tooltip,
} from 'antd';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import {
	DATE,
	GlobalLaytimeCalculationMethod,
	SofActions,
} from '@shared/utils/constants';
import formatMinutes from '@shared/utils/formatTime';
import type { SofItemProps } from '@api/models/sof-item';
import type { Port } from '@api/utils/ports';
import type { UserData } from '@api/utils/sequelize/calculateUserData';
import { formatDate } from '@client/utils/formatDate';
import EditableCellTableRedux from '@client/components/EditableTableRedux/EditableCellTableRedux';
import AddButton from '@client/components/AddButton';
import Button from '@client/components/Button';
import styles from '@client/components/styles/LaytimeCalculationDetails.module.css';
import fetchPdf from '@client/utils/fetchPdf';
import { getSofAsPdf } from '@client/lib/api';
import { DefaultUserData } from '@client/lib/auth';
import CreditNoteCheckbox from '@client/components/CreditNoteCheckbox';

export type SofItems = Array<Omit<SofItemProps, 'laytimeCalculationId'> & {
	laytimeCalculationId?: number;
}>

const LaytimeCalculationDetails = ({
	onDeleteCalculation,
	sofItems,
	onSave,
	tzOffset,
	port,
	versionCreatedAt,
	activeTab,
	userData,
	askForCreditNote,
	createCreditNote,
	setCreateCreditNote,
}: {
	onSave: (updatedSofItems: SofItemProps[] | undefined) => void;
	tzOffset: number;
	sofItems?: SofItems;
	port: Port;
	versionCreatedAt: string;
	onDeleteCalculation: () => void;
	activeTab: string;
	userData: UserData | DefaultUserData;
	askForCreditNote: boolean;
	createCreditNote: boolean;
	setCreateCreditNote: (value: boolean) => void;
}) => {
	const [items, setItems] = useState<Array<string | { value: string }>>(Object.values(SofActions));
	const [tableData, setTableData] = useState<SofItems | undefined>(sofItems);
	const [customItem, setCustomItem] = useState<{ value: string }>({ value: '' });
	const [disabledAddCustom, setDisabledAddCustom] = useState(true);

	const onChangeCustomItem = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCustomItem({ value: event.target.value });
		if (event.target.value.length !== 0) {
			setDisabledAddCustom(false);

			return;
		}

		setDisabledAddCustom(true);
	};

	const addItem = () => {
		setItems([customItem, ...items]);
		setDisabledAddCustom(true);
		setCustomItem({ value: '' });
	};

	useEffect(() => {
		if (sofItems != null) {
			setTableData(sofItems);
		}
	}, [sofItems]);

	const formattedTotalTime = useMemo(() => {
		if (tableData == null) {
			return 0;
		}

		let total: number = 0;
		tableData.forEach((v) => {
			if (v.toCount) {
				total += v.timeUsedInMinutes * (v.percentageToCount / 100);
			}
		});

		return formatMinutes(total);
	}, [tableData]);

	const reCalcTimes = (newData: SofItems) => {
		for (let i = 0; i < newData.length; i++) {
			const prevSof = newData[i - 1];

			let timeUsedInMinutes = prevSof !== undefined ? (moment.duration(
				toMoment(newData[i].date).diff(toMoment(newData[i - 1].date)),
			)).asMinutes() : 0;

			timeUsedInMinutes = timeUsedInMinutes < 0 ? 0 : timeUsedInMinutes;

			newData[i].timeUsedInMinutes = timeUsedInMinutes;
		}
	};

	const handleTableDataChange = (newData: SofItems) => {
		reCalcTimes(newData);
		setTableData([...newData]);
	};

	const deleteRow = (sof: SofItems[number]) => {
		if (tableData != null) {
			setTableData(tableData.filter((v) => v !== sof));
		}
	};

	const addNewRow = () => {
		const newRow = {
			action: SofActions.ALL_FAST,
			date: nowMoment().utcOffset(tzOffset),
			timeUsedInMinutes: 0,
			toCount: userData?.globalLaytimeCalculationMethod ===
				GlobalLaytimeCalculationMethod.DEDUCTIONS,
			percentageToCount: 100,
		};

		setTableData((prevState) => [...(prevState ?? []), newRow]);
	};

	return (
		<>
			<div className={styles.btnContainer}>
				<Row>
					<Col span={8}>
						<Space>
							<Button
								disabled={tableData == null || tableData.length === 0}
								onClick={() => fetchPdf({
									fileName: `Statement_of_Facts_${formatDate(versionCreatedAt, DATE)}.pdf`,
									fetchCallback: () => getSofAsPdf(
										versionCreatedAt,
										tableData || [],
										port,
										formattedTotalTime,
									),
								})}
							>
								<DownloadOutlined />
								Save PDF
							</Button>
							<Button
								onClick={onDeleteCalculation}
								confirmTitle="Are you sure you want to delete this laytime calculation?"
								icon={(<DeleteOutlined />)}
								disabled={activeTab === '-1'}
								danger
							>
								Delete
							</Button>
						</Space>
					</Col>
				</Row>
			</div>
			{tableData != null && (
				<EditableCellTableRedux<SofItems[number]>
					columns={[
						{
							title: 'Action',
							dataIndex: 'action',
							editable: true,
							type: 'select',
							inputProps: {
								showSearch: true,
								filterOption: (input, option) => {
									let val = option?.value;

									if (typeof val === 'number') {
										val = val.toString();
									}

									return (
										val?.toLowerCase().includes(input.toLowerCase()) ?? false
									);
								},
								options: items.map((t) => {
									if (typeof t === 'object') {
										return {
											label: (
												<Space>
													<Tooltip title="This is a custom entry">
														<ExclamationCircleOutlined />
													</Tooltip>
													{t.value}
												</Space>
											),
											value: t.value,
										};
									}

									return {
										label: t,
										value: t,
									};
								}),
								dropdownRender: (menu) => (
									<>
										{menu}
										<Divider className={styles.customAddDivider} />
										<Space className={styles.customAddSpace}>
											<Input
												placeholder="Please enter item"
												value={customItem.value}
												onChange={onChangeCustomItem}
											/>
											<Button
												type="text"
												icon={(<PlusOutlined />)}
												onClick={addItem}
												disabled={disabledAddCustom}
												disabledTooltip="Cannot input empty item"
											>
												Add item
											</Button>
										</Space>
									</>
								),
							},
							className: styles.actionCol,
						},
						{
							title: 'Time finished',
							dataIndex: 'date',
							editable: true,
							type: 'date',
							inputProps: {
								time: true,
							},
							className: styles.dateCol,
							width: 275,
						},
						{
							title: (
								<>
									Duration
									{' '}
									<Tooltip title="Calculated time from previous SoF date">
										<InfoCircleOutlined />
									</Tooltip>
								</>
							),
							dataIndex: 'timeUsedInMinutes',
							editable: false,
							type: 'number',
							width: 105,
							inputProps: {
								addonAfter: 'm',
							},
							render: (v) => formatMinutes(v.timeUsedInMinutes),
							className: styles.durationCol,
						},
						{
							title: 'To Count?',
							dataIndex: 'toCount',
							editable: true,
							type: 'checkbox',
							width: 50,
							className: styles.toCountRow,
						},
						{
							title: '% To Count',
							dataIndex: 'percentageToCount',
							editable: true,
							type: 'number',
							inputProps: {
								addonAfter: '%',
								min: 0,
								max: 100,
							},
							width: 105,
						},
						{
							title: (
								<AddButton
									onClick={() => addNewRow()}
								/>
							),
							render: (v: SofItems[number]) => (
								<Button
									onClick={() => deleteRow(v)}
									type="text"
									confirmTitle="Are you sure you want to delete this row?"
									danger
									icon={(<DeleteOutlined />)}
								/>
							),
							width: 50,
						},
					]}
					dataSource={tableData}
					size="small"
					pagination={false}
					onChange={handleTableDataChange}
					emptyText="No SoF items"
					summary={() => (
						<>
							<Table.Summary.Row className={styles.totalRow}>
								<Table.Summary.Cell index={0}>Laytime used</Table.Summary.Cell>
								<Table.Summary.Cell index={1} />
								<Table.Summary.Cell index={2} className={styles.durationCol}>
									{formattedTotalTime}
								</Table.Summary.Cell>
							</Table.Summary.Row>
						</>
					)}
				/>
			)}
			<div className={styles.saveResetBtnContainer}>
				<Button
					confirmTitle={!askForCreditNote ? undefined : (
						<CreditNoteCheckbox
							description="Since laytime has already been invoiced, you might want to generate a credit note"
							createCreditNote={createCreditNote}
							setCreateCreditNote={setCreateCreditNote}
							showCumulativeAlert={false}
						/>
					)}
					className={styles.saveResetBtn}
					key="save-button"
					type="primary"
					onClick={() => {
						onSave(tableData as unknown as SofItemProps[]);
						setTableData(sofItems ?? []);
					}}
				>
					Save
				</Button>
				<Button
					danger
					onClick={() => {
						setTableData(sofItems ?? []);
					}}
				>
					Reset
				</Button>
			</div>
		</>
	);
};

export default LaytimeCalculationDetails;
