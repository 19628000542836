import HIIHirePeriod from '@shared/hireInvoice/HIIHirePeriod';

const groupInvoiceItems = (items = [], differences = []) => {
	let groupedItems = items;
	let groupedDifferences = differences;

	({ items: groupedItems, differences: groupedDifferences } = HIIHirePeriod.groupItems(
		groupedItems,
		differences,
	));

	return {
		items: groupedItems,
		differences: groupedDifferences,
	};
};

export default groupInvoiceItems;
