import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../navbar/NavbarMenu.module.css';

const buildMenuItemComponents = (menuItems) => (
	menuItems
		.filter((i) => !i.hidden)
		.map((item) => {
			const {
				children,
				icon,
				text,
				link,
				...rest
			} = item;

			if (children != null) {
				return (
					<Menu.SubMenu
						{...rest}
						key={text}
						title={(
							<>
								{icon != null && (
									<FontAwesomeIcon
										icon={icon}
										className={styles.menuIcon}
									/>
								)}
								{text}
							</>
						)}
					>
						{buildMenuItemComponents(children)}
					</Menu.SubMenu>
				);
			}

			return (
				<Menu.Item {...rest} key={link}>
					<Link to={link}>
						{icon != null && (
							<FontAwesomeIcon
								icon={icon}
								className={styles.menuIcon}
							/>
						)}
						{text}
					</Link>
				</Menu.Item>
			);
		})
);

export default buildMenuItemComponents;
