import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Alert,
	Divider,
	Grid,
	Input,
	Space,
	Switch,
	Tooltip,
} from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { Moment } from 'moment';
import {
	AccountTypes,
	BankAccountTypes,
	ChildBunkerTypes,
	CalculationMethodForExpensesSubjectToHireDays,
	FixtureTypes,
	HireTypes,
	VoyageBunkerTypeLabels,
	FixtureTypeLabels,
	PaymentTerms,
	PaymentTermLabels,
	ExpensesSubjectToHireDaysIntervalLabels,
	ExpensesSubjectToHireDaysIntervals,
	DATE_AND_TIME,
	AccountingItemApprovalStates,
	HireUnit,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { capitalize } from '@shared/utils/string';
import { toMoment } from '@shared/utils/date';
import { Values } from '@shared/utils/objectEnums';
import { formatPercentage } from '@shared/utils/formatPercentage';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { UpdateFixtureBrokerRequest } from '@api/features/fixtures/updateFixtureBroker';
import type { AddFixtureBrokerRequest } from '@api/features/fixtures/addFixtureBroker';
import type { CreateFixtureExpenseRequest } from '@api/features/fixtures/createFixtureExpense';
import type { UpdateFixtureExpenseRequest } from '@api/features/fixtures/updateFixtureExpense';
import type { VoyageBunkerEntry } from '@api/utils/sequelize/getVoyageBunkers';
import { formatDate } from '@client/utils/formatDate';
import {
	getAddressCommissionField,
	getBankAccountField,
	getChartererField,
	getCpDateField,
	getCpFormField,
	getDefaultLaycanUtcOffset,
	getIdentifierField,
	getLaycanFromField,
	getLaycanToField,
	getNotesField,
	getVesselField,
} from '@client/screens/fixtures/shared/sectionItems';
import getPortAndRangeOptions from '@client/utils/getPortAndRangeOptions';
import CVEFormulaLabel from '@client/components/CVEFormulaLabel';
import VesselTimeline from '@client/components/VesselTimeline';
import {
	getBrokers,
	getVessels,
	createExpenseSubjectToHireDays,
	updateExpenseSubjectToHireDays,
	deleteExpenseSubjectToHireDays,
	addFixtureBroker,
	updateFixtureBroker,
	removeFixtureBroker,
	createBroker,
	createFixtureExpense,
	updateFixtureExpense,
	deleteFixtureExpense,
	getPortsAndRanges,
	updateFixture,
	getVesselBankAccounts,
	getOrgBankAccounts,
	createRevenueSubjectToDays,
	updateRevenueSubjectToDays,
	deleteRevenueSubjectToDays,
	getReportGroups,
} from '@client/lib/api';
import BunkersTable from '@client/components/BunkersTable';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { useAuth } from '@client/lib/auth';
import EditableTable from '@client/components/EditableTable';
import FormPopover from '@client/components/FormPopover';
import Button from '@client/components/Button';
import TooltipIcon from '@client/components/TooltipIcon';
import DatePicker from '@client/components/DatePicker';
import NumericInput from '@client/components/NumericInput';
import Select from '@client/components/Select';
import StateTag from '@client/components/StateTag';
import styles from './styles/useTcSectionItems.module.css';
import RateScheduleTable from './RateScheduleTable';

type Props = {
	fixture: GetFixtureDetailsResponse<TcFixtureProps> | undefined;
	fixtureLoading: boolean;
	refreshFixture: () => Promise<void> | void;
	setRateScheduleDrawerOpen: (open: boolean) => void;
}

type BrokersFromFixtureDetails = GetFixtureDetailsResponse<TcFixtureProps>['brokers']

export const useTcSectionItems = ({
	fixture: incomingFixture,
	fixtureLoading,
	refreshFixture,
	setRateScheduleDrawerOpen,
}: Props) => {
	const [fixture, setFixture] = useState<
		GetFixtureDetailsResponse<TcFixtureProps> | undefined
	>(incomingFixture);
	const [portsAndRanges] = useFetchedState(getPortsAndRanges);
	const [minDurationDateExact, setMinDurationDateExact] = useState(false);
	const [maxDurationDateExact, setMaxDurationDateExact] = useState(false);
	const [brokers, refreshBrokers] = useFetchedState(getBrokers);
	const [vessels] = useFetchedState(getVessels);
	const [reportGroups] = useFetchedState(getReportGroups);
	const screens = Grid.useBreakpoint();

	const availableVessels = useMemo(() => {
		return (vessels ?? [])
			.sort((a, b) => a.name.toString().localeCompare(b.name))
			.filter((v) => {
				if (fixture?.type === VesselOwnershipTypes.TC_IN) {
					return v.ownershipType === VesselOwnershipTypes.TC_IN;
				}

				return true;
			});
	}, [vessels, fixture?.type]);

	useEffect(() => setFixture(incomingFixture), [incomingFixture]);

	const [bankAccounts] = useFetchedState(async () => {
		if (fixture?.vesselId == null || vessels == null || vessels.length === 0) {
			return [];
		}

		const bankAccountType = fixture.type === FixtureTypes.TC_IN ?
			BankAccountTypes.TC_IN :
			BankAccountTypes.OURS;

		const vesselAccounts = await getVesselBankAccounts(fixture.vesselId, bankAccountType);
		const orgAccounts = await getOrgBankAccounts(true);

		return [...vesselAccounts, ...orgAccounts];
	}, [fixture, vessels]);

	const { userInfo } = useAuth();
	const [defaultLaycanUtcOffset, setDefaultLaycanUtcOffset] = useState(
		getDefaultLaycanUtcOffset(fixture),
	);

	const [defaultPickerValue, setDefaultPickerValue] = useState(fixture?.laycanFrom);

	useEffect(() => {
		setDefaultLaycanUtcOffset(getDefaultLaycanUtcOffset(fixture));
		if (fixture?.laycanFrom != null) {
			setDefaultPickerValue(fixture?.laycanFrom);
		}
	}, [fixture]);

	const [portSearchValue, setPortSearchValue] = useState<string | null>(null);

	useEffect(() => {
		if (fixture != null) {
			setMinDurationDateExact(fixture.minimumDurationExact != null);
			setMaxDurationDateExact(fixture.maximumDurationExact != null);
		}
	}, [fixture]);

	const portsAndRangesOptions = useMemo(
		() => {
			if (portsAndRanges == null) {
				return [];
			}

			return getPortAndRangeOptions(
				portsAndRanges,
				portSearchValue,
			);
		},
		[portSearchValue, portsAndRanges],
	);

	const bunkerColumns: ColumnsType<VoyageBunkerEntry> = [
		{
			dataIndex: 'fuelGrade',
			title: 'Grade',
		},
		{ dataIndex: 'type', title: 'Type', render: (type) => capitalize(VoyageBunkerTypeLabels[type]) },
		{
			dataIndex: 'quantity',
			title: 'Quantity',
			render: (c: number) => `${c} MT`,
		},
		{
			dataIndex: 'pricePerTon',
			title: 'Price / MT',
			render: (c, record) => {
				let price = c;

				if (record.currency !== fixtureCurrency) {
					price /= record.exchangeRate;
				}

				return `${formatCurrency(price, fixtureCurrency)}`;
			},
		},
		{
			dataIndex: 'total',
			title: 'Total',
			render: (c, record) => {
				let price = record.pricePerTon;

				if (record.currency !== fixtureCurrency) {
					price /= record.exchangeRate;
				}

				return `${formatCurrency(price * record.quantity, fixtureCurrency)}`;
			},
		},
		{
			dataIndex: 'state',
			title: 'State',
			render: (state: Values<typeof AccountingItemApprovalStates>) => (
				<StateTag state={state} />
			),
			width: 100,
		},
	];

	const brokerOptions = useMemo(() => (brokers || [])
		.sort((a, b) => a.name.toString().localeCompare(b.name))
		.map((b) => ({
			label: b.name,
			value: b.id,
			// Don't allow selecting brokers that are already taken
			disabled: fixture?.brokers?.some(((fixtureBroker) => fixtureBroker.id === b.id)),
		})), [brokers, fixture]);

	const selectedBankAccount = useMemo(() => {
		if (bankAccounts == null) {
			return null;
		}

		return bankAccounts.find((a) => a.id === fixture?.bankAccountId);
	}, [bankAccounts, fixture]);

	const fixtureCurrency = selectedBankAccount == null ?
		userInfo.baseCurrency :
		selectedBankAccount.currency;

	if (fixtureLoading || fixture?.id == null) {
		return [];
	}

	const allowEditKeyTerms = !fixture?.hasHireInvoices;

	const handleCreateExpenseSubjectToHireDays = async (
		values: {
			name: string;
			amount: number;
			interval: Values<typeof ExpensesSubjectToHireDaysIntervals>;
		},
	) => {
		await createExpenseSubjectToHireDays(fixture?.id, values);
		await refreshFixture();
	};

	const handleSaveExpenseSubjectToHireDays = async (
		id: number, values: {
			name: string;
			amount: number;
			interval: Values<typeof ExpensesSubjectToHireDaysIntervals>;
		},
	) => {
		await updateExpenseSubjectToHireDays(fixture?.id, id, values);
		await refreshFixture();
	};

	const handleDeleteExpenseSubjectToHireDays = async (id: number) => {
		await deleteExpenseSubjectToHireDays(fixture?.id, id);
		await refreshFixture();
	};

	const handleCreateRevenueSubjectToDays = async (
		values: { name: string; amount: number },
	) => {
		await createRevenueSubjectToDays(fixture?.id, values);
		await refreshFixture();
	};

	const handleUpdateRevenueSubjectToDays = async (
		id: number, values: { name: string; amount: number },
	) => {
		await updateRevenueSubjectToDays(fixture?.id, id, values);
		await refreshFixture();
	};

	const handleDeleteRevenueSubjectToDays = async (id: number) => {
		await deleteRevenueSubjectToDays(fixture?.id, id);
		await refreshFixture();
	};

	const handleAddBroker = async (
		values: Omit<AddFixtureBrokerRequest, 'fixtureId'>,
	) => {
		await addFixtureBroker(fixture.id, values);
		await refreshFixture();
	};

	const handleUpdateBroker = async (
		brokerId: number,
		values: UpdateFixtureBrokerRequest['attributes'],
	) => {
		await updateFixtureBroker(fixture.id, brokerId, values);
		await refreshFixture();
	};

	const handleRemoveBroker = async (brokerId: number) => {
		await removeFixtureBroker(fixture.id, brokerId);
		await refreshFixture();
	};

	const handleCreateExpense = async (
		account: CreateFixtureExpenseRequest['account'],
		values: Omit<CreateFixtureExpenseRequest, 'fixtureId' | 'account'>,
	) => {
		await createFixtureExpense(fixture.id, { account, ...values });
		await refreshFixture();
	};

	const handleSaveExpense = async (
		id: number,
		values: UpdateFixtureExpenseRequest['attributes'],
	) => {
		await updateFixtureExpense(fixture.id, id, values);
		await refreshFixture();
	};

	const handleDeleteExpense = async (id: number) => {
		await deleteFixtureExpense(fixture.id, id);
		await refreshFixture();
	};

	const getDefaultDate = (
		firstDate: string | Moment | null,
		secondDate: string | Moment | null,
	) => {
		if (firstDate != null) {
			return toMoment(firstDate);
		}

		if (secondDate != null) {
			return toMoment(secondDate);
		}

		return undefined;
	};

	const getRedeliveryStr = (): string => {
		let identifier = '';

		if (
			fixture.prevVoyage?.identifier != null &&
			fixture.prevFixture?.FixtureCounterparty.name != null
		) {
			identifier = `(${fixture.prevVoyage?.identifier} with ${fixture.prevFixture?.FixtureCounterparty.name})`;
		}

		return (
			`The vessel's previous ${fixture.prevFixture != null && FixtureTypeLabels[fixture.prevFixture.type]} voyage ${identifier} redelivered with the following bunkers:`
		);
	};

	return [
		{
			title: 'Key Terms',
			fields: [
				getChartererField({ fixture, required: true }),
				getIdentifierField({ fixture }),
				getVesselField({ fixture, vessels: availableVessels ?? [], required: true }),
				getBankAccountField({ fixture, bankAccounts: bankAccounts ?? [], required: true }),
			],
		},
		{
			title: 'Broker & Commissions',
			fields: [
				getAddressCommissionField({ fixture, allowEditKeyTerms, required: false }),
			],
			content: (
				<>
					<br />
					<EditableTable<BrokersFromFixtureDetails[number], 'id'>
						iconButtons
						pagination={false}
						enableDelete={allowEditKeyTerms}
						enableEdit={allowEditKeyTerms}
						allowAddNew={allowEditKeyTerms}
						addNewText="Broker(s)"
						emptyText="No brokers"
						dataSource={fixture.brokers}
						onSave={handleUpdateBroker}
						onAddNew={handleAddBroker}
						onDelete={handleRemoveBroker}
						editingRowClassName={styles.editingBroker}
						keyDataIndex="id"
						useCards={screens.xs}
						columns={[
							{
								dataIndex: 'brokerId',
								title: 'Broker',
								editable: true,
								render: (id: number) => (brokers ?? []).find((b) => b.id === id)?.name,
								editingProps: {
									type: 'select',
									inputProps: {},
									options: brokerOptions,
									renderExtra: ({ form }) => (
										<FormPopover
											title="Create broker"
											fields={[
												{
													label: 'Name',
													name: 'name',
													type: 'text',
													required: true,
												},
											]}
											onSubmit={async (values: { name: string }) => {
												const broker = await createBroker(values.name);
												await refreshBrokers();
												if (form == null || broker == null) {
													return;
												}

												form.setFieldsValue({ brokerId: broker.id });
											}}
											buttonText="Submit"
										>
											<Button type="link" className={styles.createBrokerButton}>Create new broker</Button>
										</FormPopover>
									),
								},
							},
							{
								dataIndex: 'commission',
								title: 'Commission',
								editable: true,
								editingProps: {
									type: 'percentage',
									inputProps: {},
								},
								render: (c: number) => c != null && formatPercentage(c),
							},
							{
								dataIndex: 'paidBy',
								title: 'Paid by',
								editable: true,
								render: (a: string) => capitalize(a),
								editingProps: {
									type: 'select',
									inputProps: {},
									options: [
										{
											label: (
												<>
													Charterer
													<TooltipIcon>
														Broker is paid by the charterer.
														<br />
														Broker commission is deducted from the hire invoice.
													</TooltipIcon>
												</>
											),
											value: AccountTypes.CHARTERER,
										},
										{
											label: (
												<>
													Owner
													<TooltipIcon>
														Broker is paid by the owner.
														<br />
														Broker commission is
														<strong>{' not '}</strong>
														deducted from the hire invoice.
													</TooltipIcon>
												</>
											),
											value: AccountTypes.OWNER,
										},
									],
									defaultValue: AccountTypes.CHARTERER,
								},
							},
						]}
					/>
				</>
			),
		},
		{
			title: 'CP',
			fields: [
				getCpDateField({ fixture, required: false }),
				getCpFormField({ fixture, required: false }),
			],
		},
		{
			title: 'Ports & Laycan',
			fields: [
				{
					key: 'deliveryPortsOrRanges',
					label: 'Delivery Port(s) / Range(s)',
					value: [
						...fixture.deliveryPortsOrRanges.map((p) => p.id),
						...fixture.customDeliveryPorts,
					],
					editable: true,
					required: false,
					type: 'select',
					options: portsAndRangesOptions,
					multiple: true,
					transformResult: (ports: (string | number)[]) => {
						const customPorts = ports.filter((port): port is string => typeof port === 'string');
						const regularPorts = ports.filter((port): port is number => typeof port === 'number');

						return {
							deliveryPortsOrRanges: regularPorts,
							customDeliveryPorts: customPorts,
						};
					},
					inputProps: {
						showSearch: true,
						onSearch: (port: string) => {
							setPortSearchValue(port);
						},
					},
					span: 3,
				},
				{
					key: 'redeliveryPortsOrRanges',
					label: 'Redelivery Port(s) / Range(s)',
					value: [
						...fixture.redeliveryPortsOrRanges.map((p) => p.id),
						...fixture.customRedeliveryPorts,
					],
					editable: true,
					required: false,
					type: 'select',
					options: portsAndRangesOptions,
					multiple: true,
					transformResult: (ports: (string | number)[]) => {
						const customPorts = ports.filter((port): port is string => typeof port === 'string');
						const regularPorts = ports.filter((port): port is number => typeof port === 'number');

						return {
							redeliveryPortsOrRanges: regularPorts,
							customRedeliveryPorts: customPorts,
						};
					},
					inputProps: {
						showSearch: true,
						onSearch: (port: string) => {
							setPortSearchValue(port);
						},
					},
					span: 3,
				},
				getLaycanFromField({
					fixture,
					required: true,
					defaultLaycanUtcOffset,
					setDefaultLaycanUtcOffset,
					defaultPickerValue,
					setDefaultPickerValue,
				}),
				getLaycanToField({
					fixture,
					required: true,
					defaultLaycanUtcOffset,
					setDefaultLaycanUtcOffset,
					defaultPickerValue,
					setDefaultPickerValue,
				}),
			],
		},
		{
			title: 'Duration',
			header: fixture.vesselTimelineData?.fixtures?.length > 0 && (
				<>
					<VesselTimeline
						monthsToShow={6}
						data={fixture?.vesselTimelineData}
					/>
					<Divider />
				</>
			),
			fields: [
				{
					key: 'basePeriod',
					label: 'Base Period',
					render: () => null,
					span: 3,
					labelStyle: { width: '30%' },
				},
				{
					key: 'basePeriodMin',
					label: '— Minimum',
					value: [
						fixture.durationMinimumValue,
						fixture.durationMinimumUnit,
						fixture.durationMinimumVariance,
					],
					type: 'period',
					transformResult: ([periodValue, unit, variance]: [number | string, string, number]) => ({
						durationMinimumVariance: variance,
						durationMinimumValue: periodValue === '' ? null : periodValue,
						durationMinimumUnit: unit,
					}),
					render: (
						{
							value: [value, unit, variance],
						}: {
							value: [number | string, string, number];
						},
					) => `${value} ${unit} ± ${variance} days`,
					editable: !fixture.completed,
					span: 4,
					required: true,
					inputProps: {
						valueInputProps: {
							disabled: minDurationDateExact,
						},
						unitInputProps: {
							disabled: minDurationDateExact,
						},
						varianceInputProps: {
							disabled: minDurationDateExact,
						},
						labelRight: (
							<div>
								{
									minDurationDateExact ? (
										<>
											<DatePicker
												disabled={!minDurationDateExact}
												time
												defaultValue={getDefaultDate(
													fixture.minimumDurationExact,
													fixture.estimatedRedeliveryDate,
												)}
												onChange={(val) => {
													updateFixture(fixture.id, {
														'minimumDurationExact': val as Moment | null | undefined,
													}).then(refreshFixture);
												}}
												range={false}
											/>
											<Button
												className={styles.exactDateWithPicker}
												type="link"
												onClick={() => {
													setMinDurationDateExact(false);
													if (minDurationDateExact) {
														updateFixture(fixture.id, { 'minimumDurationExact': null }).then(refreshFixture);
													}
												}}
											>
												Use date estimate
											</Button>
										</>
									) : (
										<div>
											{fixture.estimatedRedeliveryDate != null ? (
												<p>{formatDate(fixture.estimatedRedeliveryDate, DATE_AND_TIME)}</p>
											) : (
												<em>No date set</em>
											)}
											<Button
												className={styles.exactDateWithoutPicker}
												type="link"
												onClick={() => setMinDurationDateExact(true)}
											>
												Set exact date
											</Button>
										</div>
									)
								}
							</div>
						),
					},
					labelStyle: { width: '30%', height: '90px' },
				},
				{
					key: 'basePeriodMax',
					label: '— Maximum',
					value: [
						fixture.durationMaximumValue,
						fixture.durationMaximumUnit,
						fixture.durationMaximumVariance,
					],
					transformResult: ([periodValue, unit, variance]: [number | string, string, number]) => ({
						durationMaximumVariance: variance,
						durationMaximumValue: periodValue === '' ? null : periodValue,
						durationMaximumUnit: unit,
					}),
					render: (
						{
							value: [value, unit, variance],
						}: {
							value: [number | string, string, number];
						},
					) => `${value} ${unit} ± ${variance} days`,
					type: 'period',
					span: 4,
					editable: !fixture.completed,
					required: true,
					inputProps: {
						valueInputProps: {
							disabled: maxDurationDateExact,
						},
						unitInputProps: {
							disabled: maxDurationDateExact,
						},
						varianceInputProps: {
							disabled: maxDurationDateExact,
						},
						labelRight: (
							<div>
								{
									maxDurationDateExact ? (
										<>
											<DatePicker
												disabled={!maxDurationDateExact}
												time
												defaultValue={getDefaultDate(
													fixture.maximumDurationExact,
													fixture.maxRedeliveryDate,
												)}
												onChange={(val) => {
													updateFixture(fixture.id, {
														'maximumDurationExact': val as Moment | null | undefined,
													}).then(refreshFixture);
												}}
												range={false}
											/>
											<Button
												className={styles.exactDateWithPicker}
												type="link"
												onClick={() => {
													setMaxDurationDateExact(false);
													if (maxDurationDateExact) {
														updateFixture(fixture.id, { 'maximumDurationExact': null }).then(refreshFixture);
													}
												}}
											>
												Use date estimate
											</Button>
										</>
									) : (
										<div>
											{fixture.maxRedeliveryDate != null ? (
												<p>{formatDate(fixture.maxRedeliveryDate, DATE_AND_TIME)}</p>
											) : (
												<em>No date set</em>
											)}
											<Button
												className={styles.exactDateWithoutPicker}
												type="link"
												onClick={() => setMaxDurationDateExact(true)}
											>
												Set exact date
											</Button>
										</div>
									)
								}
							</div>
						),
					},
					labelStyle: { width: '30%', height: '90px' },
				},
				{
					key: 'durationDescription',
					label: '— Description',
					value: fixture.durationDescription,
					type: 'textarea',
					span: 4,
					editable: true,
					required: false,
					labelStyle: { width: '22%' },
				},
			],
		},
		{
			title: 'Hire',
			fields: [
				{
					key: 'hireType',
					label: 'Hire Type',
					type: 'select',
					value: fixture.hireType,
					options: Object.values(HireTypes).map((t) => ({
						label: capitalize(t),
						value: t,
					})),
					editable: true,
					required: true,
				},
				{
					key: 'hireUnit',
					label: 'Hire unit',
					type: 'select',
					value: fixture.hireUnit === HireUnit.MONTHS ? ' Per month ' : ' Per day ',
					options: Object.values(HireUnit).map((value) => ({
						label: fixture.fixed ? (
							<Tooltip title="Cannot edit this field when estimate is fixed">
								{capitalize(value)}
							</Tooltip>
						) : capitalize(value),
						value,
					})),
					editable: !fixture.fixed,
					required: true,
				},
				{
					key: 'hireRate',
					label: 'Hire rate',
					type: 'currency',
					value: fixture.useRateSchedule ? null : fixture.hireRate,
					render: (i: { value: number }) => {
						if (fixture.useRateSchedule) {
							return ' - ';
						}

						return formatCurrency(i.value, fixtureCurrency);
					},
					inputProps: {
						key: `rate-${fixture.hireRate}`,
						returnNullOnEmpty: true,
						disabled: fixture.useRateSchedule,
						disabledTooltip: fixture.useRateSchedule ? 'This estimate uses a rate schedule' : null,
						tooltip: 'test',
						currency: fixtureCurrency,
						addonAfter: fixture.hireUnit === HireUnit.MONTHS ? '/ month' : '/ day',
					},
					editable: allowEditKeyTerms,
				},
				{
					key: 'hireDescription',
					label: 'Hire Description',
					value: fixture.hireDescription,
					type: 'textarea',
					editable: allowEditKeyTerms,
				},
				{
					key: 'grossBallastBonus',
					label: 'Gross Ballast Bonus',
					type: 'currency',
					value: fixture.grossBallastBonus,
					render: (i: { value: number }) => formatCurrency(i.value || 0, fixtureCurrency),
					inputProps: {
						placeholder: 'No Bonus',
						returnNullOnEmpty: true,
						currency: fixtureCurrency,
					},
					editable: allowEditKeyTerms,
				},
				{
					key: 'paymentTermValue',
					label: 'Payment Terms',
					value: fixture.paymentTermValue,
					editable: true,
					renderInput: ({ value, onChange }: {value: string; onChange: () => void}) => (
						<Space>
							<NumericInput
								// eslint-disable-next-line react/forbid-component-props
								style={{ width: 50 }}
								value={Number(value)}
								onChange={onChange}
							/>
							<div>Days from</div>
							<Select
								// eslint-disable-next-line react/forbid-component-props
								style={{ width: 250 }}
								allowClear={false}
								value={fixture.paymentTerm}
								onChange={(val: string | null) => {
									if (val != null) {
										updateFixture(fixture.id, {
											'paymentTerm': val,
										}).then(refreshFixture);
									}
								}}
								options={[{
									label: PaymentTermLabels[PaymentTerms.PERIOD_START],
									value: PaymentTerms.PERIOD_START,
								}, {
									label: PaymentTermLabels[PaymentTerms.PERIOD_END],
									value: PaymentTerms.PERIOD_END,
								}]}
								placeholder="Select term"
							/>
						</Space>
					),
				},
				{
					key: 'hireHeader',
					label: (<b>Days per hire period</b>),
					render: () => null,
				},
				{
					key: 'useUTC',
					label: 'UTC / Local time',
					type: 'select',
					value: fixture.useUTC,
					editable: true,
					renderInput: ({ value, onChange }: { value: number; onChange: () => void }) => {
						if (fixture.hireUnit === HireUnit.MONTHS) {
							return (
								<Tooltip title="Cannot edit this field when hire unit is set to Months">
									<Select
										value=" - "
										disabled
									/>
								</Tooltip>
							);
						}

						return (
							<Select
								value={value}
								onChange={onChange}
								options={[
									{ label: 'UTC', value: true },
									{ label: 'Local time', value: false },
								]}
							/>
						);
					},
				},
				{
					key: 'invoicePerCalenderMonth',
					label: '— Full calendar month (PCM)',
					type: 'switch',
					renderInput: ({ value, onChange }: { value: boolean; onChange: () => void }) => {
						return (
							<Tooltip title={`${fixture.hireUnit === HireUnit.MONTHS ? 'Cannot edit this field when hire unit is set to Months' : ''}`}>
								<Switch
									checked={value || false}
									disabled={fixture.hireUnit === HireUnit.MONTHS}
									onChange={onChange}
								/>
							</Tooltip>
						);
					},
					value: fixture.invoicePerCalenderMonth,
					editable: allowEditKeyTerms,
				},
				{
					key: 'daysPerHirePeriod',
					label: '— Or enter manually',
					value: fixture.daysPerHirePeriod,
					type: 'number',
					renderInput: ({ value, onChange }: { value: number; onChange: () => void }) => {
						if (fixture.invoicePerCalenderMonth || fixture.hireUnit === HireUnit.MONTHS) {
							return (
								<Tooltip title="Cannot edit this field when PCM is on or hire unit is set to Months">
									<Input
										value=""
										disabled
										addonAfter="Days"
									/>
								</Tooltip>
							);
						}

						return (
							<NumericInput
								value={value}
								onChange={onChange}
								addonAfter="Days"
								min={1}
								returnNullOnEmpty
							/>
						);
					},
					editable: allowEditKeyTerms,
				},
			],
		},
		{
			title: 'Rate schedule',
			fields: [
				{
					key: 'useRateSchedule',
					label: 'Use rate schedule',
					type: 'switch',
					editable: true,
					render: () => (fixture.useRateSchedule ? 'Using rate schedule' : 'Not selected'),
					value: fixture.useRateSchedule,
				},
			],
			content: fixture.useRateSchedule ? (
				<RateScheduleTable
					fixture={fixture}
					fixtureCurrency={fixtureCurrency}
					setRateScheduleDrawerOpen={setRateScheduleDrawerOpen}
					refreshFixture={refreshFixture}
				/>
			) : (<p>Enable rate schedule to start editing the schedule</p>),
		},
		{
			title: 'Expenses, C/V/E & Other Revenue',
			isFilled: (
				fixture.expensesSubjectToHireDays.length > 0 &&
				fixture.ownerExpenses.length > 0 &&
				fixture.chartererExpenses.length > 0
			),
			fields: [
				{
					key: 'calculationMethodForExpensesSubjectToHireDays',
					label: 'Calculation method for hire days',
					labelSuffix: (
						<TooltipIcon>This applies only to expenses subject to hire days</TooltipIcon>
					),
					value: fixture.calculationMethodForExpensesSubjectToHireDays,
					type: 'select',
					editable: allowEditKeyTerms,
					required: true,
					inputProps: {
						placeholder: 'C/V/E Formula',
						style: {
							height: '55px',
						},
					},
					options: [
						{
							label: (
								<CVEFormulaLabel type={CalculationMethodForExpensesSubjectToHireDays.ANNUALLY} />
							),
							value: CalculationMethodForExpensesSubjectToHireDays.ANNUALLY,
						},
						{
							label: (
								<CVEFormulaLabel
									type={CalculationMethodForExpensesSubjectToHireDays.MONTHLY}
								/>
							),
							value: CalculationMethodForExpensesSubjectToHireDays.MONTHLY,
						},
						{
							label: 'Fixed',
							value: CalculationMethodForExpensesSubjectToHireDays.FIXED,
						},
					],
				},

			],
			content: (
				<>
					<br />
					<EditableTable
						iconButtons
						pagination={false}
						enableDelete={() => allowEditKeyTerms}
						enableEdit={() => allowEditKeyTerms}
						allowAddNew={allowEditKeyTerms}
						addNewText="Expenses subject to hire days"
						emptyText={(<>No expenses yet</>)}
						dataSource={fixture.expensesSubjectToHireDays}
						onSave={handleSaveExpenseSubjectToHireDays}
						onAddNew={handleCreateExpenseSubjectToHireDays}
						onDelete={handleDeleteExpenseSubjectToHireDays}
						keyDataIndex="id"
						useCards={screens.xs}
						columns={
							[
								{
									dataIndex: 'name',
									title: 'Name',
									editable: true,
								},
								{
									dataIndex: 'amount',
									title: 'Amount',
									editable: true,
									editingProps: {
										type: 'currency',
										inputProps: {
											currency: fixtureCurrency,
										},
									},
									render: (c: number) => c != null && (
										<>
											{formatCurrency(
												c,
												fixtureCurrency,
												{ forceDecimals: true },
											)}
										</>
									),
								},
								{
									dataIndex: 'interval',
									title: 'Interval',
									render: (interval: Values<typeof ExpensesSubjectToHireDaysIntervals>) => (
										ExpensesSubjectToHireDaysIntervalLabels[interval]
									),
									editable: true,
									editingProps: {
										type: 'select',
										defaultValue: 'pcm',
										inputProps: {},
										options: [
											{ label: 'Per calendar month', value: 'pcm' },
											{ label: 'Per day', value: 'perDay' },
										],
									},
								},
							]
						}
					/>
					<br />
					<br />
					<EditableTable
						iconButtons
						pagination={false}
						enableDelete={() => allowEditKeyTerms}
						enableEdit={() => allowEditKeyTerms}
						allowAddNew={allowEditKeyTerms}
						addNewText="Revenues subject to days"
						emptyText={(<>No revenue items yet</>)}
						dataSource={fixture.revenuesSubjectToDays}
						onSave={handleUpdateRevenueSubjectToDays}
						onAddNew={handleCreateRevenueSubjectToDays}
						onDelete={handleDeleteRevenueSubjectToDays}
						keyDataIndex="id"
						useCards={screens.xs}
						columns={
							[
								{
									dataIndex: 'name',
									title: 'Name',
									editable: true,
								},
								{
									dataIndex: 'amount',
									title: 'Amount (per day)',
									editable: true,
									editingProps: {
										type: 'currency',
										inputProps: {
											currency: fixtureCurrency,
											addonAfter: 'Per day',
											precision: 3,
										},
									},
									render: (c: number) => c != null && formatCurrency(
										c,
										fixtureCurrency,
										{ forceDecimals: true },
									),
								},
							]
						}
					/>
					<br />
					<br />
					<EditableTable
						iconButtons
						pagination={false}
						enableDelete={() => true}
						enableEdit={() => true}
						allowAddNew
						addNewText="Charterer's expenses"
						emptyText={(<>No charterers expenses</>)}
						dataSource={fixture.chartererExpenses}
						onAddNew={(values) => handleCreateExpense(AccountTypes.CHARTERER, values)}
						onSave={handleSaveExpense}
						onDelete={handleDeleteExpense}
						keyDataIndex="id"
						useCards={screens.xs}
						columns={
							[
								{
									dataIndex: 'name',
									title: 'Name',
									editable: true,
								},
								{
									dataIndex: 'amount',
									title: 'Amount',
									editable: true,
									editingProps: {
										type: 'currency',
										inputProps: {
											currency: fixtureCurrency,
										},
									},
									render: (c: number) => c != null && formatCurrency(
										c,
										fixtureCurrency,
										{ forceDecimals: true },
									),
								},
							]
						}
					/>
					<br />
					<br />
					<EditableTable
						iconButtons
						pagination={false}
						enableDelete={() => allowEditKeyTerms}
						enableEdit={() => allowEditKeyTerms}
						allowAddNew={allowEditKeyTerms}
						addNewText="Owner's expenses"
						emptyText={(<>No owners expenses</>)}
						dataSource={fixture.ownerExpenses}
						onAddNew={(values) => handleCreateExpense(AccountTypes.OWNER, values)}
						onSave={handleSaveExpense}
						onDelete={handleDeleteExpense}
						keyDataIndex="id"
						useCards={screens.xs}
						columns={
							[
								{
									dataIndex: 'name',
									title: 'Name',
									editable: true,
								},
								{
									dataIndex: 'amount',
									title: 'Amount',
									editable: true,
									editingProps: {
										type: 'currency',
										inputProps: {
											currency: fixtureCurrency,
										},
									},
									render: (c: number) => c != null && formatCurrency(
										c,
										fixtureCurrency,
										{ forceDecimals: true },
									),
								},
							]
						}
					/>
				</>
			),
		},
		{
			title: 'Bunkers',
			isFilled: fixture.bunkers.length > 0,
			required: false,
			content: (
				<Space direction="vertical" className={styles.fullWidth}>
					{fixture.prevRedeliveryBunkers.length > 0 && (
						<>
							<Alert
								message="Redelivery bunkers"
								description={(
									<>
										{getRedeliveryStr()}
										<br />
										{fixture.prevRedeliveryBunkers.map((b) => {
											const bunker = b.Bunker;

											return (
												<b>
													{`- ${bunker.quantity}MT ${bunker.fuelGrade} at ${formatCurrency(bunker.pricePerTon, fixtureCurrency)}/MT`}
													<br />
												</b>
											);
										})}
									</>
								)}
								type="info"
								showIcon
							/>
							<Divider />
						</>
					)}
					<BunkersTable<VoyageBunkerEntry>
						childType={ChildBunkerTypes.VOYAGE}
						// @ts-ignore i know, i know - will fix. System needs a rework.
						bunkers={fixture.bunkers}
						vesselOrVoyageId={fixture.voyageId}
						refreshDetails={refreshFixture}
						currency={fixtureCurrency}
						columns={bunkerColumns}
						size="small"
					/>
				</Space>
			),
		},
		...(reportGroups != null ? [
			{
				title: 'Report Groups',
				fields: [
					{
						key: 'holdingReportGroup',
						label: (
							<p>
								Holding Report Group
								<TooltipIcon>
									These are set by management. If no values are present, contact management.
								</TooltipIcon>
							</p>
						),
						type: 'select',
						editable: allowEditKeyTerms,
						value: fixture.holdingReportGroup,
						inputProps: {
							disabled:
								reportGroups?.holdingReportGroups == null ||
								reportGroups?.holdingReportGroups?.length === 0,
							placeholder: 'Holding report group',
						},
						options: reportGroups?.holdingReportGroups?.map((g) => ({
							value: g,
							label: g,
						})) ?? [],
						render: () => null,
					},
					{
						key: 'investorReportGroup',
						label: (
							<p>
								Investor Report Group
								<TooltipIcon>
									These are set by management. If no values are present, contact management.
								</TooltipIcon>
							</p>
						),
						type: 'select',
						editable: allowEditKeyTerms,
						value: fixture.investorReportGroup,
						inputProps: {
							disabled:
								reportGroups?.investorReportGroups == null ||
								reportGroups?.investorReportGroups?.length === 0,
							placeholder: 'Investor report group',
						},
						options: reportGroups?.investorReportGroups?.map((g) => ({
							value: g,
							label: g,
						})) ?? [],
						render: () => null,
					},
					{
						key: 'clientReportGroup',
						label: (
							<p>
								Client Report Group
								<TooltipIcon>
									These are set by management. If no values are present, contact management.
								</TooltipIcon>
							</p>
						),
						type: 'select',
						editable: allowEditKeyTerms,
						value: fixture.clientReportGroup,
						inputProps: {
							disabled:
								reportGroups?.clientReportGroups == null ||
								reportGroups?.clientReportGroups?.length === 0,
							placeholder: 'Client report group',
						},
						options: reportGroups?.clientReportGroups?.map((g) => ({
							value: g,
							label: g,
						})) ?? [],
						render: () => null,
					},
				],
			},
		] : []),
		{
			title: 'Notices',
			fields: [
				{
					key: 'deliveryNoticesHeader',
					label: 'Delivery',
					render: () => null,
				},
				{
					key: 'deliveryNoticesApproximate',
					label: '— Notices Approx.',
					type: 'numberList',
					value: [
						...(Array.isArray(fixture.deliveryNoticesApproximate) ?
							fixture.deliveryNoticesApproximate : []
						)].sort((a, b) => b - a),
					editable: true,
				},
				{
					key: 'deliveryNoticesDefinite',
					label: '— Notices Definite',
					type: 'numberList',
					value: [
						...(Array.isArray(fixture.deliveryNoticesDefinite) ?
							fixture.deliveryNoticesDefinite : []
						)].sort((a, b) => b - a),
					editable: true,
				},
				{
					key: 'redeliveryNoticesHeader',
					label: 'Redelivery',
					render: () => null,
				},
				{
					key: 'redeliveryNoticesApproximate',
					label: '— Notices Approx.',
					type: 'numberList',
					value: [
						...(Array.isArray(fixture.redeliveryNoticesApproximate) ?
							fixture.redeliveryNoticesApproximate : []
						)].sort((a, b) => b - a),
					editable: true,
				},
				{
					key: 'redeliveryNoticesDefinite',
					label: '— Notices Definite',
					type: 'numberList',
					value: [
						...(Array.isArray(fixture.redeliveryNoticesDefinite) ?
							fixture.redeliveryNoticesDefinite : []
						)].sort((a, b) => b - a),
					editable: true,
				},
			],
		},
		{
			title: 'Notes',
			fields: [
				getNotesField({ fixture, required: false }),
			],
		},
	];
};
