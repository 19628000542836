import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Currencies } from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import {
	round,
	roundFixedDecimals,
} from '@shared/utils/math';
import { Values } from '@shared/utils/objectEnums';
import { TablePnlEntry } from '../VcPnlTab';

const numberField = (v: number) => (v == null ? ' ' : round(v, 2));

const getPnlTableColumns = (currency: Values<typeof Currencies>): ColumnsType<TablePnlEntry> => {
	const currencyField = (v: number) => (v == null ? ' ' : formatCurrency(v, currency, { forceDecimals: true, maximumFractionDigits: 2 }));

	return [
		{
			title: 'Description',
			dataIndex: 'item',
			key: 'item',
			render: (item: string, entry: TablePnlEntry) => (entry.bold ? (<b>{item}</b>) : item),
		},
		{
			title: 'Estimated',
			dataIndex: 'estimated',
			key: 'estimated',
			render: (v: number, entry: TablePnlEntry) => (
				entry.number != null ? numberField(v) : currencyField(v)
			),
			width: 150,
			align: 'right',
		},
		{
			title: 'Actual',
			dataIndex: 'actual',
			key: 'actual',
			render: (v: number, entry: TablePnlEntry) => (
				entry.number != null ? numberField(v) : currencyField(v)
			),
			width: 150,
			align: 'right',
		},
		{
			title: 'Variance',
			key: 'varAmount',
			render: (entry: TablePnlEntry) => {
				const v = entry.actual - entry.estimated;

				return entry.number != null ? numberField(v) : currencyField(v);
			},
			align: 'right',
			width: 150,
		},
		{
			title: '%',
			key: 'varPercentage',
			render: (entry: TablePnlEntry) => {
				const variance = entry.actual - entry.estimated;
				const varianceInPercentage = entry.estimated === 0 ? 0 : (variance / entry.estimated) * 100;

				return `${roundFixedDecimals(varianceInPercentage, 2)}%`;
			},
			align: 'right',
			width: 80,
		},
	];
};

export default getPnlTableColumns;
