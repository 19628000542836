import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from 'antd';
import {
	toMoment,
	nowMoment,
} from '@shared/utils/date';
import { DATE_AND_TIME } from '@shared/utils/constants';
import { formatDate } from '@client/utils/formatDate';
import VesselDescription from '@client/components/VesselDescription';
import { getVoyageEvents } from '@client/lib/api';
import { Links } from '@client/utils/links';
import { useAuth } from '@client/lib/auth';
import TableWidget from '../components/TableWidget';

const getColumns = () => [
	{
		key: 'vesselName',
		dataIndex: 'vesselName',
		title: 'Vessel',
		render: (_, voyage) => (
			<VesselDescription
				name={voyage.vesselName}
				flag={voyage.vesselFlag}
			/>
		),
	}, {
		key: 'voyage',
		dataIndex: 'voyageIdentifier',
		title: 'Contract',
		render: (voyageIdentifier, { voyageId }) => (
			<Link to={Links.Voyage.get(voyageId)}>
				{voyageIdentifier}
			</Link>
		),
	},
	{
		key: 'event',
		dataIndex: 'event',
		title: 'Event',
	},
	{
		key: 'date',
		dataIndex: 'date',
		title: 'Date (UTC)',
		render: (d) => (
			<>
				{formatDate(d, DATE_AND_TIME, false)}
				<br />
				<em>
					<small>
						{toMoment(d).from(nowMoment().startOf('day'))}
					</small>
				</em>
			</>
		),
	},
];

const VoyageEventsTableWidget = () => {
	const { userInfo } = useAuth();
	const screens = Grid.useBreakpoint();

	const vesselFilter = userInfo.filterVessels && userInfo.relevantVessels.length > 0 ?
		userInfo.relevantVessels.map((v) => v.name) :
		null;

	const columns = getColumns(screens);

	return (
		<TableWidget
			data-cy="voyageEventsTableWidget"
			header="Upcoming Contract Events"
			fetchData={getVoyageEvents}
			fetchDataParams={[]}
			transformData={(vesselFilter != null) && (
				(data) => data.filter((e) => vesselFilter.some((v) => v === e.vesselName))
			)}
			columns={columns}
			rowKey={(row) => `${row.voyageId}-${row.event}`}
			tableProps={{
				useCards: screens.xs,
				pagination: {
					hideOnSinglePage: true,
					pageSize: 5,
				},
				emptyText: 'No upcoming events',
				size: 'small',
			}}
		/>
	);
};

export default VoyageEventsTableWidget;
