// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WUjNdmi5aMsqqWA5E1C8 {\n\tborder: 5px solid rgba(182, 182, 182, 0.26);\n\tborder-radius: 10px;\n}\n\n.c0yLvIxjrFMqpIl61sBn .ant-typography {\n\tdisplay: flex;\n\ttext-align: center;\n\tjustify-content: center;\n\tmargin: 0;\n\tcolor: #2848c0;\n}\n\n.KS0HIN9_L4EhWC9g56Pi {\n\tposition: absolute;\n\tleft: 0;\n\tright: 0;\n\ttop: 0;\n\tbottom: 0;\n\tbackground-color: #ccc3;\n\tz-index: 10;\n}\n\n.OPq2SqMw5lqCbCGEhdl9 {\n\twhite-space: pre;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/general/HomeScreen/dashboards/FleetPnlDashboard.module.css"],"names":[],"mappings":"AAAA;CACC,2CAA2C;CAC3C,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,kBAAkB;CAClB,uBAAuB;CACvB,SAAS;CACT,cAAc;AACf;;AAEA;CACC,kBAAkB;CAClB,OAAO;CACP,QAAQ;CACR,MAAM;CACN,SAAS;CACT,uBAAuB;CACvB,WAAW;AACZ;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".profit {\n\tborder: 5px solid rgba(182, 182, 182, 0.26);\n\tborder-radius: 10px;\n}\n\n.columnTitle :global(.ant-typography) {\n\tdisplay: flex;\n\ttext-align: center;\n\tjustify-content: center;\n\tmargin: 0;\n\tcolor: #2848c0;\n}\n\n.loadingOverlay {\n\tposition: absolute;\n\tleft: 0;\n\tright: 0;\n\ttop: 0;\n\tbottom: 0;\n\tbackground-color: #ccc3;\n\tz-index: 10;\n}\n\n.formulaTooltip {\n\twhite-space: pre;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profit": "WUjNdmi5aMsqqWA5E1C8",
	"columnTitle": "c0yLvIxjrFMqpIl61sBn",
	"loadingOverlay": "KS0HIN9_L4EhWC9g56Pi",
	"formulaTooltip": "OPq2SqMw5lqCbCGEhdl9"
};
export default ___CSS_LOADER_EXPORT___;
