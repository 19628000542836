import React, {
	useMemo,
	useState,
} from 'react';
import {
	Alert,
	Dropdown,
	Grid,
	Menu,
	Popover,
	Space,
} from 'antd';
import { Moment } from 'moment';
import {
	AccountingItemApprovalStates,
	FixtureTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import AddButton from '@client/components/AddButton';
import Table from '@client/components/Table/Table';
import getInvoicePresets from '@client/utils/getInvoicePresets';
import Card from '@client/components/Card/Card';
import { getHireInvoiceColumns } from '@client/screens/fleet/VoyageDetailsScreen/helpers/tableColumns';
import DatePicker, { DatePickerValue } from '@client/components/DatePicker';
import Button from '@client/components/Button';
import {
	submitHireInvoice,
	updateVoyage,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import HireInvoiceForm from './HireInvoiceForm';
import {
	onDeleteHireInvoice,
	onUnsubmitHireInvoice,
} from './helpers/invoiceFunctions';

const HireInvoicesTable = ({
	voyageDetails,
	fixtureDetails,
	refreshDetails,
	loading,
} : {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps>;
	refreshDetails: () => void;
	loading: boolean;
}) => {
	const [open, setOpen] = useState<boolean | Values<typeof getInvoicePresets> >(false);
	const [showDeliveryDateForm, setShowDeliveryDateForm] = useState<boolean>(false);
	const [deliveryDate, setDeliveryDate] = useState<DatePickerValue | null>(null);

	const invoices = voyageDetails.hireInvoices;

	const {
		readyForCumulative,
	} = voyageDetails;

	const screens = Grid.useBreakpoint();

	const expandedRowKeys = useMemo(() => {
		return (invoices ?? []).reduce<Array<number>>((acc, h) => {
			if (h.state === AccountingItemApprovalStates.REJECTED) {
				acc.push(h.id);
			}

			return acc;
		}, []);
	}, [invoices]);

	const hireInvoiceColumns = getHireInvoiceColumns({
		onDelete: async (id) => {
			await onDeleteHireInvoice(id);
			await refreshDetails();
		},
		onSubmit: async (id) => {
			if (invoices != null) {
				const requiresApproval = invoices[0]?.requiresApproval;
				const successMsg = requiresApproval ? 'Submitted for approval' : 'Invoice approved';
				await submitHireInvoice(id);
				showSuccessNotification(successMsg);
				await refreshDetails();
			}
		},
		onUndo: async (id) => {
			await onUnsubmitHireInvoice(id);
			refreshDetails();
		},
		voyageDetails,
		fixtureDetails,
	});

	const hasDeliveryDate = voyageDetails.deliveryDate != null;

	const presets = getInvoicePresets(fixtureDetails);

	const saveDeliveryDate = async () => {
		if (deliveryDate != null) {
			try {
				await updateVoyage(voyageDetails.id, { deliveryDate: deliveryDate as Moment });
				refreshDetails();
			} catch (e) {
				if (deliveryDate !== undefined) {
					showErrorNotification('Could not update contract', e as Error);
				}
			}
		}

		setShowDeliveryDateForm(false);
	};

	const addButton = (
		<AddButton
			disabledTooltip="Cannot generate hire invoices before delivery date is set"
			data-tour="createHireInvoice"
			loading={loading}
		>
			{(fixtureDetails.type === FixtureTypes.TC_IN ?
				'New Statement' :
				'New Invoice'
			)}
		</AddButton>
	);

	const getExtraContent = () => {
		if (!readyForCumulative) {
			return (
				<Dropdown
					trigger={['click']}
					overlay={hasDeliveryDate ? (
						<Menu>
							{Object.entries(presets).map(([key, preset]) => (
								<Menu.Item
									key={key}
									onClick={() => setOpen(preset)}
								>
									{preset?.btnTitle}
								</Menu.Item>
							))}
						</Menu>
					) : <></>}
				>
					{!hasDeliveryDate ? (
						<Popover
							trigger={['click']}
							placement="bottomLeft"
							title={(
								<Space direction="vertical">
									Set Delivery Date
								</Space>
							)}
							visible={showDeliveryDateForm}
							onVisibleChange={(v) => setShowDeliveryDateForm(v)}
							content={(
								<Space direction="vertical">
									<Alert
										message="You must set a delivery date to raise an invoice"
										type="warning"
										showIcon
									/>
									<br />
									<div style={{ textAlign: 'center' }}>
										<Space>
											Delivery Date
											<DatePicker
												defaultPickerValue={voyageDetails.commencementDate || undefined}
												maxDate={voyageDetails.completionDate || undefined}
												time
												defaultUtcOffset={voyageDetails.commencementPortUtcOffset}
												showNow={false}
												onChange={(v) => setDeliveryDate(v)}
											/>
										</Space>
									</div>
									<br />
									<div style={{ textAlign: 'center' }}>
										<Button
											onClick={() => saveDeliveryDate()}
											type="primary"
											disabled={deliveryDate === null}
											disabledTooltip="Please select a date before saving"
										>
											Save
										</Button>
									</div>
									<br />
								</Space>
							)}
							destroyTooltipOnHide
						>
							{addButton}
						</Popover>
					) : addButton}
				</Dropdown>
			);
		}

		if (fixtureDetails.fixed && readyForCumulative) {
			return (
				<AddButton
					onClick={() => setOpen(true)}
					data-tour="createHireInvoice"
				>
					New Cumulative Statement
				</AddButton>
			);
		}

		return null;
	};

	return (
		<>
			<Card
				slim
				title={(fixtureDetails.type === FixtureTypes.TC_IN ?
					'Hire Statements' :
					'Hire Invoices'
				)}
				extra={getExtraContent()}
			>
				<Table
					data-tour="hireInvoicesTable"
					size="small"
					rowKey="id"
					pagination={false}
					// @ts-ignore TODO
					columns={hireInvoiceColumns}
					loading={loading}
					dataSource={invoices ?? []}
					useCards={screens.xs}
					expandable={{
						expandedRowRender: (row) => (row.stateNote != null && (
							<div>
								<strong>Reason:</strong>
								<p>{row.stateNote}</p>
							</div>
						)),
						rowExpandable: (row) => row.stateNote != null,
						defaultExpandedRowKeys: expandedRowKeys,
					}}
				/>
			</Card>
			<HireInvoiceForm
				open={open}
				cumulative={readyForCumulative}
				voyageDetails={voyageDetails}
				fixtureDetails={fixtureDetails}
				onClose={() => setOpen(false)}
				onInvoiceCreated={refreshDetails}
				readOnly={false}
			/>
		</>
	);
};

export default HireInvoicesTable;
