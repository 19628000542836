import React, { useCallback } from 'react';
import {
	Grid,
	notification,
} from 'antd';
import { faAddressBook } from '@fortawesome/pro-duotone-svg-icons';
import { FixtureCounterpartyTypes } from '@shared/utils/constants';
import { standardSort } from '@shared/utils/standardSort';
import {
	getCounterparties,
	createCounterparty,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { renderDate } from '@client/utils/table';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import { Links } from '@client/utils/links';

const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		defaultSortOrder: 'ascend',
		sorter: standardSort('name'),
	},
	{
		title: 'Address',
		dataIndex: 'address',
		key: 'address',
		sorter: standardSort('address'),
	},
	{
		title: 'City',
		dataIndex: 'city',
		key: 'city',
		sorter: standardSort('city'),
	},
	{
		title: 'Country',
		dataIndex: 'country',
		key: 'country',
		sorter: standardSort('country'),
	},
	{
		title: 'Phone number',
		dataIndex: 'phoneNumber',
		key: 'phoneNumber',
		sorter: standardSort('phoneNumber'),
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
		sorter: standardSort('email'),
	},
	{
		title: 'Last updated',
		dataIndex: 'updatedAt',
		key: 'updatedAt',
		sorter: standardSort('updatedAt'),
		render: renderDate(),
	},
];

const CharterersListScreen = () => {
	const screens = Grid.useBreakpoint();
	const [charterers, reloadCharterers, error, loading] = useFetchedState(
		() => getCounterparties(FixtureCounterpartyTypes.CHARTERER),
	);

	const createNewCharterer = useCallback(async ({ name }) => {
		const result = await createCounterparty(FixtureCounterpartyTypes.CHARTERER, name);

		await reloadCharterers();

		notification.success({
			message: 'Charterer created',
			description: `${name} has been created.`,
		});

		return result;
	}, [reloadCharterers]);

	return (
		<ListTableScreen
			title="Charterers"
			rootPageTitle="Charterers"
			columns={columns}
			loading={loading}
			error={error}
			tableProps={{ useCards: screens.xs }}
			data={charterers}
			formFields={[
				{
					label: 'Name',
					name: 'name',
					type: 'text',
					required: true,
				},
			]}
			formButtonText="Create charterer"
			onFormSubmit={createNewCharterer}
			getRowHref={(row) => Links.Charterer.get(row.id)}
			showEmptyArrow
			emptyTitle="You have not registered any charterers yet"
			emptyDescription="Click the button to create one"
			emptyIcon={faAddressBook}
		/>
	);
};

export default CharterersListScreen;
