import React from 'react';
import { formatCurrency } from '@shared/utils/currency';
import { getStatForwardHire } from '@client/lib/api';
import { useAuth } from '@client/lib/auth';
import NumberWidget from '../components/NumberWidget';
import { useDashboardPeriod } from '../helpers/dashboardInfo';

const ForwardHireNumberWidget = () => {
	const period = useDashboardPeriod();
	const { userInfo } = useAuth();

	return (
		<NumberWidget
			title="Forward Gross Hire"
			badge="GROSS"
			headerTooltip="Expected gross hire from TC Out voyages assuming earliest possible redelivery"
			fetchData={getStatForwardHire}
			fetchDataParams={[period]}
			transformData={(data) => formatCurrency(Math.round(data), userInfo.baseCurrency)}
			forward
		/>
	);
};

export default ForwardHireNumberWidget;
