import React, { useState } from 'react';
import { Popover } from 'antd';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBuilding,
	faWrench,
} from '@fortawesome/pro-light-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import type { UserData } from '@api/utils/sequelize/calculateUserData';
import Button from '@client/components/Button';
import {
	AuthValues,
	useAuth,
} from '@client/lib/auth';
import UserSettingsModal from '@client/screens/settings/UserSettingsModal/UserSettingsModal';
import UserCircle from '../UserCircle';
import VideoGuidePopover from '../VideoGuide/VideoGuidePopover';
import styles from './TopBar.module.css';
import TopBarButton from './private/TopBarButton';
import OrganizationSelect from './private/OrganizationSelect';

const TopBar = ({ sidebarCollapsed }: { sidebarCollapsed: boolean }) => {
	const {
		logout,
		userInfo: {
			organizationName,
			allOrganizations,
			name,
		},
	} = useAuth() as AuthValues & { userInfo: UserData };

	const [settingsModalOpen, setSettingsModalOpen] = useState(false);

	return (
		<div
			className={classNames(styles.topBarWrapper, {
				[styles.collapsedSidebar]: sidebarCollapsed,
			})}
		>
			<div className={styles.userSection}>
				{allOrganizations.length > 1 && (
					<OrganizationSelect>
						<div className={styles.buttonWrapper}>
							<TopBarButton icon={(<FontAwesomeIcon icon={faBuilding as Icon} />)}>
								{organizationName}
							</TopBarButton>
						</div>
					</OrganizationSelect>
				)}
				<VideoGuidePopover />
				<TopBarButton
					onClick={() => setSettingsModalOpen(true)}
					icon={(
						<FontAwesomeIcon
							icon={faWrench as Icon}
						/>
					)}
				/>
				<Popover
					content={(
						<div className={styles.topBarOptions}>
							<Button
								className={styles.userOption}
								type="link"
								onClick={logout}
							>
								Log out
							</Button>
						</div>
					)}
					trigger="click"
					placement="bottomRight"
					arrowPointAtCenter
				>
					<div className={styles.buttonWrapper}>
						<TopBarButton icon={(<UserCircle name={name ?? ''} />)} />
					</div>
				</Popover>
				<UserSettingsModal
					onCancel={() => setSettingsModalOpen(false)}
					visible={settingsModalOpen}
				/>
			</div>
		</div>
	);
};

export default TopBar;
