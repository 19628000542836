import React from 'react';
import { getForwardCoverage } from '@client/lib/api';
import { ChartColors } from '@client/utils/constants';
import { useDashboardPeriod } from '../helpers/dashboardInfo';
import PieChartWidget from './PieChartWidget';

const ForwardCoverageWidget = () => {
	const period = useDashboardPeriod();
	const entries = [
		{
			label: 'Fixed days',
			key: 'fixedDays',
			fill: ChartColors.BLUE,
		},
		{
			label: 'Unfixed days',
			key: 'unfixedDays',
			fill: ChartColors.CYAN,
		},
		{
			label: 'Redelivery days',
			key: 'redeliveryPeriodDays',
			fill: ChartColors.LIGHTBLUE,
		},
	];

	return (
		<PieChartWidget
			entries={entries}
			fetchData={getForwardCoverage}
			fetchDataParams={[period]}
			header="Forward Coverage"
			unit="days"
			roundTotal
			forward
		/>
	);
};

export default ForwardCoverageWidget;
