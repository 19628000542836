import { Moment } from 'moment';
import { toMoment } from '@shared/utils/date';
import { TcFixtureProps } from '@api/models/tc-fixture';

const getExactDurationStr = (startDate: Moment, exactDate: Moment) => {
	const maxDays = toMoment(exactDate).diff(startDate, 'day');

	return maxDays > 0 ? `${maxDays} ${maxDays > 1 ? 'days' : 'day'}` : '';
};

const formatTcFixtureDuration = (fixture: (TcFixtureProps)) => {
	let { durationMinimumValue: minValue, durationMaximumValue: maxValue } = fixture;
	const {
		durationMinimumUnit: minUnit,
		durationMinimumVariance: minVariance,
		durationMaximumUnit: maxUnit,
		durationMaximumVariance: maxVariance,
		minimumDurationExact: minExact,
		maximumDurationExact: maxExact,
		laycanFrom,
		laycanTo,
	} = fixture;

	if (
		((minValue == null || minUnit == null) && minExact == null) ||
		((maxValue == null || maxUnit == null) && maxExact == null)
	) {
		return null;
	}

	if (laycanTo != null) {
		const startDate = toMoment(laycanTo);

		if (minUnit === 'months') {
			minValue = toMoment(startDate).add(minValue, 'months').diff(startDate, 'day');
		}

		if (minUnit === 'years') {
			minValue = toMoment(startDate).add(minValue, 'years').diff(startDate, 'day');
		}
	}

	if (laycanFrom != null && laycanTo != null) {
		const startDate = toMoment(laycanTo);

		if (maxUnit === 'months') {
			maxValue = toMoment(startDate).add(maxValue, 'months').diff(toMoment(laycanFrom), 'day');
		}

		if (maxUnit === 'years') {
			maxValue = toMoment(startDate).add(maxValue, 'years').diff(toMoment(laycanFrom), 'day');
		}

		if (maxUnit === 'days') {
			maxValue = toMoment(startDate).add(maxValue, 'days').diff(toMoment(laycanFrom), 'day');
		}
	}

	let maxDuration;
	let minDuration;

	if (minExact != null && laycanTo != null) {
		minDuration = getExactDurationStr(toMoment(laycanTo), minExact);
	}

	if (maxExact != null && laycanFrom != null) {
		maxDuration = getExactDurationStr(toMoment(laycanFrom), maxExact);
	}

	const minVarianceString = (minVariance === 0 || minVariance === null) ?
		'' :
		` ± ${minVariance} days`;
	const maxVarianceString = (maxVariance === 0 || maxVariance === null) ?
		'' :
		` ± ${maxVariance} days`;

	let minStr;
	let maxStr;

	if (minDuration != null || minValue != null) {
		minStr = minDuration ?? `${minValue} days${minVarianceString}`;
	}

	if (maxDuration != null || maxValue != null) {
		maxStr = maxDuration ?? `${maxValue} days${maxVarianceString}`;
	}

	if (minStr == null && maxStr == null) {
		return null;
	}

	return `${minStr ?? ''} - ${maxStr ?? ''}`;
};

export default formatTcFixtureDuration;
