import React from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import {
	Currencies,
	PnlItemTypes,
	PnlRenderTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import PnlItem from '@client/screens/fleet/VoyageDetailsScreen/components/ProfitAndLossTab/utils/PnlItem';
import {
	Render,
	RenderItem,
} from '../ProfitAndLossTab';
import { PnlData } from './combinePnlActualEstimate';

type Props = {
	render: Render;
	currency: Values<typeof Currencies>;
}

const getPnlColumns = ({
	render,
	currency,
}: Props): ColumnsType<PnlData> => [
	{
		key: 'description',
		title: 'Description',
		dataIndex: 'desc',
		align: 'left',
		render: (c: string) => c,
	},
	{
		key: 'amount',
		dataIndex: 'amount',
		render,
		title: 'Actual',
		width: 150,
		align: 'right',
	},
	{
		key: 'estAmount',
		dataIndex: 'estAmount',
		render,
		title: 'Estimated',
		width: 150,
		align: 'right',
	},
	{
		key: 'varAmount',
		dataIndex: 'varAmount',
		render,
		title: 'Variance',
		width: 150,
		align: 'right',
	},
	{
		width: 85,
		key: 'varPercentage',
		dataIndex: 'varPercentage',
		align: 'right',
		render: (value: number, i: RenderItem) => {
			if (typeof i.render === 'function') {
				return i.render(value, i);
			}

			return (
				<PnlItem
					value={value}
					renderType={PnlRenderTypes.PERCENTAGE}
					currency={currency}
					bold={i.bold}
					reverseColor={i.type === PnlItemTypes.DAYS}
				/>
			);
		},
		title: '%',
	}];

export default getPnlColumns;
