/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useMemo } from 'react';
import {
	Col,
	Empty,
	Form,
	Input,
	Row,
	Typography,
} from 'antd';
import classNames from 'classnames';
import {
	CargoUnitSingularLabels,
	LaytimeCalculationMethods,
	LaytimeIntervals,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import { formatNumber } from '@shared/utils/formatNumber';
import type { CargoPortProps } from '@api/models/cargo-port';
import type { EditableCargo } from '@api/features/cargos/updateCargo';
import type { CargoPortWithEstimatedValues } from '@api/features/cargos/transformCargos';
import NumericInput from '@client/components/NumericInput';
import Select from '@client/components/Select';
import TooltipIcon from '@client/components/TooltipIcon';
import DatePicker, { DatePickerValue } from '@client/components/DatePicker';
import styles from './CargosListScreen.module.css';

const formatTime = (time: number | null | undefined) => {
	if (time == null) {
		return 0;
	}

	if (Number.isNaN(time) || !Number.isFinite(time)) {
		return 0;
	}

	return round(time, 2);
};

const CargoPortForm = ({
	cargoPort,
	type,
	cargo,
	enableDemDes,
	quantitiesRemaining,
	handleCargoPortChange,
}: {
	cargoPort: CargoPortProps | undefined;
	type: 'loadingPort' | 'dischargePort';
	enableDemDes: boolean;
	cargo: EditableCargo;
	quantitiesRemaining: {loadedRemaining: number; dischargeRemaining: number} | null;
	refreshCargoDetails: () => void;
	handleCargoPortChange: (values: {
		cargoPortId: number; cargoId: number; attributes: any; }
	) => void;
}) => {
	const interval = cargoPort?.laytimeInterval ?? LaytimeIntervals.DAY;

	const quantityRemaining = useMemo(() => {
		if (quantitiesRemaining == null) {
			return 0;
		}

		if (type === 'loadingPort') {
			return quantitiesRemaining.loadedRemaining;
		}

		return quantitiesRemaining.dischargeRemaining;
	}, [quantitiesRemaining, type]);

	if (cargoPort == null) {
		return (<Empty description="No cargo-port selected" />);
	}

	const handleUpdate = (
		fieldName: keyof CargoPortWithEstimatedValues,
		value: number | string | DatePickerValue | null,
	) => {
		handleCargoPortChange({
			cargoPortId: cargoPort.id,
			cargoId: cargo.id,
			attributes: {
				[fieldName]: value,
			},
		});
	};

	return (
		<>
			<Row gutter={[16, 0]}>
				<Col span={12}>
					<Form.Item
						valuePropName="v"
						name="estimatedQuantity"
						label={(
							<Row
								className={styles.quantityRemainingContainer}
							>
								<p>
									{type === 'loadingPort' ? 'Amount Loaded' : 'Amount Discharged'}
								</p>
								<Typography.Text
									// @ts-ignore
									italic
									className={classNames(styles.quantityRemaining, {
										[styles.redText]: quantityRemaining < 0,
									})}
								>
									(Remaining:
									{` ${formatNumber(quantityRemaining, { separateThousands: true })} ${cargo.unit}`}
									)
								</Typography.Text>
							</Row>
						)}
						colon={false}
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
					>
						<NumericInput
							key={cargoPort.id}
							tabIndex={10}
							placeholder={type === 'loadingPort' ? 'Amount Loaded' : 'Amount Discharged'}
							addonAfter={CargoUnitSingularLabels[cargo.unit] ?? 'MT'}
							value={cargoPort.estimatedQuantity}
							onChange={(value) => handleUpdate('estimatedQuantity', value)}
						/>
					</Form.Item>
				</Col>
				<Col span={7}>
					<Form.Item
						valuePropName="v"
						name="loadingRate"
						label={type === 'loadingPort' ? 'Loading Rate' : 'Discharge Rate'}
						colon={false}
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
					>
						<NumericInput
							key={cargoPort.id}
							addonAfter={`/${interval}`}
							tabIndex={11}
							placeholder={`${CargoUnitSingularLabels[cargo.unit] ?? 'MT'}/${interval}`}
							value={cargoPort.loadingRate}
							onChange={(value) => handleUpdate('loadingRate', value)}
						/>
					</Form.Item>
				</Col>
				<Col span={5}>
					<Form.Item
						valuePropName="v"
						name="laytimeInterval"
						label="Interval"
						initialValue={LaytimeIntervals.DAY}
						colon={false}
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
					>
						<Select
							options={[{
								label: 'Day',
								value: 'day',
							},
							{
								label: 'Hour',
								value: 'hour',
							}]}
							tabIndex={12}
							value={cargoPort.laytimeInterval}
							key={cargoPort.id}
							onChange={(value) => handleUpdate('laytimeInterval', value)}
						/>
					</Form.Item>
				</Col>
				{type === 'loadingPort' && (
					<>
						<Col span={12}>
							<Form.Item
								valuePropName="v"
								name="laycanFrom"
								label="Laycan From"
								className={styles.formItemLabel}
								labelCol={{ span: 24 }}
								colon={false}
							>
								<DatePicker
									key={cargoPort.id}
									className={styles.fullWidth}
									placeholder="Select laycan from date"
									tabIndex={13}
									value={cargoPort.laycanFrom}
									onChange={(value) => handleUpdate('laycanFrom', value)}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								valuePropName="v"
								name="laycanTo"
								label="Laycan To"
								className={styles.formItemLabel}
								labelCol={{ span: 24 }}
								colon={false}
							>
								<DatePicker
									key={cargoPort.id}
									className={styles.fullWidth}
									placeholder="Select laycan to date"
									tabIndex={14}
									value={cargoPort.laycanTo}
									onChange={(value) => handleUpdate('laycanTo', value)}
								/>
							</Form.Item>
						</Col>
					</>
				)}
				<Col span={12}>
					<Form.Item
						valuePropName="v"
						name="demurrage"
						key={cargoPort.id}
						colon={false}
						label={(
							<Row>
								<Typography>Demurrage Rate</Typography>
								{!enableDemDes && (
									<TooltipIcon>
										When laytime terms are not non-reversible,
										refer to demurrage on the cargo itself.
									</TooltipIcon>
								)}
							</Row>
						)}
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
					>
						<NumericInput
							tabIndex={15}
							addonAfter={`${cargo.currency}/${interval}`}
							placeholder="Demurrage"
							disabled={!enableDemDes}
							key={cargoPort.id}
							value={cargoPort.demurrage}
							onChange={(value) => handleUpdate('demurrage', value)}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						valuePropName="v"
						name="despatch"
						colon={false}
						label={(
							<Row>
								<Typography>Despatch Rate</Typography>
								{!enableDemDes && (
									<TooltipIcon>
										When laytime terms are not non-reversible,
										refer to despatch on the cargo itself.
									</TooltipIcon>
								)}
							</Row>
						)}
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
					>
						<NumericInput
							tabIndex={16}
							addonAfter={`${cargo.currency}/${interval}`}
							placeholder="Despatch"
							disabled={!enableDemDes}
							key={cargoPort.id}
							value={cargoPort.despatch}
							onChange={(value) => handleUpdate('despatch', value)}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						valuePropName="v"
						name="timeAllowed"
						label="Time Allowed"
						colon={false}
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
					>
						<Input
							tabIndex={17}
							disabled
							placeholder="Time allowed"
							addonAfter={`${interval}`}
							key={cargoPort.id}
							value={formatTime(cargoPort.timeAllowed)}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						valuePropName="v"
						name="calculationMethod"
						label="Calculation Method"
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
						colon={false}
					>
						<Select
							tabIndex={18}
							options={[
								{
									label: LaytimeCalculationMethods.SHEX_EIU,
									value: LaytimeCalculationMethods.SHEX_EIU,
								},
								{
									label: LaytimeCalculationMethods.SHEX_UU_FULL_DAY,
									value: LaytimeCalculationMethods.SHEX_UU_FULL_DAY,
								},
								{
									label: LaytimeCalculationMethods.SHEX_UU_HALF_DAY,
									value: LaytimeCalculationMethods.SHEX_UU_HALF_DAY,
								},
								{
									label: LaytimeCalculationMethods.SHINC,
									value: LaytimeCalculationMethods.SHINC,
								},
							]}
							placeholder="Select Method"
							key={cargoPort.id}
							value={cargoPort.calculationMethod}
							onChange={(value) => handleUpdate('calculationMethod', value)}
						/>
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

export default CargoPortForm;
