import React from 'react';
import {
	Currencies,
	PnlRenderTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import styles from '@client/screens/fixtures/shared/styles/SummaryCard.module.css';
import Table from '@client/components/Table/Table';
import PnlItem from '@client/screens/fleet/VoyageDetailsScreen/components/ProfitAndLossTab/utils/PnlItem';
//
type SectionData = {
	description: string;
	amount: number;
	bold: boolean;
	className: string;
	renderType: Values<typeof PnlRenderTypes> | null;
	render: Function;
}
export const getFixtureEstimateColumns = (
	sectionData: Array<SectionData>,
	currency: Values<typeof Currencies>,
) => (
	<Table
		className={styles.table}
		pagination={false}
		size="small"
		columns={[{
			key: 'description',
			title: 'Description',
			dataIndex: 'desc',
			width: '70%',
		},
		{
			key: 'amount',
			dataIndex: 'amount',
			title: 'Amount',
			width: '30%',
			render: (value, i) => {
				let rendered = value;

				if (typeof i.render === 'function') {
					rendered = i.render(value);
				} else if (i.renderType != null) {
					rendered = (
						<PnlItem
							value={value}
							renderType={i.renderType}
							currency={currency}
							bold={i.bold}
							className={i.className}
						/>
					);
				}

				return (
					<div className={styles.amountCells}>
						{rendered}
					</div>
				);
			},
		}]}
		dataSource={sectionData}
	/>
);
