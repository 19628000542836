import { groupByProperty } from './utils/array';
import {
	NULL_STRING,
	VoyageBunkerTypeLabels,
	VoyageBunkerTypes,
} from './utils/constants';

class TemplateItem {
	/*
		params contains:
		// Set total to false if nothing should be shown in total column
		total : number | false
		columns: [
			// columns[][] is an array containing columns (recursively)
			content : string | columns[]
			small : bool
			italic : bool
			span : number
		]
		children : TemplateItem[]
	 */
	constructor(sectionName, params = {}) {
		this.sectionName = sectionName;

		this.total = params.total;
		this.columns = params.columns;
		this.children = params.children || null;
		this.exchangeRate = params.exchangeRate || null;
	}

	static templateItemsToSectionArray(templateItems) {
		// Reduce from TemplateItem[] to an object containing the section name
		// as the property name and an array of Section objects as the value
		// We check for null (and turn it to NULL_STRING) so it doesn't get turned
		// into a string 'null' and show up as the section title
		const sectionsObject = groupByProperty(
			templateItems,
			(i) => (i.sectionName == null ?
				NULL_STRING :
				i.sectionName
			),
			(i) => ({
				total: i.total,
				columns: i.columns,
				children: i.children && TemplateItem.templateItemsToSectionArray(i.children),
			}),
		);

		// Map from object entries to an array of objects containing title and items
		const sections = Object.entries(sectionsObject).map(([title, items]) => ({
			title: title === NULL_STRING ? null : title,
			items,
		}));

		const sectionsOrder = Object.values(TemplateItem.Sections);

		// Sort the sections correctly, by the index of the title in the TemplateItem.Sections object
		const sorted = sections.sort((a, b) => (
			sectionsOrder.indexOf(a.title) - sectionsOrder.indexOf(b.title)
		));

		return sorted;
	}
}

// This object defines all the different sections on a hire invoice
// The order matters, as the sections will be shown in the same order as the properties below
TemplateItem.Sections = {
	HIRE: 'Hire',
	COMMISSIONS: 'Commissions',
	FREIGHT: 'Freight',
	LAYTIME: 'Laytime',
	DND: 'Demurrage & Despatch',
	BUNKERS_DELIVERY: `Bunkers on ${VoyageBunkerTypeLabels[VoyageBunkerTypes.DELIVERY]}`,
	BUNKERS_REDELIVERY: `Bunkers on ${VoyageBunkerTypeLabels[VoyageBunkerTypes.REDELIVERY]}`,
	BUNKERS_OWNERS_ACCOUNT: `Bunkers for ${VoyageBunkerTypeLabels[VoyageBunkerTypes.OWNERS_ACCOUNT]}`,
	BUNKERS_CHARTERERS_ACCOUNT: `Bunkers for ${VoyageBunkerTypeLabels[VoyageBunkerTypes.CHARTERERS_ACCOUNT]}`,
	EXPENSES_CONTRACTUAL: 'Contractual expenses',
	EXPENSES_OWNER: 'Owner\'s expenses',
	EXPENSES_CHARTERER: 'Charterer\'s expenses',
	REVENUES: 'Revenues',
	OFF_HIRE: 'Off-hire',
	OFF_HIRE_BUNKERS: 'Bunkers consumed',
	PAYMENTS: 'Charterer\'s payments',
	OTHER: 'Other',
};

export default TemplateItem;
