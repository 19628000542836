import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	Col,
	Grid,
	List,
	Row,
	Space,
} from 'antd';
import { CompassOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
	DATE_AND_TIME,
	FixtureTypes,
	FreightRateType,
	PortCallTypes,
	VcContractCompletionTypes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import isItemPortCall from '@shared/utils/isItemPortCall';
import { formatCurrency } from '@shared/utils/currency';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { GetVoyageItineraryResponse } from '@api/features/voyages/getVoyageItinerary';
import type { ItineraryPortCallDto } from '@api/features/ops/getVesselItinerary';
import type { Port } from '@api/utils/ports';
import type { GetCargosResponse } from '@api/features/cargos/getCargos';
import { formatDate } from '@client/utils/formatDate';
import {
	getEstimateDetails,
	getPorts,
	getVesselDetails,
	getVoyages,
	updateVoyage,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import CountryFlag from '@client/components/CountryFlag';
import showErrorNotification from '@client/utils/showErrorNotification';
import Select from '@client/components/Select';
import TooltipIcon from '@client/components/TooltipIcon';
import VoyageSelector from '@client/screens/fleet/VoyageDetailsScreen/components/VoyageSelector/VoyageSelector';
import LinkedVoyages from '@client/screens/fleet/VoyageDetailsScreen/components/LinkedVoyages/LinkedVoyages';
import { Links } from '@client/utils/links';
import Button from '@client/components/Button';
import Card from '@client/components/Card/Card';
import Table from '@client/components/Table/Table';
import { getPortOptions } from '@client/utils/getPortAndRangeOptions';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import styles from '../styles/VcSummaryTab.module.css';
import EstimatedDates from '../EstimatedDates/EstimatedDates';

const VcSummaryTab = ({
	itinerary,
	itineraryLoading,
	voyageDetails,
	fixtureDetails,
	refreshDetails,
}: {
	itinerary: GetVoyageItineraryResponse | undefined;
	itineraryLoading: boolean;
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<
		TcFixtureProps | SpotFixtureProps
	> & { estimateId: number };
	refreshDetails: () => Promise<void> | void;
}) => {
	const {
		xxl,
	} = Grid.useBreakpoint();

	const [ports] = useFetchedState(getPorts);
	const portOptions = getPortOptions(ports ?? []);

	const [voyages] = useFetchedState(getVoyages);

	const filteredVoyages = useMemo(() => {
		if (voyages == null) {
			return [];
		}

		return voyages.filter((v) => (
			v.vesselId === voyageDetails.vesselId &&
			v.id !== voyageDetails.id
		));
	}, [voyageDetails, voyages]);

	const [vessel, _refresh, error, loadingVesselDetails] = useFetchedState(
		() => getVesselDetails(voyageDetails.vesselId),
		[voyageDetails.vesselId],
	);

	const [
		estimateDetails,
		_refreshEstimate,
		estimateError,
		loadingEstimate,
	] = useFetchedState(
		async () => {
			const { estimateId } = fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>;

			if (estimateId != null) {
				return await getEstimateDetails(estimateId);
			}

			return null;
		},
		[fixtureDetails],
	);

	const cargos = estimateDetails?.cargos;

	const updateVoyageField = useCallback(async (
		field: string,
		value: any,
		contractId?: number | null,
	) => {
		try {
			await updateVoyage(contractId ?? voyageDetails.id, { [field]: value });
			await refreshDetails();
			showSuccessNotification('Contract updated');
		} catch (e) {
			if (value !== undefined) {
				showErrorNotification('Could not update contract', e as Error);
			}
		}
	}, [refreshDetails, voyageDetails.id]);

	const completionCard = useMemo(() => {
		return (
			<div className={styles.completionContainer}>
				<Row>
					<Col span={12}>
						<div className={styles.completionContainer}>
							<b>
								Contract completion type
							</b>
							<Select
								className={styles.completionTypeSelect}
								value={voyageDetails.completionType}
								defaultValue={voyageDetails.completionType}
								onSelect={async (value: VcContractCompletionTypes | null) => {
									await updateVoyageField('completionType', value);
								}}
								options={[
									{
										value: VcContractCompletionTypes.NEXT_CONTRACT,
										label: (
											<>
												Next contract
												<TooltipIcon>
													This requires you set manually set a completion date.
													Bunkers on completion will be carried over to the next contract.
												</TooltipIcon>
											</>
										),
									},
									{
										value: VcContractCompletionTypes.TC_IN_DELIVERY,
										label: (
											<>
												TC In redelivery
												<TooltipIcon>
													This will automatically set the completion date
													to be equal the delivery date of the TC in contract.
													All bunkers will be sold on redelivery.
												</TooltipIcon>
											</>
										),
									},
								]}
							/>
						</div>
					</Col>
					<Col span={12}>
						<div className={styles.completionContainer}>
							<b>
								Linked TC-in voyage
							</b>
							<div className={styles.linkedVoyage}>
								<VoyageSelector
									type="linkedTcIn"
									defaultValue={voyageDetails?.linkedTcInVoyageId}
									voyages={
										(filteredVoyages ?? [])
											.filter((v) => v.fixtureType === FixtureTypes.TC_IN)
									}
									onSelect={async (c) => {
										await updateVoyageField('linkedTcInVoyageId', c);
									}}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		);
	}, [updateVoyageField, filteredVoyages, voyageDetails]);

	if (
		error ||
		estimateError ||
		loadingEstimate ||
		loadingVesselDetails ||
		vessel == null ||
		estimateDetails == null
	) {
		return null;
	}

	return (
		<Row gutter={[16, 16]}>
			<Col xxl={8} lg={24}>
				<Card className={classNames([styles.smallerCard, !xxl && styles.linkedVoyageCard])}>
					<LinkedVoyages
						voyageDetails={voyageDetails}
						voyages={filteredVoyages}
						ownershipPeriods={vessel.ownershipPeriods}
						refreshVoyageDetails={refreshDetails}
					/>
					{voyageDetails.vessel.ownershipType === VesselOwnershipTypes.TC_IN && completionCard}
				</Card>
			</Col>
			<Col xxl={16} lg={24}>
				<Card
					className={classNames([styles.smallerCard, styles.estimatedDatesCard])}
				>
					<EstimatedDates
						voyageDetails={voyageDetails}
						updateVoyageField={updateVoyageField}
						portOptions={portOptions}
					/>
				</Card>
			</Col>
			<Col xxl={12} lg={24}>
				<Card
					className={styles.smallerCard}
					size="small"
					title="Port Rotation"
					extra={(
						<Link key="itinerary-link" to={`${Links.Voyage.get(voyageDetails.id)}/#/itinerary`}>
							<Button
								type="link"
								icon={(<CompassOutlined />)}
							>
								Go to itinerary
							</Button>
						</Link>
					)}
				>

					<List
						size="small"
						bordered
						loading={itineraryLoading}
						dataSource={itinerary?.filter((p): p is ItineraryPortCallDto => isItemPortCall(p))}
						renderItem={(item: ItineraryPortCallDto) => {
							const arrivalDate = item.arrivalDate ?? item.estimatedArrivalDate;
							const departureDate = item.departureDate ?? item.estimatedDepartureDate;
							const arrivalLabel = item.arrivalDate == null ? 'Estimated' : '';
							const departureLabel = item.departureDate == null ? 'Estimated' : '';
							const showArrival = item.type !== PortCallTypes.COMMENCEMENT;

							return (
								<List.Item>
									<Row>
										<Col span={24}>
											<Space>
												<CountryFlag countryCode={item?.port?.countryCode} />
												<span style={{ fontWeight: 500 }}>
													{`${item?.port?.name}`}
												</span>
											</Space>
										</Col>
										{showArrival && (
											<Col span={24}>
												<div className={styles.dateOuterContainer}>
													<div className={styles.dateContainer}>
														<p className={styles.dateLabel}>Arrival:</p>
														{' '}
														{formatDate(arrivalDate, DATE_AND_TIME)}
													</div>
													<p className={styles.estimatedLabel}>{arrivalLabel}</p>
												</div>
											</Col>
										)}
										<Col span={24}>
											<div className={styles.dateOuterContainer}>
												<div className={styles.dateContainer}>
													<p className={styles.dateLabel}>Departure:</p>
													{' '}
													{formatDate(departureDate, DATE_AND_TIME)}
												</div>
												<p className={styles.estimatedLabel}>{departureLabel}</p>
											</div>
										</Col>
									</Row>
								</List.Item>
							);
						}}
					/>
				</Card>
			</Col>
			<Col xxl={12} lg={24}>
				<Card slim title="Cargo" size="small">
					<Table
						size="small"
						pagination={false}
						columns={[
							{
								title: 'Type',
								dataIndex: 'type',
								key: 'cargo',
								render: (type, row) => (<Link to={`${Links.Cargo.get(row.id)}`}>{type}</Link>),
							},
							{
								title: 'Freight',
								key: 'freight',
								render: (row: GetCargosResponse[number]) => {
									const total = row.freightType === FreightRateType.LUMPSUM ?
										row.freightRate :
										row.quantity * row.freightRate;

									return formatCurrency(total, row.currency);
								},
							},
							{
								title: 'Loading',
								dataIndex: 'loadingPorts',
								key: 'loadingPorts',
								render: (lps: Port[]) => lps.map((lp) => lp.name).join(', '),
							},
							{
								title: 'Discharge',
								dataIndex: 'dischargePorts',
								key: 'dischargePorts',
								render: (lps: Port[]) => lps.map((lp) => lp.name).join(', '),
							},
							{
								title: 'Charterer',
								dataIndex: 'chartererName',
								key: 'chartererName',
								render: (charterer) => charterer,
							},
						]}
						dataSource={cargos ?? []}
					/>
				</Card>
			</Col>
		</Row>
	);
};

export default VcSummaryTab;

