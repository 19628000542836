import {
	PnlItemTypes,
	Currencies,
	PnlRenderTypes,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import { Values } from '@shared/utils/objectEnums';

export type PnlData = {
	type?: Values<typeof PnlItemTypes>;
	renderType: Values<typeof PnlRenderTypes> | null;
	desc: string | React.ReactElement;
	amount: number;
	bold?: boolean | undefined;
	className?: any;
	render?: (() => React.ReactElement | string | number) | undefined;
	estAmount?: number;
	varAmount?: number;
	varPercentage?: number;
};

export const combinePnlActualEstimate = ({
	actualData,
	estimateData,
}: {
	actualData: Array<PnlData>;
	estimateData: Array<PnlData>;
	elapsedDays: number;
	estimatedDays: number;
	currency: Values<typeof Currencies>;
}): Array<PnlData> => {
	const result = actualData.map((entry) => {
		const changedEntry = { ...entry };
		estimateData.forEach((estimateObj) => {
			if (entry.type === estimateObj.type) {
				const actualAmount = entry.amount;
				let estimatedAmount = 0;

				estimatedAmount = estimateObj.amount;

				const varianceAmount = round(actualAmount - estimatedAmount);
				let percentageVariance = estimatedAmount !== 0 ?
					round((varianceAmount / estimatedAmount) * 100, 2) : undefined;

				if (estimatedAmount < 0 && actualAmount < 0 && percentageVariance != null) {
					percentageVariance = -percentageVariance;
				}

				changedEntry.estAmount = estimatedAmount;
				changedEntry.varAmount = varianceAmount;
				if (percentageVariance != null) {
					changedEntry.varPercentage = percentageVariance;
				}
			}
		});

		return changedEntry;
	});

	return result;
};
