import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	Card,
	Col,
	Row,
	Space,
	Switch,
} from 'antd';
import { Moment } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TimeFormat } from '@shared/utils/date';
import isItemPortCall from '@shared/utils/isItemPortCall';
import type {
	ItineraryEntryState,
	ItineraryPortCallDto,
	ItinerarySeaPassageDto,
} from '@api/features/ops/getVesselItinerary';
import LoadingIndicator from '@client/components/LoadingIndicator';
import getPortAndRangeOptions from '@client/utils/getPortAndRangeOptions';
import getFormFields from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/utils/newPortCallForm';
import FormPopover from '@client/components/FormPopover';
import TimelinePart from './TimelinePart';
import { getPortCallProgress } from './utils/getPortCallProgress';
import { getSeaPassageProgress } from './utils/getSeaPassageProgress';
import styles from './ItineraryTab.module.css';
import PortCallEntry from './PortCallEntry';
import SeaPassageEntry from './SeaPassageEntry';

type ItineraryProps = {
	addEntry: (values: any) => void;
	entries: Array<ItinerarySeaPassageDto | ItineraryPortCallDto>;
	lastActualPortCallDate: Moment | null;
	loading: boolean;
	error: Error | null;
	setActiveTab: React.Dispatch<React.SetStateAction<string>>;
	selectedEntry: ItinerarySeaPassageDto | ItineraryPortCallDto | undefined;
	setSelectedEntry: (e: ItineraryPortCallDto | ItinerarySeaPassageDto) => void;
	reorderPortCall: (draggedIndex: number, droppedOverIndex: number) => void;
	selectedTimeFormat: TimeFormat;
	setSelectedTimeFormat: (s: TimeFormat) => void;
	portOptions: ReturnType<typeof getPortAndRangeOptions>;
	setStartDate: (date: string) => void;
	setEndDate: (date: string) => void;
	startDate?: string | null;
	endDate?: string | null;
};

const Itinerary = (props: ItineraryProps) => {
	const {
		addEntry,
		entries,
		loading,
		selectedEntry,
		setSelectedEntry,
		setSelectedTimeFormat,
		reorderPortCall,
		lastActualPortCallDate,
		selectedTimeFormat,
		portOptions,
		setActiveTab,
	} = props;

	const portCalls = useMemo(
		() => entries.filter((e): e is ItineraryPortCallDto => isItemPortCall(e)),
		[entries],
	);

	const latestActualEntryIndex = useMemo(() => {
		let candidate = portCalls[0]?.arrivalDate ?? portCalls[0]?.departureDate;
		let candidateIndex = 0;

		portCalls.forEach((portCall, index) => {
			const actualDate = portCall.arrivalDate ?? portCall.departureDate;

			if (actualDate?.isAfter(candidate) || (actualDate != null && candidate == null)) {
				candidate = actualDate;
				candidateIndex = index;
			}
		});

		return candidateIndex;
	}, [portCalls]);

	const renderPortCall = useCallback(
		(
			portCall: ItineraryPortCallDto,
			state: ItineraryEntryState,
			index: number,
		) => {
			const currentIdx = portCalls.findIndex((pc) => pc.id === portCall.id);
			const progress = getPortCallProgress(currentIdx < latestActualEntryIndex ? 'completed' : state);

			const stale = (lastActualPortCallDate?.isAfter(
				portCall.arrivalDate ?? portCall.departureDate,
			) && portCall.estimated) || false;

			return (
				<div className={styles.cardContainer} key={`PC_${portCall.id}`}>
					<TimelinePart connected progress={progress} />
					<PortCallEntry
						selectedTimeFormat={selectedTimeFormat}
						state={state}
						portCall={portCall}
						selectPortCall={() => setSelectedEntry(portCall)}
						selected={
							selectedEntry?.id === portCall.id && isItemPortCall(selectedEntry)
						}
						stale={stale}
						order={index}
						onReorder={reorderPortCall}
					/>
				</div>
			);
		},
		[
			portCalls,
			latestActualEntryIndex,
			lastActualPortCallDate,
			selectedTimeFormat,
			selectedEntry,
			reorderPortCall,
			setSelectedEntry,
		],
	);

	const formFields = getFormFields(portOptions, entries);

	const renderSeaPassage = useCallback(
		(seaPassage: ItinerarySeaPassageDto, state: ItineraryEntryState) => {
			const previousPortCallIndex = portCalls
				.findIndex((pc) => pc.id === seaPassage.prevPortCallId);
			const nextPortCallIndex = portCalls
				.findIndex((pc) => pc.id === seaPassage.nextPortCallId);

			let progress = getSeaPassageProgress(seaPassage, state);

			if (previousPortCallIndex <= latestActualEntryIndex) {
				progress = 50;
			}

			if (nextPortCallIndex <= latestActualEntryIndex) {
				progress = 100;
			}

			if (latestActualEntryIndex == null || latestActualEntryIndex === 0) {
				progress = 0;
			}

			return (
				<div className={styles.spContainer} key={`SP_${seaPassage.id}`}>
					<TimelinePart connected={false} progress={progress} />
					<SeaPassageEntry
						departurePort={seaPassage.departurePort}
						arrivalPort={seaPassage.arrivalPort}
						state={state}
						arrivalDate={seaPassage.arrivalDate}
						departureDate={seaPassage.departureDate}
						selectSeaPassage={() => {
							setSelectedEntry(seaPassage);
						}}
						selected={
							selectedEntry?.id === seaPassage.id &&
							!isItemPortCall(selectedEntry)
						}
						setActiveTab={setActiveTab}
						speed={seaPassage.routeOptions?.speed}
					/>
				</div>
			);
		},
		[latestActualEntryIndex, portCalls, selectedEntry, setActiveTab, setSelectedEntry],
	);

	return (
		<Row>
			<Col span={24}>
				<Row align="middle">
					<Col span={24}>
						<Card size="small" className={styles.itineraryControls}>
							<div className={styles.buttonWrapper}>
								<FormPopover
									title="Add planned port call"
									// @ts-ignore
									fields={formFields}
									buttonText={(
										<Space>
											<FontAwesomeIcon icon={faPlus as IconProp} />
											Add Planned Port Call
										</Space>
									)}
									buttonProps={{ disabledTooltip: 'Cannot add entries after completion of voyage' }}
									onSubmit={addEntry}
								/>
								<div>
									<Space align="center">
										Local Time
										<Switch
											checked={selectedTimeFormat === 'utc'}
											onChange={(checked) => {
												setSelectedTimeFormat(checked ? 'utc' : 'localTime');
											}}
										/>
										UTC
									</Space>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
			</Col>
			<Col span={24} className={styles.itineraryColumn}>
				{loading && (<LoadingIndicator containerClassName={styles.centered} />)}
				{!loading && (
					<>
						{entries.map((item, index) => {
							let portCallState: ItineraryEntryState = 'completed';
							let seaPassageState: ItineraryEntryState = 'completed';

							const isPortCall = isItemPortCall(item);

							if (
								isPortCall &&
								item.departureDate == null &&
								item.arrivalDate != null
							) {
								portCallState = 'in progress';
							}

							if (
								isPortCall &&
								item.departureDate == null &&
								item.arrivalDate == null
							) {
								portCallState = 'estimated';
							}

							if (!isPortCall) {
								const prevPortCall = portCalls.find(
									(pc) => pc.nextSeaPassageId === item.id,
								);
								const nextPortCall = portCalls.find(
									(pc) => pc.prevSeaPassageId === item.id,
								);

								if (prevPortCall?.departureDate == null) {
									seaPassageState = 'estimated';
								} else if (nextPortCall?.arrivalDate == null) {
									seaPassageState = 'in progress';
								}
							}

							return isPortCall ?
								renderPortCall(item, portCallState, index) :
								renderSeaPassage(item, seaPassageState);
						})}
					</>
				)}
			</Col>
		</Row>
	);
};

export default Itinerary;
