import React, {
	useMemo,
	useState,
} from 'react';
import {
	Card,
	Divider,
	Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import {
	faMinus,
	faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import {
	AttachmentTypes,
	FixtureTypes,
} from '@shared/utils/constants';
import { getVoyageDetails } from '@client/lib/api';
import Details from '@client/components/Details';
import AttachmentLibrary from '@client/components/AttachmentLibrary';
import Button from '@client/components/Button';
import { getEstimateData } from '@client/utils/getEstimateData';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import LoadingIndicator from '@client/components/LoadingIndicator';
import { getFixtureEstimateColumns } from '@client/utils/getFixtureEstimateColumns';
import styles from './styles/SummaryCard.module.css';

const SummaryCard = ({
	itemGroups,
	fixture,
	setFixtureRecapGeneratorOpen,
	refreshFixture,
	currency,
}) => {
	const [activeTab, setActiveTab] = useState(fixture.type === FixtureTypes.SPOT ? 'summary' : 'estimate');
	const isTcInContract = fixture.type === FixtureTypes.TC_IN;

	const [voyageDetails, _refreshVoyages, __error, loadingVoyage] = useFetchedState(
		() => {
			if (fixture.voyageId != null) {
				return getVoyageDetails(fixture.voyageId);
			}

			return null;
		},
	);

	const [estimateData, _refresh, _error, _initialLoad, isLoading] = useFetchedState(
		() => (!loadingVoyage ? getEstimateData(
			fixture, voyageDetails, currency, styles, isTcInContract,
		) : null),
		[fixture, loadingVoyage],
		{},
	);

	const estimate = useMemo(() => estimateData ?? {}, [estimateData]);

	const { revenue, expenses, summary } = estimate;

	return (
		<Card
			activeTabKey={activeTab}
			onTabChange={setActiveTab}
			tabList={[
				...(fixture.type !== FixtureTypes.SPOT ?
					[{
						tab: 'Estimate',
						key: 'estimate',
					}] : []),
				{
					tab: 'Summary',
					key: 'summary',
				},
				{
					tab: 'Documents',
					key: 'documents',
				}]}
		>
			{activeTab === 'summary' && (
				<>
					<div style={{ textAlign: 'center' }}>
						<Button
							disabled={!fixture.fixed}
							disabledTooltip="You cannot generate a recap before estimate is marked as fixed"
							type="primary"
							onClick={() => setFixtureRecapGeneratorOpen(true)}
							icon={(
								<FontAwesomeIcon
									className={styles.icon}
									icon={faFilePdf}
								/>
							)}
						>
							Generate Recap
						</Button>
					</div>
					{itemGroups.map((group) => (
						<>
							<Divider>{group.header}</Divider>
							<Details
								colon={false}
								bordered
								size="small"
								hideHeader
								boldValue
								items={group.items}
								clean
								className={styles.details}
							/>
						</>
					))}
				</>
			)}
			{activeTab === 'documents' && (
				<AttachmentLibrary
					attachments={fixture.attachments}
					refreshAttachments={refreshFixture}
					type={AttachmentTypes.FIXTURE}
					attachToId={fixture.id}
				/>
			)}
			{activeTab === 'estimate' && (
				<>
					<Divider>
						<Space>
							<FontAwesomeIcon icon={faPlus} />
							{ isTcInContract ? 'Costs' : 'Revenue' }
							{
								isLoading.loading ?
									(
										<LoadingIndicator
											containerClassName={styles.loadingEstimate}
											size="small"
										/>
									) :
									null
							}
						</Space>
					</Divider>
					{getFixtureEstimateColumns(revenue, currency)}
					<>
						{expenses && fixture.type !== FixtureTypes.TC_IN ? (
							<>
								<Divider>
									<Space>
										<FontAwesomeIcon
											icon={faMinus}
										/>
										Expenses
									</Space>
								</Divider>
								<>
									{getFixtureEstimateColumns(expenses, currency)}
								</>
							</>
						) : null}
					</>
					<Divider>Summary</Divider>
					{getFixtureEstimateColumns(summary, currency)}
				</>
			)}
		</Card>
	);
};

export default SummaryCard;
