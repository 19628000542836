import TemplateItem from '@shared/TemplateItem';
import { FixtureTypes } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';

const presets = {
	[FixtureTypes.TC_IN]: {
		HIRE: {
			title: 'Hire Statement',
			btnTitle: 'Hire Statement',
			showPrevious: false,
			includeHire: true,
		},
		RECEIVABLES: {
			title: 'Statement',
			btnTitle: 'Blank Statement',
			showPrevious: false,
			includeHire: false,
		},
	},
	[FixtureTypes.TC_OUT]: {
		HIRE: {
			title: 'Hire Invoice',
			btnTitle: 'Hire Invoice',
			showPrevious: false,
			includeHire: true,
		},
		RECEIVABLES: {
			title: 'Invoice',
			btnTitle: 'Blank Invoice',
			showPrevious: false,
			includeHire: false,
		},
	},
	[FixtureTypes.SPOT]: {
		FREIGHT: {
			title: 'Invoice',
			openSections: [TemplateItem.Sections.FREIGHT],
		},
		DEMURRAGE: {
			title: 'Demurrage Invoice',
			openSections: [TemplateItem.Sections.LAYTIME],
		},
	},
};

const getInvoicePresets = (
	fixtureDetails: { type: Values<typeof FixtureTypes> },
) => presets[fixtureDetails.type] || {};

export default getInvoicePresets;
