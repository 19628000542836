import React, {
	useRef,
	useMemo,
} from 'react';
import { Typography } from 'antd';
import {
	faBalanceScale,
	faBooks,
	faChartPieAlt,
	faDollar,
	faDonate,
	faFileAlt,
	faFileInvoiceDollar,
	faGasPump,
	faListTimeline,
	faUserTie,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	AccountTypes,
	AttachmentTypes,
	Currencies,
	FixtureTypes,
} from '@shared/utils/constants';
import { fixtureTypeToPascalCase } from '@shared/utils/string';
import { sortByDates } from '@shared/utils/sortByDates';
import { Values } from '@shared/utils/objectEnums';
import { nowMoment } from '@shared/utils/date';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import DetailsTabScreen from '@client/components/screens/DetailsTabScreen';
import { getVoyageItinerary } from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import VesselDescription from '@client/components/VesselDescription';
import { Links } from '@client/utils/links';
import AttachmentLibrary from '@client/components/AttachmentLibrary';
import HeaderAction from './components/HeaderAction';
import ProfitAndLossTab from './components/ProfitAndLossTab/ProfitAndLossTab';
import TcExpensesTab from './components/TcExpensesTab';
import InvoicesTab from './components/InvoicesTab';
import CumulativeComparison from './components/CumulativeComparison';
import Recap from './components/tabs/Recap';
import TcSummaryTab from './components/TcSummaryTab';
import CommissionsTab from './components/CommissionsTab/CommissionsTab';
import ItineraryTab from './components/ItineraryTab/ItineraryTab';
import BunkerExpenditureTab from './tabs/BunkerExpenditureTab/BunkerExpenditureTab';

const TcContractDetailsScreen = ({
	voyageDetails,
	fixtureDetails,
	refreshVoyageDetails,
	refreshFixtureDetails,
	currency,
}: {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps>;
	refreshVoyageDetails: () => void;
	refreshFixtureDetails: () => void;
	currency: Values<typeof Currencies>;
}) => {
	const tabRef = useRef<HTMLDivElement>(null);

	const [
		itinerary,
		itineraryError,
		itineraryLoading,
	] = useFetchedState(async () => (voyageDetails == null ? [] : getVoyageItinerary(
		Number(voyageDetails.id),
	)), [voyageDetails]);

	const refreshDetails = async () => {
		refreshVoyageDetails();
		refreshFixtureDetails();
	};

	const expenses = useMemo(() => {
		if (!voyageDetails?.id) {
			return [];
		}

		const sortedByDate = sortByDates(voyageDetails.voyageExpenseReceivables, 'createdAt');

		return sortedByDate.sort((a, b) => a.account.localeCompare(b.account));
	}, [voyageDetails]);

	const bunkersHasCurrencyDiff = voyageDetails.voyageBunkers.some((e) => e.currency !== currency);

	const tabs = [
		{
			key: 'summary',
			title: 'Summary',
			icon: faChartPieAlt as IconProp,
			render: () => (
				<TcSummaryTab
					voyageDetails={voyageDetails}
					fixtureDetails={fixtureDetails}
					refreshVoyageDetails={refreshVoyageDetails}
					refreshFixtureDetails={refreshFixtureDetails}
				/>
			),
		},
		{
			key: 'recap',
			title: 'Recap',
			icon: faFileAlt as IconProp,
			render: () => (
				<Recap
					voyageDetails={voyageDetails}
					fixtureDetails={fixtureDetails}
				/>
			),
		},
		{
			key: 'itinerary',
			title: 'Itinerary',
			icon: faListTimeline as IconProp,
			render: () => (
				<ItineraryTab
					startDate={nowMoment().toISOString()}
					setStartDate={() => null}
					// @ts-ignore
					itineraryError={itineraryError}
					// @ts-ignore
					itineraryLoading={itineraryLoading}
					endDate={nowMoment().toISOString()}
					setEndDate={() => null}
					itinerary={itinerary}
					voyageDetails={voyageDetails}
					refreshDetails={refreshDetails}
				/>
			),
		},
		{
			key: 'expenses',
			title: 'Expenses',
			forceRender: true,
			icon: faDonate as IconProp,
			render: () => (
				<TcExpensesTab
					voyageDetails={voyageDetails}
					fixtureCurrency={currency}
					refreshDetails={refreshDetails}
					expenses={expenses}
					bunkersHasCurrencyDiff={bunkersHasCurrencyDiff}
					fixtureDetails={
						fixtureDetails as GetFixtureDetailsResponse<TcFixtureProps>
					}
				/>
			),
		},
		{
			key: 'invoices',
			title: 'Invoices',
			icon: faFileInvoiceDollar as IconProp,
			render: () => (
				<InvoicesTab
					voyageDetails={voyageDetails}
					refreshDetails={refreshDetails}
					fixtureDetails={
						fixtureDetails as GetFixtureDetailsResponse<TcFixtureProps>
					}
				/>
			),
		},
		...(fixtureDetails.brokers?.some((b) => {
			if (fixtureDetails.type === FixtureTypes.TC_OUT) {
				return b.paidBy === AccountTypes.OWNER;
			}

			if (fixtureDetails.type === FixtureTypes.TC_IN) {
				return b.paidBy === AccountTypes.CHARTERER;
			}

			return false;
		}) ? [{
				key: 'commissions',
				title: 'Commissions',
				icon: faUserTie as IconProp,
				render: () => (
					<CommissionsTab
						fixtureDetails={fixtureDetails as GetFixtureDetailsResponse<TcFixtureProps>}
						voyageDetails={voyageDetails}
						refreshDetails={refreshDetails}
					/>
				),
			}] : []),
		...(fixtureDetails.type === FixtureTypes.TC_OUT ? [{
			key: 'pnl',
			title: 'P&L',
			icon: faDollar as IconProp,
			render: () => (
				<ProfitAndLossTab
					fixtureDetails={fixtureDetails}
					voyageDetails={voyageDetails}
				/>
			),
		}] : []),
		{
			key: 'reconciliation',
			title: 'SoA',
			icon: faBalanceScale as IconProp,
			render: ({ active }: {active: boolean}) => (
				<CumulativeComparison
					voyageDetails={voyageDetails}
					refreshDetails={refreshDetails}
					focused={active}
					fixtureDetails={
						fixtureDetails as GetFixtureDetailsResponse<TcFixtureProps>
					}
				/>
			),
		},
		{
			key: 'bunkers',
			title: 'Bunkers',
			icon: faGasPump as IconProp,
			render: () => (
				<BunkerExpenditureTab
					vessel={voyageDetails.vessel}
					voyageDetails={voyageDetails}
					refreshVoyageDetails={refreshDetails}
					fixtureDetails={fixtureDetails}
				/>
			),
		},
		{
			key: 'documents',
			title: 'Documents',
			icon: faBooks as IconProp,
			render: () => (
				<AttachmentLibrary
					attachments={voyageDetails.attachments}
					refreshAttachments={refreshDetails}
					type={AttachmentTypes.VOYAGE}
					attachToId={voyageDetails.id}
				/>
			),
		},
	];

	return (
		<DetailsTabScreen
			rootPageTitle="Contract - TC"
			tabContainerRef={tabRef}
			defaultTabKey="summary"
			canGoBack
			tabsProps={{ destroyInactiveTabPane: true }}
			tabs={tabs}
			breadcrumbs={
				[[
					`Contracts - ${fixtureDetails == null ? 'N/A' : fixtureTypeToPascalCase(fixtureDetails.type)}`,
					Links.Voyages.get(),
				]]
			}
			title={(
				<Typography.Title level={4}>
					<VesselDescription
						name={voyageDetails?.vesselName}
						flag={voyageDetails?.vesselFlag}
					/>
					{' | '}
					{voyageDetails?.identifier}
				</Typography.Title>
			)}
			extra={(
				<HeaderAction
					fixtureDetails={fixtureDetails}
					details={voyageDetails}
					refreshDetails={refreshDetails}
				/>
			)}
		/>
	);
};

export default TcContractDetailsScreen;

