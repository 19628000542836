import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Card,
	Col,
	Collapse,
	Empty,
	Grid,
	Row,
	Space,
	Tabs,
	Typography,
} from 'antd';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowRight,
	faChartPie,
	faChevronDoubleRight,
} from '@fortawesome/pro-light-svg-icons';
import { faChevronDoubleLeft } from '@fortawesome/pro-thin-svg-icons';
import classNames from 'classnames';
import { Moment } from 'moment';
import { LegendType } from 'recharts';
import {
	CrewReportTypes,
	DATE,
	DATE_AND_TIME,
	DATE_SHORT_MONTH,
	NoonReportThresholds,
	UNKNOWN_PORT,
} from '@shared/utils/constants';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import type { GetPerformanceDetailsResponse } from '@api/features/performance/getPerformanceDetails';
import { formatDate } from '@client/utils/formatDate';
import manOnBoat from '@client/assets/images/man_on_boat.svg';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getPerformanceDetails,
	getPerformanceOverview,
	getVesselSeaPassages,
} from '@client/lib/api';
import LoadingIndicator from '@client/components/LoadingIndicator';
import Table from '@client/components/Table/Table';
import PerformanceLineChart, { TooltipProps } from '@client/components/charts/LineChart/PerformanceLineChart';
import { ChartColors } from '@client/utils/constants';
import TooltipIcon from '@client/components/TooltipIcon';
import Map, { Viewport } from '@client/components/Map/Map';
import ReportsLayer, { AllPerformanceReports } from '@client/components/Map/layers/ReportsLayer';
import { CrewReportDrawer } from '@client/screens/fleet/VesselDetailsScreen/components/CrewReportDrawer/CrewReportDrawer';
import Button from '@client/components/Button';
import ErrorPage from '@client/components/ErrorPage';
import Select from '@client/components/Select';
import DatePicker from '@client/components/DatePicker';
import { Links } from '@client/utils/links';
import VesselsLayer from '@client/components/Map/layers/VesselsLayer';
import {
	getPerformanceStatusColumns,
	PerformanceData,
} from './helpers/getPerformanceStatusColumns';
import styles from './PerformanceDetailsScreen.module.css';

type ShadedArea = {
	x1: string;
	x2: string;
};

const PerformanceDetailsScreen = () => {
	const { id } = useParams<{ id: string }>();
	const [openRemarks, setOpenRemarks] = useState<string>();
	const [expandMap, setExpandMap] = useState(false);
	const [defaultMapPosition, setDefaultMapPosition] = useState({ latitude: 0, longitude: 0 });
	const [reportToDisplay, setReportToDisplay] = useState(null);
	const [dateRange, setDateRange] = useState<[Moment, Moment | undefined]>([nowMoment().subtract(14, 'days'), nowMoment()]);
	const [selectedSeaPassageIndex, setSelectedSeaPassageIndex] = useState<number | undefined>(0);

	const vesselId = Number(id);

	const screens = Grid.useBreakpoint();

	const [seaPassages] = useFetchedState(
		() => getVesselSeaPassages(vesselId),
		[id],
	);

	const [performanceDetails, _refreshDetails, errorDetails, loadingDetails] = useFetchedState(
		async () => {
			if (seaPassages == null) {
				return null;
			}

			return getPerformanceDetails(
				vesselId,
				// If from date is null, start tomorrow
				// That way we know there will be no reports - so we show "no sea passages"
				dateRange?.[0].toISOString(true) ?? nowMoment().add(10, 'years').toISOString(true),
				dateRange?.[1]?.toISOString(true) ?? null,
			);
		},
		[vesselId, dateRange, seaPassages],
	);

	const [vessel, _reloadVessels, errorOverview, loadingOverview] = useFetchedState(
		() => getPerformanceOverview([vesselId]),
		[vesselId],
	);

	const selectedSeaPassage = (selectedSeaPassageIndex === 0 || selectedSeaPassageIndex == null ?
		null :
		seaPassages?.[selectedSeaPassageIndex]
	);

	useEffect(() => {
		if (selectedSeaPassage == null) {
			return;
		}

		// When a sea passage is selected, set the dates to match
		setDateRange([
			toMoment(selectedSeaPassage.startDate),
			selectedSeaPassage.endDate != null ?
				toMoment(selectedSeaPassage.endDate) :
				nowMoment(),
		]);
	}, [selectedSeaPassage]);

	useEffect(() => {
		if (performanceDetails != null) {
			const { noonAtSeaReports: reports } = performanceDetails;
			const latestReport = reports[reports.length - 1];

			if (
				latestReport == null ||
				(
					defaultMapPosition?.latitude === latestReport.latitude &&
					defaultMapPosition?.longitude === latestReport.longitude
				)
			) {
				return;
			}

			setDefaultMapPosition({
				latitude: latestReport.latitude,
				longitude: latestReport.longitude,
			});
		}
	}, [
		performanceDetails,
		selectedSeaPassageIndex,
		loadingDetails,
		errorDetails,
		defaultMapPosition,
	]);

	useEffect(() => {
		const isOngoing = (
			dateRange?.[1] == null ||
			dateRange?.[1]?.isSame(nowMoment(), 'day')
		);

		const isStartDateDifferent = !dateRange?.[0].isSame(selectedSeaPassage?.startDate, 'day');
		const isEndDateDifferent = !(
			(selectedSeaPassage?.endDate == null && isOngoing) ||
			dateRange?.[1]?.isSame(selectedSeaPassage?.endDate, 'day')
		);

		if (
			selectedSeaPassage != null &&
			dateRange != null &&
			(isStartDateDifferent || isEndDateDifferent)
		) {
			setSelectedSeaPassageIndex(undefined);
		}
		// Only clear sea passage when changing the dateRange - not when changing the sea passage
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateRange]);

	const openReportDrawer = (report: any) => {
		setReportToDisplay(report);
	};

	const closeReportDrawer = () => {
		setReportToDisplay(null);
	};

	useEffect(() => {
		const reports = performanceDetails?.noonAtSeaReports;

		if (
			reports == null ||
			reports.length === 0
		) {
			return;
		}

		setOpenRemarks(reports[reports.length - 1].id);
	}, [performanceDetails?.noonAtSeaReports]);

	const allReports = useMemo(() => [
		...performanceDetails?.noonAtSeaReports ?? [],
		...(performanceDetails?.noonInPortReports ?? []).map((r) => ({
			...r,
			isInPort: true,
			averageSpeed: null,
			instructedSpeed: null,
			actualConsumption: null,
			warrantedConsumption: null,
			windForceBeaufort: null,
			windDirection: null,
			averageRPM: null,
			mainEnginePower: null,
		})),
	].filter((r1, i1, all) => all.every((r2, i2) => (
		formatDate(r1.date, DATE_SHORT_MONTH) !== formatDate(r2.date, DATE_SHORT_MONTH) || i1 <= i2
	))), [performanceDetails]);

	const chartData = [...allReports].sort((a, b) => toMoment(a.date).diff(b.date));

	const reports: AllPerformanceReports | [] = useMemo(() => {
		if (performanceDetails == null) {
			return [];
		}

		const {
			noonAtSeaReports,
			noonInPortReports,
			arrivalReports,
			departureReports,
		} = performanceDetails as GetPerformanceDetailsResponse;

		if (noonAtSeaReports == null) {
			return [];
		}

		return [
			...noonAtSeaReports,
			...noonInPortReports,
			...arrivalReports,
			...departureReports,
		];
	}, [performanceDetails]);

	const height = expandMap ? 600 : 400;

	const filteredNoonInPortReports = useMemo(() => allReports.filter(
		(r) => r.type === CrewReportTypes.NOON_IN_PORT,
	), [allReports]);

	const shadedAreas = useMemo(() => {
		const doGroupingPass = (arr: ShadedArea[]): [ShadedArea[], boolean] => {
			for (let i = 0; i < arr.length; i++) {
				const entry = arr[i];

				for (let j = 0; j < arr.length; j++) {
					const partner = arr[j];

					if (i === j) {
						// eslint-disable-next-line no-continue
						continue;
					}

					if (entry.x2 === partner.x1) {
						const newEntry = { x1: entry.x1, x2: partner.x2 };
						const filteredArray = arr.filter((_, index) => index !== j && index !== i);

						return [[newEntry, ...filteredArray], true];
					}
				}
			}

			return [arr, false];
		};

		const groupShadedAreas = (input: ShadedArea[]): ShadedArea[] => {
			let output = input;
			let hasChanged;

			do {
				[output, hasChanged] = doGroupingPass(output);
			} while (hasChanged);

			return output;
		};

		const ungroupedShadedAreas = filteredNoonInPortReports
			.reduce<ShadedArea[]>(
				(arr: ShadedArea[], report: { date: Moment }): ShadedArea[] => {
					const x1 = formatDate(toMoment(report.date), DATE_SHORT_MONTH);

					const nextReport = allReports.reduce<{ date: Moment } | null>((candidate, r) => {
						if (
							toMoment(r.date)
								.isAfter(report.date) &&
							(candidate == null || toMoment(r.date)
								.isBefore(candidate.date))
						) {
							return r;
						}

						return candidate;
					}, null);

					if (nextReport == null) {
						return arr;
					}

					const x2 = formatDate(toMoment(nextReport.date), DATE_SHORT_MONTH);

					if (x1 == null || x2 == null) {
						return arr;
					}

					return [...arr, {
						x1,
						x2,
					}];
				}, [],
			);

		return groupShadedAreas(ungroupedShadedAreas);
	}, [allReports, filteredNoonInPortReports]);

	const getWindDirectionTooltip = useCallback((
		report: (typeof chartData)[number],
	): TooltipProps | null => {
		if (report.windDirection == null) {
			return null;
		}

		return ({
			name: 'Direction',
			value: report.windDirection.toString(),
			color: 'black',
		});
	}, []);

	if (errorDetails || errorOverview) {
		return (<ErrorPage error={errorDetails || errorOverview} />);
	}

	if (loadingDetails || loadingOverview || performanceDetails == null) {
		return (<LoadingIndicator />);
	}

	if ((seaPassages == null || seaPassages.length === 0) && dateRange == null) {
		return (
			<SimpleScreen
				centerContent
				title={performanceDetails.vesselName}
				breadcrumbs={[['Performance', Links.PerformanceOverview.get()]]}
				rootPageTitle="Performance"
				canGoBack
			>
				<div className={styles.emptyWrapper}>
					<Empty
						className={styles.emptyMessage}
						description="You do currently not have any sea passages on this vessel"
						image={manOnBoat}
					/>
				</div>
			</SimpleScreen>
		);
	}

	const {
		noonAtSeaReports,
		noonInPortReports,
		departureReports,
		arrivalReports,
	} = performanceDetails;

	const combinedReports = [
		...noonAtSeaReports,
		...noonInPortReports,
		...arrivalReports,
		...departureReports,
	].sort((a, b) => {
		if (toMoment(a.date).isBefore(toMoment(b.date))) {
			return 1;
		}

		return -1;
	});

	const endDate = dateRange?.[1] == null ? nowMoment() : dateRange[1];

	const hasShadedAreas = noonInPortReports.some((r) => (
		toMoment(r.date).isSameOrAfter(dateRange?.[0], 'days') &&
		toMoment(r.date).isSameOrBefore(endDate, 'days')
	));

	const clickToOpenTooltipItem = {
		label: '(Click to open report)',
		color: 'grey',
		noData: true,
	} as const;

	const shadedAreasLegendOrNothing = !hasShadedAreas ? [] : [
		{
			label: 'In Port',
			color: '#a7a7a7',
			type: 'rect' as LegendType,
		},
	];

	const getSeaPassageOptions = () => {
		if (seaPassages == null || seaPassages.length === 0) {
			return [
				{
					value: -1,
					label: (
						<div className={styles.seaPassageSelectNoPassages}>
							<span>
								No sea passages available
							</span>
							<TooltipIcon>
								The beginning of a sea passage is indicated by a departure report.
								No departure reports have been received for this vessel,
								so no sea passages are available.
							</TooltipIcon>
						</div>
					),
				},
			];
		}

		return seaPassages.map((sp, i) => ({
			value: i,
			label: (
				<div className={styles.seaPassageSelectLabel}>
					<div className={styles.seaPassagePartWrapper}>
						<span className={styles.seaPassagePortName}>
							{sp.startPortName ?? UNKNOWN_PORT}
						</span>
						<span className={styles.deemphasized}>
							{toMoment(sp.startDate).format(DATE)}
						</span>
					</div>
					{' '}
					<FontAwesomeIcon icon={faArrowRight as IconProp} />
					{' '}
					<div className={styles.seaPassagePartWrapper}>
						<span className={styles.seaPassagePortName}>
							{sp.endPortName ?? '(Ongoing)'}
						</span>
						<span className={styles.deemphasized}>
							{sp.endDate != null ? toMoment(sp.endDate).format(DATE) : ''}
						</span>
					</div>
				</div>
			),
		}));
	};

	return (
		<SimpleScreen
			title={performanceDetails.vesselName}
			breadcrumbs={[['Performance', Links.PerformanceOverview.get()]]}
			rootPageTitle="Performance"
			canGoBack
		>
			<CrewReportDrawer
				report={reportToDisplay}
				onClose={closeReportDrawer}
			/>
			<Row gutter={[16, 16]}>
				<Col md={24} lg={24} xl={6} className={styles.cardContainer}>
					<Tabs
						defaultActiveKey="dates"
						type="card"
					>
						<Tabs.TabPane
							tab="Sea Passage"
							key="seaPassage"
						>
							<div className={styles.seaPassageWrapper}>
								<div>
									<Select<number>
										defaultActiveFirstOption
										disabled={seaPassages == null || seaPassages.length === 0}
										placeholder="Select sea passage"
										onChange={(newValue) => setSelectedSeaPassageIndex(newValue as number ?? 0)}
										value={
											(seaPassages == null || seaPassages.length === 0) ?
												-1 :
												selectedSeaPassageIndex
										}
										fullWidth
										options={getSeaPassageOptions()}
									/>
								</div>
							</div>
						</Tabs.TabPane>
						<Tabs.TabPane
							tab="Dates"
							key="dates"
						>
							<div className={classNames(styles.rangeSelectorWrapper)}>
								<DatePicker
									range
									onChange={
										(newRange) => setDateRange((newRange as [Moment, Moment]) ?? undefined)
									}
									// @ts-ignore
									value={dateRange}
									time={false}
									rangeHorizontal
									placeholder={['', 'Ongoing']}
									allowClear={false}
								/>
							</div>
						</Tabs.TabPane>
					</Tabs>
				</Col>
				<Col lg={24} xl={18}>
					<Table<PerformanceData>
						columns={getPerformanceStatusColumns(false)}
						loading={loadingOverview}
						dataSource={vessel}
						className={styles.overviewTable}
						pagination={false}
						size="small"
					/>
				</Col>
			</Row>
			<Row
				gutter={[16, 16]}
				// eslint-disable-next-line react/forbid-component-props
				style={{ marginTop: 16 }}
			>
				<Col lg={24} xl={expandMap ? 0 : 12}>
					<Row gutter={[16, 16]}>
						{chartData == null || chartData.length === 0 ? (
							<Col span={24}>
								<Card>
									<Empty
										image={(
											<FontAwesomeIcon
												color="crimson"
												size="4x"
												icon={faChartPie as IconProp}
											/>
										)}
										description={(
											<>
												<Typography.Title level={3}>
													This dashboard needs more data
												</Typography.Title>
												<Typography.Text type="secondary">
													We need more data before we can show performance charts
												</Typography.Text>
											</>
										)}
									/>
								</Card>
							</Col>
						) : (
							<>
								<Col span={24}>
									<Card title="Speed" size="small">
										<PerformanceLineChart
											data={chartData}
											height={280}
											shadedAreas={shadedAreas}
											openReportDrawer={openReportDrawer}
											extraLegendItems={[
												...shadedAreasLegendOrNothing,
											]}
											xAxis={{
												dataKey: (i) => formatDate(
													i.date,
													DATE_SHORT_MONTH,
												) ?? i.date.toISOString(),
											}}
											yAxis={{
												dataKey: 'averageSpeed',
											}}
											dataLines={[
												{
													dataKey: (i) => (i.averageSpeed == null ? 'hidden' : i.averageSpeed),
													label: 'Speed (knots)',
													color: ChartColors.DARK_LIGHTBLUE,
												},
											]}
											referenceLine={{
												dataKey: 'instructedSpeed',
												label: 'Charter party speed',
											}}
											yAxisTicks={10}
											extraTooltipItems={[
												clickToOpenTooltipItem,
											]}
											thresholds={NoonReportThresholds.AVERAGE_SPEED}
										/>
									</Card>
								</Col>
								<Col span={24}>
									<Card title="Consumption" size="small">
										<PerformanceLineChart
											data={chartData}
											height={300}
											shadedAreas={shadedAreas}
											openReportDrawer={openReportDrawer}
											extraLegendItems={[
												...shadedAreasLegendOrNothing,
											]}
											xAxis={{
												dataKey: (i) => formatDate(
													i.date,
													DATE_SHORT_MONTH,
												) ?? i.date.toISOString(),
											}}
											yAxis={{
												dataKey: 'actualConsumption',
											}}
											dataLines={[
												{
													dataKey: (i) => (i.actualConsumption == null ? 'hidden' : i.actualConsumption),
													label: 'Consumption (MT)',
													color: ChartColors.DARK_LIGHTBLUE,
												},
											]}
											referenceLine={{
												dataKey: 'warrantedConsumption',
												label: 'Warranty',
											}}
											yAxisTicks={10}
											extraTooltipItems={[
												clickToOpenTooltipItem,
											]}
											thresholds={NoonReportThresholds.CONSUMPTION}
										/>
									</Card>
								</Col>
								<Col span={24}>
									<Card title="Wind" size="small">
										<PerformanceLineChart
											data={chartData}
											height={200}
											xAxis={{
												dataKey: (i) => formatDate(
													i.date,
													DATE_SHORT_MONTH,
												) ?? i.date.toISOString(),
											}}
											yAxis={{
												dataKey: 'windForceBeaufort',
											}}
											shadedAreas={shadedAreas}
											openReportDrawer={openReportDrawer}
											extraLegendItems={[
												...shadedAreasLegendOrNothing,
											]}
											dataLines={[
												{
													dataKey: (i) => (i.windForceBeaufort == null ? 'hidden' : i.windForceBeaufort),
													label: 'Wind (BF)',
													color: ChartColors.DARK_LIGHTBLUE,
												},
											]}
											referenceLine={{
												y: 4,
												label: 'BF 4',
											}}
											yAxisTicks={8}
											extraTooltipItems={[
												getWindDirectionTooltip,
												clickToOpenTooltipItem,
											]}
											thresholds={NoonReportThresholds.WIND_FORCE}
										/>
									</Card>
								</Col>
								<Col span={24}>
									<Card title="RPM" size="small">
										<PerformanceLineChart
											data={chartData}
											height={180}
											xAxis={{
												dataKey: (i) => formatDate(
													i.date,
													DATE_SHORT_MONTH,
												) ?? i.date.toISOString(),
											}}
											yAxis={{
												dataKey: 'averageRPM',
											}}
											shadedAreas={shadedAreas}
											openReportDrawer={openReportDrawer}
											extraLegendItems={[
												...shadedAreasLegendOrNothing,
											]}
											dataLines={[
												{
													dataKey: (i) => (i.averageRPM == null ? 'hidden' : i.averageRPM),
													label: 'RPM',
													color: ChartColors.DARK_LIGHTBLUE,
												},
											]}
											yAxisTicks={5}
											extraTooltipItems={[
												clickToOpenTooltipItem,
											]}
											thresholds={NoonReportThresholds.AVERAGE_RPM}
										/>
									</Card>
								</Col>
								<Col span={24}>
									<Card title="ME Power kWH" size="small">
										<PerformanceLineChart
											data={chartData}
											height={180}
											xAxis={{
												dataKey: (i) => formatDate(
													i.date,
													DATE_SHORT_MONTH,
												) ?? i.date.toISOString(),
											}}
											yAxis={{
												dataKey: 'mainEnginePower',
											}}
											shadedAreas={shadedAreas}
											openReportDrawer={openReportDrawer}
											extraLegendItems={[
												...shadedAreasLegendOrNothing,
											]}
											dataLines={[
												{
													dataKey: (i) => (i.mainEnginePower == null ? 'hidden' : i.mainEnginePower),
													label: 'ME Power kWH',
													color: ChartColors.DARK_LIGHTBLUE,
												},
											]}
											yAxisTicks={3}
											extraTooltipItems={[
												clickToOpenTooltipItem,
											]}
											thresholds={NoonReportThresholds.MAIN_ENGINE_OUTPUT}
										/>
									</Card>
								</Col>
							</>
						)}
					</Row>
				</Col>
				<Col md={24} lg={24} xl={expandMap ? 24 : 12}>
					<Card title="Report overview">
						<Collapse>
							<Collapse.Panel
								header="Map"
								key={1}
							>
								<>
									<div style={{ height, marginBottom: 20 }}>
										<Map defaultPosition={defaultMapPosition}>
											{({ viewport }: { viewport: Viewport }) => (
												<>
													<ReportsLayer
														connectReports
														zoom={viewport.zoom}
														openReportDrawer={openReportDrawer}
														reports={reports}
													/>
													<VesselsLayer
														vesselIdsToShow={[vesselId]}
													/>
													{!screens.xl ? (<></>) : (
														!expandMap ? (
															<Button
																className={styles.titleAction}
																onClick={() => setExpandMap(true)}
																type="default"
																padding={7}
															>
																<Space>
																	Expand
																	<FontAwesomeIcon
																		icon={faChevronDoubleRight as IconProp}
																	/>
																</Space>
															</Button>
														) : (
															<Button
																onClick={() => setExpandMap(false)}
																className={styles.titleAction}
																type="default"
																padding={7}
															>
																<Space>
																	Collapse
																	<FontAwesomeIcon
																		icon={faChevronDoubleLeft as IconProp}
																	/>
																</Space>
															</Button>
														)
													)}
												</>
											)}
										</Map>
									</div>
								</>
							</Collapse.Panel>
						</Collapse>
						{combinedReports.length > 0 && (
							<div className={styles.remarksBox}>
								<Typography.Title level={4}>Remarks</Typography.Title>
								<div style={{ maxHeight: 500, overflowY: 'scroll' }}>
									<Collapse
										activeKey={openRemarks}
										onChange={(v) => setOpenRemarks(Array.isArray(v) ? v[v.length - 1] : v)}
										expandIconPosition="right"
									>
										{combinedReports.map((r) => (
											<Collapse.Panel key={r.id} header={`${r.type}: ${formatDate(r.date, DATE_AND_TIME)}`}>
												<div className={styles.remarksTitle}>
													<Button
														type="link"
														padding={0}
														className={styles.viewFullReportButton}
														onClick={() => openReportDrawer(r)}
													>
														View full report
													</Button>
												</div>
												<div className={styles.remarks}>
													{r.remarks ?? (
														<em>None</em>
													)}
												</div>
											</Collapse.Panel>
										))}
									</Collapse>
								</div>
							</div>
						)}
					</Card>
				</Col>
			</Row>
		</SimpleScreen>
	);
};

export default PerformanceDetailsScreen;
