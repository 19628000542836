import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
	Col,
	Form,
	Row,
	Skeleton,
} from 'antd';
import { Moment } from 'moment';
import {
	CrewReportTypes,
	Currencies,
	FuelTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import AddButton from '@client/components/AddButton';
import DatePicker from '@client/components/DatePicker';
import EditableCellTableRedux from '@client/components/EditableTableRedux/EditableCellTableRedux';
import Select from '@client/components/Select';
import { getPortOptions } from '@client/utils/getPortAndRangeOptions';
import Button from '@client/components/Button';
import { rules } from '@client/utils/form';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getPorts } from '@client/lib/api';
import { NullableBunkerRecord } from '@client/screens/fleet/VoyageDetailsScreen/tabs/components/RobEntryDrawer';

type Record = {
	quantity: number;
	pricePerTon?: number;
	fuelGrade: Values<typeof FuelTypes>;
}

type Props = {
	eventType: Values<typeof CrewReportTypes> | null;
	fixtureCurrency: Values<typeof Currencies>;
	newRobs: NullableBunkerRecord[];
	onDeleteRob: (rob: boolean, record: NullableBunkerRecord) => void;
	setNewRobs: (records: NullableBunkerRecord[]) => void;
	onSaveRob: () => void;
	setBunkersReceived: (records: NullableBunkerRecord[]) => void;
	bunkersReceived: NullableBunkerRecord[];
	selectedDate: Moment | null;
	acceptableFuels: Array<Values<typeof FuelTypes>>;
	voyageDetails: GetVoyageDetailsResponse | undefined;
}

const RobEntryFormItems = ({
	eventType,
	fixtureCurrency,
	newRobs,
	onDeleteRob,
	setNewRobs,
	onSaveRob,
	setBunkersReceived,
	bunkersReceived,
	selectedDate,
	acceptableFuels,
	voyageDetails,
}: Props) => {
	const [ports] = useFetchedState(getPorts);

	const canEdit = (
		eventType === CrewReportTypes.BUNKERING ||
		eventType === CrewReportTypes.BUNKERS_ROB ||
		eventType === CrewReportTypes.REDELIVERY ||
		eventType === CrewReportTypes.REEVALUATION
	);

	let fuelOptions = Object.keys(FuelTypes).map((key) => ({
		label: FuelTypes[key],
		value: key,
	}));

	if (voyageDetails?.linkedTcInVoyageId != null) {
		const uniqueFuels = Array.from(new Set([
			...(acceptableFuels ?? []),
			...(bunkersReceived ?? []).map((b) => b.fuelGrade),
		]));

		fuelOptions = uniqueFuels.map((f) => ({
			value: f,
			label: f,
		}));
	}

	return (
		<Row gutter={[16, 16]}>
			{eventType !== CrewReportTypes.COMMENCEMENT && (
				<>
					<Col span={12}>
						<Form.Item
							name="event"
							shouldUpdate
							label="Event"
							rules={[rules.required]}
						>
							<Select
								disabled={eventType != null && !canEdit}
								showSearch
								options={[
									{ label: CrewReportTypes.BUNKERING, value: CrewReportTypes.BUNKERING },
									{ label: CrewReportTypes.BUNKERS_ROB, value: CrewReportTypes.BUNKERS_ROB },
								]}
								placeholder="Select Event"
							/>
						</Form.Item>
					</Col>
					{eventType !== CrewReportTypes.BUNKERS_ROB && (
						<Col span={12}>
							<Form.Item
								name="port"
								shouldUpdate
								label="Port"
								rules={[rules.required]}
							>
								<Select
									disabled={!canEdit}
									showSearch
									options={ports == null ? [] : getPortOptions(ports)}
									placeholder="Select Port"
								/>
							</Form.Item>
						</Col>
					)}
					<Col span={12}>
						<Form.Item
							name="date"
							label="Date"
							valuePropName="date"
							rules={[rules.required]}
						>
							<DatePicker
								time
								placeholder="Select date"
								defaultPickerValue={selectedDate ?? undefined}
								value={selectedDate}
								disabled={!canEdit}
							/>
						</Form.Item>
					</Col>
				</>
			)}
			<Col span={24}>
				{eventType == null ? (
					<>
						<p>* Select event type to enter ROB&apos;s</p>
						<Skeleton />
					</>
				) : (
					<>
						{(
							eventType === CrewReportTypes.BUNKERING ||
							eventType === CrewReportTypes.REDELIVERY
						) && (
							<Form.Item label={eventType === CrewReportTypes.BUNKERING ? 'Bunkers Received' : 'Bunkers Sold'}>
								<EditableCellTableRedux<NullableBunkerRecord>
									dataSource={bunkersReceived}
									size="small"
									pagination={false}
									onChange={(v) => setBunkersReceived(v)}
									emptyText="Empty"
									columns={[
										{
											title: 'Fuel grade',
											dataIndex: 'fuelGrade',
											editable: true,
											width: 100,
											type: 'select',
											inputProps: {
												defaultValue: fuelOptions[0].value,
												options: Object.keys(FuelTypes).map((key) => ({
													label: FuelTypes[key],
													value: key,
												})),
											},
										},
										{
											title: 'Quantity',
											dataIndex: 'quantity',
											editable: true,
											type: 'number',
											inputProps: {
												addonAfter: 'MT',
											},
											transformData: {
												in: Math.abs,
												out: Math.abs,
											},
										},
										// @ts-ignore stfu this is legal
										{
											title: 'Price',
											dataIndex: 'pricePerTon',
											editable: true,
											type: 'currency',
											inputProps: {
												currency: fixtureCurrency,
											},
										},
										{
											title: (
												<AddButton
													onClick={() => {
														setBunkersReceived([
															...bunkersReceived,
															{
																fuelGrade: acceptableFuels?.[0] ?? FuelTypes.VLSFO,
																quantity: 0,
																pricePerTon: 0,
															},
														]);
													}}
												/>
											),
											// @ts-ignore
											dataIndex: 'addEntry',
											render: (record: Record) => (
												<Button
													onClick={() => onDeleteRob(false, record)}
													type="text"
													confirmTitle="Are you sure you want to delete this row?"
													danger
													icon={(<DeleteOutlined />)}
												/>
											),
										},
									]}
								/>
							</Form.Item>
						)}
						<Form.Item label={`${eventType === CrewReportTypes.BUNKERING ? 'ROB (including bunkered quantities)' : 'ROB'}`}>
							<EditableCellTableRedux<NullableBunkerRecord>
								dataSource={newRobs}
								size="small"
								pagination={false}
								onChange={(v) => setNewRobs(v)}
								emptyText="Empty"
								columns={[
									{
										title: 'Fuel grade',
										dataIndex: 'fuelGrade',
										editable: true,
										width: 100,
										type: 'select',
										inputProps: {
											defaultValue: fuelOptions[0].value,
											options: fuelOptions,
										},
									},
									{
										title: 'Quantity',
										dataIndex: 'quantity',
										editable: true,
										type: 'number',
										inputProps: {
											addonAfter: 'MT',
										},
									},
									// @ts-ignore stfu this is legal
									...(
										eventType === CrewReportTypes.DELIVERY ||
										eventType === CrewReportTypes.REEVALUATION ||
										eventType === CrewReportTypes.COMMENCEMENT ?
											[{
												title: `${eventType === CrewReportTypes.REEVALUATION ? 'New' : 'Purchase'} price`,
												dataIndex: 'pricePerTon',
												editable: true,
												type: 'currency',
												inputProps: {
													currency: fixtureCurrency,
												},
											}] : []
									),
									{
										title: (
											<AddButton
												onClick={() => {
													setNewRobs([
														...newRobs,
														{
															fuelGrade: acceptableFuels?.[0] ?? FuelTypes.VLSFO,
															quantity: 0,
															pricePerTon: 0,
														},
													]);
												}}
											/>
										),
										// @ts-ignore
										dataIndex: 'addEntry',
										render: (record: Record) => (
											<Button
												onClick={() => onDeleteRob(true, record)}
												type="text"
												confirmTitle="Are you sure you want to delete this row?"
												danger
												icon={(<DeleteOutlined />)}
											/>
										),
									},
								]}
							/>
						</Form.Item>
					</>
				)}
			</Col>
			<Col span={24}>
				<Button
					type="primary"
					onClick={onSaveRob}
				>
					Save & close
				</Button>
			</Col>
		</Row>
	);
};

export default RobEntryFormItems;
