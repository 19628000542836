import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import qs from 'qs';
import {
	CloseOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router';
import {
	Card,
	Col,
	Divider,
	Row,
	Switch,
	Tag,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronDown,
	faChevronUp,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import debounce from 'lodash.debounce';
import bbox from '@turf/bbox';
import {
	LinearInterpolator,
	WebMercatorViewport,
} from 'react-map-gl';
import { faRoute } from '@fortawesome/pro-duotone-svg-icons';
import {
	DEFAULT_SEA_ROUTE_SETTINGS,
	MetersPer,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import type { Route } from '@api/features/geo/calculateMultiRoute';
import type { Port } from '@api/utils/ports';
import type { VesselWithPosition } from '@api/features/geo/getVesselsWithPositions';
import VesselDescription from '@client/components/VesselDescription';
import Select from '@client/components/Select';
import Map from '@client/components/Map/Map';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	calculateMultiRoute,
	getPorts,
	getVesselsWithPositions,
} from '@client/lib/api';
import FullSizeScreen from '@client/components/screens/FullSizeScreen';
import DraggableList from '@client/components/DraggableList/DraggableList';
import Button from '@client/components/Button';
import PointLayer from '@client/components/Map/layers/PointLayer';
import RouteLayer from '@client/components/Map/layers/RouteLayer';
import showErrorNotification from '@client/utils/showErrorNotification';
import NumericInput from '@client/components/NumericInput';
import styles from './MapScreen.module.css';

type RouteEntry = {
	name: string;
	latitude: number;
	longitude: number;
}

const defaultViewport = {
	latitude: 0,
	longitude: 0,
	zoom: 2,
};

const MapScreen = () => {
	const location = useLocation();
	const [stops, setStops] = useState<RouteEntry[]>([]);
	const [route, setRoute] = useState<Route | null>(null);
	const [selectedItem, setSelectedItem] = useState<Port | VesselWithPosition | null>(null);
	const [routeLoading, setRouteLoading] = useState<boolean>(false);
	const [routeOptions, setRouteOptions] = useState(DEFAULT_SEA_ROUTE_SETTINGS);
	const [showRouteOptions, setShowRouteOptions] = useState<boolean>(false);

	const mapWrapperRef = useRef<HTMLDivElement>(null);

	const [routeViewport, setRouteViewport] = useState(null);

	const [vessels] = useFetchedState(getVesselsWithPositions);

	const [
		ports,
		_refreshPorts,
		_portError,
		loadingPorts,
	] = useFetchedState(getPorts);

	const filteredPorts = useMemo(
		() => ports?.filter((p) => p.name !== 'AT SEA'),
		[ports],
	);

	const selectItem = (value: string | null, reset: boolean = false) => {
		const port = (filteredPorts ?? []).find((p) => p.name === value);
		const vessel = (vessels || []).find((v) => v.name === value);

		const item = vessel || port;

		if (item == null) {
			return;
		}

		let latitude = 0;
		let longitude = 0;

		if (item === port) {
			latitude = port.latitude;
			longitude = port.longitude;
		}

		if (item === vessel) {
			latitude = vessel.position.latitude;
			longitude = vessel.position.longitude;
		}

		const stop = { name: item.name, longitude, latitude };

		if (reset) {
			setStops([stop]);
			setRouteViewport({
				// @ts-ignore
				latitude,
				longitude,
				transitionInterpolator: new LinearInterpolator(),
				transitionDuration: 400,
			});
			setSelectedItem(item);
		} else {
			const newStops = [...stops, { name: item.name, longitude, latitude }];
			setStops(newStops);
		}
	};

	useEffect(() => {
		if (vessels == null) {
			return;
		}

		const { v: focusedVesselId } = qs.parse(location.search.replace('?', ''));

		if (focusedVesselId == null) {
			return;
		}

		const focusedVessel = vessels.find((v) => v.id === Number(focusedVesselId));

		if (focusedVessel == null) {
			return;
		}

		selectItem(focusedVessel.name);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vessels]);

	const removeStop = (index: number) => {
		const newStops = stops.filter((_stop, i) => i !== index);
		setStops(newStops);
	};

	const calculateRoute = useCallback(async () => {
		setRouteLoading(true);

		try {
			const result = await calculateMultiRoute(stops, routeOptions);

			setRoute(result);

			const [minLng, minLat, maxLng, maxLat] = bbox(result);

			if (mapWrapperRef == null || mapWrapperRef.current == null) {
				return;
			}

			const newViewport = new WebMercatorViewport({
				width: mapWrapperRef.current.offsetWidth,
				height: mapWrapperRef.current.offsetHeight,
			}).fitBounds([
				[minLng, minLat],
				[maxLng, maxLat],
			], { padding: 40 });

			setRouteViewport({
				...newViewport,
				// @ts-ignore
				transitionInterpolator: new LinearInterpolator(),
				transitionDuration: 400,
			});
		} catch (e) {
			showErrorNotification('Could not find route', e as Error);
		}

		setRouteLoading(false);
	}, [stops, routeOptions]);

	const changeSetting = useCallback(async (setting: string, value: number | boolean) => {
		const newSettings = {
			...routeOptions,
		};
		newSettings[setting] = value;
		setRouteOptions(newSettings);
	}, [routeOptions]);

	const debouncedOnChange = useMemo(() => {
		return debounce((setting, value) => changeSetting(setting, value), 700);
	}, [changeSetting]);

	useEffect(() => {
		if (stops.length === 0 || stops.length === 1) {
			setRoute(null);
		}

		if (stops.length > 1) {
			calculateRoute();
		}
	}, [stops, calculateRoute, setRoute]);

	const totalDistance = useMemo(() => {
		if (route == null) {
			return 0;
		}

		const result = route.properties.distance;

		return result;
	}, [route]);

	const getLegDistance = (meters: number, formatted = true) => {
		const distance = meters / MetersPer.NAUTICAL_MILE;

		if (formatted) {
			return `${round(distance, 0)}nm`;
		}

		return distance;
	};

	const getLegDuration = (distance: number, speed: number) => {
		const rawHours = getLegDistance(distance, false) as number / speed;

		const endTime = nowMoment().add(rawHours, 'hours');
		const rawMinutes = toMoment(endTime).diff(nowMoment(), 'minutes');

		const days = Math.floor(rawMinutes / 1440);
		const hours = Math.floor((rawMinutes % 1440) / 60);

		return `${`${days}d ${hours}h`}`;
	};

	return (
		<FullSizeScreen
			rootPageTitle="World Map"
		>
			<div className={styles.mapWrapper} ref={mapWrapperRef}>
				<Card className={styles.searchCard}>
					<Select
						className={styles.search}
						showSearch
						onChange={(name: string | null) => selectItem(name, true)}
						suffixIcon={(<SearchOutlined />)}
						optionFilterProp="value"
						placeholder="Search for vessels and ports"
						// @ts-ignore
						value={selectedItem?.name}
						options={[
							{
								label: 'My vessels',
								options: (vessels ?? [])
									.filter((v) => v.ownershipType !== VesselOwnershipTypes.MARKET)
									.sort((a, b) => a.name.toString().localeCompare(b.name))
									.map((v) => ({
										label: (
											<VesselDescription
												name={v.name}
												flag={v.flag}
											/>
										),
										value: v.name,
									})),
							},

							{
								label: 'Market vessels',
								options: (vessels ?? [])
									.filter((v) => v.ownershipType === VesselOwnershipTypes.MARKET)
									.sort((a, b) => a.name.toString().localeCompare(b.name))
									.map((v) => ({
										label: (
											<VesselDescription
												name={v.name}
												flag={v.flag}
											/>
										),
										value: v.name,
									})),
							},
							{
								label: 'Ports',
								options: (filteredPorts ?? [])
									.sort((a, b) => a.name.toString().localeCompare(b.name))
									.map((p) => ({
										label: p.name,
										value: p.name,
									})),
							}]}

					/>
					{stops.length > 0 && (<Divider className={styles.slimmer} />)}
					{stops.length > 1 && (
						<DraggableList
							dataItems={stops}
							onOrderChange={setStops}
							itemKeyProp="name"
							renderSeparator={(_stop: RouteEntry, i: number) => {
								const nextStop = stops[i + 1];
								const legData = route?.features[i];

								if (legData == null && nextStop != null) {
									return (
										(
											<Divider className={styles.slim}>
												<Tag color="blue">
													...
												</Tag>
											</Divider>
										)
									);
								}

								if (nextStop == null || legData == null) {
									return (
										(<Divider className={styles.slim} />)
									);
								}

								return (
									(
										<Divider className={styles.slim}>
											<Tag
												icon={(
													<FontAwesomeIcon
														className={styles.icon}
														icon={faRoute as IconProp}
													/>
												)}
												color="blue"
											>
												{`${getLegDistance(legData.properties.distance)} | ${getLegDuration(legData.properties.distance, routeOptions.speed)}`}
											</Tag>
										</Divider>
									)
								);
							}}
							renderItem={(stop: RouteEntry, i: number) => (
								<div className={styles.entry}>
									{`${(stop.name || 'Point')}`}
									<Button
										onClick={() => removeStop(i)}
										type="link"
									>
										<CloseOutlined />
									</Button>
								</div>
							)}
							getCanDrag={undefined}
							className={undefined}
						/>
					)}
					{stops.length >= 1 && (
						<span>
							<Select
								loading={loadingPorts}
								bordered={false}
								placeholder="+ Add a stop to route"
								showArrow={false}
								showSearch
								optionFilterProp="value"
								className={styles.portSelect}
								dropdownMatchSelectWidth={400}
								// @ts-ignore
								value={null}
								onChange={selectItem}
								options={[{
									label: 'Vessels',
									options: (vessels ?? [])
										.sort((a, b) => a.name.toString().localeCompare(b.name))
										.map((v) => ({
											label: (
												<VesselDescription
													name={v.name}
													flag={v.flag}
												/>
											),
											value: v.name,
										})),
								},
								{
									label: 'Ports',
									options: (filteredPorts ?? [])
										.sort((a, b) => a.name.toString().localeCompare(b.name))
										.map((p) => ({
											label: p.name,
											value: p.name,
										})),
								}]}
							/>
						</span>
					)}
					{stops.length > 1 && (
						<>
							<Divider className={styles.slim} />
							{route != null && (
								<Row gutter={[16, 16]}>
									<Col span={12}>
										Distance:
										<br />
										<b>{getLegDistance(totalDistance)}</b>
										<div className={styles.secaDistance}>
											{getLegDistance(route?.properties.secaIntersection)}
											{' '}
											in ECA
										</div>
									</Col>
									<Col span={12}>
										Duration:
										<br />
										<b>{getLegDuration(totalDistance, routeOptions.speed)}</b>
									</Col>
									<Divider className={styles.slim} />
								</Row>
							)}
							<div style={{ textAlign: 'center' }}>
								<Button
									type="link"
									onClick={() => setShowRouteOptions(!showRouteOptions)}
									className={styles.button}
									icon={(
										<FontAwesomeIcon
											className={styles.icon}
											icon={(showRouteOptions ? faChevronUp : faChevronDown) as IconProp}
										/>
									)}
								>
									{showRouteOptions ? 'Hide ' : 'Show '}
									route options
								</Button>
							</div>
						</>
					)}
					{showRouteOptions && (
						<Row gutter={[16, 16]} className={styles.settings}>
							<Col span={12}>
								<b>Allow Panama</b>
								<br />
								<Switch
									disabled={routeLoading}
									onChange={(value) => changeSetting('allowPanamaTransit', value)}
									checked={routeOptions.allowPanamaTransit}
								/>
							</Col>
							<Col span={12}>
								<b>Allow Suez</b>
								<br />
								<Switch
									disabled={routeLoading}
									onChange={(value) => changeSetting('allowSuezTransit', value)}
									checked={routeOptions.allowSuezTransit}
								/>
							</Col>
							<Col span={12}>
								<b>Avoid ECA</b>
								<br />
								<Switch
									disabled={routeLoading}
									onChange={(value) => changeSetting('avoidSeca', value)}
									checked={routeOptions.avoidSeca}
								/>
							</Col>
							<Col span={12}>
								<b>Avoid HRA</b>
								<br />
								<Switch
									disabled={routeLoading}
									onChange={(value) => changeSetting('avoidHRA', value)}
									checked={routeOptions.avoidHRA}
								/>
							</Col>
							<Col span={24}>
								<NumericInput
									disabled={routeLoading}
									onChange={(value) => debouncedOnChange('speed', value)}
									value={routeOptions.speed}
									addonAfter="knots"
								/>
							</Col>
						</Row>
					)}
				</Card>
				<div className={styles.mapWrapper}>
					<Map
						anchorPosition={routeViewport || defaultViewport}
						defaultZoom={2}
						enableLegend
						legendShowWhat={{
							vesselsLayer: true,
							majorPortsLayer: true,
							ecaLayer: true,
						}}
					>
						{() => (
							<>
								{route != null && (
									<RouteLayer
										id="route"
										data={route}
										loading={routeLoading}
									/>
								)}
								{stops.map(({ name, latitude, longitude }) => (
									<PointLayer
										key={name}
										id={`destination-${name}`}
										name={name}
										latitude={latitude}
										longitude={longitude}
										layerStyle={{
											paint: {
												'circle-color': '#FF0000',
												'circle-radius': 6,
											},
										}}
									/>
								))}
							</>
						)}
					</Map>
				</div>
			</div>
		</FullSizeScreen>
	);
};

export default MapScreen;
