// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mmmNM_x9tW_OnmYedlH3 {\n\tborder-bottom: 0;\n\tborder-bottom-left-radius: 0;\n\tborder-bottom-right-radius: 0;\n\tmax-width: 500px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/styles/PortCallBunkersTab.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,4BAA4B;CAC5B,6BAA6B;CAC7B,gBAAgB;AACjB","sourcesContent":[".cardTable {\n\tborder-bottom: 0;\n\tborder-bottom-left-radius: 0;\n\tborder-bottom-right-radius: 0;\n\tmax-width: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardTable": "mmmNM_x9tW_OnmYedlH3"
};
export default ___CSS_LOADER_EXPORT___;
