import { formatQuantity } from '@shared/utils/string';
import {
	CrewReportTypes,
	Currencies,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import type { GetRobsResponse } from '@api/features/vessels/getRobs';

export const getBunkerRobExpenditureStrings = (
	entry: GetRobsResponse[number]['RobBunkers'][number] | undefined,
	event: Values<typeof CrewReportTypes>,
	currency: Values<typeof Currencies>,
	isTcInRedelivery: boolean,
) => {
	const change = entry?.change;
	const bunkered = entry?.change?.bunkered;
	const details = entry?.change.details;

	const totalQuantity = entry?.change.totalQuantity;

	const positiveChange = (
		totalQuantity != null &&
		totalQuantity
	) > 0;

	const quantityChangeStr = `${positiveChange ? '+' : ''}${formatQuantity(change?.totalQuantity ?? 0, 'MT')}`;

	const priceChangeStr: string[] = [];

	if (change?.customDescription != null) {
		if (Array.isArray(change.customDescription)) {
			change.customDescription.forEach((e) => priceChangeStr.push(e));
		} else {
			priceChangeStr.push(change?.customDescription);
		}
	}

	if (details != null && event !== CrewReportTypes.COMMENCEMENT) {
		details
			.filter((d) => d.quantity !== 0)
			.forEach(
				(d) => priceChangeStr.push(`
						${formatQuantity(Math.abs(d.quantity), 'MT')} consumed @ ${formatCurrency(d.pricePerTon ?? 0, currency)} / MT
						`),
			);
	}

	let bunkeredStr: string | null = null;

	if (bunkered?.quantity != null && bunkered.quantity > 0) {
		let eventStr = 'bunkered';

		if (event === CrewReportTypes.DELIVERY) {
			eventStr = 'delivered';
		}

		if (event === CrewReportTypes.REDELIVERY) {
			eventStr = 'redelivered';
		}

		bunkeredStr = `${bunkered.quantity} MT ${eventStr} @ ${formatCurrency(bunkered.pricePerTon ?? 0, currency)} / MT`;
	}

	return {
		bunkered,
		bunkeredStr,
		priceChangeStr,
		quantityChangeStr: isTcInRedelivery ? '' : quantityChangeStr,
		quantityChangeColor: totalQuantity === 0 ? 'black' : (positiveChange ? 'green' : 'red'),
	};
};
