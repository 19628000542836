import React from 'react';
import {
	Card,
	Col,
	Row,
	Typography,
} from 'antd';
import { formatCurrency } from '@shared/utils/currency';
import { round } from '@shared/utils/math';
import { YearQuarterDashboardPeriods } from '@shared/utils/constants';
import { useAuth } from '@client/lib/auth';
import { DashboardComponent } from '@client/screens/general/HomeScreen/helpers/dashboardInfo';
import { getFleetPnl } from '@client/lib/api';
import PnlWaterfallWidget from '@client/components/PnlWaterfallWidget';
import NumberWidget from '../components/NumberWidget';
import DashboardWidgetWrapper from '../components/DashboardWidgetWrapper';
import styles from './FleetPnlDashboard.module.css';

const FleetPnlDashboard: DashboardComponent<YearQuarterDashboardPeriods> = ({ period }) => {
	const { userInfo } = useAuth();

	return (
		<>
			<Row gutter={[16, 16]} data-tour="dashboardTop">
				<Col xs={24} xl={14} md={24}>
					<Row gutter={[16, 16]}>
						<Col className={styles.columnTitle} span={12}>
							<Typography.Title level={4}>
								{period === 'year' ? 'Year to Date' : 'Quarterly'}
							</Typography.Title>
						</Col>
						<Col className={styles.columnTitle} span={12}>
							<Typography.Title level={4}>
								Per Day
							</Typography.Title>
						</Col>
						<Col span={12}>
							<NumberWidget
								title="Net Revenue"
								headerTooltip={(
									<div className={styles.formulaTooltip}>
										+ Gross Hire
										{'\n'}
										+ Other Revenue
										{'\n'}
										- Commissions
										{'\n'}
										- Gain / (Loss) on Bunkers
										{'\n'}
										= Net Revenue
									</div>
								)}
								badge="NET"
								fetchData={getFleetPnl}
								fetchDataParams={[period]}
								transformData={(data) => formatCurrency(data.revenue || 0, userInfo.baseCurrency)}
							/>
						</Col>
						<Col span={12}>
							<NumberWidget
								title="Revenue / Day"
								headerTooltip="Net revenue per day from voyages in the period"
								badge="NET"
								fetchData={getFleetPnl}
								fetchDataParams={[period]}
								transformData={(data) => formatCurrency(
									round(data.revenue / data.numberOfDays, 0),
									userInfo.baseCurrency,
								)}
							/>
						</Col>
						<Col span={12}>
							<NumberWidget
								title="TC In Costs"
								headerTooltip="TC In costs from voyages in the period"
								badge="NET"
								fetchData={getFleetPnl}
								fetchDataParams={[period]}
								transformData={(data) => formatCurrency(
									data.tcInCost * -1 || 0,
									userInfo.baseCurrency,
								)}
							/>
						</Col>
						<Col span={12}>
							<NumberWidget
								title="TC In Costs / Day"
								headerTooltip="TC in costs per day from voyages in the period"
								badge="NET"
								fetchData={getFleetPnl}
								fetchDataParams={[period]}
								transformData={(data) => formatCurrency(
									round(-1 * (data.tcInCost / data.numberOfDays), 0),
									userInfo.baseCurrency,
								)}
							/>
						</Col>
						<Col span={12}>
							<NumberWidget
								title="Voyage Expenses"
								headerTooltip="Other expenses from voyages in the period"
								badge="NET"
								fetchData={getFleetPnl}
								fetchDataParams={[period]}
								transformData={(data) => formatCurrency(
									-1 * data.otherExpenses || 0,
									userInfo.baseCurrency,
								)}
							/>
						</Col>
						<Col span={12}>
							<NumberWidget
								title="Voyage Expenses / Day"
								headerTooltip="Other expenses per day from voyages in the period"
								badge="NET"
								fetchData={getFleetPnl}
								fetchDataParams={[period]}
								transformData={(data) => formatCurrency(
									-1 * round(data.otherExpenses / data.numberOfDays, 0),
									userInfo.baseCurrency,
								)}
							/>
						</Col>
						<Col span={12}>
							<NumberWidget
								title="CAPEX & OPEX"
								headerTooltip="CAPEX & OPEX from vessels in the period"
								badge="NET"
								fetchData={getFleetPnl}
								fetchDataParams={[period]}
								transformData={(data) => formatCurrency(
									-1 * (data.capexCost + data.opexCost) || 0,
									userInfo.baseCurrency,
								)}
							/>
						</Col>
						<Col span={12}>
							<NumberWidget
								title="CAPEX & OPEX / Day"
								headerTooltip="CAPEX & OPEX per day from vessels in the period"
								badge="NET"
								fetchData={getFleetPnl}
								fetchDataParams={[period]}
								transformData={(data) => formatCurrency(
									-1 * round((data.capexCost + data.opexCost) / data.numberOfDays, 0),
									userInfo.baseCurrency,
								)}
							/>
						</Col>
						<Col span={12}>
							<div className={styles.profit}>
								<NumberWidget
									title="Profit"
									headerTooltip={(
										<div className={styles.formulaTooltip}>
											+ Revenue
											{'\n'}
											- Voyage Expenses
											{'\n'}
											- CAPEX
											{'\n'}
											- OPEX
											{'\n'}
											- TC-In costs
											{'\n'}
											= Profit
										</div>
									)}
									badge="NET"
									fetchData={getFleetPnl}
									fetchDataParams={[period]}
									transformData={(data) => formatCurrency(
										data.profit || 0,
										userInfo.baseCurrency,
									)}
								/>
							</div>
						</Col>
						<Col span={12}>
							<div className={styles.profit}>
								<NumberWidget
									title="Profit / Day"
									headerTooltip={(data) => (data != null ? (
										`Profit of ${formatCurrency(round(data.profit, 0), userInfo.baseCurrency)} divided by ${round(data.numberOfDays)} days in period`
									) : undefined)}
									badge="NET"
									fetchData={getFleetPnl}
									fetchDataParams={[period]}
									transformData={(data) => formatCurrency(
										round(data.profit / data.numberOfDays, 0),
										userInfo.baseCurrency,
									)}
								/>
							</div>
						</Col>
					</Row>
				</Col>
				<Col xl={10} md={24} lg={24}>
					<Card>
						<DashboardWidgetWrapper
							noContainer
							fetchData={getFleetPnl}
							fetchDataParams={[period]}
							renderWidget={({ data }) => (
								<PnlWaterfallWidget
									data={[
										{
											name: 'Net Revenue',
											value: data?.revenue || 0,
										},
										{
											name: 'TC In Costs',
											value: data?.tcInCost || 0,
										},
										{
											name: 'CAPEX',
											value: data?.capexCost || 0,
										},
										{
											name: 'OPEX',
											value: data?.opexCost || 0,
										},
										{
											name: 'Other Expenses',
											value: data?.otherExpenses || 0,
										},
										{
											name: 'Profit',
											value: data?.profit || 0,
										},
									]}
									currency={userInfo.baseCurrency}
								/>
							)}
						/>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default FleetPnlDashboard;
