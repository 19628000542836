import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Badge,
	Empty,
	Tabs,
} from 'antd';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { UnnamedBankAccountName } from '@shared/utils/constants';
import { getBankingItems } from '@client/screens/fleet/VesselDetailsScreen/helpers/descriptionItemsHelper';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import Button from '@client/components/Button';
import styles from './styles/BankAccountTabs.module.css';

export const BankAccountTabs = ({
	bankAccounts,
	addBankAccount,
	saveBankAccount,
	deleteBankAccount,
	allowCreate,
	allowCurrencyChange,
	vesselOwner = false,
	setActiveBankAccountTab,
	activeBankAccountTab,
}) => {
	const onDeleteBankAccount = async (bankAccountId) => {
		await deleteBankAccount(bankAccountId);

		const newActiveId = bankAccounts.find((a) => a.id !== bankAccountId)?.id;
		setActiveBankAccountTab(newActiveId);
	};

	const onAddBankAccount = async () => {
		const newAccount = await addBankAccount();
		setActiveBankAccountTab(newAccount.id);
	};

	const addBankAccountButton = (
		<Button
			type="primary"
			onClick={async () => onAddBankAccount()}
			className={styles.saveButton}
		>
			Create Bank Account
		</Button>
	);

	return (
		<Tabs
			tabBarExtraContent={allowCreate ? addBankAccountButton : null}
			onChange={setActiveBankAccountTab}
			activeKey={activeBankAccountTab?.toString()}
		>
			{
				bankAccounts.length > 0 ? bankAccounts?.map((account) => (
					<Tabs.TabPane
						closable={false}
						key={account.id.toString()}
						tab={(
							<>
								<span>{account.name || UnnamedBankAccountName}</span>
								{bankAccounts.length > 1 && (
									<Button
										type="link"
										className={styles.tabDeleteButton}
										danger
										onClick={() => onDeleteBankAccount(account.id)}
										confirmTitle="Are you sure you want to delete this bank account?"
										icon={(<FontAwesomeIcon icon={faTimes} />)}
									/>
								)}
							</>
						)}
					>
						<EditableDetails
							key={account.id.toString()}
							className={styles.bankAccount}
							items={getBankingItems(account, allowCurrencyChange, vesselOwner)}
							onSave={(changes) => saveBankAccount(account, changes)}
							title={(
								<div className={styles.titleContainer}>
									Details
									<div className={styles.badgeContainer}>
										<Badge
											dot
											color="red"
											className={styles.bankAccountBadge}
										/>
										<p>Does not appear on invoices</p>
									</div>
								</div>
							)}
						/>
					</Tabs.TabPane>
				)) : (
					<div className={styles.emptyContainer}>
						<Empty
							description="No bank accounts found"
						/>
					</div>

				)
			}
		</Tabs>
	);
};
