import React from 'react';
import {
	Breakpoint,
	Tag,
} from 'antd';
import {
	PortActionTypeLabels,
	PortActionTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';

export const getPortCallActionTag = (
	action: Values<typeof PortActionTypes>,
	screens: Partial<Record<Breakpoint, boolean>>,
) => {
	let color;
	let label = PortActionTypeLabels[action];

	if (!screens.xxl) {
		// eslint-disable-next-line prefer-destructuring
		label = label[0];
	}

	switch (action) {
		case PortActionTypes.LOADING:
			color = 'geekblue';
			break;

		case PortActionTypes.DISCHARGING:
			color = 'red';
			break;

		case PortActionTypes.BUNKERING:
			color = 'green';
			break;

		default:
			color = 'gray';
	}

	return (
		<Tag color={color}>
			{label}
		</Tag>
	);
};
