import React, {
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { CardTabListType } from 'antd/es/card';
import {
	Col,
	Empty,
	Row,
	Typography,
	Collapse,
	Alert,
	Space,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import {
	faPencil,
	faSave,
	faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { CheckOutlined } from '@ant-design/icons';
import {
	ConsumptionRelationTypes,
	FuelTypes,
	FuelZoneTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { ConsumptionSetProps } from '@api/models/consumption-set';
import type { PerformanceEntryProps } from '@api/models/performance-entry';
import Card from '@client/components/Card/Card';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import EditableCellTableRedux from '@client/components/EditableTableRedux/EditableCellTableRedux';
import showErrorNotification from '@client/utils/showErrorNotification';
import {
	createVesselConsumptionSet,
	deleteVesselConsumptionSet,
	updateVessel,
	updateVesselConsumptionEntries,
	updateVesselConsumptionSet,
} from '@client/lib/api';
import AsyncSwitch from '@client/components/Async/AsyncSwitch';
import Button from '@client/components/Button';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import Table from '@client/components/Table/Table';
import Details from '@client/components/Details';
import {
	getAtSeaEntries,
	getColumns,
	getInPortEntries,
	SIMPLE_COLUMNS_AT_SEA,
	SIMPLE_COLUMNS_IN_PORT,
} from '@client/screens/fleet/VesselDetailsScreen/components/ConsumptionSets/helpers';
import styles from './ConsumptionSets.module.css';

export type SimplePerformance = {
	consumptionSetId?: number;
	relation: ConsumptionRelationTypes;
	fuelZoneType: Values<typeof FuelZoneTypes>;
	fuelType: Values<typeof FuelTypes>;
	ballast?: number;
	laden?: number;
	working?: number;
	idle?: number;
	ballastId?: number;
	ladenId?: number;
	workingId?: number;
	idleId?: number;
}

type Props = {
	activeConsumptionSetId?: number | null;
	onMakePrimary?: (id: number) => void;
	consumptionSets: Array<
		ConsumptionSetProps & { PerformanceEntries: Array<PerformanceEntryProps> }
	>;
	inPortEntries: Array<PerformanceEntryProps> | undefined;
	vesselId: number | undefined | null;
	refreshVessel: () => void;
	secondaryInPortEnabled: boolean;
	inEstimate?: boolean;
	setEditing?: React.Dispatch<SetStateAction<boolean>>;
	setBlockClose?: React.Dispatch<SetStateAction<boolean>>;
	unsavedChanges?: boolean;
	onRefresh?: () => void;
}

const ConsumptionSets = ({
	consumptionSets,
	onMakePrimary,
	inPortEntries,
	vesselId,
	refreshVessel,
	secondaryInPortEnabled,
	inEstimate = false,
	setEditing,
	activeConsumptionSetId,
	unsavedChanges,
	onRefresh,
	setBlockClose,
}: Props) => {
	const [tabs, setTabs] = useState<Array<CardTabListType>>();
	const [currentTab, setCurrentTab] = useState<string | undefined>(
		activeConsumptionSetId?.toString(),
	);
	const [loading, setLoading] = useState(false);
	const [oldVesselId] = useState<number | undefined | null>(vesselId);

	const [localData, setLocalData] = useState<{
		atSea: {
			main: Array<SimplePerformance>;
			secondary: Array<SimplePerformance>;
		};
		inPort: {
			main: Array<SimplePerformance>;
			secondary: Array<SimplePerformance>;
		};
	}
	>({
		atSea: {
			main: [],
			secondary: [],
		},
		inPort: {
			main: [],
			secondary: [],
		},
	});

	const handleDelete = useCallback(async (id: number) => {
		if (vesselId == null) {
			return;
		}

		try {
			await deleteVesselConsumptionSet(id, vesselId);
			refreshVessel();
			setCurrentTab(tabs?.[0]?.key);
			showSuccessNotification('Consumption set deleted');
			if (typeof onRefresh === 'function') {
				onRefresh();
			}
		} catch (e) {
			showErrorNotification('Could not delete consumption set', e as Error);
		}
	}, [refreshVessel, onRefresh, tabs, vesselId]);

	useEffect(() => {
		if (vesselId !== oldVesselId) {
			setTabs(undefined);
			setCurrentTab(undefined);
		}
	}, [oldVesselId, vesselId]);

	const getTabs = useCallback((
		sets: Array<ConsumptionSetProps>,
	): Array<CardTabListType> => {
		const isActive = (id: number) => id === activeConsumptionSetId;

		return sets
			.map((s) => ({
				label: (isActive != null && isActive(s.id)) ? `${s.name} (active)` : s.name,
				key: s.id.toString(),
				id: s.id.toString(),
				closeable: s.name !== 'Standard',
				closeIcon: (
					s.name !== 'Standard' && (
						<Button
							danger
							type="text"
							icon={(<FontAwesomeIcon icon={faTrash} />)}
							onClick={async () => {
								await handleDelete(s.id);
							}}
							confirmTitle="Are you sure you want to delete this entry?"
						/>
					)
				),
			}))
			.sort((a, b) => Number(a.id) - Number(b.id));
	}, [handleDelete, activeConsumptionSetId]);

	useEffect(() => {
		if (consumptionSets != null && tabs == null) {
			setTabs(getTabs(consumptionSets));
		}
	}, [consumptionSets, getTabs, tabs, activeConsumptionSetId]);

	useEffect(() => {
		if (currentTab == null) {
			setCurrentTab(tabs?.[0]?.key);
		}
	}, [currentTab, tabs]);

	const relevantEntry = useMemo(() => {
		if (currentTab == null) {
			return null;
		}

		return consumptionSets.find((s) => s.id.toString() === currentTab);
	}, [consumptionSets, currentTab]);

	useEffect(() => {
		if (loading) {
			return;
		}

		const inPort = getInPortEntries(inPortEntries ?? []);
		const atSea = getAtSeaEntries(relevantEntry);

		setLocalData({
			atSea,
			inPort,
		});
	}, [consumptionSets, inPortEntries, loading, relevantEntry]);

	const updateConsumptionSet = async (values: { [s: string]: any }) => {
		if (vesselId == null) {
			return;
		}

		if (changesReady) {
			await updateEntriesOnServer(false);
		}

		try {
			await updateVesselConsumptionSet({
				consumptionSetId: Number(currentTab),
				vesselId,
				attributes: values,
			});
			refreshVessel();
			if (typeof onRefresh === 'function') {
				onRefresh();
			}
		} catch (e) {
			showErrorNotification('Could not update consumption set', e as Error);
		}
	};

	const getDiff = useCallback(() => {
		const { inPort } = localData;
		const { atSea } = localData;

		const transformedInPort = [
			...inPort.main,
			...inPort.secondary,
		].map((v) => {
			const working = {
				id: v.workingId,
				consumption: v.working,
				fuelType: v.fuelType,
			};

			const idle = {
				id: v.idleId,
				consumption: v.idle,
				fuelType: v.fuelType,
			};

			return [working, idle];
		}).flat();

		const transformedAtSea = [
			...atSea.main,
			...atSea.secondary,
		].map((v) => {
			const ballast = {
				id: v.ballastId,
				consumption: v.ballast,
				fuelType: v.fuelType,
			};

			const laden = {
				id: v.ladenId,
				consumption: v.laden,
				fuelType: v.fuelType,
			};

			return [ballast, laden];
		}).flat();

		const together = [...transformedAtSea, ...transformedInPort];

		const performanceEntries = consumptionSets.map((p) => p.PerformanceEntries).flat();
		const allOldEntries = [...(inPortEntries ?? []), ...performanceEntries];

		const toUpdate: Array<{
			id?: number;
			consumption?: number;
			fuelType?: Values<typeof FuelTypes>;
		}> = [];

		together.forEach((newEntry) => {
			const oldEntry = allOldEntries.find((old) => old.id === newEntry.id);

			if (oldEntry == null) {
				return;
			}

			if (
				oldEntry.consumption !== newEntry.consumption ||
				oldEntry.fuelType !== newEntry.fuelType
			) {
				toUpdate.push(newEntry);
			}
		});

		return toUpdate;
	}, [consumptionSets, inPortEntries, localData]);

	const makePrimary = async () => {
		if (relevantEntry != null && onMakePrimary != null) {
			await onMakePrimary(relevantEntry.id);
		}
	};

	const changesReady = useMemo(() => {
		const changes = getDiff().length !== 0;

		setBlockClose?.(changes);

		return changes;
	}, [getDiff, setBlockClose]);

	const updateEntriesOnServer = useCallback(async (_refresh?: boolean) => {
		if (vesselId == null) {
			return;
		}

		setLoading(true);

		const diff = getDiff();

		if (diff.length === 0) {
			setLoading(false);

			return;
		}

		try {
			await updateVesselConsumptionEntries({
				vesselId,
				attributes: diff,
			});
			if (typeof onRefresh === 'function') {
				await onRefresh();
			}

			await refreshVessel();

			setLoading(false);
		} catch (e) {
			showErrorNotification('Could not update consumption entry', e as Error);
		}
	}, [vesselId, getDiff, onRefresh, refreshVessel]);

	const updateEntries = async (vals: Array<SimplePerformance>, type: 'inPort' | 'atSea', relation: 'main' | 'secondary') => {
		if (vesselId == null) {
			return;
		}

		setLocalData((prev) => ({
			...prev,
			[type]: {
				...prev[type],
				[relation]: vals,
			},
		}));
	};

	const handleAdd = async (
		_tab: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
		event: 'add' | 'remove',
	) => {
		if (vesselId == null || event === 'remove') {
			return;
		}

		try {
			await createVesselConsumptionSet(vesselId);
			refreshVessel();
			if (typeof onRefresh === 'function') {
				onRefresh();
			}
		} catch (e) {
			showErrorNotification('Could not create new consumption set', e as Error);
		}
	};

	const copyNormalRow = async (ecaRow: SimplePerformance, inPort: boolean = false) => {
		if (vesselId == null) {
			return;
		}

		const type = inPort ? 'inPort' : 'atSea';

		const [normalRow] = localData[type][ecaRow.relation]
			.filter((n) => n.fuelZoneType === FuelZoneTypes.NORMAL);

		let copiedRow = {};

		if (inPort) {
			copiedRow = {
				...ecaRow,
				working: normalRow.working,
				idle: normalRow.idle,
			};
		} else {
			copiedRow = {
				...ecaRow,
				ballast: normalRow.ballast,
				laden: normalRow.laden,
			};
		}

		setLocalData((prev) => ({
			...prev,
			[type]: {
				...prev[type],
				[ecaRow.relation]: [normalRow, copiedRow],
			},
		}));
	};

	if (relevantEntry == null) {
		return (<Empty>No consumption data available</Empty>);
	}

	if (inEstimate) {
		const firstTable = (
			<Table
				className={styles.simpleTable}
				pagination={false}
				size="small"
				dataSource={localData.atSea.main}
				bordered
				columns={[{
					title: 'At Sea - Main',
					children: SIMPLE_COLUMNS_AT_SEA,
				}]}
			/>
		);

		const secondTable = (
			<Table
				className={styles.simpleTable}
				pagination={false}
				size="small"
				dataSource={localData.inPort.main}
				bordered
				columns={[{
					title: 'In Port - Main',
					children: SIMPLE_COLUMNS_IN_PORT,
				}]}
			/>
		);

		const thirdTable = relevantEntry.enableSecondary ? (
			<Table
				pagination={false}
				size="small"
				dataSource={localData.atSea.secondary}
				bordered
				columns={[{
					title: 'At Sea - Secondary',
					children: SIMPLE_COLUMNS_AT_SEA,
				}]}
			/>
		) : null;

		const fourthTable = secondaryInPortEnabled ? (
			<Table
				pagination={false}
				size="small"
				dataSource={localData.inPort.secondary}
				bordered
				columns={[{
					title: 'In Port - Secondary',
					children: SIMPLE_COLUMNS_IN_PORT,
				}]}
			/>
		) : null;

		const tables = [firstTable, secondTable, thirdTable, fourthTable];
		const filtered = tables.filter((t): t is React.ReactElement => t !== null);

		return (
			<Card
				tabList={getTabs(consumptionSets)}
				onTabChange={(v) => setCurrentTab(v)}
				activeTabKey={currentTab}
				tabBarExtraContent={(
					<Space>
						{(
							activeConsumptionSetId != null &&
							activeConsumptionSetId !== relevantEntry.id
						) && (
							<Button
								icon={(<CheckOutlined />)}
								onClick={makePrimary}
							>
								Use this consumption profile
							</Button>
						)}
						<Button
							disabled={unsavedChanges}
							disabledTooltip="You have unsaved changes. Please save these before editing consumptions."
							icon={(<FontAwesomeIcon icon={faPencil} />)}
							type="primary"
							onClick={() => {
								if (typeof setEditing === 'function') {
									setEditing(true);
								}
							}}
						>
							Edit
						</Button>
					</Space>
				)}
				className={styles.smallCard}
				bordered={!inEstimate}
				slim
				size="small"
			>
				<div className={styles.simpleContainer}>
					<Row gutter={16}>
						<Col span={4} sm={6}>
							<Details
								hideHeader
								labelWidth={70}
								items={[
									{
										label: 'Ballast',
										key: 'ballastSpeed',
										value: `${relevantEntry.ballastSpeed} kt`,
									}, {
										label: 'Laden',
										key: 'ladenSpeed',
										value: `${relevantEntry.ladenSpeed} kt`,
									},
								]}
							/>
						</Col>
						<Col span={18}>
							<div className={styles.flexVertical}>
								<Space className={styles.flex} align="start">
									{filtered.slice(0, 2)}
								</Space>
								<Space className={styles.flex}>
									{filtered.slice(2, 4)}
								</Space>
							</div>

						</Col>
					</Row>
				</div>
			</Card>
		);
	}

	return (
		<Space direction="vertical">
			<Alert
				className={styles.alert}
				message={`
				Consumption figures are used for estimating bunker consumption during voyages.
				Secondary fuel types will only be included in estimated consumptions if enabled.
				`}
			/>
			<Button
				type="primary"
				size="large"
				className={styles.saveBtn}
				icon={(<FontAwesomeIcon icon={faSave} />)}
				disabled={!changesReady}
				onClick={() => updateEntriesOnServer(true)}
			>
				Save changes
			</Button>
			<Collapse defaultActiveKey={['atSea', 'inPort']}>
				<Collapse.Panel
					key="inPort"
					header={(<Title level={5} className={styles.title}>In Port</Title>)}
					className={styles.collapse}
				>
					<Space>
						<EditableCellTableRedux<SimplePerformance>
							title={() => (<Title level={5} className={styles.title}>Main</Title>)}
							className={styles.consumptionTable}
							pagination={false}
							dataSource={localData.inPort.main}
							columns={getColumns(copyNormalRow, false, true)}
							size="small"
							onChange={(values) => updateEntries(values, 'inPort', 'main')}
							loading={loading}
						/>
						<EditableCellTableRedux<SimplePerformance>
							title={() => (
								<div className={styles.spaceBetween}>
									<Title level={5} className={styles.title}>
										Secondary
									</Title>
									<Space>
										<Typography.Text>
											Enable secondary fuel type
										</Typography.Text>
										<AsyncSwitch
											checked={secondaryInPortEnabled}
											onChange={async (v) => {
												if (vesselId == null) {
													return;
												}

												if (changesReady) {
													await updateEntriesOnServer(true);
												}

												await updateVessel(vesselId, {
													enableSecondaryInPortConsumption: v,
												});

												refreshVessel();

												if (typeof onRefresh === 'function') {
													onRefresh();
												}
											}}
										/>
									</Space>
								</div>
							)}
							className={styles.consumptionTable}
							pagination={false}
							dataSource={localData.inPort.secondary}
							columns={getColumns(copyNormalRow, !secondaryInPortEnabled, true)}
							size="small"
							onChange={(values) => updateEntries(values, 'inPort', 'secondary')}
							loading={loading}
						/>
					</Space>
				</Collapse.Panel>
				<Collapse.Panel
					key="atSea"
					header={(<Title level={5} className={styles.title}>At Sea</Title>)}
					className={styles.collapse}
				>
					<Card
						tabList={getTabs(consumptionSets)}
						onTabChange={(v) => setCurrentTab(v)}
						activeTabKey={currentTab}
						className={classNames({
							[styles.card]: !inEstimate,
							[styles.cardWithMargin]: inEstimate,
						})}
						tabProps={{
							type: 'editable-card',
							onEdit: handleAdd,
							addIcon: (
								<Button
									className={styles.tabBtn}
									type="link"
									icon={(<FontAwesomeIcon size="lg" icon={faPlus} />)}
									onClick={async (e) => {
										e?.stopPropagation();
										if (changesReady) {
											await updateEntriesOnServer(true);
										}

										await handleAdd('', 'add');
									}}
									popconfirmProps={{
										onCancel: (e) => e?.stopPropagation(),
									}}
									confirmTitle={changesReady ? 'You have unsaved changes. Do you want to save?' : undefined}
									clickPropagation={false}
								/>
							),
						}}
						bordered={false}
					>
						<Row gutter={16}>
							<Col span={8}>
								<Space className={styles.space} direction="vertical">
									<EditableDetails
										title="Details"
										labelWidth={100}
										onSave={updateConsumptionSet}
										items={[
											{
												label: 'Entry name',
												key: 'name',
												type: 'text',
												editable: true,
												value: relevantEntry.name,
											},
											{
												label: 'Ballast',
												key: 'ballastSpeed',
												type: 'number',
												inputProps: {
													addonAfter: 'kt',
												},
												editable: true,
												value: relevantEntry.ballastSpeed,
												render: (c) => (
													<>
														{c.value}
														{' '}
														kt
													</>
												),
											}, {
												label: 'Laden',
												key: 'ladenSpeed',
												type: 'number',
												inputProps: {
													addonAfter: 'kt',
												},
												editable: true,
												value: relevantEntry.ladenSpeed,
												render: (c) => (
													<>
														{c.value}
														{' '}
														kt
													</>
												),
											},
										]}
									/>
								</Space>
							</Col>
							<Col span={16}>
								<div className={styles.flexVertical}>
									<EditableCellTableRedux<SimplePerformance>
										className={styles.consumptionTable}
										pagination={false}
										title={() => (<Title level={5} className={styles.title}>Main</Title>)}
										dataSource={localData.atSea.main}
										columns={getColumns(copyNormalRow)}
										size="small"
										onChange={(values) => updateEntries(values, 'atSea', 'main')}
										loading={loading}
									/>
									<EditableCellTableRedux<SimplePerformance>
										className={styles.consumptionTable}
										pagination={false}
										title={() => (
											<div className={styles.spaceBetween}>
												<Title level={5} className={styles.title}>
													Secondary
												</Title>
												<Space>
													<Typography.Text>
														Enable secondary fuel type
													</Typography.Text>
													<AsyncSwitch
														key={`switch-${relevantEntry?.id}`}
														checked={relevantEntry?.enableSecondary}
														onChange={async (v) => {
															await updateConsumptionSet({ 'enableSecondary': v });
														}}
													/>
												</Space>
											</div>
										)}
										dataSource={localData.atSea.secondary}
										columns={getColumns(copyNormalRow, !relevantEntry.enableSecondary)}
										size="small"
										onChange={(values) => updateEntries(values, 'atSea', 'secondary')}
										loading={loading}
									/>
								</div>
							</Col>
						</Row>
					</Card>
				</Collapse.Panel>
			</Collapse>
		</Space>
	);
};

export default ConsumptionSets;
