import React, {
	HTMLAttributes,
	ReactNode,
	RefObject,
	useEffect,
	useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Link,
	useHistory,
	useLocation,
} from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
	Breadcrumb,
	Card,
	Tabs,
} from 'antd';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TabsProps } from 'antd/lib/tabs';
import {
	getCurrentTab,
	navigateToTab,
} from '@client/utils/history';
import styles from './styles/DetailsTabScreen.module.css';
import RootScreen from './RootScreen';
import LoadingDetailsTabScreen, { LoadingDetailsTabScreenProps } from './private/LoadingDetailsTabScreen';

type Tab = {
	key: string;
	icon: IconProp;
	title: string;
	render: (params: { active: boolean }) => ReactNode;
	disabled?: boolean;
	forceRender?: boolean;
	menuItemProps?: HTMLAttributes<HTMLSpanElement>;
}

export type DetailsTabScreenProps = {
	tabs: Tab[];
	title?: ReactNode;
	extra?: ReactNode;
	defaultTabKey: string;
	loading?: boolean;
	tabContainerRef?: RefObject<HTMLDivElement>;
	tabsProps?: TabsProps;
	canGoBack?: boolean;
	breadcrumbs?: [string, string | undefined][];
	children?: React.ReactElement | Array<React.ReactElement | null>;
} & LoadingDetailsTabScreenProps;

const DetailsTabScreen = ({
	tabs,
	title,
	extra,
	defaultTabKey,
	loading,
	tabContainerRef,
	tabsProps,
	canGoBack,
	breadcrumbs = [],
	...props
}: DetailsTabScreenProps) => {
	const { hash } = useLocation();
	const history = useHistory();

	const [activeTab, setActiveTab] = useState<string>(getCurrentTab() || defaultTabKey);

	// Since history.push automatically reacts to navigation block
	// We don't need to manually block this
	// Simply update the hash value in the url - state will be updated by the useEffect hook
	const changeTab = (t: string) => {
		if (t === activeTab) {
			return;
		}

		navigateToTab(t);
	};

	// Keep the activeTab state up to date with the url hash value
	useEffect(() => {
		if (hash === '') {
			setActiveTab(defaultTabKey);

			return;
		}

		const hashTab = getCurrentTab().split('?')[0];

		const initialTab = tabs.find((t) => t.key === hashTab);

		if (initialTab == null || initialTab.key === activeTab) {
			return;
		}

		setActiveTab(initialTab.key);

	// Only run this when hash changes
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hash, loading]);

	if (loading) {
		return (
			<LoadingDetailsTabScreen
				{...props}
			/>
		);
	}

	return (
		<RootScreen {...props}>
			<Card className={styles.headerCard}>
				<PageHeader
					title={(
						<div className={styles.headerContainer}>
							<div className={styles.titleContainer}>
								<>
									<Breadcrumb
										className={styles.titleText}
									>
										{breadcrumbs.map(([text, path]) => (
											<Breadcrumb.Item>
												{path != null ? (
													<Link to={path}>{text}</Link>
												) : text}
											</Breadcrumb.Item>
										))}
										<Breadcrumb.Item>
											{title}
										</Breadcrumb.Item>
									</Breadcrumb>
									{alert}
								</>
							</div>
							{extra}
						</div>

					)}
					onBack={canGoBack ? () => history.goBack() : undefined}
					className={styles.header}
				/>
				<Tabs
					className={styles.cardTabs}
					activeKey={activeTab}
					onChange={changeTab}
				>
					{tabs.map((t) => (
						<Tabs.TabPane
							key={t.key}
							disabled={t.disabled}
							tab={(
								<span {...(t.menuItemProps)}>
									<FontAwesomeIcon
										icon={t.icon}
										className={styles.tabIcon}
									/>
									{t.title}
								</span>
							)}
						/>
					))}
				</Tabs>
			</Card>
			<div
				className={styles.tabContainer}
				ref={tabContainerRef}
			>
				<Tabs
					activeKey={activeTab}
					renderTabBar={() => (<></>)}
					{...tabsProps}
				>
					{tabs.map((t) => (
						<Tabs.TabPane forceRender={t.forceRender} key={t.key}>
							{t.render({ active: activeTab === t.key })}
						</Tabs.TabPane>
					))}
				</Tabs>
			</div>
		</RootScreen>
	);
};

export default DetailsTabScreen;
