import React from 'react';
import {
	Collapse,
	Empty,
	Grid,
	Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AlertTypeLabels } from '@shared/utils/constants';
import { useAuth } from '@client/lib/auth';
import { getVoyageAlerts } from '@client/lib/api';
import LoadingIndicator from '@client/components/LoadingIndicator';
import getWarningDescription from '@client/utils/getWarningDescription';
import LinkButton from '@client/components/LinkButton';
import VesselDescription from '@client/components/VesselDescription';
import DashboardWidgetWrapper from '../components/DashboardWidgetWrapper';
import styles from './styles/WarningsWidget.module.css';

const {
	Panel,
} = Collapse;

const WarningsWidget = () => {
	const { userInfo } = useAuth();
	const screens = Grid.useBreakpoint();

	const vesselFilter = userInfo.filterVessels && userInfo.relevantVessels.length > 0 ?
		userInfo.relevantVessels.map((v) => v.name) :
		null;

	return (
		<DashboardWidgetWrapper
			header="Warnings & Exceptions"
			fetchData={getVoyageAlerts}
			fetchDataParams={[]}
			renderWidget={({ data, loading }) => {
				if (loading || data == null) {
					return (<LoadingIndicator />);
				}

				const filteredData = (vesselFilter != null) ? (
					data.filter((e) => vesselFilter.some((v) => v === e.voyage.Vessel.name))
				) : data;

				return (
					<>
						{filteredData.length > 0 ? (
							<div className={styles.columns}>
								<b style={{ flex: screens.xl ? 3 : 2 }}>Warning</b>
								<b style={{ flex: 2 }}>Vessel</b>
								{screens.xl && (
									<b style={{ flex: 1 }}>Contract</b>
								)}
							</div>
						) : (
							<Empty
								className={styles.emptyWarnings}
								description="No warnings or exceptions found"
								image={(
									<FontAwesomeIcon
										size="4x"
										icon={faCheck as IconProp}
									/>
								)}
							/>
						)}
						{filteredData.map((warning) => (
							<Collapse
								bordered={false}
								className={styles.warning}
							>
								<Panel
									key="1"
									header={(
										<div className={styles.header}>
											<div style={{ flex: screens.xl ? 3 : 2 }}>
												<Space>
													<FontAwesomeIcon
														className={styles.warningIcon}
														icon={faTriangleExclamation as IconProp}
													/>
													<b>{AlertTypeLabels[warning.type]}</b>
												</Space>
											</div>
											<b style={{ flex: 2 }}>
												<VesselDescription
													name={warning.voyage.Vessel.name}
													flag={warning.voyage.Vessel.flag}
												/>
											</b>
											{screens.xl && (
												<div style={{ flex: 1 }}>
													<LinkButton
														clickPropagation={false}
														url={`/contracts/${warning.voyage.id}`}
													>
														<b>{warning.voyage.identifier}</b>
													</LinkButton>
												</div>
											)}
										</div>
									)}
								>
									{getWarningDescription(warning)}
								</Panel>
							</Collapse>
						))}
					</>
				);
			}}
		/>
	);
};

export default WarningsWidget;
