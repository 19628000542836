import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import isEqual from 'lodash.isequal';
import { formatCurrency } from '@shared/utils/currency';
import Select from '@client/components/Select';
import CurrencyInput from '@client/components/CurrencyInput';
import Button from '@client/components/Button';
import styles from './styles/PaymentInvoiceInput.module.css';

const getNewRow = () => ({
	key: Math.random(),
	dirty: false,
	hireInvoiceId: -1,
});

const getInitialState = (value) => {
	if (value != null && value.length > 0) {
		return value.map((val) => ({
			key: Math.random(),
			dirty: true,
			hireInvoiceId: val.hireInvoiceId,
			amount: val.amount,
			originalAmount: val.amount,
		}));
	}

	return [getNewRow()];
};

const statesAreEqual = (value, internalRows) => {
	if (value && value.length > 0) {
		const initialState = value.map((val) => ({
			hireInvoiceId: val.hireInvoiceId,
			amount: val.amount,
			originalAmount: val.amount,
		}));

		const transformedRows = internalRows.map(({ hireInvoiceId, amount, originalAmount }) => ({
			hireInvoiceId,
			amount,
			originalAmount,
		}));

		return isEqual(initialState, transformedRows);
	}

	return true;
};

const PaymentInvoiceInput = ({
	hireInvoices,
	totalAmount,
	onChange,
	currency,
	value,
}) => {
	const [initialTotalAmount, setInitialTotalAmount] = useState(totalAmount);

	const [internalRows, setInternalRows] = useState(getInitialState(value));

	const updateAmount = (rowKey) => (newAmount) => {
		setInternalRows((oldRows) => oldRows.map((row) => {
			if (row.key !== rowKey) {
				return row;
			}

			return {
				...row,
				amount: newAmount,
				dirty: true,
			};
		}));
	};

	const updateInvoiceId = (rowKey) => (newInvoiceId) => {
		setInternalRows((oldRows) => oldRows.map((row) => (row.key === rowKey ? ({
			...row,
			hireInvoiceId: newInvoiceId,
		}) : row)));
	};

	useEffect(() => {
		if (
			statesAreEqual(value, internalRows) &&
			totalAmount === initialTotalAmount
		) {
			return;
		}

		if (internalRows.every((row) => row.hireInvoiceId === -1)) {
			onChange([{
				hireInvoiceId: -1,
				amount: totalAmount,
			}]);

			return;
		}

		if (internalRows.length === 1 && internalRows[0].hireInvoiceId > 0) {
			const [row] = internalRows;

			onChange([{
				hireInvoiceId: row.hireInvoiceId,
				amount: totalAmount,
			}]);

			return;
		}

		onChange(internalRows.map((row) => ({
			hireInvoiceId: row.hireInvoiceId,
			amount: row.amount,
		})));
		// Don't update every time onChange is updated
		// We don't control that prop, since we're using Ant Forms
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [internalRows, totalAmount]);

	const selectedInvoices = useMemo(() => (
		internalRows.map((row) => row.hireInvoiceId)
	), [internalRows]);

	const addRow = () => setInternalRows((oldRows) => [
		...oldRows,
		getNewRow(),
	]);

	const isLarge = internalRows.length > 1 || internalRows[0].hireInvoiceId === null;

	return (
		<div className={styles.wrapper}>
			<div className={styles.inputs}>
				<div className={styles.invoices}>
					{internalRows.map(({ key, hireInvoiceId }) => (
						<Select
							key={key}
							className={styles.invoiceInput}
							value={hireInvoiceId}
							onChange={updateInvoiceId(key)}
							placeholder="Invoice"
							defaultValue="No invoice"
							allowClear={internalRows.length > 1}
							onClear={() => {
								setInternalRows(internalRows.filter((row) => row.key !== key));
								setInitialTotalAmount(0);
							}}
							dropdownMatchSelectWidth={false}
							optionLabelProp="selectedLabel"
							size={isLarge ? 'large' : 'middle'}
							options={[
								{
									label: 'No invoice',
									selectedLabel: 'No invoice',
									value: -1,
								},
								...(hireInvoices
									.map((hireInvoice) => ({
										label: (
											<div className={styles.invoiceWrapper}>
												{hireInvoice.invoiceIdentifier}
												<span className={styles.outstanding}>
													{`${formatCurrency(
														hireInvoice.amount,
														currency,
													)} invoiced`}
												</span>
											</div>
										),
										value: hireInvoice.id,
										disabled: selectedInvoices.includes(hireInvoice.id),
										selectedLabel: (
											<span className={styles.invoiceOptionLabel}>
												<span>{hireInvoice.invoiceIdentifier}</span>
												<span className={styles.outstanding}>
													{formatCurrency(
														hireInvoice.amount,
														currency,
													)}
												</span>
											</span>
										),
									}))
								),
							]}
						/>
					))}
				</div>
				{internalRows.length > 1 && (
					<div className={styles.amounts}>
						{internalRows.map(({ key, amount }) => (
							<CurrencyInput
								currency={currency}
								key={key}
								className={styles.amountInput}
								size="large"
								value={amount}
								onChange={updateAmount(key)}
								allowNegative
							/>
						))}
					</div>
				)}
			</div>
			{internalRows.length !== hireInvoices.length + 1 && (
				<Button
					onClick={addRow}
					type="link"
					className={styles.addButton}
					icon={(<PlusOutlined />)}
				>
					Split payment
				</Button>
			)}
		</div>
	);
};

export default PaymentInvoiceInput;
