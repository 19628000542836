import React from 'react';
import { Link } from 'react-router-dom';
import { toMoment } from '@shared/utils/date';
import {
	FixtureTypes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import VesselDescription from '@client/components/VesselDescription';
import { getFleetTimelineData } from '@client/lib/api';
import LoadingIndicator from '@client/components/LoadingIndicator';
import { ChartColors } from '@client/utils/constants';
import Timeline from '@client/components/Timeline';
import { Links } from '@client/utils/links';
import DashboardWidgetWrapper from '../components/DashboardWidgetWrapper';
import styles from './styles/FleetTimelineWidget.module.css';

const FleetTimelineWidget = () => (
	<DashboardWidgetWrapper
		header="Fleet Overview"
		fetchData={getFleetTimelineData}
		fetchDataParams={[[FixtureTypes.TC_OUT]]}
		renderWidget={({ data, loading }) => {
			if (loading) {
				return (<LoadingIndicator />);
			}

			const {
				vessels,
				fixtures,
				offHirePeriods,
			} = data;

			const filteredVessels = vessels.filter((v) => v.ownershipType !==
				VesselOwnershipTypes.MARKET);

			const items = filteredVessels.map((v) => {
				const vesselFixtures = fixtures.filter((f) => f.vesselId === v.id);
				const vesselOffHirePeriods = offHirePeriods.filter((f) => f.vesselId === v.id);

				return {
					id: v.id,
					label: (
						<VesselDescription
							name={v.name}
							flag={v.flag}
						/>
					),
					items: [
						...v.VesselOwnershipPeriods.map((p) => (
							{
								id: `${v.id}-owned`,
								link: Links.Vessel.get(v.id),
								period: [p.fromDate, p.toDate || toMoment(p.fromDate).add(100, 'years')],
								backgroundColor: ChartColors.GREY,
							}
						)),
						...vesselFixtures.map((f) => ({
							id: `${f.id}-fixed`,
							label: f.identifier,
							link: Links.Voyage.get(f.voyageId),
							period: f.minPeriod,
							backgroundColor: ChartColors.BLUE,
						})),
						...vesselFixtures
							.filter((f) => !toMoment(f.minPeriod[1]).isSame(f.maxPeriod[1]))
							.map((f) => ({
								id: `${f.id}-redelivery`,
								link: Links.Voyage.get(f.voyageId),
								period: [f.minPeriod[1], f.maxPeriod[1]],
								backgroundColor: ChartColors.LIGHTBLUE,
							})),
						...vesselOffHirePeriods.map((o) => ({
							id: `${o.id}-offHire`,
							link: Links.Voyage.get(o.voyageId),
							period: o.period,
							backgroundColor: ChartColors.RED,
						})),
					],
				};
			});

			const legendItems = [
				{
					key: 'ownedPeriod',
					label: 'Owned Period',
					fill: ChartColors.GREY,
				},
				{
					key: 'fixedPeriod',
					label: 'Fixed Period',
					fill: ChartColors.BLUE,
				},
				{
					key: 'redeliveryPeriod',
					label: 'Redelivery Period',
					fill: ChartColors.LIGHTBLUE,
				},
				{
					key: 'offHire',
					label: 'Off-Hire',
					fill: ChartColors.RED,
				},
			];

			return (
				<Timeline
					className={styles.fleetTimeline}
					groupRenderer={({ group }) => (
						<Link to={Links.Vessel.get(group.id)}>{group.title}</Link>
					)}
					sections={items}
					selected={[-1]}
					legend={legendItems}
					groupHeight={40}
				/>
			);
		}}
	/>
);

export default FleetTimelineWidget;
