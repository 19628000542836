import { Moment } from 'moment';
import {
	AccountTypes,
	FixtureCounterpartyTypes,
	BankAccountTypes,
	FixtureTypes,
	FuelZoneTypes,
	VesselOwnershipTypes,
	YearQuarterDashboardPeriods,
	CrewReportTypes,
	FuelTypes,
	Currencies,
	VcPortTypes,
	DepartmentKey,
	AccrualItemClosingPeriodFormats,
	AttachmentTypes,
	VesselConditionTypes,
	PeriodUnits,
	CustomFieldType,
	CustomFieldParentTypes,
	AccountingSystemStrategies,
	VesselTypes,
	CapexOpexInput,
	DashboardPeriods,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import { formatDate } from '@shared/utils/date';
import type {
	GetEstimateGroupRequest,
	GetEstimateGroupResponse,
} from '@api/features/estimates/getEstimateGroup';
import type {
	GetVesselsRequest,
	GetVesselsResponse,
} from '@api/features/vessels/getVessels';
import type { DeleteFixtureRequest } from '@api/features/fixtures/deleteFixture';
import type {
	GetVesselDetailsRequest,
	GetVesselDetailsResponse,
} from '@api/features/vessels/getVesselDetails';
import type {
	GetVoyagesRequest,
	GetVoyagesResponse,
} from '@api/features/voyages/getVoyages';
import type {
	GetPerformanceDetailsRequest,
	GetPerformanceDetailsResponse,
} from '@api/features/performance/getPerformanceDetails';
import type {
	GetVesselSeaPassagesRequest,
	GetVesselSeaPassagesResponse,
} from '@api/features/performance/getVesselSeaPassages';
import type {
	CreateFixturePerformanceEntryRequest,
	CreateFixturePerformanceEntryResponse,
} from '@api/features/fixtures/createFixturePerformanceEntry';
import type {
	UpdateFixturePerformanceEntryRequest,
	UpdateFixturePerformanceEntryResponse,
} from '@api/features/fixtures/updateFixturePerformanceEntry';
import type {
	UpdateRateScheduleItemRequest,
	UpdateRateScheduleItemResponse,
} from '@api/features/fixtures/updateRateScheduleItem';
import type {
	DeleteRateScheduleItemRequest,
	DeleteRateScheduleItemResponse,
} from '@api/features/fixtures/deleteRateScheduleItem';
import type {
	CreateRateScheduleItemResponse,
	CreateRateScheduleItemRequest,
} from '@api/features/fixtures/createRateScheduleItem';
import type {
	GetVesselsWithPositionsRequest,
	GetVesselsWithPositionsResponse,
} from '@api/features/geo/getVesselsWithPositions';
import type {
	GetPrimaryPortsRequest,
	GetPrimaryPortsResponse,
} from '@api/features/geo/getPrimaryPorts';
import type {
	UpdateHireInvoiceDetailsRequest,
	UpdateHireInvoiceDetailsResponse,
} from '@api/features/invoices/hire-invoices/updateHireInvoiceDetails';
import type {
	GetHireInvoiceDetailsRequest,
	GetHireInvoiceDetailsResponse,
} from '@api/features/invoices/hire-invoices/getHireInvoiceDetails';
import type {
	DeleteFixturePerformanceEntryRequest,
	DeleteFixturePerformanceEntryResponse,
} from '@api/features/fixtures/deleteFixturePerformanceEntry';
import type {
	CreateVesselRequest,
	CreateVesselResponse,
} from '@api/features/vessels/createVessel';
import type {
	GetPerformanceOverviewRequest,
	GetPerformanceOverviewResponse,
} from '@api/features/performance/getPerformanceOverview';
import type {
	CreateFixtureExpenseRequest,
	CreateFixtureExpenseResponse,
} from '@api/features/fixtures/createFixtureExpense';
import type {
	CreateSupplierRequest,
	CreateSupplierResponse,
} from '@api/features/suppliers/createSupplier';
import type {
	GetAttachmentUrlRequest,
	GetAttachmentUrlResponse,
} from '@api/features/attachments/getAttachmentUrl';
import type {
	GetTcInAllocationItemsRequest,
	GetTcInAllocationItemsResponse,
} from '@api/features/voyages/getTcInAllocationItems';
import type {
	UpdateTcInAllocationItemRequest,
	UpdateTcInAllocationItemResponse,
} from '@api/features/voyages/updateTcInAllocationItem';
import type {
	CreateVoyageBunkersRequest,
	CreateVoyageBunkersResponse,
} from '@api/features/voyages/createVoyageBunkers';
import type {
	DeleteFixtureExpenseRequest,
	DeleteFixtureExpenseResponse,
} from '@api/features/fixtures/deleteFixtureExpense';
import type {
	GetCounterpartiesRequest,
	GetCounterpartiesResponse,
} from '@api/features/counterparties/getCounterparties';
import type {
	DeleteSupplierRequest,
	DeleteSupplierResponse,
} from '@api/features/suppliers/deleteSupplier';
import type {
	DeleteVoyageBunkersRequest,
	DeleteVoyageBunkersResponse,
} from '@api/features/voyages/deleteVoyageBunkers';
import type {
	GetFleetPnlRequest,
	GetFleetPnlResponse,
} from '@api/features/statistics/getFleetPnl';
import type {
	GetOffHireDataRequest,
	GetOffHireDataResponse,
} from '@api/features/statistics/getOffHireData';
import type {
	GetSuppliersRequest,
	GetSuppliersResponse,
} from '@api/features/suppliers/getSuppliers';
import type {
	GetFeatureHighlightsRequest,
	GetFeatureHighlightsResponse,
} from '@api/features/admin/getFeatureHighlights';
import type {
	GetForwardFloatingDaysRequest,
	GetForwardFloatingDaysResponse,
} from '@api/features/statistics/getForwardFloatingDays';
import type {
	GetOperatingUtilisationRequest,
	GetOperatingUtilisationResponse,
} from '@api/features/statistics/getOperatingUtilisation';
import type {
	GetHistoricalTcPerformanceRequest,
	GetHistoricalTcPerformanceResponse,
} from '@api/features/statistics/getHistoricalTcPerformance';
import type {
	getHistoricalFleetUtilisationRequest,
	getHistoricalFleetUtilisationResponse,
} from '@api/features/statistics/getHistoricalFleetUtilisation';
import type {
	UpdateFixtureExpenseRequest,
	UpdateFixtureExpenseResponse,
} from '@api/features/fixtures/updateFixtureExpense';
import type {
	UpdateSupplierRequest,
	UpdateSupplierResponse,
} from '@api/features/suppliers/updateSupplier';
import type {
	UpdateCounterpartyRequest,
	UpdateCounterpartyResponse,
} from '@api/features/counterparties/updateCounterparty';
import type {
	UpdateVoyageBunkersRequest,
	UpdateVoyageBunkersResponse,
} from '@api/features/voyages/updateVoyageBunkers';
import type {
	UpdateHireInvoiceRequest,
	UpdateHireInvoiceResponse,
} from '@api/features/voyages/updateHireInvoice';
import type {
	UpdateVoyagePaymentRequest,
	UpdateVoyagePaymentResponse,
} from '@api/features/voyages/updateVoyagePayment';
import type {
	DeleteOffHirePeriodRequest,
	DeleteOffHirePeriodResponse,
} from '@api/features/voyages/deleteVoyageOffHirePeriod';
import type {
	UpdateOffHirePeriodAttributes,
	UpdateOffHirePeriodRequest,
	UpdateOffHirePeriodResponse,
} from '@api/features/voyages/updateVoyageOffHirePeriod';
import type {
	GetUserDataRequest,
	GetUserDataResponse,
} from '@api/features/user/getUserData';
import type {
	GetConnectionAndMappingRequest,
	GetConnectionAndMappingResponse,
} from '@api/features/accounting-system/getConnectionAndMapping';
import type {
	GetAccountingCustomersRequest,
	GetAccountingCustomersResponse,
} from '@api/features/accounting-system/getAccountingCustomers';
import type {
	GetCompanyAccountsRequest,
	GetCompanyAccountsResponse,
} from '@api/features/accounting-system/getCompanyAccounts';
import type {
	GetAccountingSuppliersRequest,
	GetAccountingSuppliersResponse,
} from '@api/features/accounting-system/getAccountingSuppliers';
import type {
	CreateVesselOffHireRequest,
	CreateVesselOffHireResponse,
} from '@api/features/vessels/createVesselOffHirePeriod';
import type {
	CreateConnectionRequest,
	CreateConnectionResponse,
} from '@api/features/accounting-system/createConnection';
import type {
	GetNavisionCompaniesRequest,
	GetNavisionCompaniesResponse,
} from '@api/features/accounting-system/navision/getNavisionCompanies';
import type {
	DisconnectAccountingSystemRequest,
	DisconnectAccountingSystemResponse,
} from '@api/features/accounting-system/disconnectAccountingSystem';
import type {
	CreateMappingEntryRequest,
	CreateMappingEntryResponse,
} from '@api/features/accounting-system/createMappingEntry';
import type {
	RefreshEuaPriceRequest,
	RefreshEuaPriceResponse,
} from '@api/features/estimates/refreshEuaPrice';
import type {
	UpdateVesselOffHirePeriodRequest,
	UpdateVesselOffHirePeriodResponse,
} from '@api/features/vessels/updateVesselOffHirePeriod';
import type {
	CreateVoyagePaymentRequest,
	CreateVoyagePaymentResponse,
} from '@api/features/voyages/createVoyagePayment';
import type {
	DeleteVoyagePaymentRequest,
	DeleteVoyagePaymentResponse,
} from '@api/features/voyages/deleteVoyagePayment';
import type {
	CreateVesselBunkerRequest,
	CreateVesselBunkerResponse,
} from '@api/features/vessels/createVesselBunker';
import type {
	UpdateVesselBunkerRequest,
	UpdateVesselBunkerResponse,
} from '@api/features/vessels/updateVesselBunker';
import type {
	DeleteVesselOffHirePeriodRequest,
	DeleteVesselOffHirePeriodResponse,
} from '@api/features/vessels/deleteVesselOffHirePeriod';
import type {
	AllocateVesselOffHirePeriodRequest,
	AllocateVesselOffHirePeriodResponse,
} from '@api/features/vessels/allocateVesselOffHirePeriod';
import type {
	UnAllocateVesselOffHirePeriodRequest,
	UnAllocateVesselOffHirePeriodResponse,
} from '@api/features/vessels/unAllocateVesselOffHirePeriod';
import type {
	DeleteVesselBunkerRequest,
	DeleteVesselBunkerResponse,
} from '@api/features/vessels/deleteVesselBunker';
import type {
	DeleteItineraryEntryRequest,
	DeleteItineraryEntryResponse,
} from '@api/features/ops/deleteItineraryEntry';
import type {
	UpdatePortCallRequest,
	UpdatePortCallResponse,
} from '@api/features/ops/updatePortCall';
import type {
	UpdateSeaPassageRequest,
	UpdateSeaPassageResponse,
} from '@api/features/ops/updateSeaPassage';
import type {
	CreateItineraryEntryRequest,
	CreateItineraryEntryResponse,
} from '@api/features/ops/createItineraryEntry';
import type {
	CreateCompanyRequest,
	CreateCompanyResponse,
} from '@api/features/integration/codat/createCompany';
import type {
	DeleteCompanyRequest,
	DeleteCompanyResponse,
} from '@api/features/integration/codat/deleteCompany';
import type {
	CalculateMultiRouteRequest,
	CalculateMultiRouteResponse,
} from '@api/features/geo/calculateMultiRoute';
import type {
	GetOrganizationDetailsRequest,
	GetOrganizationDetailsResponse,
} from '@api/features/admin/getOrganizationDetails';
import type {
	UpdateReportEmailRequest,
	UpdateReportEmailResponse,
} from '@api/features/admin/updateReportEmail';
import type VoyagePaymentPart from '@api/models/voyage-payment-part';
import type {
	Port,
	Range,
} from '@api/utils/ports';
import type {
	GetEstimateDetailsRequest,
	GetEstimateDetailsResponse,
} from '@api/features/estimates/getEstimateDetails';
import type {
	GetEstimatedBrokerCommissionsRequest,
	GetEstimatedBrokerCommissionsResponse,
} from '@api/features/estimates/getEstimatedBrokerCommissions';
import type {
	GetEstimatedDespatchAndDemurrageRequest,
	GetEstimatedDespatchAndDemurrageResponse,
} from '@api/features/estimates/getEstimatedDespatchAndDemurrage';
import type {
	AddCargoToEstimateRequest,
	AddCargoToEstimateResponse,
} from '@api/features/estimates/addCargoToEstimate';
import type {
	UpdateEstimateRequest,
	UpdateEstimateResponse,
} from '@api/features/estimates/updateEstimate';
import type {
	GetEstimatesRequest,
	GetEstimatesResponse,
} from '@api/features/estimates/getEstimates';
import type {
	GetCargosResponse,
	GetCargosRequest,
} from '@api/features/cargos/getCargos';
import type {
	CreateEstimateRequest,
	CreateEstimateResponse,
} from '@api/features/estimates/createEstimate';
import type {
	CreateContractFromEstimateRequest,
	CreateContractFromEstimateResponse,
} from '@api/features/estimates/createContractFromEstimate';
import type {
	DeleteEstimateRequest,
	DeleteEstimateResponse,
} from '@api/features/estimates/deleteEstimate';
import type {
	GetFixtureDetailsRequest,
	GetFixtureDetailsResponse,
} from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type {
	GetBrokersRequest,
	GetBrokersResponse,
} from '@api/features/brokers/getBrokers';
import type {
	GetVoyageCumulativeRequest,
	GetVoyageCumulativeResponse,
} from '@api/features/voyages/getVoyageCumulative';
import type {
	GetVesselBankAccountsRequest,
	GetVesselBankAccountsResponse,
} from '@api/features/vessels/getVesselBankAccounts';
import type {
	CreateBrokerRequest,
	CreateBrokerResponse,
} from '@api/features/brokers/createBroker';
import type {
	GetFixturesRequest,
	GetFixturesResponse,
} from '@api/features/fixtures/getFixtures';
import type {
	PostAccountingItemsRequest,
	PostAccountingItemsResponse,
} from '@api/features/financials/postAccountingItems';
import type {
	CloseAccountingItemsRequest,
	CloseAccountingItemsResponse,
} from '@api/features/financials/closeAccountingItems';
import type {
	PreviewFixtureRecapRequest,
	PreviewFixtureRecapResponse,
} from '@api/features/fixtures/previewFixtureRecap';
import type {
	CreateFixtureRecapRequest,
	CreateFixtureRecapResponse,
} from '@api/features/fixtures/createFixtureRecap';
import type {
	GetVesselItineraryRequest,
	GetVesselItineraryResponse,
} from '@api/features/ops/getVesselItinerary';
import type {
	GetCrewReportAsPdfRequest,
	GetCrewReportAsPdfResponse,
} from '@api/features/vessels/getCrewReportAsPdf';
import type {
	GetSofAsPdfRequest,
	GetSofAsPdfResponse,
} from '@api/features/vessels/getSofAsPdf';
import type {
	GetCrewReportsRequest,
	GetCrewReportsResponse,
} from '@api/features/vessels/getCrewReports';
import type {
	GetVesselActivityRequest,
	GetVesselActivityResponse,
} from '@api/features/vessels/getVesselActivity';
import type {
	AddItineraryCommentRequest,
	AddItineraryCommentResponse,
} from '@api/features/ops/addItineraryComment';
import type {
	DeleteItineraryCommentRequest,
	DeleteItineraryCommentResponse,
} from '@api/features/ops/deleteItineraryComment';
import type {
	ReorderPortCallRequest,
	ReorderPortCallResponse,
} from '@api/features/ops/reorderPortCall';
import type {
	GetVoyageDetailsRequest,
	GetVoyageDetailsResponse,
} from '@api/features/voyages/getVoyageDetails';
import type {
	GetSimpleVoyageDetailsRequest,
	GetSimpleVoyageDetailsResponse,
} from '@api/features/voyages/getSimpleVoyageDetails';
import type {
	GetVoyageCompletionTypeRequest,
	GetVoyageCompletionTypeResponse,
} from '@api/features/voyages/getVoyageCompletionType';
import type {
	GetCargoDetailsRequest,
	GetCargoDetailsResponse,
} from '@api/features/cargos/getCargoDetails';
import type {
	CreateCargoRequest,
	CreateCargoResponse,
} from '@api/features/cargos/createCargo';
import type {
	UpdateCargoRequest,
	UpdateCargoResponse,
} from '@api/features/cargos/updateCargo';
import type {
	UpdateRobRequest,
	UpdateRobResponse,
} from '@api/features/vessels/updateRob';
import type {
	DeleteRobRequest,
	DeleteRobResponse,
} from '@api/features/vessels/deleteRob';
import type {
	DeleteRobBunkerRequest,
	DeleteRobBunkerResponse,
} from '@api/features/vessels/deleteRobBunker';
import type {
	UpdateCargoPortRequest,
	UpdateCargoPortResponse,
} from '@api/features/cargos/updateCargoPort';
import type {
	GetEstimateGroupsRequest,
	GetEstimateGroupsResponse,
} from '@api/features/estimates/getEstimateGroups';
import type {
	DeleteVoyageExpenseReceivableRequest,
	DeleteVoyageExpenseReceivableResponse,
} from '@api/features/voyages/deleteVoyageExpenseReceivable';
import type {
	UpdateVoyageExpenseReceivableRequest,
	UpdateVoyageExpenseReceivableResponse,
} from '@api/features/voyages/updateVoyageExpenseReceivable';
import type {
	DeleteAttachmentRequest,
	DeleteAttachmentResponse,
} from '@api/features/attachments/deleteAttachment';
import type {
	GetOrgBankAccountsRequest,
	GetOrgBankAccountsResponse,
} from '@api/features/organizations/getOrgBankAccounts';
import type {
	UpdateBankAccountRequest,
	UpdateBankAccountResponse,
} from '@api/features/bank-accounts/updateBankAccount';
import type {
	UpdateFixtureAttributes,
	UpdateFixtureRequest,
	UpdateFixtureResponse,
} from '@api/features/fixtures/updateFixture';
import type {
	CreateBankAccountRequest,
	CreateBankAccountResponse,
} from '@api/features/bank-accounts/createBankAccount';
import type {
	UpdateUserRequest,
	UpdateUserResponse,
	UserAttributes,
} from '@api/features/user/updateUser';
import type {
	GetRobsRequest,
	GetRobsResponse,
} from '@api/features/vessels/getRobs';
import type {
	GetRobsFromPortCallRequest,
	GetRobsFromPortCallResponse,
} from '@api/features/vessels/getRobsFromPortCall';
import type {
	CreateRobsRequest,
	CreateRobsResponse,
} from '@api/features/vessels/createRob';
import type {
	DeleteBankAccountRequest,
	DeleteBankAccountResponse,
} from '@api/features/bank-accounts/deleteBankAccount';
import type { SofItemProps } from '@api/models/sof-item';
import type {
	GetTimezoneOffsetRequest,
	GetTimezoneOffsetResponse,
} from '@api/features/geo/getTimezoneOffset';
import type {
	GetCargosAndLaytimesRequest,
	GetCargosAndLaytimesResponse,
} from '@api/features/laytime/getCargosAndLaytimes';
import type {
	CreateLaytimeCalculationRequest,
	CreateLaytimeCalculationResponse,
} from '@api/features/laytime/createLaytimeCalculation';
import type {
	DeleteLaytimeCalculationRequest,
	DeleteLaytimeCalculationResponse,
} from '@api/features/laytime/deleteLaytimeCalculation';
import type {
	GetVoyageExpensesRequest,
	GetVoyageExpensesResponse,
} from '@api/features/invoices/getVoyageExpenses';
import type {
	CreateHireInvoiceRequest,
	CreateHireInvoiceResponse,
} from '@api/features/invoices/hire-invoices/createHireInvoice';
import type {
	SendCrewAppInvitationRequest,
	SendCrewAppInvitationResponse,
} from '@api/features/vessels/sendCrewAppInvitation';
import type {
	GetBunkerExpendituresRequest,
	GetBunkerExpendituresResponse,
} from '@api/features/voyages/getBunkerExpenditures';
import type {
	ResetVoyageDurationToEstimateRequest,
	ResetVoyageDurationToEstimateResponse,
} from '@api/features/voyages/resetVoyageDurationToEstimate';
import type {
	RejectExpenseRequest,
	RejectExpenseResponse,
} from '@api/features/invoices/rejectExpense';
import type {
	ApproveExpenseRequest,
	ApproveExpenseResponse,
} from '@api/features/invoices/approveExpense';
import type {
	UpdateVoyageAttributes,
	UpdateVoyageRequest,
	UpdateVoyageResponse,
} from '@api/features/voyages/updateVoyage';
import type {
	GetVcPnlRequest,
	GetVcPnlResponse,
} from '@api/features/voyages/getVcPnl';
import type {
	UpdateOrganizationDetailsRequest,
	UpdateOrganizationDetailsResponse,
} from '@api/features/organizations/updateOrganizationDetails';
import type { ApprovalItem } from '@api/models/organization';
import type {
	GetRequiredApprovalsRequest,
	GetRequiredApprovalsResponse,
} from '@api/features/organizations/getRequiredApprovals';
import type {
	GetAccountingItemsForApprovalRequest,
	GetAccountingItemsForApprovalResponse,
} from '@api/features/invoices/getAccountingItemsForApproval';
import type {
	SubmitHireInvoiceRequest,
	SubmitHireInvoiceResponse,
} from '@api/features/invoices/hire-invoices/submitHireInvoice';
import type {
	ApproveHireInvoiceRequest,
	ApproveHireInvoiceResponse,
} from '@api/features/invoices/hire-invoices/approveHireInvoice';
import type {
	RejectHireInvoiceRequest,
	RejectHireInvoiceResponse,
} from '@api/features/invoices/hire-invoices/rejectHireInvoice';
import type {
	UnsubmitHireInvoiceRequest,
	UnsubmitHireInvoiceResponse,
} from '@api/features/invoices/hire-invoices/unsubmitHireInvoice';
import type {
	CreateRevenueItemRequest,
	CreateRevenueItemResponse,
} from '@api/features/invoices/createRevenueItem';
import type {
	UpdateRevenueItemRequest,
	UpdateRevenueItemResponse,
} from '@api/features/invoices/updateRevenueItem';
import type {
	GetAccountingItemsRequest,
	GetAccountingItemsResponse,
} from '@api/features/financials/getAccountingItems';
import type {
	UpdateVesselRequest,
	UpdateVesselResponse,
	UpdateVesselAttributes,
} from '@api/features/vessels/updateVessel';
import type { GetUsersResponse } from '@api/features/organizations/getUsers';
import type {
	UpdateUserDepartmentsRequest,
	UpdateUserDepartmentsResponse,
} from '@api/features/organizations/updateUserDepartments';
import type {
	CreateBrokerInvoiceRequest,
	CreateBrokerInvoiceResponse,
} from '@api/features/brokers/invoices/createBrokerInvoice';
import type {
	SubmitBrokerInvoiceRequest,
	SubmitBrokerInvoiceResponse,
} from '@api/features/brokers/invoices/submitBrokerInvoice';
import type {
	DeleteBrokerInvoiceRequest,
	DeleteBrokerInvoiceResponse,
} from '@api/features/brokers/invoices/deleteBrokerInvoice';
import type {
	UnsubmitBrokerInvoiceRequest,
	UnsubmitBrokerInvoiceResponse,
} from '@api/features/brokers/invoices/unsubmitBrokerInvoice';
import type {
	UpdateBrokerInvoiceRequest,
	UpdateBrokerInvoiceResponse,
} from '@api/features/brokers/invoices/updateBrokerInvoice';
import type {
	ApproveBrokerInvoiceRequest,
	ApproveBrokerInvoiceResponse,
} from '@api/features/brokers/invoices/approveBrokerInvoice';
import type {
	RejectBrokerInvoiceRequest,
	RejectBrokerInvoiceResponse,
} from '@api/features/brokers/invoices/rejectBrokerInvoice';
import type {
	UpdateVoyageExpenseRequest,
	UpdateVoyageExpenseResponse,
} from '@api/features/invoices/updateVoyageExpense';
import type {
	GetAccrualItemsRequest,
	GetAccrualItemsResponse,
	TransformedAccrualItem,
} from '@api/features/financials/getAccrualItems';
import type {
	DeleteVoyageExpenseRequest,
	DeleteVoyageExpenseResponse,
} from '@api/features/invoices/deleteVoyageExpense';
import type {
	GetVoyageAlertsRequest,
	GetVoyageAlertsResponse,
} from '@api/features/statistics/getVoyageAlerts';
import type {
	GetClosedPeriodsRequest,
	GetClosedPeriodsResponse,
} from '@api/features/financials/getClosedPeriods';
import type {
	SendAdminInvitationRequest,
	SendAdminInvitationsResponse,
} from '@api/features/admin/sendAdminInvitation';
import type {
	CreateFeatureHighlightRequest,
	CreateFeatureHighlightResponse,
} from '@api/features/admin/createFeatureHighlight';
import type {
	UpdateFeatureHighlightRequest,
	UpdateFeatureHighlightResponse,
} from '@api/features/admin/updateFeatureHighlight';
import type {
	SendUserInvitationsRequest,
	SendUserInvitationsResponse,
} from '@api/features/admin/sendUserInvitations';
import type {
	SendWelcomeEmailRequest,
	SendWelcomeEmailResponse,
} from '@api/features/admin/sendWelcomeEmail';
import type {
	UpdateOrganizationUserRequest,
	UpdateOrganizationUserResponse,
} from '@api/features/admin/updateOrganizationUser';
import type {
	Attributes,
	UpdateAttachmentRequest,
	UpdateAttachmentResponse,
} from '@api/features/attachments/updateAttachment';
import type {
	AddAttachmentRequest,
	AddAttachmentResponse,
} from '@api/features/attachments/addAttachment';
import type { ActivateOrganizationRequest } from '@api/features/auth/activateOrganization';
import type {
	GetActivationDetailsRequest,
	GetActivationDetailsResponse,
} from '@api/features/auth/getActivationDetails';
import type {
	UpdateBrokerAttributes,
	UpdateBrokerRequest,
	UpdateBrokerResponse,
} from '@api/features/brokers/updateBroker';
import type {
	GetBrokerDetailsRequest,
	GetBrokerDetailsResponse,
} from '@api/features/brokers/getBrokerDetails';
import type {
	DeleteBrokerRequest,
	DeleteBrokerResponse,
} from '@api/features/brokers/deleteBroker';
import type {
	DeleteFeatureHighlightRequest,
	DeleteFeatureHighlightResponse,
} from '@api/features/admin/deleteFeatureHighlight';
import type {
	CreateCounterpartyRequest,
	CreateCounterpartyResponse,
} from '@api/features/counterparties/createCounterparty';
import type {
	GetCounterpartyDetailsRequest,
	GetCounterpartyDetailsResponse,
} from '@api/features/counterparties/getCounterpartyDetails';
import type {
	GetReceivablesAgingDataRequest,
	GetReceivablesAgingDataResponse,
} from '@api/features/statistics/getReceivablesAgingData';
import type {
	DeleteCounterpartyRequest,
	DeleteCounterpartyResponse,
} from '@api/features/counterparties/deleteCounterparty';
import type {
	UpdateAccrualItemRequest,
	UpdateAccrualItemResponse,
} from '@api/features/financials/updateAccrualItem';
import type {
	GetVideoGuidesRequest,
	GetVideoGuidesResponse,
} from '@api/features/admin/getVideoGuides';
import type {
	CreateVideoGuideRequest,
	CreateVideoGuideResponse,
} from '@api/features/admin/createVideoGuide';
import type {
	UpdateVideoGuideRequest,
	UpdateVideoGuideResponse,
} from '@api/features/admin/updateVideoGuide';
import type {
	DeleteVideoGuideRequest,
	DeleteVideoGuideResponse,
} from '@api/features/admin/deleteVideoGuide';
import type { UpdateVideoGuideOrderRequest } from '@api/features/admin/updateVideoGuideOrder';
import type { VideoGuideProps } from '@api/models/video-guide';
import type {
	GetVoyageDurationRequest,
	GetVoyageDurationResponse,
} from '@api/features/voyages/getVoyageDurations';
import type {
	ReportGroupAttributes,
	UpdateReportGroupsRequest,
	UpdateReportGroupsResponse,
} from '@api/features/organizations/updateReportGroups';
import type {
	UpdateOffHireTypesAttributes,
	UpdateOffHireTypesRequest,
	UpdateOffHireTypesResponse,
} from '@api/features/organizations/updateOffHireTypes';
import type {
	GetReportGroupsRequest,
	GetReportGroupsResponse,
} from '@api/features/organizations/getReportGroups';
import type {
	GetVoyageItineraryRequest,
	GetVoyageItineraryResponse,
} from '@api/features/voyages/getVoyageItinerary';
import type {
	GetPreviousVoyageBunkersRequest,
	GetPreviousVoyageBunkersResponse,
} from '@api/features/voyages/getPreviousVoyageBunkers';
import type {
	GetVoyageInvoicesRequest,
	GetVoyageInvoicesResponse,
} from '@api/features/invoices/getVoyageInvoices';
import type {
	GetSeaPassageConsumptionRequest,
	GetSeaPassageConsumptionResponse,
} from '@api/utils/performance/getSeaPassageConsumption';
import type {
	UpdateVesselConsumptionEntryRequest,
	UpdateVesselConsumptionEntryResponse,
} from '@api/features/vessels/updateVesselConsumptionEntries';
import type {
	CreateVesselConsumptionSetRequest,
	CreateVesselConsumptionSetResponse,
} from '@api/features/vessels/createVesselConsumptionSet';
import type {
	UpdateVesselConsumptionSetRequest,
	UpdateVesselConsumptionSetResponse,
} from '@api/features/vessels/updateVesselConsumptionSet';
import type {
	DeleteVesselConsumptionSetRequest,
	DeleteVesselConsumptionSetResponse,
} from '@api/features/vessels/deleteVesselConsumptionSet';
import type {
	GetOutstandingsDataRequest,
	GetOutstandingsDataResponse,
} from '@api/features/statistics/getOutstandingsData';
import type {
	GetFullFleetTimelineDataRequest,
	GetFullFleetTimelineDataResponse,
} from '@api/features/statistics/getFullFleetTimelineData';
import type { CustomFieldValueTypes } from '@api/models/custom-field';
import type {
	CreateCustomFieldRequest,
	CreateCustomFieldResponse,
} from '@api/features/misc/createCustomField';
import type {
	GetCustomFieldsRequest,
	GetCustomFieldsResponse,
} from '@api/features/misc/getCustomFields';
import type {
	DeleteCustomFieldRequest,
	DeleteCustomFieldResponse,
} from '@api/features/misc/deleteCustomField';
import type {
	UpdateCustomFieldRequest,
	UpdateCustomFieldResponse,
} from '@api/features/misc/updateCustomField';
import type { UpdatePortRotationRequest } from '@api/features/estimates/updatePortRotation';
import type {
	MarkFixtureAsUnfixedRequest,
	MarkFixtureAsUnfixedResponse,
} from '@api/features/fixtures/markFixtureAsUnfixed';
import type { SofItems } from
	'@client/screens/fleet/VoyageDetailsScreen/components/LaytimeCalculatorTab/LaytimeCalculationDetails';
import type {
	BunkerRecord,
	NullableBunkerRecord,
} from '@client/screens/fleet/VoyageDetailsScreen/tabs/components/RobEntryDrawer';
import { RootPages } from '@client/utils/links';
import type { UserInvite } from '@client/screens/admin/OrganizationDetailsScreen/components/InviteUsersDrawer';
import type { PartialPortRotationEntry } from '@client/screens/estimates/details/context/hooks/usePortRotationHandlers';
import { sendRequest } from './sendRequest';

export const getOrganizationDetails = (organizationId: number) => sendRequest<GetOrganizationDetailsRequest,
	GetOrganizationDetailsResponse>(
		`/admin/organizations/${organizationId}`,
		'get',
		{ id: organizationId },
	);

export const getForwardFloatingDays = (
	period: YearQuarterDashboardPeriods,
) => sendRequest<GetForwardFloatingDaysRequest,
	GetForwardFloatingDaysResponse>(
		'/statistics/forward-floating-days',
		'get',
		{ period },
	);

export const getOperatingUtilisation = (
	period: YearQuarterDashboardPeriods,
) => sendRequest<GetOperatingUtilisationRequest,
	GetOperatingUtilisationResponse>(
		'/statistics/operating-utilisation',
		'get',
		{ period },
	);

export const getHistoricalTcPerformance = (
	period: YearQuarterDashboardPeriods,
) => sendRequest<
	GetHistoricalTcPerformanceRequest,
	GetHistoricalTcPerformanceResponse
>(
	'/statistics/historical-performance',
	'get',
	{ period },
);

export const getHistoricalUtilisation = (
	period: YearQuarterDashboardPeriods,
) => sendRequest<
	getHistoricalFleetUtilisationRequest,
	getHistoricalFleetUtilisationResponse
>(
	'/statistics/historical-utilisation',
	'get',
	{ period },
);

export const getFleetPnl = (
	period: YearQuarterDashboardPeriods,
) => sendRequest<GetFleetPnlRequest, GetFleetPnlResponse>(
	'/statistics/fleet-pnl',
	'get',
	{ period },
);

export const getOffHireData = (
	period: YearQuarterDashboardPeriods,
) => sendRequest<GetOffHireDataRequest, GetOffHireDataResponse>(
	'/statistics/off-hire',
	'get',
	{ period },
);

export const getVersionInfo = () => sendRequest<undefined,
	{
		LATEST_TAG: string;
		LATEST_COMMIT: string;
		NODE_ENV: string;
	}>('/version');

export const getVessels = () => sendRequest<GetVesselsRequest, GetVesselsResponse>(
	'/vessels',
);
export const getPerformanceOverview = (
	vesselIds?: number[],
) => sendRequest<GetPerformanceOverviewRequest, GetPerformanceOverviewResponse>(
	'/performance',
	'get',
	{ vesselIds },
);

export const getPerformanceDetails = (
	id: number,
	fromDate: string,
	toDate: string | null,
) => sendRequest<GetPerformanceDetailsRequest,
	GetPerformanceDetailsResponse>(
		`/performance/${id}`,
		'get',
		{
			fromDate,
			// Since this is a GET request, we need to make it undefined
			// @ts-ignore
			toDate: toDate == null ? undefined : toDate,
		},
	);

export const createVessel = (
	name: string,
	ownershipType: Values<typeof VesselOwnershipTypes>,
	type: Values<typeof VesselTypes> | null,
	size: string | null,
) => sendRequest<CreateVesselRequest, CreateVesselResponse>(
	'/vessels',
	'post',
	{ name, ownershipType, type, size },
);

export const updateVessel = (vesselId: number, attributes: UpdateVesselAttributes) => sendRequest<
	UpdateVesselRequest,
	UpdateVesselResponse
>(
	`/vessels/${vesselId}`,
	'patch',
	{ id: vesselId, attributes },
);

export const getVesselDetails = (vesselId: number) => sendRequest<GetVesselDetailsRequest, GetVesselDetailsResponse>(
	`/vessels/${vesselId}`,
	'get',
	{ id: vesselId },
);

export const getVesselSeaPassages = (vesselId: number) => sendRequest<GetVesselSeaPassagesRequest,
	GetVesselSeaPassagesResponse>(
		`/performance/${vesselId}/sea-passages`,
		'get',
		{
			id: vesselId,
		},
	);

export const updateFixturePerformanceEntry = (
	fixtureId: number,
	id: number,
	attributes: UpdateFixturePerformanceEntryRequest['attributes'],
) => sendRequest<UpdateFixturePerformanceEntryRequest,
	UpdateFixturePerformanceEntryResponse>(
		`/fixtures/${fixtureId}/performance-entries/${id}`,
		'patch',
		{
			fixtureId,
			id,
			attributes,
		},
	);

export const updateRateScheduleItem = (
	fixtureId: number,
	id: number,
	attributes: UpdateRateScheduleItemRequest['attributes'],
) => sendRequest<UpdateRateScheduleItemRequest,
		UpdateRateScheduleItemResponse>(
			`/fixtures/${fixtureId}/rate-schedule/${id}`,
			'patch',
			{
				fixtureId,
				id,
				attributes,
			},
		);

export const createVesselBunker = (
	vesselId: number,
	values: Omit<CreateVesselBunkerRequest, 'vesselId'>,
) => sendRequest<CreateVesselBunkerRequest, CreateVesselBunkerResponse>(
	`/vessels/${vesselId}/bunkers`,
	'post',
	{ ...values, vesselId },
);

export const updateVesselBunker = (
	vesselId: number,
	vesselBunkerId: number,
	attributes: UpdateVesselBunkerRequest['attributes'],
) => sendRequest<UpdateVesselBunkerRequest, UpdateVesselBunkerResponse>(
	`/vessels/${vesselId}/bunkers/${vesselBunkerId}`,
	'patch',
	{ vesselId, vesselBunkerId, attributes },
);

export const deleteVesselBunker = (
	vesselId: number,
	vesselBunkerId: number,
) => sendRequest<DeleteVesselBunkerRequest, DeleteVesselBunkerResponse>(
	`/vessels/${vesselId}/bunkers/${vesselBunkerId}`,
	'delete',
	{ vesselBunkerId, vesselId },
);

export const deleteItineraryEntry = (
	vesselId: number,
	portCallId: number,
) => sendRequest<DeleteItineraryEntryRequest, DeleteItineraryEntryResponse>(
	`/ops/itinerary/${vesselId}/${portCallId}`,
	'delete',
	{ portCallId, vesselId },
);

export const updatePortCall = (
	vesselId: number,
	portCallId: number,
	attributes: UpdatePortCallRequest['attributes'],
	savingIndicator: boolean = false,
) => sendRequest<UpdatePortCallRequest, UpdatePortCallResponse>(
	`/ops/itinerary/${vesselId}/pc/${portCallId}`,
	'patch',
	{ portCallId, vesselId, attributes },
	{},
	{ savingIndicator },
);

export const updateSeaPassage = (
	vesselId: number,
	seaPassageId: number,
	attributes: Partial<UpdateSeaPassageRequest['attributes']>,
	savingIndicator: boolean = false,
) => sendRequest<UpdateSeaPassageRequest, UpdateSeaPassageResponse>(
	`/ops/itinerary/${vesselId}/sp/${seaPassageId}`,
	'patch',
	{ seaPassageId, vesselId, attributes },
	{},
	{ savingIndicator },
);

export const getSeaPassageConsumption = (
	normalDistance: number,
	secaDistance: number,
	vesselId: number,
	speed: number,
	vesselCondition: typeof VesselConditionTypes.BALLAST | typeof VesselConditionTypes.LADEN,
	seaMargin: number,
) => sendRequest<GetSeaPassageConsumptionRequest, GetSeaPassageConsumptionResponse>(
	`/performance/sp-performance/${vesselId}`,
	'get',
	{
		normalDistance,
		secaDistance,
		vesselId,
		speed,
		vesselCondition,
		seaMargin,
	},
);

export const createItineraryEntry = ({
	vesselId,
	voyageId,
	portId,
	estimatedDepartureDate,
	savingIndicator = false,
}: {
	vesselId: number;
	portId: number;
	estimatedDepartureDate?: Moment;
	savingIndicator: boolean;
	voyageId?: number;
}) => sendRequest<
	CreateItineraryEntryRequest,
	CreateItineraryEntryResponse
>(
	`/ops/itinerary/${vesselId}`,
	'post',
	{
		vesselId,
		voyageId,
		portId,
		estimatedDepartureDate,
	},
	{},
	{ savingIndicator },
);

export const createFixturePerformanceEntry = (
	fixtureId: number,
	speed: number,
	ballast: number,
	laden: number,
	fuelZoneType: Values<typeof FuelZoneTypes>,
) => sendRequest<CreateFixturePerformanceEntryRequest,
	CreateFixturePerformanceEntryResponse>(
		`/fixtures/${fixtureId}/performance-entries`,
		'post',
		{
			fixtureId,
			fuelZoneType,
			speed,
			ballast,
			laden,
		},
	);

export const createRateScheduleItem = ({
	fixtureId,
	duration,
	unit,
	rate,
	exactDate,
	useExactDate,
}: {
	fixtureId: number;
	rate: number;
	duration?: number;
	unit?: Values<typeof PeriodUnits>;
	exactDate?: Moment;
	useExactDate: boolean;
}) => sendRequest<CreateRateScheduleItemRequest,
		CreateRateScheduleItemResponse>(
			`/fixtures/${fixtureId}/rate-schedule`,
			'post',
			{
				fixtureId,
				exactDate,
				useExactDate,
				duration,
				rate,
				unit,
			},
		);

export const deleteFixturePerformanceEntry = (
	fixtureId: number,
	id: number,
) => sendRequest<DeleteFixturePerformanceEntryRequest,
	DeleteFixturePerformanceEntryResponse>(
		`/fixtures/${fixtureId}/performance-entries/${id}`,
		'delete',
		{
			fixtureId,
			id,
		},
	);

export const deleteRateScheduleItem = (
	fixtureId: number,
	id: number,
) => sendRequest<DeleteRateScheduleItemRequest,
		DeleteRateScheduleItemResponse>(
			`/fixtures/${fixtureId}/rate-schedule/${id}`,
			'delete',
			{
				fixtureId,
				id,
			},
		);

export const getSuppliers = () => sendRequest<GetSuppliersRequest, GetSuppliersResponse>(
	'/suppliers',
);

export const getFeatureHighlights = () => sendRequest<GetFeatureHighlightsRequest, GetFeatureHighlightsResponse>(
	'/admin/feature-highlights',
);

export const createSupplier = (
	name: string,
) => sendRequest<CreateSupplierRequest, CreateSupplierResponse>(
	'/suppliers',
	'post',
	{ name },
);

export const updateSupplier = (
	id: number,
	attributes: UpdateSupplierRequest['attributes'],
) => sendRequest<UpdateSupplierRequest, UpdateSupplierResponse>(
	`/suppliers/${id}`,
	'patch',
	{
		id,
		attributes,
	},
);

export const updateCounterparty = (
	id: number,
	attributes: UpdateCounterpartyRequest['attributes'],
) => sendRequest<UpdateCounterpartyRequest, UpdateCounterpartyResponse>(
	`/counterparties/${id}`,
	'patch',
	{
		id,
		attributes,
	},
);

export const deleteSupplier = (
	id: number,
) => sendRequest<DeleteSupplierRequest, DeleteSupplierResponse>(
	`/suppliers/${id}`,
	'delete',
	{ id },
);

export const getPrimaryPorts = () => sendRequest<
	GetPrimaryPortsRequest,
	GetPrimaryPortsResponse
>('/geo/primary-ports');

export const getVoyages = (
	vesselId?: number,
	voyageTypeFilter?: Array<Values<typeof FixtureTypes>>,
) => sendRequest<
	GetVoyagesRequest,
	GetVoyagesResponse
>('/voyages', 'get', { vesselId, voyageTypeFilter });

export const getAccountingItems = () => sendRequest<
	GetAccountingItemsRequest,
	GetAccountingItemsResponse
>('/financials/accounting-items');

export const postAccountingItems = (items: Array<string>, unpost?: boolean) => sendRequest<
	PostAccountingItemsRequest,
	PostAccountingItemsResponse
>(
	'/financials/accounting-items',
	'post',
	{ items, unpost },
);

export const closeAccountingItems = (
	items: TransformedAccrualItem[],
	closingPeriod: Moment,
	closingPeriodFormat: Values<typeof AccrualItemClosingPeriodFormats>,
	preview?: boolean,
) => sendRequest<
	CloseAccountingItemsRequest,
	CloseAccountingItemsResponse
>(
	'/financials/accounting-items/close',
	'post',
	{ items, closingPeriod, closingPeriodFormat, preview },
);

export const updateVoyage = (voyageId: number, attributes: UpdateVoyageAttributes) => sendRequest<
	UpdateVoyageRequest,
	UpdateVoyageResponse
>(
	`/voyages/${voyageId}`,
	'patch',
	{ id: voyageId, attributes },
);

export const updateOffHirePeriod = (
	voyageId: number,
	offHireId: number,
	attributes: UpdateOffHirePeriodAttributes,
) => sendRequest<
	UpdateOffHirePeriodRequest,
	UpdateOffHirePeriodResponse
>(
	`/voyages/${voyageId}/off-hire/${offHireId}`,
	'patch',
	{ voyageId, id: offHireId, attributes },
);

export const allocateVesselOffHirePeriod = (
	offHireId: number,
	vesselId: number,
	voyageId: number,
	hirePerDay: number,
	percentageForOwnersAccount: number,
	note: string,
	invoiceNote: string,
	bunkers: {
		id: number;
		quantity: number;
		pricePerTon: number;
		currency: Values<typeof Currencies>;
		exchangeRate: number;
		fuelGrade: Values<typeof FuelTypes>;
	}[],
) => sendRequest<
	AllocateVesselOffHirePeriodRequest,
	AllocateVesselOffHirePeriodResponse
>(
	`/vessels/${vesselId}/off-hire/allocate/${offHireId}`,
	'post',
	{ voyageId, bunkers, vesselId, id: offHireId, hirePerDay, percentageForOwnersAccount, note, invoiceNote },
);

export const unAllocateVesselOffHirePeriod = (
	offHireId: number,
	vesselId: number,
	voyageId: number,
) => sendRequest<
	UnAllocateVesselOffHirePeriodRequest,
	UnAllocateVesselOffHirePeriodResponse
>(
	`/vessels/${vesselId}/off-hire/un-allocate/${offHireId}`,
	'post',
	{ voyageId, vesselId, id: offHireId },
);

export const deleteOffHirePeriod = (voyageId: number, offHireId: number) => sendRequest<
	DeleteOffHirePeriodRequest,
	DeleteOffHirePeriodResponse
>(
	`/voyages/${voyageId}/off-hire/${offHireId}`,
	'delete',
	{ voyageId, id: offHireId },
);

export const getVoyageDetails = (voyageId: number) => sendRequest<
	GetVoyageDetailsRequest,
	GetVoyageDetailsResponse
>(`/voyages/${voyageId}`, 'get', { id: voyageId });

export const getSimpleVoyageDetails = (voyageId: number) => sendRequest<
	GetSimpleVoyageDetailsRequest,
	GetSimpleVoyageDetailsResponse
>(`/voyages/simple/${voyageId}`, 'get', { id: voyageId });

export const getVoyageCompletionType = (voyageId: number) => sendRequest<
	GetVoyageCompletionTypeRequest,
	GetVoyageCompletionTypeResponse
>(`/voyages/${voyageId}/completion-type`, 'get', { id: voyageId });

export const getCargoDetails = (cargoId: number) => sendRequest<
	GetCargoDetailsRequest,
	GetCargoDetailsResponse
>(`/cargos/${cargoId}`, 'get', { id: cargoId });

export const createVoyageBunker = (
	voyageId: number,
	values: Omit<CreateVoyageBunkersRequest, 'voyageId'>,
) => sendRequest<CreateVoyageBunkersRequest, CreateVoyageBunkersResponse>(
	`/voyages/${voyageId}/bunkers`,
	'post',
	{
		...values,
		voyageId,
	},
);

export const updateVoyageBunker = (
	voyageId: number,
	voyageBunkerId: number,
	attributes: UpdateVoyageBunkersRequest['attributes'],
) => sendRequest<UpdateVoyageBunkersRequest, UpdateVoyageBunkersResponse>(
	`/voyages/${voyageId}/bunkers/${voyageBunkerId}`,
	'patch',
	{
		id: voyageBunkerId,
		voyageId,
		attributes,
	},
);

export const updateHireInvoice = (
	voyageId: number,
	hireInvoiceId: number,
	items: HireInvoiceItem[],
	generateCreditNote: boolean,
) => sendRequest<UpdateHireInvoiceRequest, UpdateHireInvoiceResponse>(
	`/voyages/${voyageId}/cumulative`,
	'post',
	{
		voyageId,
		hireInvoiceId,
		items,
		generateCreditNote,
	},
);

export const updateVoyageBrokerPayment = (
	voyageId: number,
	brokerId: number,
	itemId: number,
	note: string | null,
) => sendRequest(
	`/voyages/${voyageId}/broker-payment`,
	'patch',
	{ itemId, brokerId, note },
	{ },
	{ savingIndicator: true },
);

export const resetVoyageDurationToEstimate = (
	estimateId: number,
	voyageId: number,
	VcPortType: VcPortTypes,
) => sendRequest<ResetVoyageDurationToEstimateRequest, ResetVoyageDurationToEstimateResponse>(
	`/voyages/${voyageId}/reset-duration-to-estimate/${estimateId}`,
	'patch',
	{
		estimateId,
		voyageId,
		VcPortType,
	},
);

export const deleteVoyageBunker = (
	voyageId: number,
	voyageBunkerId: number,
) => sendRequest<DeleteVoyageBunkersRequest, DeleteVoyageBunkersResponse>(
	`/voyages/${voyageId}/bunkers/${voyageBunkerId}`,
	'delete',
	{
		id: voyageBunkerId,
		voyageId,
	},
);

export const createRevenueItem = ({
	voyageId,
	cargoId,
	itemDescription,
	amount,
	currency,
	exchangeRate,
	note,
	chartererId,
	estimated,
	subjectToCommissions,
}: {
	voyageId: number;
	cargoId: number;
	itemDescription: string;
	amount: number;
	currency: Values<typeof Currencies>;
	exchangeRate: number;
	note?: string | null;
	chartererId: number;
	subjectToCommissions: boolean;
	estimated: boolean;
}) => sendRequest<CreateRevenueItemRequest, CreateRevenueItemResponse>(
	'/invoices/revenue-items',
	'post',
	{
		voyageId,
		cargoId,
		itemDescription,
		amount,
		currency,
		exchangeRate,
		note,
		chartererId,
		subjectToCommissions,
		estimated,
	},
);

export const updateRevenueItem = ({
	id,
	cargoId,
	itemDescription,
	amount,
	currency,
	exchangeRate,
	note,
	chartererId,
	subjectToCommissions,
	estimated,
}: {
	id: number;
	cargoId: number;
	itemDescription: string;
	amount: number;
	currency: Values<typeof Currencies>;
	exchangeRate: number;
	note?: string | null;
	chartererId: number;
	subjectToCommissions: boolean;
	estimated: boolean;
}) => sendRequest<UpdateRevenueItemRequest, UpdateRevenueItemResponse>(
	`/invoices/revenue-items/${id}`,
	'patch',
	{
		id,
		cargoId,
		itemDescription,
		amount,
		currency,
		exchangeRate,
		note,
		chartererId,
		subjectToCommissions,
		estimated,
	},
);

export const deleteRevenueItem = (id: number) => sendRequest(`/invoices/revenue-items/${id}`, 'delete', { id });

export const createVoyageExpenseReceivable = ({
	voyageExpenseId,
	voyageId,
	itemDescription,
	customInvoiceId,
	account,
	amount,
	supplier,
	chartererId,
	invoiceDate,
	currency,
	exchangeRate,
	note,
	attachments,
	estimated,
	quantity,
	unit,
	unitPrice,
}: {
	voyageExpenseId?: number;
	voyageId: number;
	itemDescription: string;
	customInvoiceId?: string | null;
	account: Values<typeof AccountTypes>;
	amount: number;
	supplier?: number;
	chartererId?: number;
	invoiceDate: Moment;
	currency: Values<typeof Currencies>;
	exchangeRate: number;
	note?: string;
	estimated?: boolean;
	attachments?: File[];
	quantity?: number;
	unit?: string;
	unitPrice?: number;
}) => {
	const data = new FormData();

	const parameters = {
		voyageExpenseId,
		voyageId,
		itemDescription,
		customInvoiceId,
		account,
		supplier,
		chartererId,
		invoiceDate,
		currency,
		exchangeRate,
		amount,
		note,
		estimated,
		quantity,
		unit,
		unitPrice,
	};

	// Add all not-null values to form data
	Object.entries(parameters).forEach(([key, value]) => {
		if (value != null) {
			// @ts-ignore
			data.append(key, value);
		}
	});

	if (attachments != null) {
		attachments.forEach((a) => data.append('attachments', a));
	}

	return sendRequest(`/voyages/${voyageId}/expense-receivables`, 'post', data);
};

export const updateVoyageExpenseReceivable = async (
	voyageId: number,
	voyageExpenseReceivableId: number,
	attributes: UpdateVoyageExpenseReceivableRequest['attributes'],
	newAttachments: File[],
) => {
	await sendRequest<UpdateVoyageExpenseReceivableRequest, UpdateVoyageExpenseReceivableResponse>(
		`/voyages/${voyageId}/expense-receivables/${voyageExpenseReceivableId}`,
		'patch',
		{
			id: voyageExpenseReceivableId,
			attributes,
		},
	);

	// We can't upload new attachments and update data at the same time
	// So we do separate requests
	if (newAttachments != null && newAttachments.length > 0) {
		const data = new FormData();

		newAttachments.forEach((a) => data.append('newAttachments', a));

		await sendRequest(
			`/voyages/${voyageId}/expense-receivables/${voyageExpenseReceivableId}`,
			'patch',
			data,
		);
	}
};

export const deleteVoyageExpense = (
	id: number,
) => sendRequest<DeleteVoyageExpenseRequest, DeleteVoyageExpenseResponse>(
	`/invoices/voyage-expenses/${id}`,
	'delete',
	{ id },
);

export const deleteVoyageExpenseReceivable = (
	voyageId: number,
	voyageExpenseReceivableId: number,
) => sendRequest<DeleteVoyageExpenseReceivableRequest, DeleteVoyageExpenseReceivableResponse>(
	`/voyages/${voyageId}/expense-receivables/${voyageExpenseReceivableId}`,
	'delete',
	{
		voyageId,
		id: voyageExpenseReceivableId,
	},
);

export const approveExpense = (
	expenseId: number,
	note?: string,
) => sendRequest<ApproveExpenseRequest, ApproveExpenseResponse>(
	`/invoices/voyage-expenses/${expenseId}/approve`,
	'post',
	{
		id: expenseId,
		note,
	},
);

export const rejectExpense = (
	expenseId: number,
	reason: string,
) => sendRequest<RejectExpenseRequest, RejectExpenseResponse>(
	`/invoices/voyage-expenses/${expenseId}/reject`,
	'post',
	{
		id: expenseId,
		reason,
	},
);

export const deleteAttachment = (
	attachmentId: number,
) => sendRequest<DeleteAttachmentRequest, DeleteAttachmentResponse>(
	`/attachments/${attachmentId}`,
	'delete',
	{
		id: attachmentId,
	},
);

export const getAttachmentUrl = (
	attachmentId: number,
) => sendRequest<
	GetAttachmentUrlRequest,
	GetAttachmentUrlResponse
>(
	`/attachments/${attachmentId}/url`,
	'get',
	{ id: attachmentId },
);

export const getTcInAllocationItems = (
	voyageId: number,
	groupItems: boolean,
) => sendRequest<
	GetTcInAllocationItemsRequest,
	GetTcInAllocationItemsResponse
>(
	`/voyages/${voyageId}/allocation-items`,
	'get',
	{ voyageId, groupItems },
);

export const getHireInvoiceDetails = (
	hireInvoiceId: number,
) => sendRequest<GetHireInvoiceDetailsRequest, GetHireInvoiceDetailsResponse>(
	`/invoices/hire-invoices/${hireInvoiceId}`,
	'get',
	{ id: hireInvoiceId },
);

export const updateHireInvoiceDetails = (
	hireInvoiceId: number,
	attributes: UpdateHireInvoiceDetailsRequest['attributes'],
) => sendRequest<UpdateHireInvoiceDetailsRequest, UpdateHireInvoiceDetailsResponse>(
	`/invoices/hire-invoices/${hireInvoiceId}`,
	'patch',
	{ id: hireInvoiceId, attributes },
);

export const updateTcInAllocationItem = (
	voyageId: number,
	itemId: number,
	itemType: string,
	percentage: number,
) => sendRequest<UpdateTcInAllocationItemRequest, UpdateTcInAllocationItemResponse>(
	`/voyages/${voyageId}/allocation-items`,
	'patch',
	{ itemId, itemType, voyageId, percentage },
);

export const createVoyagePayment = (
	voyageId: number,
	date: Date,
	amount: number,
	parts: VoyagePaymentPart[],
	bankCharge: number,
) => sendRequest<CreateVoyagePaymentRequest, CreateVoyagePaymentResponse>(
	`/voyages/${voyageId}/payments`,
	'post',
	{ voyageId, date, amount, parts, bankCharge },
);

export const createConnection = (
	belongsToId: number,
	companyId?: string,
) => sendRequest<CreateConnectionRequest, CreateConnectionResponse>(
	'/accounting/connection',
	'post',
	{ belongsToId, companyId },
);

export const getNavisionCompanies = () => sendRequest<
	GetNavisionCompaniesRequest,
	GetNavisionCompaniesResponse
>('/accounting/navision/companies');

export const disconnectAccountingSystem = (
	connectionId: number,
) => sendRequest<DisconnectAccountingSystemRequest, DisconnectAccountingSystemResponse>(
	`/accounting/connection/${connectionId}`,
	'delete',
	{ connectionId },
);

export const createMappingEntry = (
	connectionId: number,
	type: 'fixtureCounterparty' | 'supplier' | 'accrual',
	cvIdentifier: string | number,
	code: string | undefined,
) => sendRequest<CreateMappingEntryRequest, CreateMappingEntryResponse>(
	`/accounting/connection/${connectionId}/mapping`,
	'post',
	{ connectionId, type, cvIdentifier, code },
);

export const createVesselOffHirePeriod = (
	vesselId: number,
	startTime: Moment,
	endTime: Moment,
	type: string,
	preferDuration: boolean,
) => sendRequest<CreateVesselOffHireRequest, CreateVesselOffHireResponse>(
	`/vessels/${vesselId}/off-hire`,
	'post',
	{ vesselId, startTime, preferDuration, endTime, type },
);

export const updateVesselOffHirePeriod = (
	id: number,
	vesselId: number,
	contractList: Array<{
		voyageOffHireId: number;
		creditNote: boolean;
	}>,
	attributes: UpdateVesselOffHirePeriodRequest['attributes'],
) => sendRequest<UpdateVesselOffHirePeriodRequest, UpdateVesselOffHirePeriodResponse>(
	`/vessels/${vesselId}/off-hire/${id}`,
	'patch',
	{ id, vesselId, contractList, attributes },
);

export const createCodatCompany = (
	vesselId: number,
) => sendRequest<CreateCompanyRequest, CreateCompanyResponse>(
	'/integration/codat',
	'post',
	{ vesselId },
);

export const deleteCodatCompany = (
	vesselId: number,
) => sendRequest<DeleteCompanyRequest, DeleteCompanyResponse>(
	'/integration/codat',
	'delete',
	{ vesselId },
);

export const getConnectionAndMapping = (
	strategy: Values<typeof AccountingSystemStrategies>,
	id: number,
) => sendRequest<GetConnectionAndMappingRequest, GetConnectionAndMappingResponse>(
	`/accounting/connection/${strategy}/${id}`,
	'get',
	{ strategy, id },
);

export const getAccountingCustomers = (
	connectionId: number,
) => sendRequest<GetAccountingCustomersRequest, GetAccountingCustomersResponse>(
	`/accounting/connection/${connectionId}/customers`,
	'get',
	{ connectionId },
);

export const getCompanyAccounts = (
	connectionId: number,
) => sendRequest<GetCompanyAccountsRequest, GetCompanyAccountsResponse>(
	`/accounting/connection/${connectionId}/accounts`,
	'get',
	{ connectionId },
);

export const getAccountingSuppliers = (
	connectionId: number,
) => sendRequest<GetAccountingSuppliersRequest, GetAccountingSuppliersResponse>(
	`/accounting/connection/${connectionId}/suppliers`,
	'get',
	{ connectionId },
);

export const updateVoyagePayment = (
	id: number,
	voyageId: number,
	attributes: UpdateVoyagePaymentRequest['attributes'],
) => sendRequest<UpdateVoyagePaymentRequest, UpdateVoyagePaymentResponse>(
	`/voyages/${voyageId}/payments`,
	'patch',
	{ id, voyageId, attributes },
);

export const deleteVoyagePayment = (
	voyageId: number,
	id: number,
) => sendRequest<DeleteVoyagePaymentRequest, DeleteVoyagePaymentResponse>(
	`/voyages/${voyageId}/payments/${id}`,
	'delete',
	{ voyageId, id },
);

export const getUserData = () => sendRequest<GetUserDataRequest, GetUserDataResponse>('/user');

export const updateReportEmail = ({ orgId, vesselId, reportEmail }: UpdateReportEmailRequest) => sendRequest<
	UpdateReportEmailRequest,
	UpdateReportEmailResponse
>(
	`/admin/organizations/${orgId}/vessels/${vesselId}/report-email`,
	'put',
	{ orgId, vesselId, reportEmail },
);

export const deleteFixture = (id: number) => sendRequest<
	DeleteFixtureRequest,
	void
>(`/fixtures/${id}`, 'delete', { id });

export const updateFixture = (
	id: number,
	attributes: UpdateFixtureAttributes,
) => sendRequest<
	UpdateFixtureRequest,
	UpdateFixtureResponse
>(
	`/fixtures/${id}`,
	'patch',
	{ id, attributes },
);

export const markFixtureAsUnfixed = (id: number) => sendRequest<
	MarkFixtureAsUnfixedRequest,
	MarkFixtureAsUnfixedResponse
>(
	`/fixtures/${id}/unfix`,
	'post',
	{ id },
);

export const createFixtureExpense = (
	fixtureId: number,
	values: {
		account: Values<typeof AccountTypes>;
		name: string;
		amount: number;
	},
) => sendRequest<CreateFixtureExpenseRequest, CreateFixtureExpenseResponse>(
	`/fixtures/${fixtureId}/expenses`,
	'post',
	{
		...values,
		fixtureId,
	},
);

export const updateFixtureExpense = (
	fixtureId: number,
	expenseId: number,
	attributes: UpdateFixtureExpenseRequest['attributes'],
) => sendRequest<UpdateFixtureExpenseRequest, UpdateFixtureExpenseResponse>(
	`/fixtures/${fixtureId}/expenses/${expenseId}`,
	'patch',
	{ attributes, fixtureId, expenseId },
);

export const getCounterparties = (type?: Values<typeof FixtureCounterpartyTypes>) => sendRequest<
	GetCounterpartiesRequest,
	GetCounterpartiesResponse
>(
	'/counterparties',
	'get',
	{ type },
);

export const getVesselsWithPositions = () => sendRequest<
	GetVesselsWithPositionsRequest,
	GetVesselsWithPositionsResponse
>(
	'/geo/vessels-with-positions',
	'get',
);

export const getVoyageAlerts = () => sendRequest<
	GetVoyageAlertsRequest,
	GetVoyageAlertsResponse
>('/statistics/voyage-alerts');

export const deleteFixtureExpense = (
	fixtureId: number,
	expenseId: number,
) => sendRequest<DeleteFixtureExpenseRequest, DeleteFixtureExpenseResponse>(
	`/fixtures/${fixtureId}/expenses/${expenseId}`,
	'delete',
	{
		fixtureId,
		expenseId,
	},
);

export const deleteVesselOffHirePeriod = (
	vesselId: number,
	id: number,
) => sendRequest<DeleteVesselOffHirePeriodRequest, DeleteVesselOffHirePeriodResponse>(
	`/vessels/${vesselId}/off-hire/${id}`,
	'delete',
	{
		vesselId,
		id,
	},
);

export const getPorts = () => sendRequest<undefined, Port[]>('/geo/ports');

export const getPortsAndRanges = () => sendRequest<undefined, (Port | Range)[]>('/geo/ports-and-ranges');

export const getEstimateDetails = (
	id: number,
) => sendRequest<GetEstimateDetailsRequest, GetEstimateDetailsResponse>(
	`/estimates/${id}`,
	'get',
	{ id },
);

export const getEstimatedBrokerCommissions = (
	estimateId: number,
) => sendRequest<GetEstimatedBrokerCommissionsRequest, GetEstimatedBrokerCommissionsResponse>(
	`/estimates/${estimateId}/broker-commissions`,
	'get',
	{ estimateId },
);

export const getEstimatedDespatchAndDemurrage = (
	estimateId: number,
	voyageId: number,
) => sendRequest<GetEstimatedDespatchAndDemurrageRequest, GetEstimatedDespatchAndDemurrageResponse>(
	`/estimates/${estimateId}/despatch-demurrage`,
	'get',
	{ estimateId, voyageId },
);

export const addCargoToEstimate = (
	estimateId: number,
	cargoId: number,
) => sendRequest<AddCargoToEstimateRequest, AddCargoToEstimateResponse>(
	`/estimates/${estimateId}/cargo/add/${cargoId}`, 'post', { cargoId, estimateId },
);

export const calculateMultiRoute = (
	route: Array<{ name: string; latitude: number; longitude: number }>,
	settings: {},
) => sendRequest<CalculateMultiRouteRequest, CalculateMultiRouteResponse>(
	'/geo/calculate-multi-route',
	'post',
	{ route, settings },
);

export const getEstimates = () => sendRequest<GetEstimatesRequest, GetEstimatesResponse>(
	'/estimates',
	'get',
);

export const getCargos = () => sendRequest<GetCargosRequest, GetCargosResponse>(
	'/cargos',
	'get',
);

export const createCargo = ({
	type,
	charterer,
	estimateId,
	loadingPorts,
	dischargePorts,
}: {
	type: string;
	charterer?: number;
	estimateId?: number;
	loadingPorts?: Port[];
	dischargePorts?: Port[];
}, savingIndicator: boolean = false) => sendRequest<CreateCargoRequest, CreateCargoResponse>(
	'/cargos',
	'post',
	{ type, charterer, estimateId, loadingPorts, dischargePorts },
	{},
	{ savingIndicator },
);

export const updateCargo = ({
	cargoId,
	attributes,
}: {
	cargoId: number;
	attributes: UpdateCargoRequest['attributes'];
}, savingIndicator: boolean = true) => sendRequest<UpdateCargoRequest, UpdateCargoResponse>(
	'/cargos',
	'patch',
	{ cargoId, attributes },
	{},
	{ savingIndicator },
);

export const updateCargoPort = ({
	cargoPortId,
	cargoId,
	attributes,
	generateCreditNote = false,
}: {
	cargoPortId: number;
	cargoId: number;
	attributes: UpdateCargoPortRequest['attributes'];
	generateCreditNote?: boolean;
}, savingIndicator: boolean = true) => sendRequest<UpdateCargoPortRequest, UpdateCargoPortResponse>(
	'/cargos/cargo-port',
	'patch',
	{ cargoPortId, cargoId, attributes, generateCreditNote },
	{},
	{ savingIndicator },
);

export const deleteCargo = (
	cargoId: number,
	estimateId?: number,
	savingIndicator: boolean = true,
) => sendRequest(
	'/cargos',
	'delete',
	{ cargoId, estimateId },
	{},
	{ savingIndicator },
);

export const updateEstimate = (
	id: number,
	attributes: UpdateEstimateRequest['attributes'],
	updateWithoutAttributes?: boolean,
	savingIndicator: boolean = true,
) => sendRequest<UpdateEstimateRequest, UpdateEstimateResponse>(
	`/estimates/${id}`,
	'patch',
	{ id, attributes, updateWithoutAttributes },
	{},
	{ savingIndicator },
);

export const getVcPnl = (
	voyageId: number,
) => sendRequest<GetVcPnlRequest, GetVcPnlResponse>(
	`/voyages/${voyageId}/pnl`,
	'get',
	{ voyageId },
);

export const createEstimate = (
	attributes: CreateEstimateRequest['attributes'],
	estimateGroupId?: number,
) => sendRequest<CreateEstimateRequest, CreateEstimateResponse>(
	'/estimates/',
	'post',
	{ attributes, estimateGroupId },
);

export const createContractFromEstimate = (
	id: number,
) => sendRequest<CreateContractFromEstimateRequest, CreateContractFromEstimateResponse>(
	`/estimates/${id}`,
	'post',
	{ id },
);

export const deleteEstimate = (
	id: number,
) => sendRequest<DeleteEstimateRequest, DeleteEstimateResponse>(
	`/estimates/${id}`,
	'delete',
	{ id },
);

export const copyEstimate = (
	id: number,
	isChild?: boolean,
) => sendRequest(`/estimates/copy/${id}`, 'post', { isChild });

export const getFixtureDetails = <FixtureType extends TcFixtureProps | SpotFixtureProps>(
	id: number,
) => sendRequest<GetFixtureDetailsRequest, GetFixtureDetailsResponse<FixtureType>>(
	`/fixtures/${id}`,
	'get',
	{ id },
);

export const getFixtures = (
	type: Values<typeof FixtureTypes> | undefined = undefined,
) => sendRequest<GetFixturesRequest, GetFixturesResponse>('/fixtures', 'get', { type });

export const getFixtureRecapPreview = (
	fixtureId: number,
	itemKeys: string[],
) => sendRequest<PreviewFixtureRecapRequest, PreviewFixtureRecapResponse>(
	`/fixtures/${fixtureId}/recap/preview`,
	'post',
	{ items: itemKeys, fixtureId },
);

export const createFixtureRecap = (
	fixtureId: number,
	itemKeys: string[],
) => sendRequest<CreateFixtureRecapRequest, CreateFixtureRecapResponse>(
	`/fixtures/${fixtureId}/recap`,
	'post',
	{ items: itemKeys, fixtureId },
);

export const getBrokers = () => sendRequest<GetBrokersRequest, GetBrokersResponse>('/brokers');

export const getOrgBankAccounts = (
	orgOnly: boolean,
) => sendRequest<GetOrgBankAccountsRequest, GetOrgBankAccountsResponse>(
	'/organization/bank-accounts',
	'get',
	{ orgOnly },
);

export const getVesselBankAccounts = (
	vesselId: number,
	bankAccountType?: Values<typeof BankAccountTypes>,
) => sendRequest<GetVesselBankAccountsRequest, GetVesselBankAccountsResponse>(
	`/vessels/${vesselId}/bank-accounts`,
	'get',
	{
		vesselId,
		type: bankAccountType,
	},
);

export const createBankAccount = (
	type: Values<typeof BankAccountTypes>,
	vesselId?: number,
	savingIndicator: boolean = true,
) => sendRequest<CreateBankAccountRequest, CreateBankAccountResponse>(
	`/bank-accounts/${vesselId}`,
	'post',
	{ type, vesselId },
	{},
	{ savingIndicator },
);

export const updateBankAccount = (
	attributes: UpdateBankAccountRequest['attributes'],
	accountId: number,
	vesselId?: number,
	savingIndicator: boolean = true,
) => sendRequest<UpdateBankAccountRequest, UpdateBankAccountResponse>(
	`/bank-accounts/${accountId}`,
	'patch',
	{ vesselId, accountId, attributes },
	{},
	{ savingIndicator },
);

export const deleteBankAccount = (
	accountId: number,
	vesselId?: number,
	savingIndicator: boolean = true,
) => sendRequest<DeleteBankAccountRequest, DeleteBankAccountResponse>(
	`/bank-accounts/${accountId}`,
	'delete',
	{ vesselId, accountId },
	{},
	{ savingIndicator },
);

export const refreshEuaPrice = (estimateId: number) => sendRequest<
	RefreshEuaPriceRequest,
	RefreshEuaPriceResponse
>(
	`/estimates/${estimateId}/refresh-eua`,
	'post',
	{ id: estimateId },
);

export const createBroker = (
	name: string,
) => sendRequest<CreateBrokerRequest, CreateBrokerResponse>('/brokers', 'post', { name });

export const getVoyageCumulative = (
	voyageId: number,
) => sendRequest<GetVoyageCumulativeRequest, GetVoyageCumulativeResponse>(
	`/voyages/${voyageId}/cumulative`,
	'get',
	{ voyageId },
);

export const getVesselItinerary = (
	vesselId: number,
	voyageId: number,
) => sendRequest<GetVesselItineraryRequest, GetVesselItineraryResponse>(
	`/ops/itinerary/${vesselId}`,
	'get',
	{ vesselId, voyageId },
);

export const getCrewReports = (
	vesselId: number,
) => sendRequest<GetCrewReportsRequest, GetCrewReportsResponse>(
	`/vessels/${vesselId}/crew-reports`,
	'get',
	{ vesselId },
);

export const getVesselActivity = () => sendRequest<GetVesselActivityRequest, GetVesselActivityResponse>(
	'/vessels/activity',
);

export const addItineraryComment = ({
	vesselId,
	comment,
	savingIndicator = false,
} : {
	vesselId: number;
	comment: AddItineraryCommentRequest['comment'];
	savingIndicator: boolean;
}) => sendRequest<AddItineraryCommentRequest, AddItineraryCommentResponse>(
	`/ops/itinerary/${vesselId}/comment`,
	'post',
	{ comment, vesselId },
	{},
	{ savingIndicator },
);

export const deleteItineraryComment = (
	vesselId: number,
	comment: DeleteItineraryCommentRequest['comment'],
	savingIndicator: boolean = false,
) => sendRequest<DeleteItineraryCommentRequest, DeleteItineraryCommentResponse>(
	`/ops/itinerary/${vesselId}/comment`,
	'delete',
	{ comment, vesselId },
	{},
	{ savingIndicator },
);

export const reorderPortCall = ({
	vesselId,
	voyageId,
	draggedId,
	droppedOverId,
}: {
	voyageId: number;
	vesselId: number;
	draggedId: number;
	droppedOverId: number;
}) => sendRequest<ReorderPortCallRequest, ReorderPortCallResponse>(
	`/ops/itinerary/${vesselId}/reorder-port-call`,
	'post',
	{ draggedId, droppedOverId, vesselId, voyageId },
);

export const getSofAsPdf = (
	versionCreatedAt: string,
	sofItemsToShow: SofItems,
	port: Port,
	formattedTotalTime: string | number,
) => sendRequest<GetSofAsPdfRequest, GetSofAsPdfResponse>(
	'/vessels/statement-of-facts/pdf',
	'post',
	{ sofItemsToShow, port, versionCreatedAt, formattedTotalTime },
);

export const getCrewReportAsPdf = (
	vesselId: number,
	reportId: string,
) => sendRequest<GetCrewReportAsPdfRequest, GetCrewReportAsPdfResponse>(
	`/vessels/${vesselId}/crew-reports/${reportId}`,
	'get',
	{ vesselId, reportId },
);

export const getVoyageExpenses = (
	voyageId?: number,
) => sendRequest<
	GetVoyageExpensesRequest,
	GetVoyageExpensesResponse
>(
	'/invoices/voyage-expenses',
	'get',
	{ voyageId },
);

export const getTimezoneOffset = (
	latitude: number,
	longitude: number,
	date?: Date | Moment | string,
) => sendRequest<GetTimezoneOffsetRequest, GetTimezoneOffsetResponse>(
	'/geo/timezone-offset',
	'get',
	{ latitude, longitude, date },
);

export const getCargosAndLaytimes = (
	fixtureId: number,
) => sendRequest<GetCargosAndLaytimesRequest, GetCargosAndLaytimesResponse>(
	`/laytimes/${fixtureId}`,
	'get',
	{ fixtureId },
);

export const createRobs = (props: {
	vesselId: number;
	voyageId: number;
	event: Values<typeof CrewReportTypes>;
	port: Port | null;
	date: Moment;
	currency: Values<typeof Currencies>;
	emptyEntry?: boolean;
	robs?: Array<{
		fuelGrade: Values<typeof FuelTypes>;
		quantity: number;
		pricePerTon?: number;
	}>;
	bunkersReceived?: Array<{
		fuelGrade: Values<typeof FuelTypes>;
		quantity: number;
		pricePerTon?: number;
	}>;
	portCallId?: number;
}) => sendRequest<CreateRobsRequest, CreateRobsResponse>(
	`/vessels/${props.vesselId}/bunker-robs`,
	'post',
	{ ...props },
);

export const getRobs = (
	vesselId: number,
	voyageId?: number,
) => sendRequest<GetRobsRequest, GetRobsResponse>(
	`/vessels/${vesselId}/bunker-robs`,
	'get',
	{ vesselId, voyageId },
);

export const getRobsFromPortCall = (
	vesselId: number,
	portCallId: number,
) => sendRequest<GetRobsFromPortCallRequest, GetRobsFromPortCallResponse>(
	`/vessels/${vesselId}/bunker-robs-from-portcall`,
	'get',
	{ vesselId, portCallId },
);

export const updateRob = ({
	voyageId,
	vesselId,
	robId,
	bunkerId,
	robBunkerId,
	currency,
	attributes,
	date,
	event,
	port,
	deleteMissingEntries,
}: {
	vesselId: number;
	robId: number;
	attributes: {
		bunkersReceived?: Array<NullableBunkerRecord>;
		remainingOnBoard?: Array<Partial<BunkerRecord>> | Partial<BunkerRecord>;
	};
	voyageId: number;
	event?: Values<typeof CrewReportTypes> | null;
	port?: Port;
	date?: Moment;
	robBunkerId?: number;
	currency?: Values<typeof Currencies>;
	bunkerId?: number;
	deleteMissingEntries?: boolean;
}, savingIndicator: boolean = true) => sendRequest<UpdateRobRequest, UpdateRobResponse>(
	`/vessels/${vesselId}/bunker-robs`,
	'patch',
	{ robId, vesselId, bunkerId, robBunkerId, currency, attributes, date, event, port, voyageId, deleteMissingEntries },
	{},
	{ savingIndicator },
);

export const deleteRob = (
	vesselId: number,
	id: number,
) => sendRequest<DeleteRobRequest, DeleteRobResponse>(
	`/vessels/${vesselId}/bunker-robs/${id}`,
	'delete',
	{ id, vesselId },
);

export const deleteRobBunker = (
	robBunkerId: number,
	vesselId: number,
) => sendRequest<DeleteRobBunkerRequest, DeleteRobBunkerResponse>(
	`/vessels/rob-bunker/${vesselId}`,
	'delete',
	{ robBunkerId, vesselId },
);

export const createLaytimeCalculation = ({
	fixtureId,
	cargoPortId,
	sofItems,
	generateCreditNote = false,
}: {
	fixtureId: number;
	cargoPortId: number;
	sofItems: SofItemProps[];
	generateCreditNote?: boolean;
}) => sendRequest<CreateLaytimeCalculationRequest, CreateLaytimeCalculationResponse>(
	`/laytimes/${fixtureId}`,
	'post',
	{
		cargoPortId,
		sofItems,
		generateCreditNote,
	},
);

export const deleteLaytimeCalculation = (
	calculationId: number,
) => sendRequest<DeleteLaytimeCalculationRequest, DeleteLaytimeCalculationResponse>(
	`/laytimes/${calculationId}`,
	'delete',
	{ calculationId },
);

export const sendCrewAppInvitation = ({
	vesselId,
	email,
}: {
	vesselId: number;
	email: string;
}) => sendRequest<SendCrewAppInvitationRequest, SendCrewAppInvitationResponse>(
	`/vessels/${vesselId}/crew-reports/invite`,
	'post',
	{
		vesselId,
		email,
	},
);

export const createHireInvoice = (
	voyageId: number,
	items: HireInvoiceItem[],
	extra: {
		cumulative: boolean;
		invoiceTitle: string;
		invoiceDate: Moment;
		chartererId?: number;
		showPreviousItems?: boolean;
		showPaymentTerms?: boolean;
		groupHirePeriods?: boolean;
		cargoId?: number;
		fromBankAccountId?: number;
		isCreditNote?: boolean;
	},
) => sendRequest<CreateHireInvoiceRequest, CreateHireInvoiceResponse>(
	'/invoices/hire-invoices',
	'post',
	{
		voyageId,
		items,
		...extra,
	},
);

export const getBunkerExpenditures = (
	voyageId: number,
) => sendRequest<GetBunkerExpendituresRequest, GetBunkerExpendituresResponse>(
	`/voyages/${voyageId}/bunker-expenditures`,
	'get',
	{ voyageId },
);

export const getRequiredApprovals = () => sendRequest<
	GetRequiredApprovalsRequest,
	GetRequiredApprovalsResponse
>('/organization/approvals', 'get', {});

export const updateOrganizationDetails = (
	attributes: {
		approvalsList?: Array<ApprovalItem>;
		defaultSeaMargin?: number;
		capexOpexInput?: CapexOpexInput;
	},
) => sendRequest<UpdateOrganizationDetailsRequest, UpdateOrganizationDetailsResponse>(
	'/organization',
	'patch',
	{ attributes },
	{},
	{ savingIndicator: true },
);

export const getAccountingItemsForApproval = () => sendRequest<
	GetAccountingItemsForApprovalRequest,
	GetAccountingItemsForApprovalResponse
>(
	'/invoices/accounting-items',
	'get',
	{},
);

export const submitHireInvoice = (invoiceId: number) => sendRequest<
	SubmitHireInvoiceRequest,
	SubmitHireInvoiceResponse
>(
	`/invoices/hire-invoices/${invoiceId}/submit`,
	'post',
	{ invoiceId },
);

export const approveHireInvoice = (invoiceId: number, note: string) => sendRequest<
	ApproveHireInvoiceRequest,
	ApproveHireInvoiceResponse
>(
	`/invoices/hire-invoices/${invoiceId}/approve`,
	'post',
	{ invoiceId, note },
);

export const rejectHireInvoice = (invoiceId: number, reason: string) => sendRequest<
	RejectHireInvoiceRequest,
	RejectHireInvoiceResponse
>(
	`/invoices/hire-invoices/${invoiceId}/reject`,
	'post',
	{ invoiceId, reason },
);

export const unsubmitHireInvoice = (invoiceId: number) => sendRequest<
	UnsubmitHireInvoiceRequest,
	UnsubmitHireInvoiceResponse
>(
	`/invoices/hire-invoices/${invoiceId}/unsubmit`,
	'post',
	{ invoiceId },
);

export const getUsers = () => sendRequest<GetUserDataRequest, GetUsersResponse>('/organization/users');

export const updateUser = ({
	userId,
	attributes,
}: {
	userId?: number;
	attributes: UserAttributes;
}) => sendRequest<
	UpdateUserRequest,
	UpdateUserResponse
>(
	'/user',
	'patch',
	{ userId, attributes },
);

export const updateUserDepartments = ({
	userId,
	departments,
}: { userId: number; departments: DepartmentKey[] }) => sendRequest<
	UpdateUserDepartmentsRequest,
	UpdateUserDepartmentsResponse
>(
	'/organization/departments',
	'patch',
	{ userId, departments },
	{ },
	{ savingIndicator: true },
);

export const createBrokerInvoice = (params: {
	voyageId: number;
	amount: number;
	currency: Values<typeof Currencies>;
	exchangeRate: number;
	dateReceived: Moment;
	dueDate: Moment;
	itemDescription: string;
	brokerId?: number;
	documentId?: number;
	attachments?: any;
}) => {
	const data = new FormData();

	if (params.attachments != null) {
		params.attachments.forEach((a: any) => data.append('attachments', a));
	}

	// Add all not-null values to form data
	Object.entries(params).forEach(([key, value]) => {
		if (value != null) {
			data.append(key, value);
		}
	});

	return sendRequest<CreateBrokerInvoiceRequest, CreateBrokerInvoiceResponse>(
		`/brokers/invoices/${params.brokerId}`,
		'post',
		// @ts-ignore requires typing of multi-part-file-upload (i think?)
		data,
	);
};

export const deleteBrokerInvoice = (brokerInvoiceId: number) => sendRequest<
	DeleteBrokerInvoiceRequest,
	DeleteBrokerInvoiceResponse
>(
	`/brokers/invoices/${brokerInvoiceId}`,
	'delete',
	{ brokerInvoiceId },
);

export const updateBrokerInvoice = (params: {
	brokerInvoiceId: number;
	itemDescription: string;
	dateReceived: Moment;
	dueDate: Moment;
	amount: number;
	currency: Values<typeof Currencies>;
	exchangeRate: number;
	attachments: any;
}) => {
	const data = new FormData();

	if (params.attachments != null) {
		params.attachments.forEach((a: any) => data.append('attachments', a));
	}

	Object.entries(params).forEach(([key, value]) => {
		if (value != null) {
			data.append(key, value);
		}
	});

	return sendRequest<
		UpdateBrokerInvoiceRequest,
		UpdateBrokerInvoiceResponse
	>(
		`/brokers/invoices/${params.brokerInvoiceId}`,
		'patch',
		// @ts-ignore requires typing of multi-part-file-upload (i think?)
		data,
	);
};

export const submitBrokerInvoice = (brokerInvoiceId: number) => sendRequest<
	SubmitBrokerInvoiceRequest,
	SubmitBrokerInvoiceResponse
>(
	`/brokers/invoices/${brokerInvoiceId}/submit`,
	'post',
	{ brokerInvoiceId },
);

export const unsubmitBrokerInvoice = (brokerInvoiceId: number) => sendRequest<
	UnsubmitBrokerInvoiceRequest,
	UnsubmitBrokerInvoiceResponse
>(
	`/brokers/invoices/${brokerInvoiceId}/unsubmit`,
	'post',
	{ brokerInvoiceId },
);

export const approveBrokerInvoice = (brokerInvoiceId: number, note?: string) => sendRequest<
	ApproveBrokerInvoiceRequest,
	ApproveBrokerInvoiceResponse
>(
	`/brokers/invoices/${brokerInvoiceId}/approve`,
	'post',
	{ brokerInvoiceId, note },
);

export const rejectBrokerInvoice = (brokerInvoiceId: number, reason?: string) => sendRequest<
	RejectBrokerInvoiceRequest,
	RejectBrokerInvoiceResponse
>(
	`/brokers/invoices/${brokerInvoiceId}/reject`,
	'post',
	{ brokerInvoiceId, reason },
);

export const updateVoyageExpense = (invoiceId: number, attributes: {
	itemDescription: string;
	amount: number;
	supplierId: number;
	invoiceDate: Date;
	customInvoiceId: string;
	dueDate: Date;
	note: string;
	currency: Values<typeof Currencies>;
	exchangeRate: number;
	isPortDA: boolean;
	attachments: any;
	estimated?: boolean;
	port?: Port;
}) => {
	const data = new FormData();

	if (attributes.attachments != null) {
		attributes.attachments.forEach((a: any) => data.append('attachments', a));
	}

	const parameters = {
		...attributes,
		invoiceId,
		invoiceDate: attributes.invoiceDate && formatDate(attributes.invoiceDate),
		dueDate: attributes.dueDate && formatDate(attributes.dueDate),
	};

	// Add all not-null values to form data
	Object.entries(parameters).forEach(([key, value]) => {
		if (value != null) {
			data.append(key, value);
		}
	});

	return sendRequest<UpdateVoyageExpenseRequest, UpdateVoyageExpenseResponse>(
		`/invoices/voyage-expenses/${invoiceId}`,
		'patch',
		// @ts-ignore requires typing of multi-part-file-upload (i think?)
		data,
	);
};

export const sendAdminInvitation = (
	id: number,
	name: string,
	email: string,
	external: boolean,
	isManagement: boolean,
) => sendRequest<
	SendAdminInvitationRequest,
	SendAdminInvitationsResponse
>(
	`/admin/organizations/${id}/invite/admin`,
	'post',
	{ id, name, email, external, isManagement },
);

export const createFeatureHighlight = (
	order: number,
	featureDescription: string,
	featureTitle: string,
	featureFunFact: string,
	featureImageUrl: string,
	recipient?: string | null,
	id?: number | null,
) => sendRequest<
	CreateFeatureHighlightRequest,
	CreateFeatureHighlightResponse
>(
	'/admin/feature-highlights',
	'post',
	{ order, featureDescription, featureTitle, featureFunFact, featureImageUrl, recipient, id },
);

export const updateFeatureHighlight = (
	id: number,
	order: number,
	featureDescription: string,
	featureTitle: string,
	featureFunFact: string,
	featureImageUrl: string,
) => sendRequest<
	UpdateFeatureHighlightRequest,
	UpdateFeatureHighlightResponse
>(
	`/admin/feature-highlights/${id}`,
	'patch',
	{ id, order, featureDescription, featureTitle, featureFunFact, featureImageUrl },
);

export const sendUserInvitations = (
	id: number,
	users: Array<UserInvite>,
) => sendRequest<
	SendUserInvitationsRequest,
	SendUserInvitationsResponse
>(
	`/admin/organizations/${id}/invite/users`,
	'post',
	{ id, users },
);

export const sendWelcomeEmail = (id: number, userId: number) => sendRequest<
	SendWelcomeEmailRequest,
	SendWelcomeEmailResponse
>(
	`/admin/organizations/${id}/welcome/${userId}`,
	'post',
	{ id, userId },
);

export const updateOrganizationUser = (userId: number, attributes: { name: string; disabled: boolean }) => sendRequest<
	UpdateOrganizationUserRequest,
	UpdateOrganizationUserResponse
>(
	`/organization/users/${userId}`,
	'patch',
	{ attributes, userId },
);

export const updateAttachment = (attachmentId: number, attributes: Attributes) => sendRequest<
	UpdateAttachmentRequest,
	UpdateAttachmentResponse
>(
	`/attachments/${attachmentId}`,
	'patch',
	{ attributes, id: attachmentId },
);

export const addAttachment = (belongsToType: Values<typeof AttachmentTypes>, belongsToId: number, file: File) => {
	const data = new FormData();

	data.append('attachment', file);
	data.append('attachedToType', belongsToType);
	data.append('attachedToId', belongsToId.toString());

	return sendRequest<AddAttachmentRequest, AddAttachmentResponse>(
		'/attachments',
		'post',
		// @ts-ignore FormData can't be typed?
		data,
	);
};

export const activateAccount = (token: string) => sendRequest<
	ActivateOrganizationRequest,
	ApproveBrokerInvoiceResponse
>(
	`/auth/activation/${token}`,
	'patch',
	{ token },
);

export const getActivationDetails = (token: string) => sendRequest<
	GetActivationDetailsRequest,
	GetActivationDetailsResponse
>(
	`/auth/activation/${token}`,
	'get',
	{ token },
);

export const updateBroker = (id: number, attributes: UpdateBrokerAttributes) => sendRequest<
	UpdateBrokerRequest,
	UpdateBrokerResponse
>(
	`/brokers/${id}`,
	'patch',
	{ id, attributes },
);

export const getBrokerDetails = (brokerId: number) => sendRequest<
	GetBrokerDetailsRequest,
	GetBrokerDetailsResponse
>(
	`/brokers/${brokerId}`,
	'get',
	{ id: brokerId },
);

export const deleteBroker = (id: number) => sendRequest<
	DeleteBrokerRequest,
	DeleteBrokerResponse
>(
	`/brokers/${id}`,
	'delete',
	{ id },
);

export const deleteFeatureHighlight = (id: number) => sendRequest<
	DeleteFeatureHighlightRequest,
	DeleteFeatureHighlightResponse
>(
	`/admin/feature-highlights/${id}`,
	'delete',
	{ id },
);

export const createCounterparty = (type: Values<typeof FixtureCounterpartyTypes>, name: string) => sendRequest<
	CreateCounterpartyRequest,
	CreateCounterpartyResponse
>(
	'/counterparties',
	'post',
	{ type, name },
);

export const getCounterpartyDetails = (id: number) => sendRequest<
	GetCounterpartyDetailsRequest,
	GetCounterpartyDetailsResponse
>(
	`/counterparties/${id}`,
	'get',
	{ id },
);

export const deleteCounterparty = (id: number) => sendRequest<
	DeleteCounterpartyRequest,
	DeleteCounterpartyResponse
>(
	`/counterparties/${id}`,
	'delete',
	{ id },
);

export const getAccrualItems = () => sendRequest<GetAccrualItemsRequest, GetAccrualItemsResponse>(
	'/financials/accrual-items',
	'get',
	{},
);

export const getPreviousCloses = () => sendRequest<GetClosedPeriodsRequest, GetClosedPeriodsResponse>(
	'/financials/previous-closes',
	'get',
	{},
);

export const updateAccrualItem = (id: number, attributes: { shouldProrate?: boolean }) => sendRequest<
	UpdateAccrualItemRequest,
	UpdateAccrualItemResponse
>(
	'/financials/accrual-items',
	'patch',
	{ id, attributes },
	{},
	{ savingIndicator: true },
);

export const getVideoGuides = (pageTitle?: typeof RootPages[number]) => sendRequest<
	GetVideoGuidesRequest,
	GetVideoGuidesResponse
>(
	'/video-guides',
	'get',
	{ pageTitle },
);

export const createVideoGuide = ({
	title,
	description,
	url,
	page,
}: CreateVideoGuideRequest) => sendRequest<CreateVideoGuideRequest, CreateVideoGuideResponse>(
	'/admin/video-guides',
	'post',
	{ title, description, url, page },
	{},
	{ savingIndicator: true },
);

export const updateVideoGuide = ({
	id,
	attributes,
}: {
	id: number;
	attributes: UpdateVideoGuideRequest['attributes'];
}) => sendRequest<UpdateVideoGuideRequest, UpdateVideoGuideResponse>(
	'/admin/video-guides',
	'patch',
	{ id, attributes },
	{},
	{ savingIndicator: true },
);

export const updateVideoGuideOrder = (
	reorderedEntries: Array<VideoGuideProps>,
) => sendRequest<UpdateVideoGuideOrderRequest, UpdateVideoGuideOrderRequest>(
	'/admin/video-guides/reorder',
	'patch',
	{ reorderedEntries },
);

export const deleteVideoGuide = (id: number) => sendRequest<DeleteVideoGuideRequest, DeleteVideoGuideResponse>(
	'/admin/video-guides',
	'delete',
	{ id },
	{},
	{ savingIndicator: true },
);

export const getVoyageDurations = (
	voyageIds: number[],
) => sendRequest<GetVoyageDurationRequest, GetVoyageDurationResponse>(
	`/voyages/duration/${voyageIds.join(',')}`,
	'get',
	{ voyageIds },
);

export const createRevenueSubjectToDays = (
	fixtureId: number,
	{ name, amount }: { name: string; amount: number },
) => sendRequest(
	`/fixtures/${fixtureId}/revenue-subject-to-days`,
	'post',
	{ name, amount },
);

export const updateRevenueSubjectToDays = (
	fixtureId: number,
	revenueId: number,
	attributes: { name: string; amount: number },
) => sendRequest(
	`/fixtures/${fixtureId}/revenue-subject-to-days/${revenueId}`,
	'patch',
	{ attributes },
);

export const deleteRevenueSubjectToDays = (
	fixtureId: number,
	revenueId: number,
) => sendRequest(
	`/fixtures/${fixtureId}/revenue-subject-to-days/${revenueId}`,
	'delete',
);

export const getReportGroups = () => sendRequest<GetReportGroupsRequest, GetReportGroupsResponse>(
	'/organization/report-groups',
	'get',
);

export const getReceivablesAgingData = (period: Values<typeof DashboardPeriods>) => sendRequest<
	GetReceivablesAgingDataRequest,
	GetReceivablesAgingDataResponse
>(
	'/statistics/receivables-aging',
	'get',
	{ period },
);

export const updateReportGroups = (
	attributes: ReportGroupAttributes,
) => sendRequest<UpdateReportGroupsRequest, UpdateReportGroupsResponse>(
	'/organization/report-groups',
	'post',
	{ attributes },
);

export const updateOffHireTypes = (
	attributes: UpdateOffHireTypesAttributes,
) => sendRequest<UpdateOffHireTypesRequest, UpdateOffHireTypesResponse>(
	'/organization/off-hire-types',
	'post',
	{ attributes },
);

export const getVoyageItinerary = (
	voyageId: number,
) => sendRequest<GetVoyageItineraryRequest, GetVoyageItineraryResponse>(
	`/voyages/${voyageId}/itinerary`,
	'get',
	{ voyageId },
);

export const getPreviousVoyageBunkers = (
	voyageId: number,
) => sendRequest<GetPreviousVoyageBunkersRequest, GetPreviousVoyageBunkersResponse>(
	`/voyages/${voyageId}/previous-bunkers`,
	'get',
	{ voyageId },
);

export const getVoyageInvoices = (voyageId: number) => sendRequest<
	GetVoyageInvoicesRequest,
	GetVoyageInvoicesResponse
>(
	'/invoices/voyage-invoices',
	'get',
	{ voyageId },
);

export const updateVesselConsumptionEntries = ({
	vesselId,
	attributes,
}: {
	vesselId: number;
	attributes: Array<{
		id?: number;
		consumption?: number;
		fuelType?: Values<typeof FuelTypes>;
	}>;
}) => sendRequest<UpdateVesselConsumptionEntryRequest, UpdateVesselConsumptionEntryResponse>(
	`/vessels/${vesselId}/consumption-entries`,
	'patch',
	{ attributes, vesselId },
	{},
	{ savingIndicator: true },
);

export const createVesselConsumptionSet = (vesselId: number) => sendRequest<
	CreateVesselConsumptionSetRequest,
	CreateVesselConsumptionSetResponse
>(
	`/vessels/${vesselId}/consumption-sets`,
	'post',
	{ vesselId },
);

export const updateVesselConsumptionSet = ({
	vesselId,
	consumptionSetId,
	attributes,
}: {
	vesselId: number;
	consumptionSetId: number;
	attributes: {
		enableSecondary?: boolean;
		name?: string;
		ballastSpeed?: number;
		ladenSpeed?: number;
	};
}) => sendRequest<
	UpdateVesselConsumptionSetRequest,
	UpdateVesselConsumptionSetResponse
>(
	`/vessels/${vesselId}/consumption-sets`,
	'patch',
	{ vesselId, consumptionSetId, attributes },
);

export const deleteVesselConsumptionSet = (consumptionSetId: number, vesselId: number) => sendRequest<
	DeleteVesselConsumptionSetRequest,
	DeleteVesselConsumptionSetResponse
>(
	`/vessels/${vesselId}/consumption-sets`,
	'delete',
	{ vesselId, consumptionSetId },
);

export const getOutstandingsData = () => sendRequest<GetOutstandingsDataRequest, GetOutstandingsDataResponse>(
	'/statistics/outstandings',
	'get',
);

export const getFullFleetTimelineData = () => sendRequest<
	GetFullFleetTimelineDataRequest,
	GetFullFleetTimelineDataResponse
>(
	'/statistics/full-fleet-timeline-data',
	'get',
);

export const createCustomField = ({ type, value, label, parentType, parentId }
: { type: CustomFieldType; value: CustomFieldValueTypes[CustomFieldType]; label: string; parentType:
CustomFieldParentTypes; parentId: number; }) => sendRequest<
	CreateCustomFieldRequest, CreateCustomFieldResponse>(
		'/misc/custom-field',
		'post',
		{ type, value, label, parentType, parentId },
	);

export const getCustomFields = ({ parentType, parentId }
: { parentType: CustomFieldParentTypes; parentId: number }) => sendRequest<
GetCustomFieldsRequest, GetCustomFieldsResponse>(
	'/misc/custom-field',
	'get',
	{ parentType, parentId },
);

export const deleteCustomField = (id: number) => sendRequest<DeleteCustomFieldRequest, DeleteCustomFieldResponse>(
	`/misc/custom-field/${id}`,
	'delete',
	{ id },
);

export const updateCustomField = ({ id, type, label, value }
: { id: number; type: CustomFieldType; label: string; value: string | number | Date }) => sendRequest<
UpdateCustomFieldRequest, UpdateCustomFieldResponse>(
	`/misc/custom-field/${id}`,
	'patch',
	{ id, type, label, value },
);

export const updatePortRotation = (
	updatedPortRotation: PartialPortRotationEntry[],
	estimateId: number,
) => sendRequest<UpdatePortRotationRequest, UpdatePortCallResponse>(
	'/estimates/reorder',
	'post',
	{ estimateId, updatedPortRotation },
);

export const getEstimateGroup = (
	estimateGroupId: number,
) => sendRequest<GetEstimateGroupRequest, GetEstimateGroupResponse>(
	`/estimates/group/${estimateGroupId}`,
	'get',
	{ estimateGroupId },
);

export const getEstimateGroups = () => sendRequest<GetEstimateGroupsRequest, GetEstimateGroupsResponse>(
	'/estimates/group',
	'get',
);

export * from './untyped-api';
export * from './sendRequest';
