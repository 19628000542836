// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FQO1CsXgcPU2uWIqNnqg {\n\twidth: 100%;\n\tdisplay: flex;\n\tmargin-bottom: 10px;\n\tbackground: #fafafa;\n\ttext-align: left;\n\tborder-bottom: 1px solid #f0f0f0;\n\tpadding: 8px;\n\tpadding-right: 16px;\n\tpadding-left: 40px;\n}\n\n.RbGKT8c9N841f3Pojozj {\n\tmargin-bottom: 15px;\n\tborder-radius: 8px;\n}\n\n.JCRoxlsrZzsgHTM5FNxm {\n\tdisplay: flex;\n\twidth: 100%;\n\tjustify-content: space-between;\n\ttext-align: left;\n}\n\n.PW4a8xS9trbyqQ0b92wv {\n\tcolor: #6d6d6d;\n}\n\n.ocftNWstlHrXfF1Y1NWQ {\n\tcolor: #fa541c;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/general/HomeScreen/widgets/styles/WarningsWidget.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,aAAa;CACb,mBAAmB;CACnB,mBAAmB;CACnB,gBAAgB;CAChB,gCAAgC;CAChC,YAAY;CACZ,mBAAmB;CACnB,kBAAkB;AACnB;;AAEA;CACC,mBAAmB;CACnB,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,WAAW;CACX,8BAA8B;CAC9B,gBAAgB;AACjB;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,cAAc;AACf","sourcesContent":[".columns {\n\twidth: 100%;\n\tdisplay: flex;\n\tmargin-bottom: 10px;\n\tbackground: #fafafa;\n\ttext-align: left;\n\tborder-bottom: 1px solid #f0f0f0;\n\tpadding: 8px;\n\tpadding-right: 16px;\n\tpadding-left: 40px;\n}\n\n.warning {\n\tmargin-bottom: 15px;\n\tborder-radius: 8px;\n}\n\n.header {\n\tdisplay: flex;\n\twidth: 100%;\n\tjustify-content: space-between;\n\ttext-align: left;\n}\n\n.emptyWarnings {\n\tcolor: #6d6d6d;\n}\n\n.warningIcon {\n\tcolor: #fa541c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columns": "FQO1CsXgcPU2uWIqNnqg",
	"warning": "RbGKT8c9N841f3Pojozj",
	"header": "JCRoxlsrZzsgHTM5FNxm",
	"emptyWarnings": "PW4a8xS9trbyqQ0b92wv",
	"warningIcon": "ocftNWstlHrXfF1Y1NWQ"
};
export default ___CSS_LOADER_EXPORT___;
