import React from 'react';
import {
	Alert,
	Space,
	Tooltip,
	Typography,
} from 'antd';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faStar,
	faWrench,
} from '@fortawesome/pro-light-svg-icons';
import {
	Icon,
	IconProp,
} from '@fortawesome/fontawesome-svg-core';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import {
	DashboardPeriodLabels,
	DashboardPeriods,
	DashboardTypes,
	DepartmentKey,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import { useAuth } from '@client/lib/auth';
import TooltipIcon from '@client/components/TooltipIcon';
import { updateUser } from '@client/lib/api';
import styles from './HomeScreen.module.css';
import DashboardInfoProvider from './helpers/dashboardInfo';
import getDepartmentDashboards from './helpers/getDepartmentDashboards';

const HomeScreen = () => {
	const { userInfo, refreshAuthInfo } = useAuth();
	const dashboards = getDepartmentDashboards(userInfo.activeDepartment as DepartmentKey);

	const setDefaultDashboard = async (entry: Values<typeof DashboardTypes>) => {
		if (entry != null) {
			await updateUser({
				attributes: {
					defaultDashboard: entry,
				},
			});

			await refreshAuthInfo();
		}
	};

	const DashboardOption = <T, >({
		label,
		selectedEntry,
		entry,
		defaultable,
		onClick,
	}: {
		label: string;
		selectedEntry: T;
		entry: T;
		defaultable?: boolean;
		onClick: (p: T) => void;
	}) => {
		return (
			<button
				type="button"
				onClick={() => onClick(entry)}
				className={classNames(styles.dashboardEntry, {
					[styles.activeEntry]: entry === selectedEntry,
				})}
			>
				{label}
				{defaultable && entry === selectedEntry && (
					<Tooltip title={`Set ${label} as your default tab`}>
						{' '}
						<FontAwesomeIcon
							onClick={async () => setDefaultDashboard(entry as Values<typeof DashboardTypes>)}
							icon={selectedEntry === userInfo.defaultDashboard ? faStarSolid : faStar as IconProp}
							size="xs"
						/>
					</Tooltip>
				)}
			</button>
		);
	};

	const filterCheck = () => {
		const savedTime = localStorage.getItem('vesselFilterAlert');

		if (savedTime == null) {
			return true;
		}

		return nowMoment().isAfter(toMoment(savedTime));
	};

	return (
		<DashboardInfoProvider dashboards={dashboards}>
			{({ period, setPeriod, DashboardComponent, selectedDashboard, setDashboard }) => (
				<SimpleScreen
					reducedPadding
					rootPageTitle="Home"
					title={(
						<div>
							<Space>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Typography.Text className={styles.smallText} type="secondary">
										Dashboard
									</Typography.Text>
									<Space>
										{dashboards.map((dashboard) => (
											<DashboardOption<Values<typeof DashboardTypes>>
												onClick={setDashboard}
												label={dashboard.label}
												selectedEntry={selectedDashboard.key}
												entry={dashboard.key}
												defaultable
											/>
										))}
									</Space>
								</div>
							</Space>
							{selectedDashboard.periodOptions != null && (
								<Space>
									<div style={{ marginLeft: 25, display: 'flex', flexDirection: 'column' }}>
										<Typography.Text className={styles.smallText} type="secondary">
											Period
										</Typography.Text>
										<div className={styles.periodContainer}>
											<Space>
												{selectedDashboard.periodOptions.map((p) => (
													<DashboardOption<Values<typeof DashboardPeriods>>
														onClick={setPeriod}
														label={DashboardPeriodLabels[p]}
														selectedEntry={period}
														entry={p}
													/>
												))}
											</Space>
											{!(userInfo.filterVessels && filterCheck()) && (
												<TooltipIcon
													marginLeft={10}
													className={styles.periodTooltip}
												>
													<span>
														The stats below are based on your default vessel filter.
														To change or disable this filter, go to
														{' '}
														<FontAwesomeIcon
															icon={faWrench as Icon}
														/>
														{' -> '}
														Vessels.
													</span>
												</TooltipIcon>
											)}
										</div>

									</div>
								</Space>
							)}
						</div>
					)}
				>
					{(userInfo.filterVessels && filterCheck()) ? (
						<Alert
							className={styles.vesselFilterAlert}
							message={(
								<span>
									The stats below are based on your default vessel filter.
									To change or disable this filter, go to
									{' '}
									<FontAwesomeIcon
										icon={faWrench as Icon}
									/>
									{' -> '}
									Vessels.
								</span>
							)}
							closable
							onClose={() => {
								localStorage.setItem(
									'vesselFilterAlert',
									nowMoment().add(1, 'month').toISOString(),
								);
							}}
						/>
					) : null}
					<DashboardComponent period={period} />
				</SimpleScreen>
			)}
		</DashboardInfoProvider>
	);
};

export default HomeScreen;
