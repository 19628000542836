import React from 'react';
import {
	Bar,
	CartesianGrid,
	Tooltip,
	XAxis,
	YAxis,
	Cell,
	ResponsiveContainer,
	BarChart,
} from 'recharts';
import { formatCurrency } from '@shared/utils/currency';
import styles from '../screens/general/HomeScreen/components/styles/PnlWaterfallWidget.module.css';

/**
 * Creates a waterfall barchart from the data passed in props.
 * Positive value will result in a green bar rising,
 * negative values a red bar going down.
 * Positive/negative-ness should be set when passing the values in.
 */

const PnlWaterfallWidget = ({ data, currency }) => {
	const getPreviousValue = (index) => {
		if (index === 1 && filteredData.length > 2) {
			return filteredData[index - 1].value;
		}

		if (index === filteredData.length - 1) {
			return 0;
		}

		return filteredData.slice(0, index).reduce(((total, item) => total + item.value), 0);
	};

	const filteredData = data.filter((item) => item.value !== 0);
	const transformedData = filteredData.map((e, index) => ({
		name: e.name,
		Amount: e.value,
		...(index > 0 ? {
			previousValue: getPreviousValue(index),
		} : { previousValue: 0 }),
	}));

	const customTooltip = ({ active, payload, label }) => {
		if (active && payload && payload[1]?.value) {
			return (
				<div className={styles.customTooltip}>
					<p className={payload[1].value > 0 ? styles.positive : styles.negative}>{`${label} : ${formatCurrency(payload[1].value, currency)}`}</p>
				</div>
			);
		}

		return null;
	};

	return (
		<ResponsiveContainer width="100%" height={600}>
			<BarChart
				width={650}
				height={300}
				data={transformedData}
				margin={{
					top: 20,
					right: 10,
					left: 50,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" interval={0} angle={-45} tick={{ fontSize: 14, dy: 38 }} height={100} />
				<YAxis tickFormatter={(tick) => (formatCurrency(tick, currency))} />
				<Tooltip content={customTooltip} />
				<Bar dataKey="previousValue" stackId="a" fill="transparent" />
				<Bar dataKey="Amount" stackId="a" fill="#82ca9d">
					{transformedData.map((item) => {
						if (item.Amount < 0) {
							return (<Cell fill="#ff7875" />);
						}

						if (item.name === 'Total') {
							return (<Cell fill="#73d13d" />);
						}

						return (<Cell fill="#b7eb8f" />);
					})}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};

export default PnlWaterfallWidget;
