import { Departments } from '@shared/utils/constants';
import { MenuItemSection } from './getMenuItems';

const OPERATIONS_ITEMS: MenuItemSection[] = [
	'HOME',
	'ADMIN',
	'FIXTURES',
	'VOYAGES',
	'VESSELS',
	'CARGOS',
	'CONNECTIONS',
	'MAP',
	'EXPENSES',
];

const FINANCE_ITEMS: MenuItemSection[] = [
	'HOME',
	'ADMIN',
	'FIXTURES',
	'VOYAGES',
	'VESSELS',
	'CARGOS',
	'CONNECTIONS',
	'MAP',
	'EXPENSES',
	'ACCOUNTING_ITEMS',
	'ACCOUNTING',
];

const CHARTERING_ITEMS: MenuItemSection[] = [
	'HOME',
	'ADMIN',
	'FIXTURES',
	'CARGOS',
	'VOYAGES',
	'VESSELS',
	'CONNECTIONS',
	'MAP',
];

const MANAGEMENT_ITEMS: MenuItemSection[] = [
	'HOME',
	'ADMIN',
	'APPROVALS',
	'COMPANY_SETTINGS',
	'MAP',
	'FIXTURES',
	'VOYAGES',
	'VESSELS',
	'CONNECTIONS',
];

const ADMIN_ITEMS: MenuItemSection[] = Array.from(
	new Set([...MANAGEMENT_ITEMS, ...CHARTERING_ITEMS, ...OPERATIONS_ITEMS, ...FINANCE_ITEMS]),
);

const departmentToMenuItemsMap = {
	[Departments.CHARTERING.key]: CHARTERING_ITEMS,
	[Departments.OPERATIONS.key]: OPERATIONS_ITEMS,
	[Departments.FINANCE.key]: FINANCE_ITEMS,
	[Departments.MANAGEMENT.key]: MANAGEMENT_ITEMS,
	[Departments.ADMIN.key]: ADMIN_ITEMS,
};

export const getDepartmentMenuItems = (department: string) => (
	departmentToMenuItemsMap[department] || CHARTERING_ITEMS
);

