import React, { useMemo } from 'react';
import {
	Card,
	Divider,
	Grid,
	Space,
} from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBolt,
	faContactBook,
	faFileSignature,
	faMap,
	faShip,
	faSparkles,
} from '@fortawesome/pro-thin-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FixtureTypes } from '@shared/utils/constants';
import { trimString } from '@shared/utils/string';
import getDataWithGradient, { gradients } from '@client/utils/getDataWithGradient';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getUserData } from '@client/lib/api';
import { Links } from '@client/utils/links';
import styles from './QuickBar.module.css';

const QuickBar = () => {
	const [userData] = useFetchedState(getUserData);

	const isEmpty = userData == null || userData.quickLinks.length < 2;
	const screens = Grid.useBreakpoint();

	const quickLinks = useMemo(() => {
		const quickStartItems = [
			{
				key: 'vessel',
				identifier: 'Create new vessel',
				icon: faShip as IconProp,
				path: Links.Vessels.path,
			},
			{
				key: 'estimate',
				identifier: 'Create an estimate',
				icon: faFileSignature as IconProp,
				path: Links.Fixtures[FixtureTypes.TC_OUT].List.path,
			},
			{
				key: 'charterer',
				identifier: 'Enter a charterer',
				icon: faContactBook as IconProp,
				path: Links.Charterers.path,
			},
			{
				key: 'map',
				identifier: 'Explore your fleet on the map',
				icon: faMap as IconProp,
				path: Links.Map.path,
			},
			...(userData?.newestOrgVessel != null ? [{
				key: 'vessel',
				identifier: `Go to vessel ${userData.newestOrgVessel.name}`,
				icon: faSparkles as IconProp,
				path: Links.Vessel.get(userData.newestOrgVessel.id),
			}] : []),
			...(userData?.newestContract != null ? [{
				key: 'contract',
				identifier: `Go to contract ${userData.newestContract.identifier}`,
				icon: faSparkles as IconProp,
				path: Links.Voyage.get(userData.newestContract.id),
			}] : []),
		];

		if (userData?.quickLinks == null) {
			return [];
		}

		const result = getDataWithGradient(
			isEmpty ? quickStartItems : userData.quickLinks,
			gradients.magic,
		);

		if (screens.xs) {
			return result.splice(0, 3);
		}

		return result.splice(0, 8);
	}, [userData, screens, isEmpty]);

	return (
		<Card size="small">
			<Space
				direction={screens.xs ? 'vertical' : 'horizontal'}
				size={screens.xxl ? 'large' : 'small'}
				align="center"
			>
				<FontAwesomeIcon className={styles.marginLeft} icon={faBolt as IconProp} color="purple" />
				{(screens.xs || screens.xxl) && (
					<span
						style={{
							fontWeight: 'bold',
							color: 'purple',
							whiteSpace: 'nowrap',
						}}
					>
						Quick-access
					</span>
				)}
				<Divider type={screens.xs ? 'horizontal' : 'vertical'} />
				{quickLinks.map((l) => (
					<>
						<Link to={l.path}>
							<Space key={l.key}>
								<Space direction="horizontal" align="center">
									{/* eslint-disable-next-line react/forbid-component-props */}
									<FontAwesomeIcon color={l.color} style={{ fontSize: 24 }} icon={l.icon} />
									<div style={{ color: l.color, fontWeight: 'normal' }}>
										{trimString(l.identifier, 35, true)}
									</div>
								</Space>
							</Space>
						</Link>
						<Divider type={screens.xs ? 'horizontal' : 'vertical'} />
					</>
				))}
			</Space>
		</Card>
	);
};

export default QuickBar;
