import React, { useCallback } from 'react';
import {
	Grid,
	notification,
} from 'antd';
import { faAddressBook } from '@fortawesome/pro-duotone-svg-icons';
import { standardSort } from '@shared/utils/standardSort';
import {
	getBrokers,
	createBroker,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { renderDate } from '@client/utils/table';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import { Links } from '@client/utils/links';

const columns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		defaultSortOrder: 'ascend',
		sorter: standardSort('name'),
	},
	{
		title: 'Point of contact',
		dataIndex: 'pointOfContactName',
		key: 'pointOfContactName',
		sorter: standardSort('address'),
	},
	{
		title: 'Last updated',
		dataIndex: 'updatedAt',
		key: 'updatedAt',
		sorter: standardSort('updatedAt'),
		render: renderDate(),
	},
];

const BrokersListScreen = () => {
	const [brokers, reloadBrokers, error, loading] = useFetchedState(getBrokers);
	const screens = Grid.useBreakpoint();

	const createNewBroker = useCallback(async ({ name }) => {
		const result = await createBroker(name);

		reloadBrokers();

		notification.success({
			message: 'Broker created',
			description: `${name} has been created.`,
		});

		return result;
	}, [reloadBrokers]);

	return (
		<ListTableScreen
			title="Brokers"
			rootPageTitle="Brokers"
			columns={columns}
			loading={loading}
			error={error}
			tableProps={{ useCards: screens.xs }}
			data={brokers ?? []}
			formFields={[
				{
					label: 'Name',
					name: 'name',
					type: 'text',
					required: true,
				},
			]}
			formButtonText="Create broker"
			onFormSubmit={createNewBroker}
			getRowHref={(row) => Links.Broker.get(row.id)}
			showEmptyArrow
			emptyTitle="You have not registered any brokers yet"
			emptyDescription="Click the button to create one"
			emptyIcon={faAddressBook}
		/>
	);
};

export default BrokersListScreen;
