import React, {
	forwardRef,
	useState,
} from 'react';
/* eslint-disable no-restricted-imports */
import {
	Button as AntButton,
	Popconfirm,
	Tooltip,
} from 'antd';
/* eslint-enable no-restricted-imports */
import { ButtonProps as AntButtonProps } from 'antd/lib/button';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { TooltipProps } from 'antd/lib/tooltip';
import { isPromise } from '@shared/utils/promise';
import { ExtendUnique } from '@client/utils/ExtendUnique';

export type ButtonProps = ExtendUnique<{
	disabled?: boolean;
	popconfirmProps?: Omit<PopconfirmProps, 'title'>;
	disabledTooltip?: string | React.ReactElement;
	confirmTitle?: string | React.ReactNode;
	tooltipProps?: TooltipProps;
	padding?: number;
	clickPropagation?: boolean;
	onClick?: (e: React.MouseEvent<HTMLElement> | undefined) => void;
}, AntButtonProps>;

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(({
	disabled,
	popconfirmProps = { style: { padding: 8 } },
	disabledTooltip,
	confirmTitle,
	tooltipProps,
	padding,
	children,
	onClick,
	type,
	clickPropagation = true,
	...props
}, ref) => {
	const hasPopconfirm = (confirmTitle != null);
	const [loading, setLoading] = useState(false);

	const click = async (e: React.MouseEvent<HTMLElement> | undefined, isConfirming?: boolean) => {
		if (!clickPropagation) {
			e?.stopPropagation();
		}

		if (hasPopconfirm && !isConfirming) {
			return;
		}

		if (typeof onClick === 'function') {
			const returnValue = onClick(e);

			// If returnValue is a promise, handle loading state
			if (isPromise(returnValue)) {
				setLoading(true);

				// makes sure to always setLoading false, if there is an error
				try {
					await returnValue;
				} finally {
					setLoading(false);
				}
			}
		}
	};

	const content = (
		<AntButton
			loading={loading}
			ref={ref}
			onClick={click}
			disabled={disabled}
			type={type}
			// eslint-disable-next-line react/forbid-component-props
			style={{ padding }}
			{...props}
		>
			{children}
		</AntButton>
	);

	if (disabled && disabledTooltip != null) {
		return (
			<Tooltip
				title={disabledTooltip}
				{...tooltipProps}
			>
				{content}
			</Tooltip>
		);
	}

	if (hasPopconfirm) {
		return (
			<Popconfirm
				trigger={disabled ? [] : 'click'}
				arrowPointAtCenter
				placement="bottomLeft"
				{...popconfirmProps}
				title={confirmTitle}
				onConfirm={(e) => click(e, true)}
			>
				{content}
			</Popconfirm>
		);
	}

	return	content;
});

export default Button;
