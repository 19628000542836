import React, {
	SetStateAction,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Alert,
	Card,
	Col,
	Descriptions,
	Divider,
	Grid,
	Row,
	Space,
	Typography,
} from 'antd';
import { Moment } from 'moment';
import {
	CloseOutlined,
	EditOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import { TimeFormat } from '@shared/utils/date';
import {
	UtcOffsets,
	PortCallTypes,
	PortActionTypes,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import normalize from '@shared/utils/normalizeNumber';
import { formatNumber } from '@shared/utils/formatNumber';
import type { ItineraryPortCallDto } from '@api/features/ops/getVesselItinerary';
import type { Port } from '@api/utils/ports';
import Button from '@client/components/Button';
import {
	deleteItineraryEntry,
	getTimezoneOffset,
	getVoyageDetails,
	updatePortCall,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import Select from '@client/components/Select';
import CountryFlag from '@client/components/CountryFlag';
import LoadingIndicator from '@client/components/LoadingIndicator';
import EditableText from '@client/components/EditableText';
import EmptyText from '@client/components/EmptyText';
import PrettyDate from '@client/components/PrettyDate/PrettyDate';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import EditableField from '@client/components/EditableField';
import styles from './styles/SummaryTab.module.css';
import { PortCallBunkersSection } from './PortCallBunkersSection';

type Props = {
	selectedEntry: ItineraryPortCallDto;
	refreshDetails: () => void;
	ports: Port[] | undefined;
	selectedTimeFormat: TimeFormat;
	allowDelete: boolean;
	latestActualDate: Moment | null;
	latestEstimatedDate: Moment | null;
	isPreviousActual: boolean;
	setSelectedIndex: React.Dispatch<SetStateAction<number | undefined | null>>;
}

const PortCallSummaryTab = ({
	selectedEntry,
	refreshDetails,
	ports,
	selectedTimeFormat,
	latestActualDate,
	latestEstimatedDate,
	isPreviousActual,
	setSelectedIndex,
}: Props) => {
	const {
		arrivalDate,
		departureDate,
		requiredArrivalDate,
		estimatedDepartureDate,
		estimatedArrivalDate,
		port,
		requiredSpeed,
		estimatedIdleDays,
		estimatedWorkingDays,
		estimatedTurningDays,
		actions,
		mastersEta,
	} = selectedEntry;

	const isNotCommencement = selectedEntry.type !== PortCallTypes.COMMENCEMENT;
	const actionStr = actions.map((a) => a.action);
	const isLoadingOrDischarge =
		actionStr.includes(PortActionTypes.LOADING) ||
		actionStr.includes(PortActionTypes.DISCHARGING);

	const disabledDeleteTooltip = useMemo(() => {
		if (isLoadingOrDischarge) {
			return 'You cannot delete port calls with loading or discharging actions';
		}

		if (!isNotCommencement) {
			return 'You cannot delete the commencement port call';
		}

		return '';
	}, [isNotCommencement, isLoadingOrDischarge]);

	const [editingPort, setEditingPort] = useState(false);
	const [selectedPort, setSelectedPort] = useState(port);

	const screen = Grid.useBreakpoint();
	const smallScreen = !screen.xl;

	const hasDaExpenses = selectedEntry.voyageExpenses.length > 0;

	const [voyageDetails] = useFetchedState(async () => {
		if (selectedEntry.voyageId == null) {
			return null;
		}

		return await getVoyageDetails(selectedEntry?.voyageId);
	});

	const [defaultPortTzOffset, _refresh, _error, _tzLoading] = useFetchedState(
		async () => {
			if (selectedPort == null) {
				return 0;
			}

			const relevantPort = ports?.find((p) => p.id === selectedPort.id);

			if (relevantPort == null) {
				return 0;
			}

			return await getTimezoneOffset(relevantPort.latitude, relevantPort.longitude);
		},
		[selectedPort],
	);

	const portOptions = useMemo(() => (ports != null ? ports?.map((p) => {
		return {
			label: (
				<div className={styles.portSelector}>
					<CountryFlag
						countryCode={typeof p?.countryCode === 'string' ? p.countryCode : undefined}
					/>
					<p>{p.name}</p>
				</div>
			),
			value: p.id,
			searchValue: p.name,
		};
	}).sort((a, b) => a.searchValue.localeCompare(b.searchValue)) : []), [ports]);

	const arrivalOffsetLabel = UtcOffsets.find((o) => o.offset === arrivalDate?.utcOffset());
	const departureOffsetLabel = UtcOffsets.find((o) => o.offset === departureDate?.utcOffset());
	const [saving, setSaving] = useState(false);

	const updateEntry = async (
		attributes: {
			arrivalDate?: Moment;
			departureDate?: Moment;
			estimatedDepartureDate?: Moment;
			requiredArrivalDate?: Moment;
			port?: Port;
			estimatedIdleDays?: number;
			estimatedWorkingDays?: number;
		},
	) => {
		setSaving(true);

		try {
			await updatePortCall(selectedEntry.vesselId, selectedEntry.id, attributes, true);
			await refreshDetails();
		} catch (e) {
			showErrorNotification('Could not update port call', e as Error);
		} finally {
			setSaving(false);
		}
	};

	const deletePortCall = async () => {
		try {
			await deleteItineraryEntry(selectedEntry.vesselId, selectedEntry.id);
			await refreshDetails();
			setSelectedIndex(0);
		} catch (e) {
			showErrorNotification('Could not delete port call', e as Error);
		}
	};

	const handlePortSelect = async (id: number | null) => {
		if (id == null) {
			return;
		}

		const newPort = ports?.find((p) => p.id === id);

		if (newPort == null) {
			return;
		}

		setSelectedPort(newPort);

		await updateEntry({
			port: newPort,
		});
		await refreshDetails();
		setEditingPort(false);
	};

	useEffect(() => {
		setSelectedPort(port);
	}, [port]);

	const requiredSpeedContent = useMemo(() => {
		if (requiredSpeed == null) {
			return (
				<Typography.Text>N/A</Typography.Text>
			);
		}

		if (Number.isNaN(requiredSpeed) || requiredSpeed < 0) {
			return (
				<Alert
					message="There is no possible speed to arrive at the required time"
					className={styles.requiredSpeedAlert}
				/>
			);
		}

		return (
			<Typography.Text>
				{requiredSpeed}
				{' '}
				kt.
			</Typography.Text>
		);
	}, [requiredSpeed]);

	const estimatedTimeInPortContent = useMemo(() => {
		const workingDays = normalize(estimatedWorkingDays);
		const idleDays = normalize(estimatedIdleDays);
		const turningDays = normalize(estimatedTurningDays);

		const inPort = round(workingDays + idleDays + turningDays, 2);

		return (
			<Typography.Text>
				{inPort}
				{' '}
				{inPort === 1 ? 'day' : 'days'}
			</Typography.Text>
		);
	}, [estimatedWorkingDays, estimatedTurningDays, estimatedIdleDays]);

	const etaAfterRequiredAlert = useMemo(() => {
		if (requiredArrivalDate?.isBefore(estimatedArrivalDate) && arrivalDate == null) {
			return (
				<Alert type="warning" message="Required arrival date is before estimated arrival date" />
			);
		}

		return null;
	}, [arrivalDate, estimatedArrivalDate, requiredArrivalDate]);

	const estimatedConsumptionsContent = useMemo(() => {
		if (selectedEntry.estimatedConsumptions == null) {
			return (
				<div>
					N/A
				</div>
			);
		}

		const cons = Object.entries(selectedEntry.estimatedConsumptions);

		return cons.map(([fuelType, { quantity }]) => {
			return (
				<div className={styles.consumptionsRow}>
					<b style={{ whiteSpace: 'nowrap' }}>{`${fuelType}: `}</b>
					<span style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
						{`${formatNumber(quantity, { separateThousands: true }, 2)} MT`}
					</span>
				</div>
			);
		});
	}, [selectedEntry]);

	if (defaultPortTzOffset == null) {
		return (<LoadingIndicator />);
	}

	return (
		<>
			<Row>
				{saving && (
					<div className={styles.loadingOverlay}>
						<LoadingIndicator />
					</div>
				)}
				<Col span={24}>
					<Row
						align="middle"
						className={styles.portNameContainer}
					>
						<div
							className={classNames(styles.flagAndNameRow)}
						>
							{editingPort ? (
								<Select
									showSearch
									className={styles.selectPort}
									size="large"
									value={selectedPort.id}
									optionFilterProp="searchValue"
									options={portOptions}
									onSelect={handlePortSelect}
									onBlur={() => setEditingPort(false)}
								/>
							) : (
								<>
									<CountryFlag
										countryCode={selectedPort?.countryCode}
										className={styles.portFlag}
										size={35}
									/>
									<Typography.Title
										level={3}
									>
										{port.name}
									</Typography.Title>
								</>
							)}
						</div>
						<div
							className={styles.topButtonRow}
						>
							{editingPort ? (
								<Button
									type="link"
									className={styles.closeIcon}
									onClick={() => setEditingPort(false)}
									icon={(
										<CloseOutlined />
									)}
								/>
							) : (
								<div className={styles.buttonContainer}>
									{!selectedEntry.isCanalTransit ? (
										<div className={styles.innerBtnContainer}>
											<Button
												type="link"
												onClick={() => setEditingPort(true)}
												confirmTitle="This port will also be changed in the cargo and the estimate"
												icon={(
													<EditOutlined />
												)}
											/>
											{etaAfterRequiredAlert}
										</div>
									) : (<div />)}
									{(arrivalDate == null && departureDate == null) && (
										<Button
											type="primary"
											danger
											disabled={!isNotCommencement || isLoadingOrDischarge}
											onClick={() => deletePortCall()}
											confirmTitle={`Are you sure you want to delete this port call? ${hasDaExpenses != null && 'Port DA\'s attached to this port call will be deleted'}`}
											disabledTooltip={disabledDeleteTooltip}
										>
											Delete port call
										</Button>
									)}
								</div>
							)}
						</div>
					</Row>
				</Col>
				<Space direction="vertical">
					<Space className={styles.estimatedDatesContainer}>
						{isNotCommencement && (
							<Card className={styles.card}>
								<Typography.Text type="secondary">
									ETA
									{arrivalOffsetLabel != null && selectedTimeFormat === 'localTime' ? ` (${arrivalOffsetLabel.name})` : ' (UTC)'}
								</Typography.Text>
								<Typography.Title
									className={styles.date}
									level={3}
								>
									{estimatedArrivalDate != null ?
										(<PrettyDate date={estimatedArrivalDate} format={selectedTimeFormat} />) :
										'N/A'}
								</Typography.Title>
							</Card>
						)}
						<Card className={styles.card}>
							<Typography.Text type="secondary">
								ETD
								{departureOffsetLabel != null && selectedTimeFormat === 'localTime' ? ` (${departureOffsetLabel.name})` : ' (UTC)'}
							</Typography.Text>
							<Typography.Title
								className={styles.date}
								level={3}
							>
								{latestEstimatedDate == null ? (
									<EditableText
										defaultValue={estimatedDepartureDate}
										placeholder={(<EmptyText />)}
										type="date"
										inputProps={{
											time: true,
											showTimezone: true,
											defaultUtcOffset: defaultPortTzOffset,
											forceUtcUpdate: true,
										}}
										onChange={(value: Moment) => updateEntry({ estimatedDepartureDate: value })}
										renderValue={
											(d: Moment) => (<PrettyDate date={d} format={selectedTimeFormat} />)
										}
										options={null}
									/>
								) : (<PrettyDate date={estimatedDepartureDate!} format={selectedTimeFormat} />)}
							</Typography.Title>
						</Card>
					</Space>
					<Space
						align="start"
						direction={smallScreen ? 'vertical' : 'horizontal'}
					>
						<Descriptions
							bordered
							size="small"
							column={1}
							className={styles.details}
						>
							{isNotCommencement && (
								<>
									<Descriptions.Item
										key="arrivalDate"
										label="Actual Arrival Date"
									>
										<EditableField
											item={{
												editable: true,
												key: 'arrivalDate',
												label: 'Actual Arrival Date',
												type: 'date',
												value: arrivalDate,
												inputProps: {
													disabled: !isPreviousActual &&
														selectedEntry.type !== PortCallTypes.DELIVERY,
													allowClear: true,
													time: true,
													showTimezone: true,
													defaultUtcOffset: defaultPortTzOffset,
													forceUtcUpdate: true,
													staticTimezonePicker: true,
													className: styles.datePicker,
												},
											}}
											editChange={(key, newValue) => updateEntry({ [key]: newValue })}
											editing
										/>
									</Descriptions.Item>
									<Descriptions.Item
										key="mastersEta"
										label="Masters ETA"
									>
										<EditableField
											item={{
												editable: true,
												key: 'mastersEta',
												label: 'Masters ETA',
												type: 'date',
												value: mastersEta,
												inputProps: {
													disabled: !isPreviousActual,
													allowClear: true,
													time: true,
													showTimezone: true,
													defaultUtcOffset: defaultPortTzOffset,
													forceUtcUpdate: true,
													staticTimezonePicker: true,
													className: styles.datePicker,
												},
											}}
											editChange={(key, newValue) => updateEntry({ [key]: newValue })}
											editing
										/>
									</Descriptions.Item>
								</>
							)}
							<Descriptions.Item
								key="departureDate"
								label={(() => {
									if (selectedEntry.type === PortCallTypes.DELIVERY) {
										return 'Actual Delivery Date';
									}

									if (selectedEntry.type === PortCallTypes.COMMENCEMENT) {
										return 'Commencement Date';
									}

									return 'Actual Departure Date';
								})()}
							>
								<EditableField
									item={{
										editable: true,
										key: 'departureDate',
										label: 'Actual Departure Date',
										type: 'date',
										inputProps: {
											disabled: arrivalDate == null && isNotCommencement,
											forceUtcUpdate: true,
											minDate: arrivalDate ?? undefined,
											allowClear: true,
											time: true,
											showTimezone: true,
											defaultUtcOffset: defaultPortTzOffset,
											staticTimezonePicker: true,
											className: styles.datePicker,
											defaultValue: departureDate,
										},
										value: departureDate,
									}}
									editChange={(key, newValue) => updateEntry({ [key]: newValue })}
									editing
								/>
							</Descriptions.Item>
							{isNotCommencement && (
								<>
									<Descriptions.Item
										key="requiredArrivalDate"
										label="Required Arrival Date"
									>
										<EditableField
											item={{
												editable: true,
												key: 'requiredArrivalDate',
												label: 'Required Arrival Date',
												type: 'date',
												value: requiredArrivalDate,
												inputProps: {
													minDate: isPreviousActual ?
														(latestActualDate ?? undefined) :
														(latestEstimatedDate ?? undefined),
													forceUtcUpdate: true,
													time: true,
													defaultUtcOffset: defaultPortTzOffset,
													showTimezone: true,
													staticTimezonePicker: true,
													className: styles.datePicker,
												},
											}}
											editChange={(key, newValue) => updateEntry({ [key]: newValue })}
											editing
										/>
									</Descriptions.Item>
									<Descriptions.Item
										key="requiredSpeed"
										label="Required Speed"
									>
										{requiredSpeedContent}
									</Descriptions.Item>

								</>
							)}
						</Descriptions>
						{isNotCommencement && (
							<Descriptions
								bordered
								size="small"
								column={1}
								className={styles.estimatedDetails}
							>
								<Descriptions.Item
									key="estimatedIdleDays"
									label="Est. idle days"
								>
									<EditableField
										item={{
											editable: true,
											key: 'estimatedIdleDays',
											label: 'Est. idle days',
											type: 'number',
											value: estimatedIdleDays,
											inputProps: {
												className: styles.portDaysInput,
											},
										}}
										editChange={(key, newValue) => updateEntry({ [key]: newValue })}
										editing
									/>
								</Descriptions.Item>
								<Descriptions.Item
									key="estimatedWorkingDays"
									label="Est. working days"
								>
									<EditableField
										item={{
											editable: true,
											key: 'estimatedWorkingDays',
											label: 'Est. working days',
											type: 'number',
											value: estimatedWorkingDays,
											inputProps: {
												className: styles.portDaysInput,
											},
										}}
										editChange={(key, newValue) => updateEntry({ [key]: newValue })}
										editing
									/>
								</Descriptions.Item>
								<Descriptions.Item
									key="estimatedTurningDays"
									label="Est. turn days"
								>
									<EditableField
										item={{
											editable: true,
											key: 'estimatedTurningDays',
											label: 'Est. turn days',
											type: 'number',
											value: estimatedTurningDays,
											inputProps: {
												className: styles.portDaysInput,
											},
										}}
										editChange={(key, newValue) => updateEntry({ [key]: newValue })}
										editing
									/>
								</Descriptions.Item>
								<Descriptions.Item
									key="estimatedTimeInPort"
									label="Est. time in port"
								>
									{estimatedTimeInPortContent}
								</Descriptions.Item>
								<Descriptions.Item
									key="estimatedConsumptions"
									label={(
										<Space
											direction="vertical"
											size={0}
										>
											<p className={styles.consumptionsLabel}>Est. consumption</p>
											<p className={styles.sulfurLabel}>
												Max sulfur:
												{selectedEntry.port.maxSulfur != null ?
													` ${selectedEntry.port.maxSulfur}% - ECA` :
													' N/A'}
											</p>
										</Space>
									)}
								>
									{estimatedConsumptionsContent}
								</Descriptions.Item>
							</Descriptions>
						)}
					</Space>
				</Space>
				<Divider>Bunkers</Divider>
				<Col span={24}>
					<PortCallBunkersSection
						selectedEntry={selectedEntry as ItineraryPortCallDto}
						voyageDetails={voyageDetails}
					/>
				</Col>
			</Row>
			<br />
		</>
	);
};

export default PortCallSummaryTab;
