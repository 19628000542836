import React, {
	useState,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import { EditOutlined } from '@ant-design/icons';
import Button, { ButtonProps } from '@client/components/Button';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import Details from '@client/components/Details';
import showErrorNotification from '@client/utils/showErrorNotification';
import EditableField, { EditableDetailsItem } from '@client/components/EditableField';
import styles from './EditableDetails.module.css';

type EditableDetailsProps<Values extends object> = {
	items: EditableDetailsItem<Partial<Values>>[];
	onSave?: (values: Partial<Values>) => void | Promise<void>;
	hideHeader?: boolean;
	editValues?: Partial<Values>;
	editing?: boolean;
	title?: string;
	onEditValuesChange?: (values: Partial<Values>) => Promise<void> | void;
	onStartEditing?: () => void;
	onCancel?: () => void;
	saveButtonProps?: ButtonProps;
	cancelButtonProps?: ButtonProps;
	editButtonProps?: ButtonProps;
	className?: string;
	labelWidth?: number;
	confirmTitle?: string | React.ReactNode;
};

const EditableDetails = <Values extends object, >({
	items,
	hideHeader,
	editValues: editValuesProp,
	editing: editingProp,
	title,
	onEditValuesChange,
	onStartEditing,
	onSave,
	onCancel,
	saveButtonProps = {},
	cancelButtonProps = {},
	editButtonProps = {},
	confirmTitle,
	className,
	...props
}: EditableDetailsProps<Values>) => {
	const timing = useRef<number>();

	const [editingState, setEditing] = useState(false);
	const [editValuesState, setEditValuesState] = useState<Partial<Values>>({});

	const editing = editingProp != null ? editingProp : editingState;

	const editValues = editValuesProp || editValuesState;
	const setEditValues = onEditValuesChange || setEditValuesState;

	const { useBlocker } = useNavigationBlock();

	useBlocker(editing && Object.keys(editValues).length > 0);

	const values = useMemo(() => ({
		...items.reduce((obj, item) => ({
			...obj,
			[item.key]: item.value,
		}), {}),
		...editValues,
	}), [items, editValues]);

	// For tracking purposes, set the time a user starts editing something
	useEffect(() => {
		if (editing) {
			timing.current = Date.now();
		}
	}, [editing]);

	const editChange = useCallback(async (fieldName: string, value: string) => {
		return await setEditValues({
			...editValues,
			[fieldName]: value,
		});
	}, [editValues, setEditValues]);

	const saveEdit = useCallback(async () => {
		try {
			if (Object.keys(editValues).length > 0 && typeof onSave === 'function') {
				await onSave(editValues);
			} else if (typeof onSave === 'function') {
				await onSave({});
			}

			setEditing(false);
			await setEditValues({});

			if (typeof onCancel === 'function') {
				onCancel();
			}
		} catch (e) {
			showErrorNotification('Could not save', e as Error);
		}
	}, [onCancel, editValues, setEditValues, onSave]);

	const cancelEdit = useCallback(() => {
		setEditValues({});
		setEditing(false);

		if (typeof onCancel === 'function') {
			onCancel();
		}
	}, [onCancel, setEditValues]);

	// On unmount, auto save if currently editing
	useEffect(() => () => {
		if (editing && Object.keys(editValues).length > 0) {
			saveEdit();
		}

		// Only want to run on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let headerButtons;

	if (editing) {
		headerButtons = [
			(
				<Button
					key="cancel-button"
					onClick={cancelEdit}
					{...cancelButtonProps}
				>
					Cancel
				</Button>
			),
			(
				<Button
					key="save-button"
					type="primary"
					onClick={saveEdit}
					className={styles.saveButton}
					{...saveButtonProps}
					confirmTitle={confirmTitle}
				>
					Save
				</Button>
			),
		];
	} else {
		headerButtons = [
			(
				<Button
					key="edit-button"
					type="link"
					onClick={() => {
						setEditing(true);

						if (typeof onStartEditing === 'function') {
							onStartEditing();
						}
					}}
					icon={(
						<EditOutlined
							className={styles.detailsIcon}
						/>
					)}
					{...editButtonProps}
				/>
			),
		];
	}

	const detailsItems = items.filter((i) => (
		i.show !== false &&
		!(typeof i.show === 'function' && !i.show(values, editing))
	)).map((item) => ({
		...item,
		key: item.key,
		label: item.label,
		value: (
			<EditableField<Partial<Values>>
				item={item}
				editChange={editChange}
				editValues={editValues}
				editing={editing}
			/>
		),
	}));

	return (
		<Details
			items={detailsItems}
			title={title}
			hideHeader={hideHeader}
			extra={headerButtons}
			{...props}
			className={className}
		/>
	);
};

export default EditableDetails;
