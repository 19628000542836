import React from 'react';
import { useParams } from 'react-router-dom';
import {
	Card,
	Col,
	Row,
} from 'antd';
import { FixtureTypes } from '@shared/utils/constants';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import {
	getFixtureDetails,
	getVoyageDetails,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import TcContractDetailsScreen from './TcContractDetailsScreen';
import VcContractDetailsScreen from './VcContractDetailsScreen';

const ContractDetailsScreen = () => {
	const { id } = useParams<{ id: string }>();
	const [
		voyageDetails,
		refreshVoyageDetails,
		_voyageDetailsError,
		loadingVoyageDetails,
	] = useFetchedState(
		async () => {
			const details = await getVoyageDetails(Number(id));

			return details;
		}, [id],
		{ showNotification: false },
	);

	const [
		fixtureDetails,
		refreshFixtureDetails,
		_fixtureDetailsError,
		loadingFixtureDetails,
	] = useFetchedState(async () => (
		voyageDetails != null ?
			getFixtureDetails(voyageDetails.fixtureId) :
			null
	), [voyageDetails]);

	const fixtureCurrency = voyageDetails?.bankAccount.currency;

	if (loadingVoyageDetails || loadingFixtureDetails) {
		return (
			<Row gutter={[16, 16]} align="middle" justify="center">
				<Col span={12}>
					<div style={{ padding: 16 }}>
						<Card title="Loading">
							Contract is loading
							<br />
							Please wait
						</Card>
					</div>
				</Col>
			</Row>
		);
	}

	if (voyageDetails == null || fixtureDetails == null || fixtureCurrency == null) {
		return (
			<Row gutter={[16, 16]} align="middle" justify="center">
				<Col span={12}>
					<div style={{ padding: 16 }}>
						<Card title="Error">
							This contract has no voyage or fixture details.
							<br />
							Please contact support
						</Card>
					</div>
				</Col>
			</Row>
		);
	}

	if (fixtureDetails.type === FixtureTypes.SPOT) {
		return (
			<VcContractDetailsScreen
				voyageDetails={voyageDetails}
				refreshFixtureDetails={refreshFixtureDetails}
				refreshVoyageDetails={refreshVoyageDetails}
				fixtureDetails={fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>}
				currency={fixtureCurrency}
			/>
		);
	}

	return (
		<TcContractDetailsScreen
			voyageDetails={voyageDetails}
			refreshFixtureDetails={refreshFixtureDetails}
			refreshVoyageDetails={refreshVoyageDetails}
			fixtureDetails={fixtureDetails as GetFixtureDetailsResponse<TcFixtureProps>}
			currency={fixtureCurrency}
		/>
	);
};

export default ContractDetailsScreen;
