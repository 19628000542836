// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gdJQ2GfX_Pqrbhg8fL8H {\n\tbackground-color: #fff;\n\tmargin: 0;\n\tpadding: 1em;\n\tdisplay: flex;\n\tborder-radius: 0.25em;\n}\n\n.gdJQ2GfX_Pqrbhg8fL8H p {\n\tmargin: 0;\n\tpadding: 0;\n}\n\n.Vq8kGeedFIEHAjXYhtl5 {\n\tcolor: #8cb972;\n}\n\n.fSRp2XKTatHMq33m0M8D {\n\tcolor: #ff7875;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/general/HomeScreen/components/styles/PnlWaterfallWidget.module.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,SAAS;CACT,YAAY;CACZ,aAAa;CACb,qBAAqB;AACtB;;AAEA;CACC,SAAS;CACT,UAAU;AACX;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,cAAc;AACf","sourcesContent":[".customTooltip {\n\tbackground-color: #fff;\n\tmargin: 0;\n\tpadding: 1em;\n\tdisplay: flex;\n\tborder-radius: 0.25em;\n}\n\n.customTooltip p {\n\tmargin: 0;\n\tpadding: 0;\n}\n\n.positive {\n\tcolor: #8cb972;\n}\n\n.negative {\n\tcolor: #ff7875;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customTooltip": "gdJQ2GfX_Pqrbhg8fL8H",
	"positive": "Vq8kGeedFIEHAjXYhtl5",
	"negative": "fSRp2XKTatHMq33m0M8D"
};
export default ___CSS_LOADER_EXPORT___;
