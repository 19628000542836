import { DATE_AND_TIME } from '@shared/utils/constants';
import { formatDate } from '@client/utils/formatDate';

const getItems = ({
	name,
	address,
	email,
	phoneNumber,
	createdAt,
	updatedAt,
}) => [
	{
		key: 'name',
		label: 'Name',
		value: name,
		editable: true,
	},
	{
		key: 'address',
		label: 'Address',
		value: address,
		editable: true,
	},
	{
		key: 'email',
		label: 'Email',
		value: email,
		editable: true,
	},
	{
		key: 'phoneNumber',
		label: 'Phone number',
		value: phoneNumber,
		editable: true,
	},
	{
		key: 'createdAt',
		label: 'Creation date',
		value: formatDate(createdAt),
	},
	{
		key: 'updatedAt',
		label: 'Last updated',
		value: formatDate(updatedAt, DATE_AND_TIME),
	},
];

export default getItems;
