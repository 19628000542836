import React, {
	useEffect,
	useState,
} from 'react';
import {
	Alert,
	Col,
	Row,
	Typography,
} from 'antd';
import {
	CrewReportTypes,
	FuelTypes,
	PortActionTypes,
	PortCallTypes,
	VcContractCompletionTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { calculateTotal } from '@shared/utils/math';
import { formatCurrency } from '@shared/utils/currency';
import { formatNumber } from '@shared/utils/formatNumber';
import type { ItineraryPortCallDto } from '@api/features/ops/getVesselItinerary';
import type { GetRobsFromPortCallResponse } from '@api/features/vessels/getRobsFromPortCall';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	deleteRobBunker,
	getRobsFromPortCall,
	getVoyageCompletionType,
	updateRob,
} from '@client/lib/api';
import EditableTable from '@client/components/EditableTable';
import Card from '@client/components/Card/Card';
import Table from '@client/components/Table/Table';
import styles from './styles/PortCallBunkersTab.module.css';

type NullableItineraryBunkerRecord = {
	id?: number;
	robId: number;
	quantity: number;
	fuelGrade: Values<typeof FuelTypes>;
	pricePerTon?: number;
	event: Values<typeof CrewReportTypes>;
}

type AcceptedEventTypes =
	typeof CrewReportTypes.ARRIVAL |
	typeof CrewReportTypes.DEPARTURE |
	typeof CrewReportTypes.COMMENCEMENT |
	typeof CrewReportTypes.COMPLETION |
	typeof CrewReportTypes.DELIVERY;

type LocalRob = Record<AcceptedEventTypes, Array<NullableItineraryBunkerRecord>>

const extractSpecificRobs = (
	robs: GetRobsFromPortCallResponse,
	event: Values<typeof CrewReportTypes>,
) => {
	const filteredRobs = robs.filter((r) => r.event === event)?.[0];

	if (filteredRobs == null) {
		return [];
	}

	return filteredRobs.RobBunkers
		.map((r) => ({
			id: r.id,
			robId: filteredRobs.id,
			quantity: calculateTotal(r.fuelQueue, (o) => o.quantity),
			fuelGrade: r.fuelGrade,
			pricePerTon: r?.fuelQueue[0]?.pricePerTon ?? 0,
			event: filteredRobs.event,
		}))
		.sort((a, b) => a.fuelGrade.localeCompare(b.fuelGrade));
};

export const PortCallBunkersSection = ({
	selectedEntry,
	voyageDetails,
}: {
	selectedEntry: ItineraryPortCallDto;
	voyageDetails: GetVoyageDetailsResponse | null | undefined;
}) => {
	const [localRobs, setLocalRobs] = useState<LocalRob>({
		[CrewReportTypes.ARRIVAL]: [],
		[CrewReportTypes.DEPARTURE]: [],
		[CrewReportTypes.COMPLETION]: [],
		[CrewReportTypes.COMMENCEMENT]: [],
		[CrewReportTypes.DELIVERY]: [],
	});

	const [fetchedRobs, refreshFetchedRobs] = useFetchedState(
		() => getRobsFromPortCall(selectedEntry.vesselId, selectedEntry.id), [selectedEntry],
	);

	const [voyageCompletion] = useFetchedState(
		async () => {
			if (selectedEntry.voyageId != null) {
				return await getVoyageCompletionType(selectedEntry.voyageId);
			}

			return null;
		}, [selectedEntry.voyageId],
	);

	const isCompletion = selectedEntry.type === PortCallTypes.COMPLETION;
	const isRedelivery = selectedEntry.actions
		.find((a) => a.action === PortActionTypes.REDELIVERING) != null;
	const isDelivery = selectedEntry.type === PortCallTypes.DELIVERY ||
		selectedEntry.actions.find((a) => a.action === PortActionTypes.DELIVERING) != null;
	const fixtureCurrency = voyageDetails?.bankAccount.currency ?? 'USD';

	useEffect(() => {
		if (fetchedRobs != null) {
			const relevantRobs = fetchedRobs.filter((r) => r.portCallId === selectedEntry.id);

			if (relevantRobs == null) {
				return;
			}

			setLocalRobs({
				[CrewReportTypes.ARRIVAL]: extractSpecificRobs(relevantRobs, CrewReportTypes.ARRIVAL),
				[CrewReportTypes.DEPARTURE]: extractSpecificRobs(relevantRobs, CrewReportTypes.DEPARTURE),
				[CrewReportTypes.COMPLETION]: extractSpecificRobs(relevantRobs, CrewReportTypes.COMPLETION),
				[CrewReportTypes.COMMENCEMENT]: extractSpecificRobs(
					relevantRobs,
					CrewReportTypes.COMMENCEMENT,
				),
				[CrewReportTypes.DELIVERY]: extractSpecificRobs(relevantRobs, CrewReportTypes.DELIVERY),
			});
		}
	}, [fetchedRobs, selectedEntry]);

	const onAddNewBunkerRob = async (
		data: NullableItineraryBunkerRecord,
		event: Values<typeof CrewReportTypes>,
	) => {
		const relevantRob = fetchedRobs?.find((r) => r.event === event);

		if (relevantRob != null && voyageDetails != null) {
			await updateRob({
				vesselId: selectedEntry.vesselId,
				voyageId: voyageDetails.id,
				robId: relevantRob.id,
				currency: fixtureCurrency,
				attributes: {
					remainingOnBoard: [{
						fuelGrade: data.fuelGrade || FuelTypes.VLSFO,
						quantity: data.quantity || 0,
						pricePerTon: data.pricePerTon || 0,
					}],
				},
				deleteMissingEntries: false,
			});
			refreshFetchedRobs();
		}
	};

	const onSave = async (id: number | undefined, rob: NullableItineraryBunkerRecord) => {
		if (id == null || voyageDetails?.id == null) {
			return;
		}

		const parentRob = fetchedRobs?.find((ro) => ro.RobBunkers.find((rb) => rb.id === id) != null);

		if (parentRob == null) {
			return;
		}

		await updateRob({
			vesselId: selectedEntry.vesselId,
			voyageId: voyageDetails.id,
			robId: parentRob.id,
			currency: fixtureCurrency,
			attributes: {
				remainingOnBoard: {
					id,
					quantity: rob.quantity,
					fuelGrade: rob.fuelGrade,
					pricePerTon: rob.pricePerTon ?? 0,
				},
			},
		});

		refreshFetchedRobs();
	};

	const onDeleteRob = async (id: number | undefined) => {
		if (id == null || voyageDetails?.vesselId == null) {
			return;
		}

		await deleteRobBunker(id, voyageDetails?.vesselId);
		refreshFetchedRobs();
	};

	// Sorting the fuel grades in the order of FuelTypes
	const fuelTypeOrder = Object.keys(FuelTypes) as Array<keyof typeof FuelTypes>;

	const compareFuelTypes = (a: keyof typeof FuelTypes,
		b: keyof typeof FuelTypes) => fuelTypeOrder.indexOf(a) - fuelTypeOrder.indexOf(b);

	const sortLocalRobsByFuelGrade = (allLocalRobs: LocalRob) => {
		Object.keys(allLocalRobs).forEach((event) => {
			const key = event as keyof LocalRob;
			allLocalRobs[key] = allLocalRobs[key].sort((a, b) => compareFuelTypes(
				a.fuelGrade, b.fuelGrade,
			));
		});

		return allLocalRobs;
	};

	const sortedLocalRobs = sortLocalRobsByFuelGrade(localRobs);

	const getColumns = (showPricePerTon?: boolean) => [
		{
			title: 'Fuel grade',
			dataIndex: 'fuelGrade',
			editable: voyageDetails?.linkedTcInVoyageId == null,
			editingProps: {
				type: 'select',
				inputProps: {
					defaultActiveFirstOption: true,
					options: Object.keys(FuelTypes).map((key) => ({
						label: FuelTypes[key],
						value: key,
					})),
				},
			},
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			editable: true,
			align: 'right',
			editingProps: {
				type: 'number',
				inputProps: {
					addonAfter: 'MT',
				},
			},
			render: (q: number) => `${formatNumber(q, { separateThousands: true })} MT`,
		},
		...(showPricePerTon ? ([{
			dataIndex: 'pricePerTon',
			title: 'Price / MT',
			editable: true,
			render: (c: any, record: any) => {
				const price = c;

				if (c == null || Number.isNaN(c)) {
					return 0;
				}

				return `${formatCurrency(price, record?.currency ?? 'USD')}`;
			},
		}]) : []),
	];

	if (selectedEntry.type === PortCallTypes.COMMENCEMENT) {
		return (
			<Card slim className={styles.cardTable}>
				<EditableTable<NullableItineraryBunkerRecord, 'id'>
					dataSource={sortedLocalRobs.Commencement}
					addNewText="Add entry"
					pagination={false}
					onSave={onSave}
					emptyText="Empty"
					// @ts-ignore
					columns={getColumns(true)}
					keyDataIndex="id"
					allowAddNew
					onAddNew={(data) => onAddNewBunkerRob(data, CrewReportTypes.COMMENCEMENT)}
					enableDelete={() => true}
					onDelete={onDeleteRob}
					iconButtons
					actionsTitle=""
					addButtonInHeader={false}
				/>
			</Card>
		);
	}

	if (
		isCompletion &&
		voyageCompletion?.completionType === VcContractCompletionTypes.TC_IN_DELIVERY
	) {
		return (
			<Card
				slim
				className={styles.cardTable}
			>
				<Table
					dataSource={
						(voyageCompletion.linkedTcInVoyageBunkers ?? []).filter((r) => r.type === 'redelivery')
							.map((b) => ({
								quantity: b.quantity,
								fuelGrade: b.fuelGrade,
								pricePerTon: b.pricePerTon,
							}))
					}
					// @ts-ignore
					columns={getColumns()}
					pagination={false}
				/>
			</Card>
		);
	}

	return (
		<Row gutter={[16, 8]}>
			{(!isDelivery && !isRedelivery) && (
				<Col xs={24} xl={12}>
					<Typography.Title level={4}>Arrival</Typography.Title>
					{(selectedEntry.arrivalDate == null) && (
						<>
							<Alert message="NOTE: You must enter an arrival date if you wish to enter R.O.B entries for arrival" type="info" />
							<br />
						</>
					)}
					{
						(selectedEntry.arrivalDate != null) ?
							(
								<Card slim className={styles.cardTable}>
									<EditableTable<NullableItineraryBunkerRecord, 'id'>
										dataSource={localRobs.Arrival}
										addNewText="Add entry"
										pagination={false}
										onSave={onSave}
										emptyText="Empty"
										// @ts-ignore
										columns={getColumns()}
										keyDataIndex="id"
										allowAddNew={
											selectedEntry.arrivalDate != null &&
										voyageDetails?.linkedTcInVoyageId == null
										}
										onAddNew={
											async (data) => await onAddNewBunkerRob(data, CrewReportTypes.ARRIVAL)
										}
										enableDelete={() => voyageDetails?.linkedTcInVoyageId == null}
										onDelete={onDeleteRob}
										iconButtons
										actionsTitle=""
										addButtonInHeader={false}
									/>
								</Card>
							) : null
					}
				</Col>
			)}
			{(
				<Col xs={24} xl={12}>
					<Typography.Title level={4}>
						{(() => {
							if (isRedelivery) {
								return 'Redelivery';
							}

							if (selectedEntry.type === PortCallTypes.DELIVERY) {
								return 'Delivery';
							}

							if (isCompletion) {
								return 'Completion';
							}

							return 'Departure';
						})()}
					</Typography.Title>
					{((!isCompletion && !isDelivery && !isRedelivery) &&
						selectedEntry.departureDate == null) && (
						<>
							<Alert message="NOTE: You must enter a departure date if you wish to enter R.O.B entries for departure" type="info" />
							<br />
						</>
					)}
					{(isCompletion && selectedEntry.departureDate == null) && (
						<>
							<Alert message={`NOTE: You must enter a departure date for ${selectedEntry.port.name} if you wish to enter completion R.O.B entries`} type="info" />
							<br />
						</>
					)}
					{selectedEntry.type === PortCallTypes.DELIVERY && (
						<>
							<Alert
								message="Any changes to delivery bunkers should be made from the Expenses section, under Bunkers"
								type="info"
							/>
							<br />
						</>
					)}
					{(selectedEntry.departureDate != null || isDelivery) ? (
						<Card slim className={styles.cardTable}>
							<EditableTable<NullableItineraryBunkerRecord, 'id'>
								dataSource={(() => {
									if (isCompletion) {
										return localRobs.Completion;
									}

									if (selectedEntry.type === PortCallTypes.DELIVERY) {
										return localRobs.Delivery;
									}

									return localRobs.Departure;
								})()}
								addNewText="Add entry"
								pagination={false}
								onSave={onSave}
								emptyText="Empty"
								// @ts-ignore
								columns={getColumns()}
								keyDataIndex="id"
								allowAddNew={
									selectedEntry.departureDate != null &&
									voyageDetails?.linkedTcInVoyageId == null &&
									selectedEntry.type !== PortCallTypes.DELIVERY
								}
								onAddNew={async (data) => {
									const event = isCompletion ?
										CrewReportTypes.COMPLETION :
										CrewReportTypes.DEPARTURE;
									await onAddNewBunkerRob(data, event);
								}}
								enableDelete={() => voyageDetails?.linkedTcInVoyageId == null && !isDelivery}
								enableEdit={() => !isDelivery}
								onDelete={onDeleteRob}
								iconButtons
								actionsTitle=""
								addButtonInHeader={false}
							/>
						</Card>
					) : null}
				</Col>
			)}
		</Row>
	);
};
