import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Empty,
	Row,
} from 'antd';
import debounce from 'lodash.debounce';
import { Moment } from 'moment';
import { Link } from 'react-router-dom';
import {
	CrewReportTypes,
	Currencies,
	FuelTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { VesselProps } from '@api/models/vessel';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { BunkerProps } from '@api/models/bunker';
import type { Port } from '@api/utils/ports';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { RobBunkerProps } from '@api/models/rob-bunker';
import Card from '@client/components/Card/Card';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	deleteRob,
	getAccrualItems,
	getRobs,
	updateRob,
	updateVoyage,
} from '@client/lib/api';
import Table from '@client/components/Table/Table';
import AddButton from '@client/components/AddButton';
import showErrorNotification from '@client/utils/showErrorNotification';
import { Links } from '@client/utils/links';
import { getRobTableColumns } from '@client/screens/fleet/VoyageDetailsScreen/tabs/BunkerExpenditureTab/getRobTableColumns';
import { getRobTableRows } from '@client/screens/fleet/VoyageDetailsScreen/tabs/BunkerExpenditureTab/getRobTableRows';
import SummaryCardExtra from '@client/screens/fleet/VoyageDetailsScreen/components/SummaryCardExtra';
import LinkedContracts from '@client/components/LinkedContracts/LinkedContracts';
import RobEntryDrawer from '../components/RobEntryDrawer';
import styles from './BunkerExpenditureTab.module.css';

export type AdjustBunkerAmountAttributes = {
	robBunkerId: number;
	robId: number;
	adjustment: number;
	bunkerId?: number;
}

export type EditValues = {
	event?: Values<typeof CrewReportTypes>;
	port?: Port | null;
	date?: Moment;
	quantity?: number;
	fuelGrade?: Values<typeof FuelTypes>;
	robs?: Array<RobBunkerProps>;
	robBunkerId?: number;
	pricePerTon?: number;
	allowSetPrice?: boolean;
	bunkerId?: number;
	robId?: number;
	VesselBunkers?: Array<{
		quantity?: number;
		fuelGrade?: Values<typeof FuelTypes>;
		pricePerTon?: number;
		Bunker: BunkerProps;
	}>;
}

const BunkerExpenditureTab = ({
	voyageDetails,
	refreshVoyageDetails,
	vessel,
	fixtureDetails,
}: {
	voyageDetails: GetVoyageDetailsResponse;
	refreshVoyageDetails: () => void;
	vessel: VesselProps;
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps | SpotFixtureProps>;
}) => {
	const fixtureCurrency = voyageDetails == null ?
		Currencies.USD :
		voyageDetails.bankAccount.currency;

	const [robDrawerOpen, setRobDrawerOpen] = useState(false);
	const [editing, setEditing] = useState <null | EditValues>(null);

	const [bunkerRobs, refreshBunkerRobs] = useFetchedState(async () => (
		vessel?.id != null ? getRobs(vessel.id, voyageDetails.id) : []
	), [vessel]);

	const [accruals] = useFetchedState(getAccrualItems);

	const updateVoyageField = useCallback(async (
		field: string,
		value: any,
		contractId?: number | null,
	) => {
		try {
			await updateVoyage(contractId ?? voyageDetails.id, { [field]: value });
			await refreshVoyageDetails();
		} catch (e) {
			if (value !== undefined) {
				showErrorNotification('Could not update contract', e as Error);
			}
		}
	}, [refreshVoyageDetails, voyageDetails.id]);

	const robs = useMemo(() => (bunkerRobs == null ? [] : bunkerRobs), [bunkerRobs]);

	const onDeleteRob = useCallback(async (id: number) => {
		await deleteRob(vessel.id, id);
		await refreshBunkerRobs();
	}, [vessel.id, refreshBunkerRobs]);

	const onSetAdjustment = useCallback(async ({
		robId,
		robBunkerId,
		bunkerId,
		adjustment,
	}: AdjustBunkerAmountAttributes) => {
		const relevantRob = robs.find((r) => r.id === robId);
		const relevantRobBunker = relevantRob?.RobBunkers.find((rb) => rb.id === robBunkerId);
		await updateRob({
			voyageId: voyageDetails.id,
			vesselId: vessel.id,
			robId,
			robBunkerId,
			bunkerId,
			attributes: {
				remainingOnBoard: {
					...relevantRobBunker,
					adjustment,
				},
			},
		});

		await refreshBunkerRobs();
	}, [robs, voyageDetails.id, vessel.id, refreshBunkerRobs]);

	const debouncedOnChange = useMemo(() => debounce(({
		robId,
		robBunkerId,
		bunkerId,
		adjustment,
	}: AdjustBunkerAmountAttributes) => onSetAdjustment({
		robId,
		robBunkerId,
		bunkerId,
		adjustment,
	}), 700), [onSetAdjustment]);

	const blockBunkersTab = voyageDetails.commencementDate == null;

	const columns = useMemo(
		() => getRobTableColumns({
			robs,
			accruals: accruals ?? [],
			onDeleteRob,
			setEditing,
			setRobDrawerOpen,
		}),
		[accruals, onDeleteRob, robs],
	);

	const rows = useMemo(() => getRobTableRows({
		robs,
		voyageDetails,
		setEditing,
		onChange: debouncedOnChange,
		fixtureCurrency,
	}), [debouncedOnChange, fixtureCurrency, robs, voyageDetails]);

	const firstRob = robs?.[0];
	const acceptableFuels = firstRob?.RobBunkers.map((rb) => rb.fuelGrade);

	const emptyContent = useMemo(() => {
		let detailedText = (
			<>
				To get started, set the actual commencement date
				by connecting this contract to a previous contract.
			</>
		);

		const { previousVoyage } = voyageDetails;

		if (previousVoyage != null && previousVoyage.id !== -1) {
			detailedText = (
				<>
					You still need to add an actual completion date for
					{' '}
					<Link to={Links.Voyage.get(previousVoyage.id)}>
						{previousVoyage.identifier}
					</Link>
				</>
			);
		}

		return (
			<>
				<Col span={24}>
					You cannot edit bunkers at this time.
					<br />
					{detailedText}
				</Col>
				<Col span={24}>
					<SummaryCardExtra
						voyageDetails={voyageDetails}
						fixtureDetails={fixtureDetails}
						updateVoyageField={updateVoyageField}
					/>
				</Col>
			</>
		);
	}, [fixtureDetails, updateVoyageField, voyageDetails]);

	if (blockBunkersTab) {
		return (
			<div className={styles.emptyWrapper}>
				<Card>
					<Empty
						className={styles.emptyMessage}
						description={(
							<Row gutter={[16, 16]}>
								{emptyContent}
							</Row>
						)}
					/>
				</Card>
			</div>
		);
	}

	return (
		<>
			<RobEntryDrawer
				setRobDrawerOpen={setRobDrawerOpen}
				open={robDrawerOpen}
				fixtureCurrency={fixtureCurrency}
				refreshData={() => {
					refreshBunkerRobs();
				}}
				vesselId={vessel.id}
				editing={editing}
				setEditing={setEditing}
				voyageDetails={voyageDetails}
				acceptableFuels={acceptableFuels}
			/>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					{robs.length === 0 ? (
						<Card className={styles.center}>
							<b>We could not find any events from the ship, with ROB information.</b>
							<br />
							Please either create them manually, or start using the ClearVoyage Crew App,
							to see your bunker expenditure table
							<br />
							<br />
							<AddButton
								onClick={() => setRobDrawerOpen(true)}
							>
								Add new
							</AddButton>
						</Card>
					) : (
						<Card slim>
							<Table
								className={styles.robTable}
								bordered
								pagination={false}
								dataSource={rows}
								columns={columns}
								expandable={{ defaultExpandAllRows: true }}
							/>
						</Card>
					)}
				</Col>
				<Col span={24}>
					<LinkedContracts
						voyageDetails={voyageDetails}
					/>
				</Col>
			</Row>
		</>
	);
};

export default BunkerExpenditureTab;
