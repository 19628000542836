// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DKCWiawc5KjkcyPIY7hm {\n\tmargin-bottom: 20px;\n}\n\n.jukPmbUDXZYPyLZvmiN7 {\n\tmargin-left: 5px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/general/HomeScreen/dashboards/OperationsDashboard.module.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".alert {\n\tmargin-bottom: 20px;\n}\n\n.icon {\n\tmargin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "DKCWiawc5KjkcyPIY7hm",
	"icon": "jukPmbUDXZYPyLZvmiN7"
};
export default ___CSS_LOADER_EXPORT___;
