import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Space,
	Switch,
	Card,
	Form,
} from 'antd';
import {
	ExportOutlined,
	MinusSquareOutlined,
	PlusSquareOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import { CSVLink } from 'react-csv';
import { ColumnsType } from 'antd/es/table';
import groupInvoiceItems from '@shared/utils/groupInvoiceItems';
import HireInvoiceDifference from '@shared/hireInvoice/HireInvoiceDifference';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import HIITotal from '@shared/hireInvoice/HIITotal';
import { calculateTotal } from '@shared/utils/math';
import { formatCurrency } from '@shared/utils/currency';
import { Currencies } from '@shared/utils/constants';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import { formatDate } from '@client/utils/formatDate';
import showErrorNotification from '@client/utils/showErrorNotification';
import {
	getVoyageCumulative,
	getVoyageInvoices,
} from '@client/lib/api';
import Button from '@client/components/Button';
import ErrorMessage from '@client/components/ErrorMessage';
import LoadingIndicator from '@client/components/LoadingIndicator';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import getHireInvoiceFromHII from '@client/utils/getHireInvoiceFromHII';
import Table from '@client/components/Table/Table';
import TooltipIcon from '@client/components/TooltipIcon';
import VoyageBunkerForm from '@client/components/VoyageBunkerForm';
import CreateVoyageExpenseReceivableForm from '@client/components/CreateVoyageExpenseReceivableForm';
import isItemDeleted from '../helpers/isItemDeleted';
import { getCumulativeComparisonColumns } from '../helpers/getCumulativeComparisonColumns';
import styles from './styles/CumulativeComparison.module.css';
import OffHireForm from './OffHireForm';

export type RowType = {
	id: number;
	type: string;
	isTotal: boolean;
	ours: number;
	theirs: number;
	difference: number;
	accepted: boolean;
	item: HireInvoiceItem;
	ourChangedItem: HireInvoiceItem;
	theirChangedItem: HireInvoiceItem;
	description: string;
	invoiceIdentifier: string;
	internalNote: string;
	isGrouped: boolean | null;
	editing?: boolean;
	parentId?: number | null;
	groupedItems?: HireInvoiceItem[];
	isFixedExpandable?: boolean;
	isExpandablePayments?: boolean;
}

type CumulativeComparisonProps = {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps>;
	refreshDetails: () => void;
	focused: boolean;
}

const CumulativeComparison: React.FC<CumulativeComparisonProps> = ({
	voyageDetails,
	fixtureDetails,
	refreshDetails,
	focused,
}) => {
	const [
		voyageInvoices,
		_refreshVoyageInvoices,
		_voyageInvoiceError,
		voyageInvoicesLoading,
	] = useFetchedState(async () => {
		if (voyageDetails != null) {
			try {
				const invoices = await getVoyageInvoices(voyageDetails.id);

				return (invoices ?? []).map((hireInvoice) => ({
					...hireInvoice,
					items: hireInvoice.items.map((i) => HireInvoiceItem.fromJSON(i)),
				}));
			} catch (e) {
				showErrorNotification('Could not load voyage invoices', e as Error);

				return [];
			}
		}

		return [];
	});

	const [bunkersForm] = Form.useForm();
	const [editingBunkers, setEditingBunkers] = useState(false);
	const [creatingExpense, setCreatingExpense] = useState(false);
	const [creatingOffHire, setCreatingOffHire] = useState(false);
	const [voyageCumulative, refreshVoyageCumulative, error, loading] = useFetchedState(
		async () => ((voyageDetails.id == null || !focused) ?
			undefined :
			getVoyageCumulative(voyageDetails.id)),
		[voyageDetails.id, focused],
		{ autoRefresh: false },
	);

	const defaultInitialValues = useMemo(() => ({
		hirePerDay: fixtureDetails.hireRate,
		percentageForOwnersAccount: 100,
	}), [fixtureDetails.hireRate]);

	const [theirDifferencesState, setTheirDifferences] = useState<HireInvoiceDifference[]>([]);
	const [ourDifferences, setOurDifferences] = useState<HireInvoiceDifference[]>([]);

	const [groupItems, setGroupItems] = useState(false);

	const [filteredData, setFilteredData] = useState<RowType[] | undefined>();

	const [itemsToAdd, setItemsToAdd] = useState<HireInvoiceItem[] | []>([]);
	const [itemsToDelete, setItemsToDelete] = useState<HireInvoiceItem[] | []>([]);

	const [expandPayments, setExpandPayments] = useState(false);

	const savedDifferences = useMemo(() => (
		(voyageCumulative?.differences ?? []).map(
			(d) => HireInvoiceDifference.fromJSON(d),
		)
	), [voyageCumulative?.differences]);

	useEffect(() => setTheirDifferences(savedDifferences), [savedDifferences]);

	const flattenItems = (items: HireInvoiceItem[]) => (
		items.reduce((arr: HireInvoiceItem[], i) => [
			...arr,
			i,
			...i.children,
		], [])
	);

	const nestedItems = useMemo(() => {
		if (voyageCumulative === undefined) {
			return [];
		}

		const result = voyageCumulative.items.map(HireInvoiceItem.fromJSON);

		return [
			...result,
			...itemsToAdd,
		];
	}, [voyageCumulative, itemsToAdd]);

	const {
		items,
		differences: theirDifferences,
	} = useMemo(() => {
		let result = {
			items: nestedItems,
			differences: theirDifferencesState,
		};

		if (groupItems) {
			result = groupInvoiceItems(result.items, theirDifferencesState);
		}

		return {
			items: flattenItems(result.items),
			differences: result.differences,
		};
	}, [groupItems, nestedItems, theirDifferencesState]);

	const ourChangedItems = useMemo(() => items.map(
		(item) => (HireInvoiceDifference.getChangedInvoiceItem(item, ourDifferences)),
	), [items, ourDifferences]);

	const theirChangedItems = useMemo(() => items.map(
		(item) => (HireInvoiceDifference.getChangedInvoiceItem(item, theirDifferences)),
	), [items, theirDifferences]);

	const ourChangedItemsById = useMemo(() => ourChangedItems.reduce((obj, item) => ({
		...obj,
		[item.id]: item,
	}), {}), [ourChangedItems]);
	const theirChangedItemsById = useMemo(() => theirChangedItems.reduce((obj, item) => ({
		...obj,
		[item.id]: item,
	}), {}), [theirChangedItems]);

	const getItemTotal = useCallback((item: HireInvoiceItem, isTheirs: boolean): number => {
		const allChangedItems = isTheirs ? theirChangedItems : ourChangedItems;
		const itemsById = isTheirs ? theirChangedItemsById : ourChangedItemsById;

		const changedItem = itemsById[item.id];

		if (!changedItem.accepted && isTheirs) {
			return 0;
		}

		return changedItem.getTotal(allChangedItems);
	}, [ourChangedItems, ourChangedItemsById, theirChangedItems, theirChangedItemsById]);

	const getDataObject = useCallback((item: HireInvoiceItem): RowType | null => {
		const isTotal = item instanceof HIITotal;
		const invoice = getHireInvoiceFromHII(item, voyageInvoices ?? []);

		const invoiceIdentifier = (item.isGrouped ?
			'Grouped' :
			invoice?.invoiceIdentifier ?? 'Cumulative'
		);

		const ours = getItemTotal(item, false);
		const theirs = getItemTotal(item, true);

		const ourChangedItem = ourChangedItemsById[item.id];
		const theirChangedItem = theirChangedItemsById[item.id];

		return {
			editing: false,
			item,
			ourChangedItem,
			theirChangedItem,
			isTotal,
			id: item.id,
			accepted: ourChangedItem.accepted,
			parentId: item.parentId,
			type: item.itemTypeLabel,
			description: item.getDescription(items),
			ours,
			theirs,
			difference: theirs - ours,
			invoiceIdentifier,
			internalNote: ourChangedItem.internalNote,
			isGrouped: item.isGrouped,
		};
	}, [
		getItemTotal,
		items,
		ourChangedItemsById,
		theirChangedItemsById,
		voyageInvoices,
	]);

	const resetData = async () => {
		if (ourDifferences.length > 0) {
			setOurDifferences([]);
		}

		if (theirDifferences.length > 0) {
			setTheirDifferences([]);
		}

		if (itemsToAdd.length > 0) {
			setItemsToAdd([]);
		}

		if (itemsToDelete.length > 0) {
			setItemsToDelete([]);
		}

		// No need to await...
		setTimeout(() => refreshVoyageCumulative(), 10);
	};

	// Reset data on un-focus
	useEffect(() => {
		if (!focused) {
			resetData();
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focused]);

	const data: RowType[] = useMemo(() => {
		const rows = items.map((i) => getDataObject(i));

		return rows.filter((row): row is RowType => row != null);
	}, [items, getDataObject]);

	const columns: ColumnsType<RowType> = getCumulativeComparisonColumns({
		voyageDetails,
		voyageInvoices: voyageInvoices ?? [],
	});

	const fixtureCurrency = voyageDetails == null ?
		Currencies.USD :
		voyageDetails.bankAccount.currency;

	const totalRows = useMemo(() => {
		if (filteredData != null && data !== filteredData) {
			const ourFilteredTotal = calculateTotal(filteredData, (row: RowType) => row.ours);
			const theirFilteredTotal = calculateTotal(filteredData, (row: RowType) => row.theirs);

			return [{
				isTotal: true,
				id: -4,
				type: 'Sum of filtered items',
				description: '',
				ours: ourFilteredTotal,
				theirs: theirFilteredTotal,
				difference: theirFilteredTotal - ourFilteredTotal,
				ourChangedItem: undefined,
				theirChangedItem: undefined,
			}];
		}

		const payments = voyageDetails.payments || [];

		const totalInvoicedOurs = calculateTotal(
			data as RowType[],
			(row) => (row != null ? row.ours : 0),
		);

		const totalInvoicedTheirs = calculateTotal(
			data as RowType[],
			(row) => (row != null ? row.theirs : 0),
		);

		const totalPayments = calculateTotal(payments, (p) => p.amount);
		const totalBankCharges = calculateTotal(payments, (p) => p.bankCharge || 0);

		const paymentsRows = payments.map((e, index) => ({
			type: (
				<>
					{`Payment #${index + 1}`}
					{e.bankCharge != null && e.bankCharge > 0 && (
						<span className={styles.bankCharge}>
							Bank charge:
							{' '}
							{formatCurrency(e.bankCharge, fixtureCurrency)}
						</span>
					)}
				</>
			),
			description: e.paymentDate,
			ours: e.amount,
			theirs: e.amount,
			difference: 0,
			isFixedExpandable: true,
			item: {
				isChild: () => true,
			},
		}));

		return [
			{
				isTotal: true,
				id: -1.5,
				type: 'Total Invoiced',
				description: '',
				ours: totalInvoicedOurs,
				theirs: totalInvoicedTheirs,
				difference: totalInvoicedTheirs - totalInvoicedOurs,
			},
			{
				isTotal: true,
				isExpandablePayments: true,
				item: {
					isChild: () => false,
					isOriginal: () => true,
				},
				id: -2.5,
				type: (
					<>
						Total Payments
						{totalBankCharges > 0 && (
							<span className={styles.bankCharge}>
								Total bank charges:
								{' '}
								{formatCurrency(totalBankCharges, fixtureCurrency)}
							</span>
						)}
					</>
				),
				description: '',
				ours: totalPayments,
				theirs: totalPayments,
				difference: 0,
				parentId: null,
				invoiceIdentifier: voyageDetails.payments.length > 0 ?
					(expandPayments ? (
						<MinusSquareOutlined
							className={styles.totalPaymentsExpand}
							data-expand
							onClick={() => setExpandPayments(false)}
						/>
					) : (
						<PlusSquareOutlined
							className={styles.totalPaymentsExpand}
							data-expand
							onClick={() => setExpandPayments(true)}
						/>
					)) : null,
			},
			...(expandPayments ? paymentsRows : []),
			{
				isTotal: true,
				id: -3.5,
				type: (
					<>
						Total Due to Owner
						<TooltipIcon>
							The difference between the total amount invoiced
							and the total amount of payments made.
						</TooltipIcon>
					</>
				),
				description: '',
				ours: totalInvoicedOurs - totalPayments,
				theirs: totalInvoicedTheirs - totalPayments,
				difference: totalInvoicedTheirs - totalInvoicedOurs,
			},
		];
	}, [data, filteredData, voyageDetails, expandPayments, fixtureCurrency]);

	const filters = useMemo(() => [
		{
			key: 'invoice',
			label: 'Filter invoices',
			allLabel: 'All invoices',
			options: [
				{
					label: 'Added directly',
					value: 'Cumulative',
				},
				...(voyageInvoices ?? []).map((hireInvoice) => ({
					label: hireInvoice.invoiceIdentifier,
					value: hireInvoice.invoiceIdentifier,
				})),
			],
			multiple: true,
			onFilter: (
				value: any,
				row: RowType,
			) => row.isTotal || value.includes(row.invoiceIdentifier),
		},
		{
			key: 'itemType',
			label: 'Filter items',
			allLabel: 'All items',
			options: Array.from(new Set(
				data
					.filter((d) => d != null && !d.isTotal && !d.item.isChild())
					.map((d) => d != null && d.type),
			)).map((type) => ({
				label: type,
				value: type,
			})),
			multiple: true,
			onFilter: (
				value: any,
				row: RowType,
			) => {
				if (row.isTotal) {
					return true;
				}

				if (row.item.isChild()) {
					const parent = row.item.getParent(items) as HireInvoiceItem;

					return parent != null && value.includes(parent.itemTypeLabel);
				}

				return value.includes(row.type);
			},
		},
		{
			key: 'differences',
			label: 'Filter differences',
			allLabel: 'All items',
			options: [
				{ label: 'Not accepted', value: 'accepted' },
				{ label: 'Has difference', value: 'difference' },
			],
			multiple: false,
			onFilter: (value, row) => {
				switch (value) {
					case 'accepted':
						return !row.accepted;
					case 'difference':
						return row.ours !== row.theirs;

					default:
						return true;
				}
			},
		},
	], [data, voyageInvoices, items]);

	const csvData = useMemo(() => [
		[
			'Invoice',
			'Type',
			'Description',
			'Ours amount',
			'Theirs amount',
			'Difference',
			'Currency',
			'Note',
		],
		...data.map((dataItem) => {
			let invoice;

			if (dataItem == null) {
				return [];
			}

			if (dataItem.isGrouped) {
				invoice = 'Grouped';
			} else if (dataItem.item.isChild()) {
				// @ts-ignore
				const item = dataItem.item.getParent(data) as RowType;
				invoice = item.invoiceIdentifier;
			} else {
				invoice = dataItem.invoiceIdentifier;
			}

			return ([
				invoice,
				dataItem.type,
				dataItem.description,
				dataItem.ours,
				dataItem.theirs,
				dataItem.difference,
				fixtureCurrency,
				dataItem.internalNote,
			]);
		}),
	], [data, fixtureCurrency]);

	if (loading || voyageInvoicesLoading) {
		return (<LoadingIndicator />);
	}

	if (error != null) {
		return (<ErrorMessage>{error.message}</ErrorMessage>);
	}

	if (voyageInvoices == null) {
		return null;
	}

	return (
		<>
			<VoyageBunkerForm
				visible={editingBunkers}
				voyageId={voyageDetails.id}
				onClose={() => setEditingBunkers(false)}
				onSave={refreshDetails}
				baseCurrency={fixtureCurrency}
				form={bunkersForm}
			/>
			{creatingExpense && (
				<CreateVoyageExpenseReceivableForm
					open={creatingExpense}
					voyageId={voyageDetails.id}
					onClose={() => setCreatingExpense(false)}
					fixtureCurrency={fixtureCurrency}
					onInvoiceCreated={refreshDetails}
					fixtureType={fixtureDetails.type}
				/>
			)}
			<OffHireForm
				visible={creatingOffHire}
				voyageId={voyageDetails.id}
				onClose={() => setCreatingOffHire(false)}
				onSaved={refreshDetails}
				currency={fixtureCurrency}
				initialValues={defaultInitialValues}
			/>
			<Card>
				<Table<RowType>
					loading={voyageInvoicesLoading}
					scroll={{ x: true }}
					columns={columns}
					size="small"
					rowKey="id"
					dataSource={data}
					// @ts-ignore
					fixedRows={totalRows}
					customFixedRowEventListeners={{
						key: '[data-expand]',
						callback: () => setExpandPayments((prev) => !prev),
					}}
					className={styles.table}
					rowClassName={(row) => classNames({
						[styles.rowToBeDeleted]: isItemDeleted(itemsToDelete, row),
						[styles.childRow]: row.item != null && row.item.isChild(),
					})}
					defaultExpandAllRows
					indentSize={1}
					pagination={false}
					onChangeFilteredData={setFilteredData}
					filters={filters}
					extraLeft={(
						<Space>

							<div className={styles.groupSwitchContainer}>
								Group hire periods:
								<Switch
									onChange={setGroupItems}
									checked={groupItems}
								/>
							</div>
							<CSVLink
								key="export-csv"
								data={csvData}
								filename={`${voyageDetails.identifier} - Reconciliation ${formatDate(new Date())}.csv`}
							>
								<Button
									icon={(<ExportOutlined />)}
								>
									Export all to CSV
								</Button>
							</CSVLink>
						</Space>
					)}
				/>
			</Card>
		</>

	);
};

export default CumulativeComparison;
