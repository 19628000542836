import React, { useState } from 'react';
import {
	Card,
	Tabs,
} from 'antd';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import OrgBankAccounts from '@client/screens/management/banking/OrgBankAccounts';
import ApprovalSettings from '@client/screens/management/approvals/ApprovalSettings';
import UserManagement from '@client/screens/management/users/UserManagement';
import ReportGroups from '@client/screens/management/report-groups/ReportGroups';
import AccrualSettings from '../accruals/AccrualSettings';
import OffHireTypes from '../off-hire-types/OffHireTypes';
import EstimatorSettings from '../estimator/EstimatorSettings';
import GeneralSettings from '../general/GeneralSettings';
import Invoicing from '../invoicing/Invoicing';
import styles from './ManagementSettingsScreen.module.css';

const ManagementSettingsScreen = () => {
	const [activeTab, setActiveTab] = useState('approvals');

	return (
		<SimpleScreen
			title="Company Settings"
			rootPageTitle="Company Settings"
		>
			<Card>
				<Tabs
					onChange={setActiveTab}
					activeKey={activeTab?.toString()}
				>
					<Tabs.TabPane key="approvals" tab="Approvals">
						<ApprovalSettings />
					</Tabs.TabPane>
					<Tabs.TabPane key="users" tab="Team Members">
						<UserManagement />
					</Tabs.TabPane>
					<Tabs.TabPane
						key="banking"
						tab="Banking"
					>
						<div className={styles.bankingContainer}>
							<OrgBankAccounts />
						</div>
					</Tabs.TabPane>
					<Tabs.TabPane key="accruals" tab="Accruals">
						<AccrualSettings />
					</Tabs.TabPane>
					<Tabs.TabPane key="reportGroups" tab="Report Groups">
						<ReportGroups />
					</Tabs.TabPane>
					<Tabs.TabPane key="offHireTypes" tab="Off-hire Types">
						<OffHireTypes />
					</Tabs.TabPane>
					<Tabs.TabPane key="estimatorSettings" tab="Estimator">
						<EstimatorSettings />
					</Tabs.TabPane>
					<Tabs.TabPane key="generalSettings" tab="General">
						<GeneralSettings />
					</Tabs.TabPane>
					<Tabs.TabPane key="invoicing" tab="Invoicing">
						<div className={styles.invoicingContainer}>
							<Invoicing />
						</div>
					</Tabs.TabPane>
				</Tabs>
			</Card>
		</SimpleScreen>
	);
};

export default ManagementSettingsScreen;
