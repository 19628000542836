import React, { SetStateAction } from 'react';
import { Space } from 'antd';
import {
	DeleteOutlined,
	EditOutlined,
} from '@ant-design/icons';
import {
	AttachmentTypeLabels,
	CrewReportTypes,
	DATE_AND_TIME,
} from '@shared/utils/constants';
import type { GetRobsResponse } from '@api/features/vessels/getRobs';
import type { GetAccrualItemsResponse } from '@api/features/financials/getAccrualItems';
import styles
	from '@client/screens/fleet/VoyageDetailsScreen/tabs/BunkerExpenditureTab/BunkerExpenditureTab.module.css';
import { formatDate } from '@client/utils/formatDate';
import Button from '@client/components/Button';
import { EditValues } from '@client/screens/fleet/VoyageDetailsScreen/tabs/BunkerExpenditureTab/BunkerExpenditureTab';
import AddButton from '@client/components/AddButton';

type Props = {
	robs: GetRobsResponse;
	accruals: GetAccrualItemsResponse;
	onDeleteRob: (id: number) => void;
	setEditing: React.Dispatch<SetStateAction<EditValues | null>>;
	setRobDrawerOpen: React.Dispatch<SetStateAction<boolean>>;
}

export const getRobTableColumns = ({
	robs,
	accruals,
	onDeleteRob,
	setEditing,
	setRobDrawerOpen,
}: Props) => {
	const checkRobForAccruals = (robId: number) => {
		const filteredAccruals = accruals?.filter(
			(a) => (
				a.origin === AttachmentTypeLabels.robBunker ||
				a.origin === AttachmentTypeLabels.rob
			) && a.originId === robId,
		);

		return filteredAccruals != null && filteredAccruals.length !== 0;
	};

	if (robs == null || !Array.isArray(robs)) {
		return [];
	}

	const columns = robs.map((rob) => {
		return ({
			key: rob.id,
			dataIndex: rob.id,
			event: rob.event,
			width: 280,
			title: (
				<div className={styles.columnTitle}>
					<div className={styles.dateAndTypeContainer}>
						{formatDate(rob.date, DATE_AND_TIME)}
						<p className={styles.entryType}>{rob.reportId != null ? 'Crew report entry' : 'Manual entry'}</p>
					</div>
					{(
						rob.reportId == null &&
						rob.event !== CrewReportTypes.COMMENCEMENT
					) && (
						<Space size="small">
							{(
								rob.event === CrewReportTypes.BUNKERING ||
								rob.event === CrewReportTypes.REDELIVERY ||
								rob.event === CrewReportTypes.REEVALUATION
							) && (
								<Button
									onClick={() => onDeleteRob(rob.id)}
									confirmTitle={
										checkRobForAccruals(rob.id) ?
											'This item has already been posted. Deleting this item will also delete/reverse the item in the accounting system' : null
									}
									icon={(<DeleteOutlined />)}
									danger
									type="link"
								/>
							)}
							{rob.event !== CrewReportTypes.DELIVERY && (
								<Button
									onClick={() => {
										const vesselBunker = rob.VesselBunkers.find((vb) => vb.robId === rob.id);
										setEditing({
											...rob,
											robs: rob.RobBunkers,
											allowSetPrice: false,
											robId: rob.id,
											bunkerId: vesselBunker?.bunkerId,
										});
									}}
									icon={(<EditOutlined />)}
									type="link"
								/>
							)}
							{rob.event === CrewReportTypes.BUNKERS_ROB && (
								<Button
									onClick={() => onDeleteRob(rob.id)}
									icon={(<DeleteOutlined />)}
									danger
									type="link"
								/>
							)}
						</Space>
					)}
				</div>
			),
		});
	});

	return [
		{
			key: 'label',
			title: '',
			dataIndex: 'label',
			width: 175,
			fixed: true,
		},
		...columns ?? [],
		{
			key: 'action',
			dataIndex: 'action',
			title: (
				<AddButton
					onClick={() => setRobDrawerOpen(true)}
				>
					Add new
				</AddButton>
			),
		},
	];
};
