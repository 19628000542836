import React, {
	SetStateAction,
	useCallback,
	useEffect,
} from 'react';
import {
	Divider,
	Empty,
} from 'antd';
import {
	FixtureCounterpartyTypes,
	LaytimeTerms,
} from '@shared/utils/constants';
import type { GetCargoDetailsResponse } from '@api/features/cargos/getCargoDetails';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getCounterparties,
	getPorts,
} from '@client/lib/api';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import Card from '@client/components/Card/Card';
import { getCargoDetailsFields } from '@client/screens/cargos/helpers/getCargoDetailsFields';
import { OnUpdateCargo } from '@client/screens/estimates/details/context/hooks/useCargoHandlers';
import getKeyCargoDetailsFields from '../helpers/getKeyCargoDetailsFields';
import BrokerCommissionDetails from '../components/CommissionsCard/BrokerCommissionDetails';
import styles from './CargoDetailsScreen.module.css';

const CargoKeyDetails = ({
	cargoDetails,
	refreshCargoDetails,
	laytimeTerms,
	handleChange,
	setLaytimeTerms,
	isTceLocked,
}: {
	cargoDetails?: GetCargoDetailsResponse | null;
	refreshCargoDetails: () => void;
	laytimeTerms: LaytimeTerms;
	setLaytimeTerms: React.Dispatch<SetStateAction<LaytimeTerms>>;
	isTceLocked?: boolean;
	handleChange: OnUpdateCargo;
}) => {
	const [ports] = useFetchedState(getPorts);
	const [charterers, _reloadCharterers, _error, _loading] = useFetchedState(
		() => getCounterparties(FixtureCounterpartyTypes.CHARTERER),
	);

	useEffect(() => {
		if (cargoDetails?.laytimeTerms != null) {
			setLaytimeTerms(cargoDetails.laytimeTerms);
		}
	}, [cargoDetails?.laytimeTerms, setLaytimeTerms]);

	const handleEdit = useCallback(async (values: GetCargoDetailsResponse) => {
		if (cargoDetails?.id == null) {
			return;
		}

		const [key, value] = Object.entries(values)[0];

		if (cargoDetails[key] === value) {
			return;
		}

		handleChange(cargoDetails.id, values);
		if (values.laytimeTerms != null) {
			setLaytimeTerms(values.laytimeTerms);
		}
	}, [cargoDetails, handleChange, setLaytimeTerms]);

	if (cargoDetails == null) {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description="No data"
			/>
		);
	}

	return (
		<>
			<Card>
				<Divider>
					Key Details
				</Divider>
				<EditableDetails
					className={styles.keyDetailsTable}
					title="Details"
					onCancel={
						() => setLaytimeTerms(cargoDetails?.laytimeTerms ?? LaytimeTerms.NON_REVERSIBLE)
					}
					// @ts-ignore
					items={getKeyCargoDetailsFields({
						cargoDetails,
						charterers,
						isTceLocked,
						ports,
					})}
					// @ts-ignore
					onEditValuesChange={handleEdit}
					editing
					hideHeader
				/>
				<Divider>
					Other Details
				</Divider>
				<EditableDetails
					className={styles.keyDetailsTable}
					title="Details"
					onCancel={
						() => setLaytimeTerms(cargoDetails?.laytimeTerms ?? LaytimeTerms.NON_REVERSIBLE)
					}
					items={getCargoDetailsFields({
						cargoDetails,
						laytimeTerms,
						setLaytimeTerms,
					})}
					// @ts-ignore
					onEditValuesChange={handleEdit}
					editing
					hideHeader
				/>
				<Divider>
					Broker Commissions
				</Divider>
				<BrokerCommissionDetails
					refreshCargoDetails={refreshCargoDetails}
					cargoDetails={cargoDetails}
					handleChange={handleChange}
				/>
			</Card>
		</>
	);
};

export default CargoKeyDetails;
