import React from 'react';
import { Typography } from 'antd';
import { formatCurrency } from '@shared/utils/currency';
import {
	CalculationMethodForExpensesSubjectToHireDays,
	Currencies,
	FixtureTypes,
	HireUnit,
	PeriodUnits,
	PnlItemTypes,
	PnlRenderTypes,
	VesselOwnershipTypes,
	VoyageBunkerTypes,
} from '@shared/utils/constants';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import {
	calculateTotal,
	round,
} from '@shared/utils/math';
import { Values } from '@shared/utils/objectEnums';
import { formatPercentage } from '@shared/utils/formatPercentage';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import {
	getVesselDetails,
	getVoyageDetails,
	getVoyages,
} from '@client/lib/api';

export const getEstimateData = async (
	fixture: GetFixtureDetailsResponse<TcFixtureProps>,
	voyage: GetVoyageDetailsResponse,
	baseCurrency: Values<typeof Currencies>,
	styles?: any,
	isTcInContract = false,
) => {
	const {
		vessel,
		durationMinimumValue,
		durationMinimumUnit,
		durationMinimumVariance,
		grossBallastBonus,
		hireRate,
		addressCommission,
		brokers,
		expensesSubjectToHireDays,
		revenuesSubjectToDays,
		calculationMethodForExpensesSubjectToHireDays,
		laycanTo,
		ownerExpenses,
		chartererExpenses,
	} = fixture as GetFixtureDetailsResponse<TcFixtureProps>;

	let fixtureDurationDays = 0;

	switch (durationMinimumUnit) {
		case PeriodUnits.DAYS:
			fixtureDurationDays = (durationMinimumValue ?? 0) - (durationMinimumVariance ?? 0);
			break;
		case PeriodUnits.MONTHS:
			if ('hireUnit' in fixture && fixture.hireUnit === HireUnit.MONTHS) {
				fixtureDurationDays = (durationMinimumValue ?? 0) - ((durationMinimumVariance ?? 0) / 30.4);
				break;
			}

			fixtureDurationDays = (durationMinimumValue ?? 0) * 30 - (durationMinimumVariance ?? 0);

			break;
		case PeriodUnits.YEARS:
			fixtureDurationDays = (durationMinimumValue ?? 0) * 365 - (durationMinimumVariance ?? 0);
			break;
		default:
			break;
	}

	const currency = fixture?.bankAccount?.currency || baseCurrency;

	const brokerCommission = calculateTotal(brokers, (b) => b.commission);
	const commissions = ((addressCommission ?? 0) + brokerCommission);
	const grossHire = (hireRate && fixtureDurationDays) ?
		(hireRate * fixtureDurationDays) : 0;
	const netHire = grossHire * ((100 - commissions) / 100);
	const netBallastBonus = (grossBallastBonus ?? 0) * ((100 - commissions) / 100);

	const netAddressCommissions = ((grossHire / 100) * (addressCommission ?? 0)) +
	(((grossBallastBonus || 0) / 100) * (addressCommission ?? 0));

	const netBrokerCommissions = ((grossHire / 100) * brokerCommission) +
	(((grossBallastBonus || 0) / 100) * brokerCommission);

	const bunkers = voyage?.voyageBunkers;
	const bunkerSales = bunkers?.filter((b) => (
		b.type === VoyageBunkerTypes.CHARTERERS_ACCOUNT || b.type === VoyageBunkerTypes.DELIVERY
	));
	const bunkerPurchases = bunkers?.filter((b) => (
		b.type === VoyageBunkerTypes.OWNERS_ACCOUNT || b.type === VoyageBunkerTypes.REDELIVERY
	));

	const totalBunkerSales = calculateTotal(bunkerSales ?? [], (b) => b.pricePerTon * b.quantity);
	const totalBunkerPurchases = calculateTotal(
		bunkerPurchases ?? [],
		(b) => b.pricePerTon * b.quantity,
	);

	const bunkerBalance = totalBunkerSales - totalBunkerPurchases;

	const revenueSubjectToDaysSum = calculateTotal(
		revenuesSubjectToDays, (r) => r.amount,
	);

	const totalRevenueSubjectToDays = revenueSubjectToDaysSum * fixtureDurationDays;

	const otherRevenue = calculateTotal(chartererExpenses, (e) => e.amount);
	const ownersExpenses = calculateTotal(ownerExpenses, (e) => e.amount);

	const totalExpensesSubjectToHireDays = calculateTotal(
		expensesSubjectToHireDays,
		(e) => {
			if (e.interval === 'pcm') {
				switch (calculationMethodForExpensesSubjectToHireDays) {
					case CalculationMethodForExpensesSubjectToHireDays.ANNUALLY:
						return ((e.amount * 12) / 365) * fixtureDurationDays;

					case CalculationMethodForExpensesSubjectToHireDays.MONTHLY:
						return ((e.amount * 12) / 360) * fixtureDurationDays;
					default:
						break;
				}
			}

			if (e.interval === 'perDay') {
				return e.amount * fixtureDurationDays;
			}

			return 0;
		},
	);

	const totalRevenue =
		netHire +
		netBallastBonus +
		totalExpensesSubjectToHireDays +
		totalRevenueSubjectToDays +
		otherRevenue +
		bunkerBalance;

	const getTcInCosts = async () => {
		const voyages = await getVoyages(vessel.id);

		if (voyages.length > 0) {
			const tcInVoyage = voyages.find((v) => v?.fixtureType === FixtureTypes.TC_IN);

			if (tcInVoyage != null && fixtureDurationDays) {
				const voyageDetails = await getVoyageDetails(tcInVoyage.id);

				return (voyageDetails?.stats?.pnl?.tce ?? 0) * fixtureDurationDays * -1;
			}
		}

		return 0;
	};

	const getTcLabel = (
		prefix: string,
		label: string,
		fallback: string,
		suffix = '',
	) => {
		const result = isTcInContract ? label : fallback;

		return (
			<b>
				{prefix}
				{' '}
				{result}
				{' '}
				{suffix}
			</b>
		);
	};

	let tcInCosts = 0;
	let capex = 0;
	let opex = 0;

	if (vessel?.ownershipType === VesselOwnershipTypes.TC_IN && fixture.type !== FixtureTypes.TC_IN) {
		tcInCosts = await getTcInCosts();
	} else if (vessel) {
		const tcOutCommencementDate = laycanTo ? toMoment(laycanTo) : nowMoment();
		const vesselDetails = await getVesselDetails(vessel.id);

		const isYearly = vesselDetails.vesselFinanceEntries.some((finance) => finance.year != null);

		let capexOpex;

		if (isYearly) {
			capexOpex = vesselDetails.vesselFinanceEntries.find(
				(finance) => finance.year === tcOutCommencementDate.year(),
			);
		} else {
			capexOpex = vesselDetails.vesselFinanceEntries.find(
				(finance) => {
					const financeDate = toMoment(finance.month!);

					return financeDate.year() === tcOutCommencementDate.year() &&
					financeDate.month() === tcOutCommencementDate.month() + 1;
				},
			);
		}

		capex = (capexOpex?.capex || 0) * fixtureDurationDays * -1;
		opex = (capexOpex?.opex || 0) * fixtureDurationDays * -1;
	}

	const revenue = [
		{
			type: PnlItemTypes.GROSS_HIRE,
			renderType: PnlRenderTypes.CURRENCY,
			desc: (
				<>
					Gross Hire
					{hireRate != null && (
						<Typography.Text className={styles.smallerText} type="secondary">
							{` (${formatCurrency(durationMinimumUnit === PeriodUnits.MONTHS ? (hireRate * 12) / 365 : hireRate, currency)}/day)`}
						</Typography.Text>
					)}
				</>
			),
			amount: round(grossHire, 0) || 0,
		},
		{
			type: PnlItemTypes.GROSS_BALLAST_BONUS,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Gross Ballast Bonus',
			amount: round((grossBallastBonus ?? 0), 0) || 0,
		},
		{
			type: PnlItemTypes.ADDRESS_COMMISSIONS,
			renderType: PnlRenderTypes.CURRENCY,
			desc: (
				<>
					Add. Commissions
					<Typography.Text className={styles.smallerText} type="secondary">
						{` (${formatPercentage(addressCommission || 0)})`}
					</Typography.Text>
				</>
			),
			amount: round(netAddressCommissions * -1, 0) || 0,
		},
		{
			type: PnlItemTypes.BROKER_COMMISSIONS,
			renderType: PnlRenderTypes.CURRENCY,
			desc: (
				<>
					Broker Commissions
					<Typography.Text className={styles.smallerText} type="secondary">
						{` (${formatPercentage(brokerCommission)})`}
					</Typography.Text>
				</>
			),
			amount: round(netBrokerCommissions * -1, 0) || 0,
		},
		{
			type: PnlItemTypes.EXPENSES_SUBJECT_TO_HIRE_DAYS,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Expenses Subject to Hire Days',
			amount: round(totalExpensesSubjectToHireDays, 0) || 0,
		},
		{
			type: PnlItemTypes.REVENUE_SUBJECT_TO_DAYS,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Revenue Subject To Days',
			amount: round(totalRevenueSubjectToDays, 0) || 0,
		},
		{
			type: PnlItemTypes.OTHER_REVENUE,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Charterer\'s Expenses',
			amount: round(otherRevenue, 0) || 0,
		},
		{
			type: PnlItemTypes.BUNKER_LOSS_GAIN,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Bunker Gain/(Loss)',
			amount: bunkerBalance,
		},
		{
			type: PnlItemTypes.TOTAL_REVENUE,
			renderType: PnlRenderTypes.CURRENCY,
			desc: getTcLabel('Total', 'Costs', 'Revenue'),
			amount: round(totalRevenue, 0) || 0,
		},
	];

	let expenses = [
		{
			type: PnlItemTypes.OTHER_EXPENSES,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'Owner\'s Expenses' as string | React.ReactElement,
			amount: round(ownersExpenses, 0) || 0,
		},
	];

	const totalExpenses = ownersExpenses;
	expenses = [
		...expenses,
		{
			type: PnlItemTypes.TOTAL_EXPENSES,
			renderType: PnlRenderTypes.CURRENCY,
			desc: (<b>Total Expenses</b>),
			amount: round(totalExpenses, 0) || 0,
		},
	];

	const totalProfit = (totalRevenue + totalExpenses) || 0;
	const netProfit = totalProfit + tcInCosts + capex + opex;

	let profitColor;

	if (styles != null) {
		profitColor = totalProfit > 0 ? styles.positive : styles.negative;
	}

	const summary = [
		{
			type: PnlItemTypes.DAYS,
			desc: durationMinimumUnit === PeriodUnits.MONTHS ? 'Estimated Months' : 'Estimated Days',
			renderType: null,
			amount: round(fixtureDurationDays, 2) || 0,
		},
		{
			type: PnlItemTypes.FINAL_VOYAGE_PROFIT,
			renderType: PnlRenderTypes.CURRENCY,
			desc: getTcLabel('Voyage', 'Cost', 'Profit'),
			amount: round((totalProfit || 0), 0),
			bold: true,
			className: totalProfit === 0 ? null : profitColor,
		},
		{
			type: PnlItemTypes.TC_IN_COSTS,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'TC-In Costs',
			amount: round(tcInCosts, 0) || 0,
		},
		{
			type: PnlItemTypes.CAPEX,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'CAPEX',
			amount: round(capex, 0) || 0,
		},
		{
			type: PnlItemTypes.OPEX,
			renderType: PnlRenderTypes.CURRENCY,
			desc: 'OPEX',
			amount: round(opex, 0) || 0,
		},
		{
			type: PnlItemTypes.FINAL_VOYAGE_PROFIT_PER_DAY,
			renderType: PnlRenderTypes.CURRENCY,
			desc: getTcLabel('Voyage', 'Cost', 'Profit', 'Per Day'),
			amount: round((totalProfit / fixtureDurationDays), 0) || 0,
			bold: true,
			className: totalProfit === 0 ? null : profitColor,
		},
		{
			type: PnlItemTypes.NET_RESULT,
			renderType: PnlRenderTypes.CURRENCY,
			amount: round((netProfit || 0), 0),
			bold: true,
			className: netProfit === 0 ? null : profitColor,
		},
		{
			type: PnlItemTypes.NET_RESULT_PER_DAY,
			renderType: PnlRenderTypes.CURRENCY,
			amount: round((netProfit / fixtureDurationDays), 0) || 0,
			bold: true,
			className: netProfit === 0 ? null : profitColor,
		},
	];

	return {
		revenue,
		expenses,
		summary,
	};
};
