import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	Card,
	Row,
	Col,
	notification,
} from 'antd';
import {
	useParams,
	useHistory,
} from 'react-router-dom';
import Table from '@client/components/Table/Table';
import {
	getCounterpartyDetails,
	updateCounterparty,
	deleteCounterparty,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import showErrorNotification from '@client/utils/showErrorNotification';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import getFixtureTableColumns from '@client/utils/getFixtureTableColumns';
import getPointOfContactItems from '@client/utils/getPointOfContactItems';
import Button from '@client/components/Button';
import { Links } from '@client/utils/links';
import getItems from './helpers/getItems';

const OwnerDetailsScreen = () => {
	const { id } = useParams();
	const history = useHistory();

	const [
		owner,
		refreshOwner,
		error,
		loading,
	] = useFetchedState(
		() => getCounterpartyDetails(id),
		[], { showNotification: false },
	);

	const fixtureColumns = useMemo(() => getFixtureTableColumns(), []);

	const saveDetails = useCallback(async (values) => {
		await updateCounterparty(id, values);
		await refreshOwner();
	}, [id, refreshOwner]);

	const onDeleteOwner = useCallback(async () => {
		try {
			await deleteCounterparty(id);

			notification.success({
				message: 'Owner was deleted',
				description: `${owner?.name} has successfully been deleted.`,
			});

			history.goBack();
		} catch (e) {
			showErrorNotification('Could not delete owner', e);
		}
	}, [id, history, owner?.name]);

	return (
		<SimpleScreen
			error={error}
			canGoBack
			breadcrumbs={[['Owners', Links.Owners.get()]]}
			title={owner?.name}
			rootPageTitle="Owners"
			loading={loading}
			headerActions={[(
				<Button
					disabled={loading}
					onClick={onDeleteOwner}
					confirmTitle={`Are you sure you want to delete ${owner?.name}?`}
					key="delete"
					type="primary"
					danger
				>
					Delete owner
				</Button>
			)]}
		>
			<Row gutter={16}>
				<Col span={12}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Card bordered>
								<EditableDetails
									data-cy="details"
									items={owner != null ? getItems(owner) : []}
									onSave={saveDetails}
								/>
							</Card>
						</Col>
						<Col span={24}>
							<Card bordered>
								<EditableDetails
									data-cy="pointOfContact"
									items={owner != null ? getPointOfContactItems(owner) : []}
									onSave={saveDetails}
									title="Point of contact"
								/>
							</Card>
						</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Card title="Estimates">
						<Table
							data-cy="fixtures"
							rowKey="id"
							dataSource={owner?.fixtures ?? []}
							columns={fixtureColumns}
						/>
					</Card>
				</Col>
			</Row>
		</SimpleScreen>
	);
};

export default OwnerDetailsScreen;
