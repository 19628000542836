import React, { useState } from 'react';
import {
	Alert,
	Drawer,
} from 'antd';
import { Link } from 'react-router-dom';
import { capitalize } from '@shared/utils/string';
import { VesselConditionTypes } from '@shared/utils/constants';
import type { ItinerarySeaPassageDto } from '@api/features/ops/getVesselItinerary';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import { updateSeaPassage } from '@client/lib/api';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import showErrorNotification from '@client/utils/showErrorNotification';
import { Links } from '@client/utils/links';
import ConsumptionSets from '@client/screens/fleet/VesselDetailsScreen/components/ConsumptionSets/ConsumptionSets';
import styles from './styles/ConditionTab.module.css';

type Props = {
	selectedEntry: ItinerarySeaPassageDto;
	refreshDetails: () => void;
	refreshVessel: () => Promise<void> | void;
	vessel: GetVesselDetailsResponse | undefined;
	isConsMissingForSpeed: boolean;
	makePrimary?: (id: number) => void;
}

const ConditionTab = ({
	selectedEntry,
	refreshDetails,
	vessel,
	refreshVessel,
	makePrimary,
}: Props) => {
	const defaultDraft = vessel?.[`${selectedEntry.vesselCondition}Draft`];
	const [editing, setEditing] = useState(false);

	if (vessel == null) {
		return null;
	}

	const refreshDataAndSetUpdated = async () => {
		await refreshVessel();
		await refreshDetails();
	};

	const handleUpdateCondition = async (values: { condition?: 'ballast' | 'laden'; draft?: number}) => {
		try {
			await updateSeaPassage(
				selectedEntry.vesselId,
				selectedEntry.id,
				{
					vesselCondition: values.condition ?? selectedEntry.vesselCondition,
					...(values.draft != null ? { vesselDraftOverride: values.draft } : {}),
				},
				true,
			);

			await refreshDataAndSetUpdated();
		} catch (e) {
			showErrorNotification('Could not update sea passage settings', e as Error);
		}
	};

	return (
		<div className={styles.container}>
			<Drawer
				open={editing}
				onClose={() => setEditing(false)}
				width={900}
			>
				{('consumptionSets' in vessel) && (
					<ConsumptionSets
						consumptionSets={vessel.consumptionSets}
						inPortEntries={vessel?.performanceEntries
							.filter((entry) => entry.condition === VesselConditionTypes.WORKING ||
								entry.condition === VesselConditionTypes.IDLE)}
						vesselId={vessel.id}
						refreshVessel={() => {
							refreshVessel();
							refreshDetails();
						}}
						secondaryInPortEnabled={vessel?.enableSecondaryInPortConsumption ?? false}
						setEditing={setEditing}
					/>
				)}
			</Drawer>
			<div className={styles.conditionContainer}>
				<EditableDetails
					title="Condition"
					onSave={handleUpdateCondition}
					items={[
						{
							key: 'condition',
							label: 'Condition',
							value: selectedEntry.vesselCondition,
							type: 'select',
							options: [
								{
									value: 'ballast',
									label: 'Ballast',
								},
								{
									value: 'laden',
									label: 'Laden',
								},
							],
							editable: true,
							render: (v) => (
								<>
									{capitalize(v.value)}
								</>
							),
						},
						{
							key: 'draft',
							label: (
								<div className={styles.draftLabel}>
									Draft Override
									<p style={{ fontStyle: 'italic' }}>
										Default vessel draft:
										{' '}
										{' '}
										{defaultDraft ?? 5}
										{' '}
										m
									</p>
								</div>
							),
							value: selectedEntry.vesselDraftOverride ?? vessel?.[`${selectedEntry.vesselCondition}Draft`] ?? 5,
							type: 'number',
							editable: true,
							render: (v) => (
								<>
									{v.value}
									{' '}
									m
								</>
							),
						},
					]}
				/>
				<Alert
					className={styles.alert}
					message={(
						<>
							Setting the draft value will change the draft for this sea passage only.
							<br />
							The default draft is based on vessel condition.
							<br />
							<br />
							To change default values, go to the vessel details -
							{' '}
							<Link to={`${Links.Vessel.get(vessel?.id)}/#/details`}>
								{vessel?.name}
							</Link>
						</>
					)}
				/>
			</div>
			{(selectedEntry.estimated || selectedEntry.inProgress) && (
				<div className={styles.consumptionContainer}>
					<ConsumptionSets
						consumptionSets={vessel.consumptionSets}
						inPortEntries={vessel.inPortEntries}
						vesselId={vessel.id}
						refreshVessel={() => {
							refreshVessel();
							refreshDetails();
						}}
						secondaryInPortEnabled={vessel.enableSecondaryInPortConsumption}
						inEstimate
						setEditing={setEditing}
						activeConsumptionSetId={selectedEntry.activeConsumptionSetId}
						onMakePrimary={makePrimary}
					/>
				</div>
			)}
		</div>
	);
};

export default ConditionTab;
