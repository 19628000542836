import React, {
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Popover,
	Row,
	Skeleton,
	Space,
} from 'antd';
import { DATE_AND_TIME } from '@shared/utils/constants';
import type { GetVoyagesResponse } from '@api/features/voyages/getVoyages';
import { formatDate } from '@client/utils/formatDate';
import Button from '@client/components/Button';
import VoyageSelector from './VoyageSelector/VoyageSelector';

const LinkVoyageButton = ({
	voyages,
	updateVoyageField,
}: {
	voyages: GetVoyagesResponse;
	updateVoyageField: (field: string, value: any, contractId?: number | null) => Promise<void>;
}) => {
	const [selectedPreviousContractId, setSelectedContractId] = useState<number | null>(null);

	const selectedPreviousContract = useMemo(() => {
		if (selectedPreviousContractId != null && voyages != null) {
			return voyages.find((v) => v.id === selectedPreviousContractId);
		}

		return null;
	}, [selectedPreviousContractId, voyages]);

	return (
		<Popover
			// @ts-ignore
			zIndex={0}
			placement="bottomRight"
			onVisibleChange={(visible) => {
				if (!visible) {
					setSelectedContractId(null);
				}
			}}
			trigger="click"
			content={(
				<div style={{ width: 350 }}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Space direction="vertical">
								<div>
									<b>Select Previous Contract</b>
									<VoyageSelector
										type="previous"
										voyages={voyages}
										setSelectedContractId={setSelectedContractId}
									/>
								</div>
							</Space>
						</Col>
						<Col span={24}>
							{selectedPreviousContract == null ? (
								<Skeleton />
							) : (
								<p>
									{selectedPreviousContract.completionPort == null ? (
										<>
											<b>Estimated Completion Port: </b>
											{selectedPreviousContract.estimatedCompletionPort?.name ?? 'Not set'}
										</>
									) : (
										<>
											<b>Completion Port: </b>
											{selectedPreviousContract?.completionPort?.name}
										</>
									)}
									<br />
									{selectedPreviousContract.completionDate == null ? (
										<>
											<b>Estimated Completion Date: </b>
											{formatDate(selectedPreviousContract?.estimatedCompletionDate, DATE_AND_TIME)}
										</>
									) : (
										<>
											<b>Completion Date: </b>
											{formatDate(selectedPreviousContract?.completionDate, DATE_AND_TIME)}
										</>
									)}
								</p>
							)}
						</Col>
						<Col span={24}>
							<Button
								onClick={async () => {
									await updateVoyageField('previousVoyageId', selectedPreviousContractId);
								}}
								type="primary"
								disabled={selectedPreviousContract == null}
							>
								Link Contract
							</Button>
						</Col>
					</Row>
				</div>
			)}
		>
			<Button
				disabled={voyages.length === 0}
				disabledTooltip="This seems to be the first OUT voyage for this vessel"
				type="primary"
			>
				Link to previous contract
			</Button>
		</Popover>
	);
};

export default LinkVoyageButton;
