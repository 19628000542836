import { useMemo } from 'react';
import { FixtureCounterpartyTypes } from '@shared/utils/constants';
import {
	getCounterparties,
	getPorts,
} from '@client/lib/api';
import { getPortOptions } from '@client/utils/getPortAndRangeOptions';
import useFetchedState from '@client/utils/hooks/useFetchedState';

export const useMiscHandlers = () => {
	const [ports] = useFetchedState(getPorts);
	const portOptions = getPortOptions(ports ?? []);

	const [charterers, _reloadCharterers, _error, _charterersLoading] = useFetchedState(
		() => getCounterparties(FixtureCounterpartyTypes.CHARTERER),
	);
	const chartererOptions = useMemo(() => {
		if (charterers == null) {
			return [];
		}

		return charterers.map((c) => ({
			chartererName: c.name,
		}));
	}, [charterers]);

	return {
		ports,
		portOptions,
		charterers,
		chartererOptions,
	};
};
