import React from 'react';
import {
	Row,
	Col,
} from 'antd';
import {
	AccountingItemApprovalStates,
	ChildBunkerTypes,
	Currencies,
	HireInvoiceItemStates,
	VoyageBunkerTypeLabels,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { capitalize } from '@shared/utils/string';
import { Values } from '@shared/utils/objectEnums';
import { standardSort } from '@shared/utils/standardSort';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { VoyageBunkerEntry } from '@api/utils/sequelize/getVoyageBunkers';
import BunkersTable from '@client/components/BunkersTable';
import StateTag from '@client/components/StateTag';
import { getFilterProps } from '@client/utils/table';
import ExpensesTable from './ExpensesTable';
import OffHireTable from './OffHireTable';

type TcExpensesTabProps = {
	fixtureCurrency: Values<typeof Currencies>;
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps>;
	refreshDetails: () => void;
	expenses: GetVoyageDetailsResponse['voyageExpenseReceivables'];
	bunkersHasCurrencyDiff: boolean;
}

const TcExpensesTab = ({
	voyageDetails,
	fixtureDetails,
	fixtureCurrency,
	refreshDetails,
	expenses,
	bunkersHasCurrencyDiff,
}: TcExpensesTabProps) => {
	const bunkerColumns = [
		{
			dataIndex: 'fuelGrade',
			title: 'Grade',
		},
		{
			dataIndex: 'type',
			title: 'Type',
			render: (
				type: Values<typeof VoyageBunkerTypeLabels>,
			) => capitalize(VoyageBunkerTypeLabels[type]),
		},
		{
			dataIndex: 'quantity',
			title: 'Quantity',
			render: (c: number) => `${c} MT`,
		},
		...(bunkersHasCurrencyDiff ?
			[
				{
					dataIndex: 'pricePerTon',
					title: 'Local Price / MT',
					align: 'right',
					render: (c: number, record: VoyageBunkerEntry) => {
						return `${formatCurrency(record.pricePerTon, record.currency)}`;
					},
				},
				{
					dataIndex: 'total',
					title: 'Local Amount',
					align: 'right',
					render: (c: number, record: VoyageBunkerEntry) => {
						const price = record.pricePerTon;

						return `${formatCurrency(price * record.quantity, record.currency)}`;
					},
				},
				{
					dataIndex: 'pricePerTon',
					title: 'Contract Price / MT',
					align: 'right',
					render: (c: number, record: VoyageBunkerEntry) => {
						const price = c;

						return `${formatCurrency(price / record.exchangeRate, fixtureCurrency)}`;
					},
				},
				{
					dataIndex: 'total',
					title: 'Contract Amount',
					align: 'right',
					render: (c: number, record: VoyageBunkerEntry) => {
						const price = record.pricePerTon;

						return `${formatCurrency(price * record.quantity, fixtureCurrency)}`;
					},
				},
			] :
			[
				{
					dataIndex: 'pricePerTon',
					title: `Price / MT (${fixtureCurrency})`,
					align: 'right',
					render: (c: number, record: VoyageBunkerEntry) => {
						const price = c;

						return `${formatCurrency(price / record.exchangeRate, fixtureCurrency, { hideSymbol: true })}`;
					},
				},
				{
					dataIndex: 'total',
					title: `Amount (${fixtureCurrency})`,
					align: 'right',
					render: (c: number, record: VoyageBunkerEntry) => {
						const price = record.pricePerTon;

						return `${formatCurrency(price * record.quantity, fixtureCurrency, { hideSymbol: true })}`;
					},
				},
			]
		),
		{
			dataIndex: 'state',
			title: 'State',
			render: (state: Values<typeof AccountingItemApprovalStates>) => (
				<StateTag state={state} />
			),
			width: 100,
			sorter: standardSort('state'),
			...getFilterProps([
				HireInvoiceItemStates.PENDING,
				HireInvoiceItemStates.INVOICED,
				HireInvoiceItemStates.UNRESOLVED,
				HireInvoiceItemStates.PAID,
			], 'select', 'state', undefined, true),
		},
	];

	const voyageId = voyageDetails.id;

	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<BunkersTable
					childType={ChildBunkerTypes.VOYAGE}
					bunkers={voyageDetails?.voyageBunkers}
					vesselOrVoyageId={voyageId}
					refreshDetails={refreshDetails}
					currency={fixtureCurrency}
					// @ts-ignore align issue
					columns={bunkerColumns}
				/>
			</Col>
			<Col span={24}>
				<ExpensesTable
					title="Voyage expenses"
					id={voyageId}
					refreshDetails={refreshDetails}
					fixtureCurrency={fixtureCurrency}
					fixtureType={fixtureDetails.type}
					expenses={expenses}
				/>
			</Col>
			<Col span={24}>
				<OffHireTable
					id={voyageId}
					details={voyageDetails}
					fixtureDetails={fixtureDetails}
					refreshDetails={refreshDetails}
				/>
			</Col>
			<Col span={24}>
				<div style={{ height: 100 }} />
			</Col>
		</Row>
	);
};

export default TcExpensesTab;
