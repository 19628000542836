import React from 'react';
import {
	faCrosshairs,
	faCrosshairsSimple,
	faLock,
	faLockOpen,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Flex,
	Form,
	Popover,
	Space,
} from 'antd';
import classNames from 'classnames';
import { formatCurrency } from '@shared/utils/currency';
import { round } from '@shared/utils/math';
import { Values } from '@shared/utils/objectEnums';
import { FuelTypes } from '@shared/utils/constants';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import type { CargoDetails } from '@api/features/cargos/getCargoDetails';
import NumericInput from '@client/components/NumericInput';
import Button from '@client/components/Button';
import styles from '../components/styles/EstimatedPnL.module.css';

type FuelType = Values<typeof FuelTypes>;
const getEstimatedPnlItems = (
	estimate: GetEstimateDetailsResponse,
	onSetTargetTce: (targetTce: number) => void,
	onLockTce: () => void,
	cargos: CargoDetails[],
	expandBunkers: boolean,
	setExpandBunkers: (expand: boolean) => void,
) => {
	const bunkerExpenses = estimate.bunkerExpenses ?? {};

	return [
		{
			label: 'Freight',
			span: 3,
			children: formatCurrency(estimate.result.freight ?? 0, estimate.currency),
		},
		{
			label: 'Other Revenue',
			span: 3,
			children: formatCurrency(estimate.result.otherRevenue ?? 0, estimate.currency),
		},
		{
			label: 'Demurrage',
			span: 3,
			children: formatCurrency(estimate.result.demurrage ?? 0, estimate.currency),
		},
		{
			label: 'Despatch',
			span: 3,
			children: formatCurrency(estimate.result.despatch ?? 0, estimate.currency),
		},
		{
			label: 'Address Commissions',
			span: 3,
			children: formatCurrency(estimate.result.addressCommissions ?? 0, estimate.currency),
		},
		{
			label: 'Broker Commissions',
			span: 3,
			children: formatCurrency(estimate.result.brokerCommissions ?? 0, estimate.currency),
		},
		{
			label: (<b>Net Revenue</b>),
			span: 3,
			children: formatCurrency(estimate.result.netRevenue ?? 0, estimate.currency),
		},
		{
			label: 'Port Costs',
			span: 3,
			children: formatCurrency(estimate.result.portCharge ?? 0, estimate.currency),
		},
		{
			label: (
				<Flex justify="space-between">
					Bunker Exp.
					<Button type="link" size="small" onClick={() => setExpandBunkers(!expandBunkers)}>
						{expandBunkers ? 'Hide' : 'Expand'}
					</Button>
				</Flex>
			),
			span: 3,
			children: formatCurrency(estimate.result.bunkerExpense ?? 0, estimate.currency),
		},
		...(expandBunkers ? Object.keys(bunkerExpenses)
			.map((key) => ({
				key,
				label: ` - ${round(bunkerExpenses[key as FuelType]?.consumption ?? 0, 0)}MT ${key}`,
				span: 3,
				children: formatCurrency(
					Number(bunkerExpenses[key as FuelType]?.pricePerTon) *
					Number(bunkerExpenses[key as FuelType]?.consumption) ?? 0,
					estimate.currency,
				),
			})) : []),
		{
			label: 'EU ETS Costs',
			span: 3,
			children: formatCurrency(estimate.result.totalEUAcost ?? 0, estimate.currency),
		},
		{
			label: 'Other Costs',
			span: 3,
			children: formatCurrency(estimate.result.otherVoyageExpenses ?? 0, estimate.currency),
		},
		{
			label: (<b>Total Voyage Exp</b>),
			span: 3,
			children: formatCurrency(estimate.result.totalVoyageExpenses ?? 0, estimate.currency),
		},
		{
			label: (<b>Net Profit</b>),
			span: 3,
			children: formatCurrency(estimate.result.voyageProfit ?? 0, estimate.currency),
		},
		{
			label: 'Total Days',
			span: 3,
			children: round((estimate.result.estimatedDuration ?? 0), 2).toFixed(2),
		},
		{
			label: (
				<Flex justify="space-between">
					<b>TCE /day</b>
					<Space size="small">
						<FontAwesomeIcon
							className={classNames(styles.cursor, {
								[styles.locked]: estimate.isTceLocked,
							})}
							onClick={onLockTce}
							icon={estimate.isTceLocked ? faLock : faLockOpen}
						/>
						<Popover
							content={(
								<Form
									initialValues={
										{
											targetTce: estimate.targetTce,
										}
									}
									onFinish={(values) => onSetTargetTce(values.targetTce)}
									layout="vertical"
								>
									<Form.Item name="targetTce" label="Target TCE">
										<NumericInput disabled={estimate.isTceLocked || cargos.length === 0} />
									</Form.Item>
									<Form.Item>
										<Button
											htmlType="submit"
											type="primary"
											disabled={cargos.length === 0 || estimate.isTceLocked}
										>
											Set Target TCE
										</Button>
									</Form.Item>
								</Form>
							)}
							trigger="click"
						>
							<FontAwesomeIcon
								color="#1677ff"
								className={styles.cursor}
								icon={estimate.targetTce == null ? faCrosshairs : faCrosshairsSimple}
							/>
						</Popover>
					</Space>
				</Flex>
			),
			span: 3,
			children: formatCurrency(estimate.result.tce ?? 0, estimate.currency),
		},
		{
			label: 'CO2 Emissions',
			span: 3,
			children: `${round(estimate.result.estimatedEmissions ?? 0, 0)} MT`,
		},
		{
			label: 'Vessel Costs',
			span: 3,
			children: formatCurrency(
				(estimate.result.tcInExpense ?? 0),
				estimate.currency,
			),
		},
		{
			label: 'Net Result',
			span: 3,
			children: formatCurrency(estimate.result.netProfit ?? 0, estimate.currency),
		},
		{
			label: 'Net Result /day',
			span: 3,
			children: formatCurrency(estimate.result.netProfitPerDay ?? 0, estimate.currency),
		},
	];
};

export default getEstimatedPnlItems;
