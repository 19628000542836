import React from 'react';
import ReactCalendarTimeline, {
	TimelineMarkers,
	CursorMarker,
	TimelineHeaders,
	DateHeader,
	TodayMarker,
	TimelineItemBase,
} from 'react-calendar-timeline';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { Grid } from 'antd';
import { Moment } from 'moment';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import styles from './styles/Timeline.module.css';
import ChartLegend from './ChartLegend';

export type Item = {
	id: string;
	period?: (Moment | null)[];
	link?: string;
	backgroundColor?: string;
	fontColor?: string;
	label?: string | null;
}

export type SectionItems = Array<{
	label: string;
	items?: Item[];
	id?: string;
}>

type EntryItem = TimelineItemBase<Date | Moment | undefined | null> & {
	link?: string;
}

type Props = {
	sections: SectionItems;
	legend: Array<{
		key?: string;
		label: string;
		fill?: string;
	}>;
	className: string;
	monthsToShow?: number;
	legendPosition?: 'top' | 'bottom';
	stickyHeader?: boolean;
	defaultTimeStart?: Moment;
	defaultTimeEnd?: Moment;
	showTodayMarker?: boolean;
	groupHeight?: number;
}

const defaultItemSettings = {
	canMove: false,
	canResize: false,
	canSelect: true,
	canChangeGroup: false,
};

const Timeline: React.FC<Props> = ({
	sections,
	defaultTimeStart = nowMoment().subtract(10, 'days').startOf('month'),
	defaultTimeEnd,
	showTodayMarker = true,
	groupHeight = 40,
	legend,
	legendPosition = 'bottom',
	className,
	monthsToShow: monthstoShowProp,
	stickyHeader,
	...props
}) => {
	const history = useHistory();

	const screens = Grid.useBreakpoint();

	// This is not optimal. If for some reason screens doesn't get initialized, the page won't render.
	if (screens.xl == null) {
		return null;
	}

	let monthsToShow = 2;

	if (screens.xl) {
		monthsToShow = 12;
	} else if (screens.lg) {
		monthsToShow = 8;
	} else if (screens.md) {
		monthsToShow = 6;
	}

	const defaultEndDate = defaultTimeEnd || toMoment(defaultTimeStart).add(monthstoShowProp || monthsToShow, 'months');

	if (legendPosition !== 'bottom' && legendPosition !== 'top') {
		throw new Error('Invalid legend position');
	}

	const items = sections.reduce((arr: EntryItem[], entry) => {
		const subItems = entry.items ?? [];
		const itemsWithLabels = subItems.filter((e) => e.label != null && e.label !== '');

		return [
			...arr,
			...subItems.map((i) => ({
				id: i.id,
				link: i.link,
				title: '',
				start_time: i.period?.[0] != null ? toMoment(i.period[0]) : null,
				end_time: i.period?.[1] != null ? toMoment(i.period[1]) : null,
				group: entry.id || entry.label,
				...defaultItemSettings,
				itemProps: {
					style: {
						background: i.backgroundColor,
						color: i.fontColor,
						pointerEvents: undefined,
					},
				},
			})),
			...itemsWithLabels.map((i) => ({
				id: `${i.id}-label`,
				title: i.label,
				link: i.link,
				start_time: i.period?.[0] != null ? toMoment(i.period[0]) : null,
				end_time: i.period?.[1] != null ? toMoment(i.period[1]) : null,
				group: entry.id ?? entry.label,
				...defaultItemSettings,
				itemProps: {
					style: {
						pointerEvents: undefined,
						background: 'transparent',
						color: i.fontColor || 'white',
					},
				},
			})),
		];
	}, []);

	const itemClick = (itemId: string) => {
		const matchingItem = items.find((i) => i.id === itemId);

		if (matchingItem != null && matchingItem.link != null) {
			history.push(matchingItem.link);
		}
	};

	return (
		<>
			{legend != null && legendPosition === 'top' && (
				<ChartLegend position="start" items={legend} />
			)}
			{/* ReactCalendarTimeline Moment & our Moment type clashes
			@ts-ignore */}
			<ReactCalendarTimeline<EntryItem>
				defaultTimeStart={defaultTimeStart}
				defaultTimeEnd={defaultEndDate}
				groups={sections.map((s) => ({
					...s,
					id: s.id || s.label,
					title: s.label,
					height: groupHeight,
				}))}
				items={items}
				// @ts-ignore
				className={classNames(styles.cleanerTimeline, className)}
				onItemSelect={itemClick}
				{...props}
			>
				<TimelineHeaders className={
					classNames({ [styles.sticky]: stickyHeader })
				}
				>
					<DateHeader unit="primaryHeader" />
					<DateHeader />
				</TimelineHeaders>
				{showTodayMarker && (
					<TimelineMarkers>
						<CursorMarker />
						<TodayMarker>
							{/* @ts-ignore */}
							{({ styles: markerStyles }) => (
								<div
									style={markerStyles}
									className={styles.todayMarker}
								/>
							)}
						</TodayMarker>
					</TimelineMarkers>
				)}
			</ReactCalendarTimeline>
			{legend != null && legendPosition === 'bottom' && (
				<ChartLegend position="start" items={legend} />
			)}
		</>
	);
};

export default Timeline;
