import React from 'react';
import { formatCurrency } from '@shared/utils/currency';
import { round } from '@shared/utils/math';
import { getStatForwardHireRate } from '@client/lib/api';
import { useAuth } from '@client/lib/auth';
import NumberWidget from '../components/NumberWidget';
import { useDashboardPeriod } from '../helpers/dashboardInfo';

const ForwardHireRateNumberWidget = () => {
	const period = useDashboardPeriod();
	const { userInfo } = useAuth();

	return (
		<NumberWidget
			title="Forward Gross Hire per day"
			badge="GROSS"
			headerTooltip={(data) => data && (
				`Expected gross hire of ${formatCurrency(round(data.meta.tcIncome, 0), userInfo.baseCurrency)} divided by ${data.meta.fixedDays} minimum fixed forward hire days`
			)}
			fetchData={getStatForwardHireRate}
			fetchDataParams={[period]}
			transformData={({ data }) => formatCurrency(round(data, 0), userInfo.baseCurrency)}
			forward
		/>
	);
};

export default ForwardHireRateNumberWidget;
