import React, { useState } from 'react';
import {
	Checkbox,
	Drawer,
	Typography,
} from 'antd';
import uuid from 'short-uuid';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { GetOrganizationDetailsResponse } from '@api/features/admin/getOrganizationDetails';
import Button from '@client/components/Button';
import { sendUserInvitations } from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import EditableTable from '@client/components/EditableTable';
import styles from './styles/InviteUsersDrawer.module.css';

export type UserInvite = {
	id: string;
	name: string;
	email: string;
	external: boolean;
	isManagement: boolean;
}

type Props = {
	open: boolean;
	organizationDetails: GetOrganizationDetailsResponse | {};
	onClose: () => void;
}

const InviteUsersDrawer = ({
	open,
	organizationDetails,
	onClose,
	...props
}: Props) => {
	const [data, setData] = useState<Array<UserInvite>>([]);
	const [loading, setLoading] = useState(false);
	const [editingValues, setEditingValues] = useState<Partial<UserInvite> | null>(null);

	const columns = [
		{
			dataIndex: 'name',
			title: 'Full name',
			editable: true,
		},
		{
			dataIndex: 'email',
			title: 'Email',
			editable: true,
		},
		{
			dataIndex: 'external',
			title: 'Non-Office 365',
			editable: true,
			editingProps: {
				type: 'check',
				inputProps: {
					onChange: (v: CheckboxChangeEvent) => {
						setEditingValues((prev) => ({
							...prev,
							external: v.target.checked,
						}));
					},
				},
			},
			render: (c: boolean) => (<Checkbox disabled checked={c} />),
		},
		{
			dataIndex: 'isManagement',
			title: 'Management',
			editable: true,
			editingProps: {
				type: 'check',
				inputProps: {
					onChange: (v: CheckboxChangeEvent) => {
						setEditingValues((prev) => ({
							...prev,
							isManagement: v.target.checked,
						}));
					},
				},
			},
			render: (c: boolean) => (<Checkbox disabled checked={c} />),
		},
	];

	const submit = async () => {
		setLoading(true);

		if (!('id' in organizationDetails)) {
			return;
		}

		try {
			await sendUserInvitations(organizationDetails.id, data);
			showSuccessNotification(
				'Team Members successfully invited',
				`${data.length} Team Members have been invited`,
			);

			setData([]);
			onClose();
		} catch (e) {
			showErrorNotification(
				'Could not invite Team Members',
				e as Error,
			);
		}

		setLoading(false);
	};

	const addRow = (newRow: UserInvite) => {
		setData((prev) => (
			[
				...prev,
				{
					...newRow,
					id: uuid.generate(),
					external: editingValues?.external ?? false,
					isManagement: editingValues?.isManagement ?? false,
				}]
		));
		setEditingValues(null);
	};

	const saveRow = (id: string, newRow: UserInvite) => {
		const filtered = data.filter((d) => d.id !== id);
		setData([...filtered, {
			...newRow,
			id: uuid.generate(),
			external: editingValues?.external ?? false,
			isManagement: editingValues?.isManagement ?? false,
		}]);
		setEditingValues(null);
	};

	const deleteRow = (email: string) => {
		setData(data.filter((r) => r.email !== email));
	};

	return (
		<Drawer
			title="Invite Team Members"
			placement="left"
			visible={open}
			{...props}
			width={800}
			onClose={onClose}
		>
			<Typography.Title level={4}>
				Invite members to team
			</Typography.Title>
			<EditableTable
				size="small"
				// @ts-ignore
				columns={columns}
				dataSource={data}
				pagination={false}
				className={styles.table}
				allowAddNew
				enableDelete
				enableEdit={() => false}
				onAddNew={addRow}
				onDelete={deleteRow}
				onSave={saveRow}
				iconButtons
				keyDataIndex="email"
			/>
			<br />
			<Button
				data-cy="invite-users-submit"
				type="primary"
				onClick={submit}
				disabled={data.length === 0}
				loading={loading}
			>
				{`Invite ${data.length} Team Members`}
			</Button>
		</Drawer>
	);
};

export default InviteUsersDrawer;
