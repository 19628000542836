import React, {
	useMemo,
	useState,
} from 'react';
import {
	Badge,
	Card,
	Col,
	Descriptions,
	Drawer,
	Input,
	Row,
	Tabs,
} from 'antd';
import type { SortOrder } from 'antd/es/table/interface';
import { DownloadOutlined } from '@ant-design/icons';
import {
	DATE_AND_TIME,
	TheShipReportTypes,
} from '@shared/utils/constants';
import { standardSort } from '@shared/utils/standardSort';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import type { CrewReportsWithTypes } from '@api/utils/sequelize/getAllCrewReports';
import {
	getCrewReports,
	sendCrewAppInvitation,
} from '@client/lib/api';
import ErrorMessage from '@client/components/ErrorMessage';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { renderDate } from '@client/utils/table';
import Table from '@client/components/Table/Table';
import Button from '@client/components/Button';
import {
	getCrewAppStatus,
	getCrewAppStatusBadge,
} from '@client/utils/getCrewAppStatusBadge';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import { getReportSettingsAlert } from '@client/screens/fleet/VesselDetailsScreen/helpers/getReportSettingsAlert';
import getCrewReportItems from '../helpers/getCrewReportItems';
import styles from './styles/VesselCrewReportsTab.module.css';

type Props = {
	vessel: GetVesselDetailsResponse | undefined;
	vesselId: number;
	openReportDrawer: (report: CrewReportsWithTypes) => void;
	refreshVessel: () => void;
}

export const VesselCrewReportsTab = ({
	vessel,
	vesselId,
	openReportDrawer,
	refreshVessel,
}: Props) => {
	let defaultTab = vessel?.crewAppActivated ? 'reports' : 'settings';

	if (vessel?.thirdPartyNoonReports != null && vessel?.thirdPartyNoonReports.length > 0) {
		defaultTab = 'externalReports';
	}

	const [tabKey, setTabKey] = useState(defaultTab);
	const [externalReportToDisplay, setExternalReportToDisplay] = useState<string | null>(null);
	const [emailInput, setEmailInput] = useState(vessel?.crewAppEmail ?? undefined);
	const [crewReports, _refreshNoonReports, error, loading] = useFetchedState(
		async () => getCrewReports(vesselId),
		[vesselId],
	);

	const externalReports = vessel?.thirdPartyNoonReports.map((r) => ({
		...r,
		vesselName: r.vessel.name,
		type: TheShipReportTypes[r.type],
		date: r.dateAndTime,
		remarks: r.comments,
	}));

	const reports = (crewReports ?? []).map((nr) => ({
		...nr,
		vesselName: (vessel || {}).name,
	}));

	const alert = useMemo(() => getReportSettingsAlert(vessel), [vessel]);

	const columns = [
		{
			title: 'Vessel Name',
			dataIndex: 'vesselName',
			key: 'vesselName',
			width: 1,
		},
		{
			title: 'Report Type',
			dataIndex: 'type',
			key: 'type',
			width: 1,
		},
		{
			title: 'Report Date',
			dataIndex: 'date',
			key: 'date',
			sorter: standardSort('date'),
			render: renderDate(DATE_AND_TIME),
			width: 1,
			defaultSortOrder: 'descend' as SortOrder,
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			key: 'remarks',
			ellipsis: true,
			width: 3,
		},
	];

	const sendInvitation = async () => {
		if (emailInput == null) {
			return;
		}

		try {
			await sendCrewAppInvitation({ vesselId, email: emailInput });
			await refreshVessel();
			await showSuccessNotification('Invitation successfully sent');
		} catch (e) {
			showErrorNotification('Could not send invitation', e as Error);
		}
	};

	const externalReportItems = useMemo(() => {
		const items = getCrewReportItems(vessel?.thirdPartyNoonReports, externalReportToDisplay);

		return items;
	}, [vessel, externalReportToDisplay]);

	if (error != null) {
		return (<ErrorMessage>{error.message}</ErrorMessage>);
	}

	if (vessel == null) {
		return (
			<Card>
				emtpy
			</Card>
		);
	}

	return (
		<>
			<Card>
				<Tabs
					onTabClick={(key) => setTabKey(key)}
					activeKey={tabKey}
				>
					<Tabs.TabPane
						key="reports"
						tab="Reports"
					>
						<Table
							loading={loading}
							dataSource={reports}
							columns={columns}
							onRowClick={openReportDrawer}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane
						key="externalReports"
						tab="External Reports"
					>
						<Table
							loading={loading}
							dataSource={externalReports}
							columns={columns}
							onRowClick={(report) => setExternalReportToDisplay(report.id)}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane
						key="settings"
						tab={(
							<Badge
								offset={[2, 2]}
								status={getCrewAppStatus(vessel)}
							>
								Settings
							</Badge>
						)}
					>
						<Card className={styles.settingsCard}>
							<Row gutter={[16, 16]}>
								<Col span={24}>
									<Row>
										<Col span={12}>
											<Input
												value={emailInput}
												onChange={(val) => setEmailInput(val.target.value)}
												placeholder="crew@ship.com"
											/>
										</Col>
										<Col span={6}>
											{/* eslint-disable-next-line react/forbid-component-props */}
											<Row justify="center" align="middle" style={{ height: '100%' }}>
												{getCrewAppStatusBadge(vessel, true)}
											</Row>
										</Col>
										<Col span={6}>
											<Row justify="end">
												<a
													aria-label="Download crew app instructions"
													href="https://cv-vms-public.s3.eu-central-1.amazonaws.com/User_Manual_ClearVoyage_Noon_Reporting.pdf"
												>
													<Button>
														<DownloadOutlined />
														Instructions
													</Button>
												</a>
											</Row>
										</Col>
									</Row>
								</Col>
								<Col span={24}>
									<Button
										type="primary"
										onClick={sendInvitation}
										disabled={emailInput == null || emailInput?.length === 0}
										confirmTitle={(vessel.crewAppActivated && emailInput !== vessel.crewAppEmail) ?
											'The reporting app is already activated with another email address. Changing this will disable reporting' +
												' from the old email, and enable it on the new. Are you sure you want to continue?' : null}
									>
										Send invitation
									</Button>
								</Col>
								<Col span={24}>
									{alert}
								</Col>
							</Row>
						</Card>
					</Tabs.TabPane>
				</Tabs>
			</Card>
			<Drawer
				width={600}
				onClose={() => setExternalReportToDisplay(null)}
				open={externalReportToDisplay != null}
			>
				<Descriptions
					title="External Report"
					items={externalReportItems}
					bordered
				/>
			</Drawer>
		</>
	);
};
