import React, {
	ReactNode,
	useEffect,
	useState,
} from 'react';
import {
	Empty,
	Tabs,
} from 'antd';
import type { AttachmentProps } from '@api/models/attachment';
import AttachmentViewer from './AttachmentViewer';
import styles from './styles/DocumentDetails.module.css';

export type DocumentDetailsProps = {
	body?: ReactNode;
	attachments: Array<AttachmentProps>;
	initialShownAttachment?: number;
	setShownAttachment?: (attachmentId: number) => void;
	autoFocusFirst?: boolean;
}

const DocumentDetails: React.FC<DocumentDetailsProps> = ({
	body,
	attachments,
	initialShownAttachment,
	setShownAttachment,
	autoFocusFirst = false,
}) => {
	const [currentTab, setCurrentTab] = useState((
		initialShownAttachment != null ?
			initialShownAttachment.toString() :
			undefined
	));

	useEffect(() => {
		if (autoFocusFirst) {
			setCurrentTab(attachments[0]?.id.toString());
		}
	}, [autoFocusFirst, attachments]);

	return (
		<Tabs
			onChange={(t) => {
				setCurrentTab(t);

				if (typeof setShownAttachment === 'function') {
					setShownAttachment(Number(t));
				}
			}}
			activeKey={currentTab}
			destroyInactiveTabPane
		>
			{body && (
				<Tabs.TabPane
					key="body"
					tab="Body"
				>
					<pre>
						{body}
					</pre>
				</Tabs.TabPane>
			)}
			{attachments.map((attachment) => (
				<Tabs.TabPane
					key={attachment.id.toString()}
					tab={(
						<span className={styles.tab}>
							{attachment.name}
							{attachment.cancelled && (
								<div className={styles.cancelledLine} />
							)}
						</span>
					)}
				>
					<div className={styles.attachmentWrapper}>
						<AttachmentViewer
							attachment={attachment}
						/>
					</div>
				</Tabs.TabPane>
			))}
			{!body && attachments.length === 0 && (
				<Tabs.TabPane
					key="no-content"
					tab="No content"
				>
					<Empty />
				</Tabs.TabPane>
			)}
		</Tabs>
	);
};

export default DocumentDetails;
