import React, { useState } from 'react';
import {
	Card,
	Col,
	Row,
} from 'antd';
import ModePicker from './components/ModePicker';
import TopBar from './components/TopBar';
import { EstimatorModes } from './helpers/types';
import EstimatedPnL from './components/EstimatedPnL';
import EstimateConfigurationCard from './components/EstimateConfigurationCard';
import CargosCard from './components/CargosCard';
import EstimateProvider from './context/EstimatorContext';
import PortRotationCard from './PortRotationCard';
import PortRotationProvider from './context/PortRotationContext';
import { CentralStoreProvider } from './context/CentralStoreContext';
import CargoProvider from './context/CargoContext';

const EstimateDetailsScreen = () => {
	const [mode, setMode] = useState<EstimatorModes>('normal');

	return (
		<CentralStoreProvider>
			<CargoProvider>
				<EstimateProvider>
					<PortRotationProvider>
						<div style={{ padding: 8 }}>
							<Row gutter={[8, 8]}>
								<Col span={3}>
									<ModePicker mode={mode} setMode={setMode} />
								</Col>
								<Col span={21}>
									<TopBar />
								</Col>
								<Col span={18}>
									<Row gutter={[8, 8]}>
										<Col span={24}>
											<EstimateConfigurationCard />
										</Col>
										<Col span={24}>
											<CargosCard />
										</Col>
										<Col span={24}>
											<PortRotationCard />
										</Col>
									</Row>
								</Col>
								<Col span={6}>
									<Card bodyStyle={{ padding: 0 }}>
										<EstimatedPnL />
									</Card>
								</Col>
							</Row>
						</div>
					</PortRotationProvider>
				</EstimateProvider>
			</CargoProvider>
		</CentralStoreProvider>
	);
};

export default EstimateDetailsScreen;
