import React, { useMemo } from 'react';
import {
	Row,
	Col,
	Alert,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { YearQuarterDashboardPeriods } from '@shared/utils/constants';
import { useAuth } from '@client/lib/auth';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getVessels } from '@client/lib/api';
import VoyageEventsTableWidget from '../widgets/VoyageEventsTableWidget';
import WarningsWidget from '../widgets/WarningsWidget';
import FullFleetTimeline from '../widgets/FullFleetTimelineWidget';
import { DashboardComponent } from '../helpers/dashboardInfo';
import styles from './OperationsDashboard.module.css';

const OperationsDashboard: DashboardComponent<YearQuarterDashboardPeriods> = () => {
	const { userInfo } = useAuth();
	const [vessels] = useFetchedState(getVessels);

	const dismissed = localStorage.getItem('vesselWarningDismissed') === 'true';
	const filterWarningDismissed = localStorage.getItem('filterWarningDismissed') === 'true';

	const shouldShowWarning = useMemo(() => {
		if (vessels == null) {
			return false;
		}

		let visibleVessels = vessels.length;

		if (userInfo.filterVessels && userInfo.relevantVessels.length > 0) {
			visibleVessels = userInfo.relevantVessels.length;
		}

		if (dismissed) {
			return false;
		}

		if (!userInfo.filterVessels || userInfo.relevantVessels.length === 0 || visibleVessels >= 15) {
			return true;
		}

		return false;
	}, [userInfo, vessels, dismissed]);

	const vesselFilter = userInfo.filterVessels && userInfo.relevantVessels.length > 0 ?
		userInfo.relevantVessels.map((v: { name: string }) => v.name) :
		null;

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					{!filterWarningDismissed && vesselFilter != null && vesselFilter.length > 0 && (
						<Alert
							message="You are currently viewing a filtered dashboard"
							description={(
								<>
									To see a complete dashboard, with all vessels of your fleet, go to
									<FontAwesomeIcon
										className={styles.icon}
										icon={faWrench as IconProp}
									/>
									<b>
										{' -> Vessels '}
									</b>
									and disable the relevant vessel filter.
								</>
							)}
							type="info"
							closable
							onClose={() => localStorage.setItem('filterWarningDismissed', 'true')}
							showIcon
							className={styles.alert}
						/>
					)}
					{shouldShowWarning && vessels != null && (
						<Alert
							message={`You are currently viewing ${vessels.length} vessels`}
							description={(
								<>
									To see a more focused dashboard, with only the vessels relevant to you, go to
									<FontAwesomeIcon
										className={styles.icon}
										icon={faWrench as IconProp}
									/>
									<b>
										{' -> Vessels '}
									</b>
									and select your relevant vessels.
								</>
							)}
							type="info"
							closable
							onClose={() => localStorage.setItem('vesselWarningDismissed', 'true')}
							showIcon
							className={styles.alert}
						/>
					)}
				</Col>
				<Col span={24}>
					<FullFleetTimeline />
				</Col>
				<Col xl={12} xs={24}>
					<WarningsWidget />
				</Col>
				<Col xl={12} xs={24}>
					<VoyageEventsTableWidget />
				</Col>
			</Row>
		</>
	);
};

export default OperationsDashboard;
