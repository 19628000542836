import { splitActionKey } from '@shared/utils/splitActionKey';
import { LaytimeTerms } from '@shared/utils/constants';
import { CargoProps } from '@api/models/cargo';
import { CargoPortProps } from '@api/models/cargo-port';
import { getEstimatedLaytimes } from '@api/features/cargos/utils/getEstimatedLaytimes';
import { round } from './math';

export const getCargoPortLaytimeEstimatedValues = (
	cargo: CargoProps,
	cp: CargoPortProps,
	estimatedWorkingDaysOverride?: number | null,
) => {
	const {
		totalTimeInPort,
		timeToCount,
		workingDays,
		idleDays,
	} = getEstimatedLaytimes(
		cp,
		estimatedWorkingDaysOverride,
	);

	const { action } = splitActionKey(cp.portAndActionKey);
	let laytimeLostOrGained = 0;
	let estimatedDemurrage = null;
	let estimatedDespatch = null;

	let demurrageRate;
	let despatchRate;

	switch (cargo.laytimeTerms) {
		case LaytimeTerms.NON_REVERSIBLE:
			demurrageRate = cp.demurrage;
			despatchRate = cp.despatch;
			break;

		case LaytimeTerms.REVERSIBLE:
			demurrageRate = cargo.demurrage;
			despatchRate = cargo.despatch;
			break;

		default:
		case LaytimeTerms.AVERAGED:
			if (action === 'loading') {
				demurrageRate = cargo.demurrageLoading;
				despatchRate = cargo.despatchLoading;
			} else {
				demurrageRate = cargo.demurrageDischarge;
				despatchRate = cargo.despatchDischarge;
			}

			break;
	}

	if (cp.timeAllowed != null && timeToCount != null) {
		laytimeLostOrGained = round(cp.timeAllowed - timeToCount, 2);
	}

	// Despatch - you're estimated to use LESS time than allowed
	if (laytimeLostOrGained > 0) {
		estimatedDespatch = round(laytimeLostOrGained * (despatchRate ?? 0), 2);
	}

	// Demurrage - you're estimated to use MORE time than allowed
	if (laytimeLostOrGained < 0) {
		estimatedDemurrage = round(laytimeLostOrGained * (demurrageRate ?? 0), 2);
	}

	const estimatedTimeAllowed = cargo.quantity / (cp.estimatedLoadingRate ?? cp.loadingRate ?? 0);

	return {
		laytimeLostOrGained,
		estimatedDemurrage,
		estimatedDespatch,
		estimatedTimeAllowed,
		totalTimeInPort,
		timeToCount,
		workingDays,
		idleDays,
	};
};
