import React, { SetStateAction } from 'react';
import { Tooltip } from 'antd';
import {
	CrewReportTypes,
	Currencies,
	FixtureTypes,
	FuelTypes,
	VcContractCompletionTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { formatCurrency } from '@shared/utils/currency';
import { round } from '@shared/utils/math';
import type { GetRobsResponse } from '@api/features/vessels/getRobs';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import Button from '@client/components/Button';
import {
	AdjustBunkerAmountAttributes,
	EditValues,
} from '@client/screens/fleet/VoyageDetailsScreen/tabs/BunkerExpenditureTab/BunkerExpenditureTab';
import NumericInput from '@client/components/NumericInput';
import { getBunkerRobExpenditureStrings } from '@client/screens/fleet/VoyageDetailsScreen/helpers/getBunkerRobExpenditureStrings';
import TooltipIcon from '@client/components/TooltipIcon';
import styles from './BunkerExpenditureTab.module.css';

type Props = {
	robs: GetRobsResponse;
	voyageDetails: GetVoyageDetailsResponse;
	setEditing: React.Dispatch<SetStateAction<EditValues | null>>;
	onChange: ({ robId, robBunkerId, bunkerId, adjustment }: AdjustBunkerAmountAttributes) => void;
	fixtureCurrency: Values<typeof Currencies>;
}

const getRobDetailsString = (
	robBunker: GetRobsResponse[number]['RobBunkers'][number] | undefined,
) => {
	if (robBunker == null) {
		return [];
	}

	const changeStrings: string[] = [];

	robBunker.fuelQueue.forEach((fq) => {
		if (fq.quantity === 0) {
			return;
		}

		changeStrings.push(`${round(fq.quantity + (fq.adjustment ?? 0), 3)} MT @ ${formatCurrency(fq.pricePerTon, fq.currency ?? 'USD')} / MT`);
	});

	return changeStrings;
};

export const getRobTableRows = ({
	robs,
	voyageDetails,
	setEditing,
	onChange,
	fixtureCurrency,
}: Props) => {
	if (robs == null || !Array.isArray(robs)) {
		return [];
	}

	const eventsRow = { key: 'events', label: '' };
	robs.forEach((rob) => {
		if (
			rob.event === CrewReportTypes.COMMENCEMENT
		) {
			let eventStr = `${rob.event} in ${rob?.port?.name}`;

			const currentFixtureType = voyageDetails.fixture?.type;
			const previousFixtureType = voyageDetails?.previousVoyage?.fixture?.type;

			const deliveryAndCommencementMatch = (
				voyageDetails.deliveryDate != null &&
				voyageDetails.deliveryDate.isSame(voyageDetails.commencementDate)
			) || voyageDetails.commencementDate == null;

			if (previousFixtureType === FixtureTypes.SPOT) {
				eventStr = `Commencement in ${rob?.port?.name} @ VC out completion - ${voyageDetails?.previousVoyage?.identifier}`;
			}

			if (previousFixtureType === FixtureTypes.TC_IN) {
				eventStr = `Commencement @ TC in delivery - ${voyageDetails?.previousVoyage?.identifier}`;
			}

			if (previousFixtureType === FixtureTypes.TC_OUT) {
				eventStr = `Commencement @ TC out redelivery - ${voyageDetails?.previousVoyage?.identifier}`;
			}

			if (currentFixtureType === FixtureTypes.TC_OUT && deliveryAndCommencementMatch) {
				eventStr = 'Commencement @ TC out delivery';
			}

			eventsRow[rob.id] = (
				<>
					{eventStr}
					{voyageDetails.previousVoyage == null && (
						<Button
							onClick={() => setEditing({
								event: CrewReportTypes.COMMENCEMENT,
								robs: rob.RobBunkers,
								robId: rob.id,
								allowSetPrice: true,
								date: rob.date,
								port: rob.port,
							})}
							type="link"
							padding={0}
						>
							Set ROB & Prices
						</Button>
					)}
				</>
			);
		} else {
			let eventStr: string = rob.event;

			if (rob.event === CrewReportTypes.COMPLETION) {
				if (voyageDetails.completionType === VcContractCompletionTypes.NEXT_CONTRACT) {
					eventStr = 'Completion (Next contract)';
				} else {
					eventStr = 'Completion (TC-in redelivery)';
				}
			}

			if (rob.event === CrewReportTypes.BUNKERS_ROB) {
				eventsRow[rob.id] = `${eventStr}`;
			} else {
				eventsRow[rob.id] = `${eventStr} in ${rob?.port?.name}`;
			}
		}
	});

	const allFuelTypes = robs.reduce((arr: Values<typeof FuelTypes>[], rob) => {
		const robFuelTypes = rob.RobBunkers.map((b) => b.fuelGrade);

		return [...arr, ...robFuelTypes];
	}, []);

	// Sorting the fuel grades in the order of FuelTypes
	const fuelTypeOrder = Object.keys(FuelTypes) as Array<keyof typeof FuelTypes>;

	const compareFuelTypes = (a: keyof typeof FuelTypes,
		b: keyof typeof FuelTypes) => fuelTypeOrder.indexOf(a) - fuelTypeOrder.indexOf(b);

	const fuelTypes = [
		...Array.from(new Set(allFuelTypes.map((fuelType) => fuelType as keyof typeof
			FuelTypes))).sort(compareFuelTypes),
	];

	const fuelTypeRows = fuelTypes.map((fuelType) => {
		const relevantRobs = robs.filter((rob) => (
			rob.RobBunkers.some((bunker) => bunker.fuelGrade === fuelType)
		));

		const robRow = { label: 'ROB' };
		const adjustmentRow = { label: 'Adjustment' };
		const changeRow = { label: 'Change' };
		const typeRow = {};

		relevantRobs?.forEach((relevantRob) => {
			let errorInData;
			const correctBunkerEntry = relevantRob.RobBunkers.find((b) => b.fuelGrade === fuelType);
			const vesselBunkerEntry = relevantRob.VesselBunkers.find(
				(vb) => vb.Bunker.fuelGrade === fuelType,
			);

			const isTcInRedelivery = relevantRob.event === CrewReportTypes.COMPLETION &&
				voyageDetails.completionType === VcContractCompletionTypes.TC_IN_DELIVERY;

			const isTcOutRedelivery = relevantRob.event === CrewReportTypes.REDELIVERY;

			let details: React.ReactElement[] | null = getRobDetailsString(correctBunkerEntry)
				.map((c) => (
					<p className={styles.robString}>{c}</p>
				));

			if (isTcInRedelivery) {
				details = null;
			}

			robRow[relevantRob.id] = (
				<div className={styles.robDetails}>
					<p>
						{
							correctBunkerEntry?.quantityToShow != null ?
								round(correctBunkerEntry.quantityToShow, 3) :
								correctBunkerEntry?.quantityToShow
						}
						{' '}
						MT
						{correctBunkerEntry?.error && (
							<TooltipIcon danger>
								The amount required for redelivery as per CP,
								and the actual amount ROB does not match
							</TooltipIcon>
						)}
					</p>
					{details}
				</div>
			);

			adjustmentRow[relevantRob.id] = (
				<NumericInput
					value={correctBunkerEntry?.adjustment}
					onChange={(adjustment) => {
						if (
							relevantRob.id == null ||
							correctBunkerEntry?.id == null ||
							adjustment == null
						) {
							return;
						}

						onChange({
							robId: relevantRob.id,
							robBunkerId: correctBunkerEntry.id,
							bunkerId: vesselBunkerEntry?.Bunker.id,
							adjustment,
						});
					}}
					addonAfter="MT"
				/>
			);

			const {
				quantityChangeColor,
				quantityChangeStr,
				priceChangeStr,
				bunkeredStr,
				bunkered,
			} = getBunkerRobExpenditureStrings(
				correctBunkerEntry,
				relevantRob.event,
				fixtureCurrency,
				isTcInRedelivery,
			);

			if (isTcOutRedelivery) {
				changeRow[relevantRob.id] = (<p style={{ fontSize: 12, color: 'grey', margin: 0 }}>{bunkeredStr}</p>);
			} else {
				changeRow[relevantRob.id] = (
					<div>
						<p style={{ color: quantityChangeColor, margin: 0 }}>
							{quantityChangeStr}
						</p>
						{bunkered != null && (
							<p style={{ fontSize: 12, color: 'grey', margin: 0 }}>{bunkeredStr}</p>
						)}
						{priceChangeStr?.map((c) => (
							<p style={{ fontSize: 12, color: 'grey', margin: 0 }}>
								{c}
							</p>
						))}
					</div>
				);
			}

			if (
				(
					relevantRob.event === CrewReportTypes.DELIVERY &&
					correctBunkerEntry?.fuelQueue[0]?.pricePerTon == null
				) ||
				(
					relevantRob.event === CrewReportTypes.BUNKERING &&
					vesselBunkerEntry != null &&
					vesselBunkerEntry.Bunker.pricePerTon == null
				) ||
				(
					relevantRob.event === CrewReportTypes.REDELIVERY &&
					(
						vesselBunkerEntry?.Bunker.pricePerTon == null &&
						correctBunkerEntry?.fuelQueue[0]?.pricePerTon == null
					)
				)
			) {
				typeRow[relevantRob.id] = (
					<>
						<b>... Missing bunker prices</b>
						<Button
							onClick={() => {
								if (
									relevantRob.event === CrewReportTypes.BUNKERING ||
									relevantRob.event === CrewReportTypes.REDELIVERY
								) {
									if (vesselBunkerEntry != null) {
										setEditing({
											...vesselBunkerEntry,
											...vesselBunkerEntry.Bunker,
											bunkerId: vesselBunkerEntry.bunkerId,
											robId: relevantRob.id,
										});
									}
								}

								if (relevantRob.event === CrewReportTypes.DELIVERY) {
									if (vesselBunkerEntry != null && correctBunkerEntry != null) {
										setEditing({
											...vesselBunkerEntry,
											...vesselBunkerEntry.Bunker,
											bunkerId: vesselBunkerEntry.bunkerId,
											robId: relevantRob.id,
											robBunkerId: correctBunkerEntry.id,
										});
									}
								}
							}}
							type="link"
						>
							Set Price
						</Button>
					</>
				);
			} else if (!errorInData) {
				typeRow[relevantRob.id] = '...';
			} else if (errorInData) {
				typeRow[relevantRob.id] = (
					<Tooltip title={`
					This is most likely caused by an error in data input, 
					where too many bunkers are consumed compared to what's left in the tank. 
					Rectify the error by correct the data entry error.
					`}
					>
						<p style={{ color: 'red', margin: 0 }}>
							Error encountered in data - unable to calculate expenditure
						</p>
					</Tooltip>

				);
			}

			if (errorInData) {
				robRow[relevantRob.id] = `Error: ${(correctBunkerEntry?.fuelQueue[0].quantity ?? 0) + (correctBunkerEntry?.adjustment ?? 0)} MT`;
				changeRow[relevantRob.id] = 'N/A';
			}
		});

		return ({
			key: fuelType,
			label: fuelType,
			...typeRow,
			children: [
				changeRow,
				adjustmentRow,
				robRow,
			],
		});
	});

	return [
		eventsRow,
		...fuelTypeRows,
	];
};
