import React, {
	FC,
	useMemo,
	useState,
} from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import { PlusOutlined } from '@ant-design/icons';
import { toMoment } from '@shared/utils/date';
import { standardSort } from '@shared/utils/standardSort';
import { formatCurrency } from '@shared/utils/currency';
import { sortByDates } from '@shared/utils/sortByDates';
import type { TransformedExpenses } from '@api/utils/getTransformedVoyageExpenses';
import StateTag from '@client/components/StateTag';
import CreateExpenseForm from '@client/components/CreateExpenseForm/CreateExpenseForm';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getVoyageExpenses } from '@client/lib/api';
import { stringSetToSortedList } from '@client/utils/stringSetToSortedList';
import Table from '@client/components/Table/Table';
import Button from '@client/components/Button';
import styles from '../ExpensesScreen/components/styles/ExpenseScreen.module.css';

type Props = {
	expenses: TransformedExpenses[];
	item: TransformedExpenses | undefined;
	setItemId: React.Dispatch<React.SetStateAction<number | undefined>>;
	refreshExpenses: () => void | Promise<void>;
};

const VendorExpensesTable: FC< Props > = ({ expenses, item, setItemId, refreshExpenses }) => {
	const [allExpenses, _refresh, _error, loading] = useFetchedState(getVoyageExpenses);

	const filterMaps = useMemo(() => {
		const contracts = new Set<string>();
		const suppliers = new Set<string>();
		const rebillableStatus = new Set<string>();
		const status = new Set<string>();

		allExpenses?.forEach((expense) => {
			if (expense.voyageIdentifier) {
				contracts.add(expense.voyageIdentifier);
			}

			if (expense.supplierName) {
				suppliers.add(expense.supplierName);
			}

			if (expense.receivable?.state) {
				rebillableStatus.add(expense.receivable?.state);
			}

			if (expense.state) {
				status.add(expense.state);
			}
		});

		return {
			contracts: stringSetToSortedList<string>(contracts),
			suppliers: stringSetToSortedList<string>(suppliers),
			rebillableStatus: stringSetToSortedList<string>(rebillableStatus),
			status: stringSetToSortedList<string>(status),
		};
	}, [allExpenses]);

	const vesselColumns: ColumnsType<TransformedExpenses> = [
		{
			width: 50,
			title: 'Id',
			dataIndex: 'customInvoiceId',
			key: 'customInvoiceId',
			render: (v) => (v ? (
				// eslint-disable-next-line react/forbid-component-props
				<Paragraph style={{ margin: 0, padding: 0 }} ellipsis={{ tooltip: v }}>
					<b>
						{v}
					</b>
				</Paragraph>
			) :
				(<b>-</b>)),
		},
		{
			title: 'Description',
			dataIndex: 'itemDescription',
			key: 'itemDescription',
			defaultSortOrder: 'ascend',
			render: (v) => (
				// eslint-disable-next-line react/forbid-component-props
				<Paragraph style={{ margin: 0 }} ellipsis={{ tooltip: v }}>
					{v}
				</Paragraph>
			),
		},
		{
			align: 'right',
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (v, record) => (v ? (
				formatCurrency(v, record.currency, { forceDecimals: true })
			) : '-'),
			sorter: standardSort('amount'),
		},
		{
			width: 95,
			title: 'Contract',
			dataIndex: 'voyageIdentifier',
			key: 'voyageIdentifier',
			filters: filterMaps.contracts.map((contract) => ({
				text: contract,
				value: contract,
			})),
			filterMultiple: true,
			onFilter: (value, record) => record.voyageIdentifier === value,
		},
		{
			title: 'Supplier',
			dataIndex: 'supplierName',
			key: 'supplierName',
			render: (v) => (
				// eslint-disable-next-line react/forbid-component-props
				<Paragraph style={{ margin: 0 }} ellipsis={{ tooltip: v }}>
					{v}
				</Paragraph>
			),
			filters: filterMaps.suppliers.map((supplier) => ({
				text: supplier,
				value: supplier,
			})),
			filterMultiple: true,
			onFilter: (value, record) => record.supplierName === value,
		},
		{
			width: 125,
			title: 'Invoice Date',
			dataIndex: 'invoiceDate',
			key: 'invoiceDate',
			render: (v) => (
				v ?? '-'
			),
			sorter: (a, b) => {
				if (a.invoiceDate == null) {
					return 1;
				}

				if (b.invoiceDate == null) {
					return -1;
				}

				return toMoment(a.invoiceDate).unix() - toMoment(b.invoiceDate).unix();
			},
		},
		{
			title: 'Rebillable status',
			dataIndex: ['receivable', 'state'],
			key: 'state',
			render: (v) => (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<StateTag
						className={styles.statusTag}
						state={v ?? 'Not created'}
					/>
				</div>
			),
			filters: filterMaps.rebillableStatus.map((rebillableStatus) => ({
				text: <StateTag
					className={styles.filterStatusTag}
					state={rebillableStatus}
				/>,
				value: rebillableStatus,
			})),
			filterMultiple: true,
			onFilter: (value, record) => record.receivable?.state === value,
		},
		{
			width: 95,
			title: 'Status',
			dataIndex: 'state',
			key: 'expense',
			render: (value) => (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<StateTag
						className={styles.statusTag}
						state={value ?? 'Not created'}
					/>
				</div>

			),
			filters: filterMaps.status.map((status) => ({
				text: <StateTag
					className={styles.filterStatusTag}
					state={status}
				/>,
				value: status,
			})),
			filterMultiple: true,
			onFilter: (value, record) => record.state === value,
		},
	];

	const [drawerVisible, setDrawerVisible] = useState(false);

	const sortedExpenses = useMemo(() => {
		if (expenses === undefined) {
			return [];
		}

		return sortByDates(expenses, 'createdAt', { newestFirst: true });
	},
	[expenses]);

	return (
		<>
			<div className={styles.tableHeaderSection}>
				<Title level={3}>
					Vendor Expenses
				</Title>
			</div>
			<Table
				loading={loading}
				scroll={{ x: 800, y: 500 }}
				columns={vesselColumns}
				dataSource={sortedExpenses}
				size="small"
				onRowClick={(rowItem) => setItemId(rowItem.id)}
				showSearch
				showExportToCsv
				rowClassName={(record) => (record === item ? styles.selectedRow : '')}
				searchAttributes={[
					'amount',
					'customInvoiceId',
					'itemDescription',
					'supplierName',
					'voyageIdentifier',
					'invoiceDate',
					'state',
				]}
				extraArea={(
					<Button
						// eslint-disable-next-line react/forbid-component-props
						style={{ marginLeft: 10 }}
						key="createExpense"
						onClick={() => setDrawerVisible(true)}
						type="primary"
						icon={(
							<PlusOutlined />
						)}
					>
						Create Expense
					</Button>
				)}
			/>
			<CreateExpenseForm
				open={drawerVisible}
				onClose={() => {
					setDrawerVisible(false);
					refreshExpenses();
				}}
			/>
		</>
	);
};

export default VendorExpensesTable;
