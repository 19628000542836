import React, { useState } from 'react';
import moment from 'moment';
import {
	CustomFieldParentTypes,
	CustomFieldType,
} from '@shared/utils/constants';
import { formatDate } from '@shared/utils/date';
import {
	createCustomField,
	deleteCustomField,
	getCustomFields,
	updateCustomField,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import EditableTable from '@client/components/EditableTable';
import useFetchedState from '@client/utils/hooks/useFetchedState';

type Props = {
	parentId: number;
	parentType: CustomFieldParentTypes;
}

const CustomFields = ({
	parentId,
	parentType,
}: Props) => {
	const handleCreate = async (
		attributes: any,
	) => {
		try {
			await createCustomField(attributes);
			refreshFields();
		} catch (e) {
			showErrorNotification('Could not create new custom field', e as Error);
		}
	};

	const handleUpdate = async (
		id: number,
		type: CustomFieldType,
		label: string,
		value: string | number | Date,
	) => {
		try {
			await updateCustomField({
				id,
				type,
				label,
				value,
			});
			refreshFields();
		} catch (e) {
			showErrorNotification('Could not update custom field', e as Error);
		}
	};

	const handleDelete = async (
		id: number,
	) => {
		try {
			await deleteCustomField(id);
			refreshFields();
		} catch (e) {
			showErrorNotification('Could not delete custom field', e as Error);
		}
	};

	const [fields, refreshFields] = useFetchedState(async () => {
		return await getCustomFields({
			parentId,
			parentType,
		});
	}, [parentId, parentType]);

	const [editingType, setEditingType] = useState<CustomFieldType>(CustomFieldType.TEXT);

	const renderField = () => {
		switch (editingType) {
			case CustomFieldType.NUMBER:
				return 'number';
			case CustomFieldType.TEXT:
				return 'textarea';
			case CustomFieldType.DATEPICKER:
				return 'date';
			default:
				return 'textarea';
		}
	};

	return (
		<EditableTable
			iconButtons
			enableDelete={() => true}
			enableEdit={() => true}
			allowAddNew
			keyDataIndex="id"
			columns={[
				{
					width: 135,
					title: 'Type',
					dataIndex: 'type',
					editable: true,
					editingProps: {
						options: Object.values(CustomFieldType).map((v) => ({ value: v, label: v })),
						type: 'select',
						inputProps: {
							showSearch: true,
							onChange: (v) => {
								setEditingType(v?.toString() as CustomFieldType);
							},
						},
					},
				},
				{
					title: 'Label',
					dataIndex: 'label',
					editable: true,
				},
				{
					title: 'Value',
					dataIndex: 'value',
					render: (value: any) => {
						if (moment.isMoment(value)) {
							return formatDate(value);
						}

						return value;
					},
					editable: true,
					editingProps: {
						type: renderField() as any,
						inputProps: {
							placeholder: 'Enter value',
							required: true,
						},
					},
				},
			]}
			dataSource={fields || []}
			size="small"
			pagination={false}
			onSave={async (id, value) => {
				await handleUpdate(
					id,
					value.type,
					value.label,
					value.value,
				);
			}}
			onAddNew={async (value) => {
				await handleCreate({
					parentId,
					parentType,
					type: editingType,
					label: value.label,
					value: value.value,
				});
			}}
			onDelete={async (id) => {
				await handleDelete(id);
			}}
			emptyText="No Custom Fields"
			addNewText={(
				<span style={{ fontSize: 'medium' }}>
					Custom fields
				</span>
			)}
		/>
	);
};

export default CustomFields;
