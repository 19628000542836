import {
	DashboardPeriods,
	DashboardTypes,
	DepartmentKey,
	Departments,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import FleetPnlDashboard from '@client/screens/general/HomeScreen/dashboards/FleetPnlDashboard';
import TcOutDashboard from '../dashboards/TcOutDashboard';
import OperationsDashboard from '../dashboards/OperationsDashboard';
import ReceivablesAgingDashboard from '../dashboards/ReceivablesAgingDashboard';
import OffHireDashboard from '../dashboards/OffHireDashboard';
import HomeDashboard from '../dashboards/HomeDashboard';
import OutstandingsDashboard from '../dashboards/OutstandingsDashboard';
import FleetTimelineDashboard from '../dashboards/FleetTimelineDashboard';
import { Dashboard } from './dashboardInfo';

const yearlyQuarterly = [DashboardPeriods.YEAR, DashboardPeriods.QUARTER];

const dashboards = [
	{
		key: DashboardTypes.HOME,
		label: 'Home',
		periodOptions: null,
		component: HomeDashboard,
	},
	{
		key: DashboardTypes.OFF_HIRE,
		label: 'Off-hire',
		periodOptions: yearlyQuarterly,
		component: OffHireDashboard,
	},
	{
		key: DashboardTypes.FLEET_PNL,
		label: 'Fleet P&L',
		periodOptions: yearlyQuarterly,
		component: FleetPnlDashboard,
	},
	{
		key: DashboardTypes.RECEIVABLES,
		label: 'Receivables',
		periodOptions: [DashboardPeriods.SIXTY_DAYS, DashboardPeriods.HUNDRED_TWENTY_DAYS],
		component: ReceivablesAgingDashboard,
	},
	{
		key: DashboardTypes.OUTSTANDINGS,
		label: 'Outstandings',
		periodOptions: null,
		component: OutstandingsDashboard,
	},
	{
		key: DashboardTypes.TC_OUT,
		label: 'TC Out',
		periodOptions: yearlyQuarterly,
		component: TcOutDashboard,
	},
	{
		key: DashboardTypes.TC_IN,
		label: 'Timeline',
		periodOptions: null,
		component: FleetTimelineDashboard,
	},
	{
		key: DashboardTypes.OPERATIONS,
		label: 'Operations',
		periodOptions: null,
		component: OperationsDashboard,
	},
] as const;

const departmentToDashboardTypesMap = {
	[Departments.CHARTERING.key]: null,
	[Departments.OPERATIONS.key]: null,
	[Departments.FINANCE.key]: null,
	[Departments.MANAGEMENT.key]: null,
	[Departments.ADMIN.key]: null,
};

const defaultDashboardsTypes = [
	DashboardTypes.HOME,
	DashboardTypes.TC_OUT,
	DashboardTypes.OUTSTANDINGS,
	DashboardTypes.RECEIVABLES,
	DashboardTypes.FLEET_PNL,
	DashboardTypes.OFF_HIRE,
	DashboardTypes.TC_IN,
];

const getDepartmentDashboards = (activeDepartment: DepartmentKey): Dashboard<Values<typeof DashboardPeriods>>[] => {
	const dashboardTypes = (
		departmentToDashboardTypesMap[activeDepartment] ||
		defaultDashboardsTypes
	);

	const departmentDashboards = (dashboardTypes
		.map((type) => dashboards.find((d) => d.key === type))
		.filter((d) => d != null)
	) as unknown as Dashboard<Values<typeof DashboardPeriods>>[];

	return departmentDashboards;
};

export default getDepartmentDashboards;
