import React, {
	useState,
	useCallback,
} from 'react';
import {
	Divider,
	Form,
	Input,
	Switch,
	Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {
	FormInstance,
	FormItemProps,
	FormProps,
} from 'antd/lib/form';
import {
	InputProps,
	TextAreaProps,
} from 'antd/lib/input';
import { UploadProps } from 'antd/lib/upload';
import NumericInput, { NumericInputProps } from '@client/components/NumericInput';
import Select, { SelectProps } from '@client/components/Select';
import Button from '@client/components/Button';
import APIError from '@client/utils/APIError';
import CurrencyInput, { CurrencyInputProps } from '@client/components/CurrencyInput';
import { ExtendUnique } from '@client/utils/ExtendUnique';
import DatePicker, { DatePickerProps } from './DatePicker';
import TooltipIcon from './TooltipIcon';
import styles from './styles/SimpleForm.module.css';
import MultiCurrencyInput, { MultiCurrencyInputProps } from './MultiCurrencyInput';
import CargoQuantityInput, { CargoQuantityInputProps } from './CargoQuantityInput';

const formLayout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
};

const bottomLayout = {
	wrapperCol: {
		offset: 8,
		span: 16,
	},
};

const getFileValue = (e: any) => {
	if (Array.isArray(e)) {
		return e;
	}

	return e && e.fileList;
};

type InputPropsByType = (
	| {
		type: 'text';
		inputProps?: InputProps;
	} | {
		type: 'number';
		inputProps?: NumericInputProps;
	} | {
		type: 'currency';
		inputProps?: CurrencyInputProps;
	} | {
		type: 'cargoQuantity';
		inputProps?: CargoQuantityInputProps;
	} | {
		type: 'multiCurrency';
		inputProps: MultiCurrencyInputProps;
	} | {
		type: 'textarea';
		inputProps?: TextAreaProps;
		rows: TextAreaProps['rows'];
	} | {
		type: 'select';
		inputProps?: SelectProps<any>;
		onChange?: SelectProps<any>['onChange'];
		options: SelectProps<any>['options'];
	} | {
		type: 'date';
		inputProps: DatePickerProps;
	} | {
		type: 'file';
		inputProps?: UploadProps;
	} | {
		type: 'switch';
		inputProps?: void;
	} | {
		type: 'divider';
		inputProps?: void;
	}
)

export type Field<Values> = {
	label: string;
	required: boolean;
	name: keyof Values;
	hide?: boolean;
	formItemProps?: FormItemProps;
	renderInput?: (props: { form: FormInstance<Values> }) => React.ReactNode;
	rules?: FormItemProps['rules'];
	tooltip?: string;
} & InputPropsByType;

export type SimpleFormProps<Values> = ExtendUnique<{
	dependencies?: Record<string, string>;
	onSubmit: (values: Values) => void;
	onChange?: (values: Partial<Values>) => void;
	submitButtonText?: string;
	form?: FormInstance<Values>;
	fields: Field<Values>[];
}, FormProps>;

const SimpleForm = <Values, >({
	fields,
	dependencies,
	onChange,
	onSubmit,
	submitButtonText,
	form: formOverride,
	...props
}: SimpleFormProps<Values>) => {
	const [form] = Form.useForm<Values>(formOverride);
	const [formLoading, setFormLoading] = useState<boolean>(false);
	const [formError, setFormError] = useState<string | null>(null);

	const valuesChange = useCallback((values: Partial<Values>) => {
		if (typeof onChange === 'function') {
			onChange(values);
		}

		setFormError(null);

		const [changedField] = Object.keys(values);

		// Dependencies are structured as { field: dependency }
		// Find all fields that depend on the changed field
		// And resets the dependant fields
		const dependents = Object.entries(dependencies || {})
			.filter(([_, value]) => value === changedField)
			.map(([key, _]) => key);

		// Reset the depending fields
		form.resetFields(dependents);
	}, [setFormError, dependencies, form, onChange]);

	const submitForm = useCallback(async (values: Values) => {
		setFormLoading(true);
		setFormError(null);

		try {
			await onSubmit(values);

			// Submit succeeded!
			form.resetFields();
		} catch (error) {
			console.error(error);

			if (error instanceof APIError) {
				setFormError(error.message);
			} else {
				setFormError('An error occurred while submitting the form');
			}
		} finally {
			setFormLoading(false);
		}
	}, [form, onSubmit]);

	const renderField = (field: Field<Values>) => {
		if (field.hide === true) {
			return null;
		}

		const formItemProps = field.formItemProps ?? {};
		let input;

		switch (field.type) {
			case 'text':
				input = (
					<Input
						{...(field.inputProps ?? {})}
					/>
				);
				break;
			case 'number':
				input = (
					<NumericInput
						{...(field.inputProps ?? {})}
					/>
				);
				break;
			case 'currency':
				input = (
					<CurrencyInput
						{...(field.inputProps ?? {})}
					/>
				);
				break;
			case 'cargoQuantity':
				input = (
					<CargoQuantityInput
						{...(field.inputProps ?? {})}
					/>
				);
				break;
			case 'multiCurrency':
				input = (
					<MultiCurrencyInput
						{...(field.inputProps ?? {})}
					/>
				);
				break;
			case 'textarea':
				input = (
					<Input.TextArea
						rows={field.rows || 4}
						{...(field.inputProps ?? {})}
					/>
				);
				break;
			case 'select':
				input = (
					<Select
						placeholder={`Select a ${field.label.toLowerCase()}`}
						{...(field.inputProps ?? {})}
						onChange={field.onChange}
						options={field.options}
					/>
				);
				break;
			case 'date':
				input = (
					<DatePicker
						fullWidth
						{...(field.inputProps ?? {})}
					/>
				);
				break;
			case 'file':
				formItemProps.valuePropName = 'fileList';
				formItemProps.getValueFromEvent = getFileValue;
				input = (
					<Upload {...(field.inputProps ?? {})}>
						<Button>
							<UploadOutlined />
							Click to upload
						</Button>
					</Upload>
				);
				break;

			case 'switch':
				input = (<Switch />);
				break;

			case 'divider':
				// NOTICE: Simply returns a divider, not an input
				return (<Divider />);

			default:
				input = null;
				break;
		}

		if (field.renderInput != null) {
			input = field.renderInput({ form: form as unknown as FormInstance<Values> });
		}

		const rules = [...(field.rules || [])];

		if (field.required) {
			rules.push({ required: true, message: `${field.label} is required` });
		}

		return (
			<Form.Item
				key={field.name as string}
				label={field.tooltip != null ? (
					<p>
						{field.label}
						<TooltipIcon
							placement="top"
							arrowPointAtCenter
						>
							{field.tooltip}
						</TooltipIcon>
					</p>
				) : field.label}
				name={field.name as string}
				className={styles.formItem}
				rules={rules.length > 0 ? rules : undefined}
				{...formItemProps}
			>
				{input}
			</Form.Item>
		);
	};

	return (
		<Form
			{...formLayout}
			colon={false}
			form={form}
			onFinish={submitForm}
			onValuesChange={valuesChange}
			{...props}
		>
			{fields.map(renderField)}
			<Form.Item
				{...bottomLayout}
				extra={(<span className={styles.error}>{formError}</span>)}
			>
				<Button
					type="primary"
					htmlType="submit"
					loading={formLoading}
				>
					{submitButtonText || 'Submit'}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default SimpleForm;
