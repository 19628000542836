import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import classNames from 'classnames';
import { FixtureTypes } from '@shared/utils/constants';
import { toMoment } from '@shared/utils/date';
import VesselDescription from '@client/components/VesselDescription';
import { getFleetTimelineData } from '@client/lib/api';
import LoadingIndicator from '@client/components/LoadingIndicator';
import { ChartColors } from '@client/utils/constants';
import Timeline from '@client/components/Timeline';
import { Links } from '@client/utils/links';
import { useAuth } from '@client/lib/auth';
import ChartLegend from '@client/components/ChartLegend';
import DashboardWidgetWrapper from '../components/DashboardWidgetWrapper';
import styles from './styles/TcInTimeline.module.css';

const getTimelineGroup = (data, vessel, label, type, single = false) => {
	const { fixtures, offHirePeriods } = data;

	const otherFixtures = fixtures.filter(
		(f) => f.vesselId === vessel.id && f.type !== FixtureTypes.SPOT,
	);
	const spotFixtures = fixtures.filter(
		(f) => f.vesselId === vessel.id && f.type === FixtureTypes.SPOT,
	);

	const vesselOffHirePeriods = offHirePeriods.filter((f) => f.vesselId === vessel.id);

	return {
		label,
		type,
		id: vessel.id,
		single,
		items: [
			...spotFixtures.map((f) => ({
				id: `${type}-${f.id}-spot`,
				label: f.identifier,
				link: Links.Voyage.get(f.voyageId),
				period: [f.minPeriod[0], f.maxPeriod[1]],
				backgroundColor: ChartColors.GREEN,
			})),
			...otherFixtures.map((f) => ({
				id: `${type}-${f.id}-fixed`,
				label: f.identifier,
				link: Links.Voyage.get(f.voyageId),
				period: f.minPeriod,
				backgroundColor: ChartColors.BLUE,
			})),
			...otherFixtures
				.filter((f) => !toMoment(f.minPeriod[1]).isSame(f.maxPeriod[1]))
				.map((f) => ({
					id: `${type}-${f.id}-redelivery`,
					link: Links.Voyage.get(f.voyageId),
					period: [f.minPeriod[1], f.maxPeriod[1]],
					backgroundColor: ChartColors.LIGHTBLUE,
				})),
			...vesselOffHirePeriods.map((o) => ({
				id: `${type}-${o.id}-offHire`,
				link: Links.Voyage.get(o.voyageId),
				period: o.period,
				backgroundColor: ChartColors.RED,
			})),
		],
	};
};

const FullFleetTimeline = () => {
	const { userInfo } = useAuth();

	const vesselFilter = userInfo.filterVessels && userInfo.relevantVessels.length > 0 ?
		userInfo.relevantVessels.map((v) => v.name) :
		null;

	return (
		<DashboardWidgetWrapper
			header="Fleet Timeline"
			fetchData={getFleetTimelineData}
			fetchDataParams={[[
				FixtureTypes.TC_OUT,
				FixtureTypes.TC_IN,
				FixtureTypes.SPOT,
			]]}
			renderWidget={({ data, loading }) => {
				if (loading) {
					return (<LoadingIndicator />);
				}

				const timelineData = {
					[FixtureTypes.TC_OUT]: {
						fixtures: [],
						offHirePeriods: [],
						vessels: [],
					},
					[FixtureTypes.TC_IN]: {
						fixtures: [],
						offHirePeriods: [],
						vessels: [],
					},
					[FixtureTypes.SPOT]: {
						fixtures: [],
						offHirePeriods: [],
						vessels: [],
					},
				};

				data.fixtures.forEach((f) => timelineData[f.type].fixtures.push(f));
				data.offHirePeriods.forEach((o) => timelineData[o.fixtureType].offHirePeriods.push(o));

				const tcOutData = timelineData[FixtureTypes.TC_OUT];
				const tcInData = timelineData[FixtureTypes.TC_IN];
				const spotData = timelineData[FixtureTypes.SPOT];

				const outFixtures = [...tcOutData.fixtures, ...spotData.fixtures];
				const outOffHire = [...tcOutData.offHirePeriods, ...spotData.offHirePeriods];

				const { vessels } = data;

				const filteredVessels = (
					vesselFilter != null && vesselFilter.length > 0) ?
					vessels.filter((v) => vesselFilter.some((f) => f === v.name)) :
					vessels;

				if (filteredVessels.length === 0) {
					return (
						<>
							<Typography.Title level={5}>No vessels found</Typography.Title>
							<Typography.Paragraph>
								Go to
								{' '}
								<Link to={Links.Vessels.get()}>vessels</Link>
								{' '}
								to create one.
							</Typography.Paragraph>
						</>
					);
				}

				const items = filteredVessels.reduce((arr, v) => {
					const hasInData = tcInData.fixtures.some((f) => f.vesselId === v.id);

					if (hasInData) {
						return [
							...arr,
							{
								label: (
									<VesselDescription
										name={v.name}
										flag={v.flag}
									/>
								),
								id: v.id,
								items: [],
							},
							...(hasInData ? [getTimelineGroup(tcInData, v, 'TC In', FixtureTypes.TC_IN)] : []),
							getTimelineGroup({ fixtures: outFixtures, offHirePeriods: outOffHire }, v, 'Out', 'out'),
						];
					}

					return [
						...arr,
						getTimelineGroup(
							{ fixtures: outFixtures, offHirePeriods: outOffHire },
							v,
							(<VesselDescription
								name={v.name}
								flag={v.flag}
							/>),
							'out',
							true,
						),
					];
				}, []);

				const legendItems = [
					{
						key: 'fixedPeriod',
						label: 'Fixed Period',
						fill: ChartColors.BLUE,
					},
					{
						key: 'spotPeriod',
						label: 'VC-Out',
						fill: ChartColors.GREEN,
					},
					{
						key: 'redeliveryPeriod',
						label: 'Redelivery Period',
						fill: ChartColors.LIGHTBLUE,
					},
					{
						key: 'offHire',
						label: 'Off-Hire',
						fill: ChartColors.RED,
					},
				];

				return (
					<>
						<div className={styles.fleetTimelineWrapper}>
							<Timeline
								stickyHeader
								className={styles.fleetTimeline}
								groupRenderer={({ group }) => (
									group.type == null || group.single ? (
										<Link to={Links.Vessel.get(group.id)}>{group.title}</Link>
									) : (
										<div
											className={classNames(styles.subGroup, {
												[styles.last]: group.type === 'out',
											})}
										>
											{group.title}
										</div>
									))}
								sections={items}
								selected={[-1]}
								groupHeight={40}
							/>
						</div>
						<ChartLegend items={legendItems} />
					</>
				);
			}}
		/>
	);
};

export default FullFleetTimeline;
