import React from 'react';
import {
	Card,
	Col,
	Row,
} from 'antd';
import NewFleetTimeline from '../components/NewFleetTimeline';

const FleetTimelineDashboard = () => {
	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<Card title="Fleet Timeline">
					<NewFleetTimeline />
				</Card>
			</Col>
		</Row>
	);
};

export default FleetTimelineDashboard;
