// @ts-nocheck

import { Moment } from 'moment';
import {
	Currencies,
	FixtureTypes,
	FuelZoneTypes,
	YearQuarterDashboardPeriods,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import { formatDate } from '@shared/utils/date';
import { sendRequest } from '@client/lib/sendRequest';

export const updateOrganization = (organizationId: number, attributes) => sendRequest(
	`/admin/organizations/${organizationId}`,
	'patch',
	{ attributes },
);

export const updateUserGroup = (groupId: number, attributes) => sendRequest(
	`/organization/user-groups/${groupId}`,
	'patch',
	{ attributes },
);

export const getWelcome = () => sendRequest('/');

export const getOrganizations = () => sendRequest('/admin/organizations');

export const createOrganization = (name: string, providerId: string) => sendRequest(
	'/admin/organizations',
	'post',
	{ name, providerId },
);

export const deleteOrganization = (organizationId: number) => sendRequest(
	`/admin/organizations/${organizationId}`,
	'delete',
);

export const getForwardCoverage = (period: YearQuarterDashboardPeriods) => sendRequest(
	'/statistics/commercial-utilisation',
	'get',
	{
		period,
		forward: true,
	},
);

export const getCommercialUtilisation = (period: YearQuarterDashboardPeriods) => sendRequest(
	'/statistics/commercial-utilisation',
	'get',
	{ period },
);

export const getStatNetVoyageProfits = () => sendRequest('/statistics/net-voyage-profits');

export const getStatTotalInvoiced = (period: YearQuarterDashboardPeriods, useRelevantVessels = false) => sendRequest(
	'/statistics/invoiced',
	'get',
	{ period, useRelevantVessels },
);

export const getStatTcInNetCost = (period: YearQuarterDashboardPeriods, useRelevantVessels = false) => sendRequest(
	'/statistics/tc-in-net-cost',
	'get',
	{ period, useRelevantVessels },
);

export const getStatTcInNetCostPerDay = (
	period: YearQuarterDashboardPeriods,
	useRelevantVessels = false,
) => sendRequest(
	'/statistics/tc-in-net-cost-per-day',
	'get',
	{ period, useRelevantVessels },
);

export const getStatTcInForwardGrossCost = (period: YearQuarterDashboardPeriods) => sendRequest(
	'/statistics/tc-in-forward-gross-cost',
	'get',
	{ period },
);

export const getStatTcInForwardGrossCostPerDay = (period: YearQuarterDashboardPeriods) => sendRequest(
	'/statistics/tc-in-forward-gross-cost-per-day',
	'get',
	{ period },
);

export const getStatTotalPayments = () => sendRequest('/statistics/payments');

export const getStatTotalOutstanding = () => sendRequest('/statistics/outstanding');

export const getStatNetRevenue = () => sendRequest('/statistics/net-revenue');

export const getStatNetProfit = (period: YearQuarterDashboardPeriods, useRelevantVessels = false) => sendRequest(
	'/statistics/net-profit',
	'get',
	{ useRelevantVessels, period },
);

export const getStatCapexOpex = (period: YearQuarterDashboardPeriods, useRelevantVessels = false) => sendRequest(
	'/statistics/fleet-capex-opex',
	'get',
	{ period, useRelevantVessels },
);

export const getStatFleetTce = (period: YearQuarterDashboardPeriods, useRelevantVessels = false) => sendRequest(
	'/statistics/fleet-tce',
	'get',
	{ period, useRelevantVessels },
);

export const getStatForwardTotalInvoiced = (period: YearQuarterDashboardPeriods) => sendRequest(
	'/statistics/forward-total-invoiced',
	'get',
	{ period },
);

export const getStatForwardHire = (period: YearQuarterDashboardPeriods) => sendRequest(
	'/statistics/forward-hire',
	'get',
	{ period },
);

export const getStatForwardHireRate = (period: YearQuarterDashboardPeriods) => sendRequest(
	'/statistics/forward-hire-per-day',
	'get',
	{ period },
);

export const getFleetTimelineData = (fixtureTypes: Values<typeof FixtureTypes>[]) => sendRequest(
	'/statistics/fleet-timeline-data',
	'get',
	{ fixtureTypes },
);

export const getActiveVoyagesSummary = () => sendRequest('/statistics/active-voyages-summary');

export const getVoyageEvents = () => sendRequest('/statistics/voyage-events');

export const getTotalAmountsByCharterer = () => sendRequest('/statistics/total-amounts-by-charterer');

export const getExposureForActiveVoyages = () => sendRequest('/statistics/exposure-for-active-voyages');

export const getTotalAmountsByVoyage = () => sendRequest('/statistics/total-amounts-by-voyage');

export const getOrganizationUsers = () => sendRequest('/organization/users');

export const getOrganizationUserDetails = (userId: number) => sendRequest(`/organization/users/${userId}`);

export const getUserGroups = () => sendRequest('/organization/user-groups');

export const getUserGroupDetails = (groupId: number) => sendRequest(`/organization/user-groups/${groupId}`);

export const createUserGroup = (name: string) => sendRequest(
	'/organization/user-groups',
	'post',
	{ name },
);

export const deleteUserGroup = (groupId: number) => sendRequest(
	`/organization/user-groups/${groupId}`,
	'delete',
);

export const deleteVessel = (id: number) => sendRequest(`/vessels/${id}`, 'delete');

export const createVesselOwnershipPeriod = (vesselId: number, fromDate: Moment, toDate: Moment | null) => sendRequest(
	`/vessels/${vesselId}/ownership-periods`,
	'post',
	{ fromDate, toDate },
);

export const createVesselFinancialData = (vesselId: number, year: number,
	month: Date | Moment | null, capex: number, opex: number) => sendRequest(
	`/vessels/${vesselId}/financial-data`,
	'post',
	{ year, month, capex, opex },
);

export const updateVesselFinancialData = (vesselId: number, entryId: number, attributes) => sendRequest(
	`/vessels/${vesselId}/financial-data/${entryId}`,
	'patch',
	{ attributes },
);

export const deleteVesselFinancialData = (vesselId: number, entryId: number) => sendRequest(
	`/vessels/${vesselId}/financial-data/${entryId}`,
	'delete',
);

export const updateVesselOwnershipPeriod = (vesselId: number, ownershipPeriodId: number, attributes) => sendRequest(
	`/vessels/${vesselId}/ownership-periods/${ownershipPeriodId}`,
	'patch',
	{ attributes },
);

export const deleteVesselOwnershipPeriod = (vesselId: number, ownershipPeriodId: number) => sendRequest(
	`/vessels/${vesselId}/ownership-periods/${ownershipPeriodId}`,
	'delete',
);

export const createVesselPerformanceEntry = (
	vesselId: number,
	fuelZoneType: Values<typeof FuelZoneTypes>,
	speed: number,
	ballast: number,
	laden: number,
) => sendRequest(
	`/vessels/${vesselId}/performance-entries`,
	'post',
	{ fuelZoneType, speed, ballast, laden },
);
export const getSupplierDetails = (supplierId: number) => sendRequest(`/suppliers/${supplierId}`);

export const createOffHirePeriod = (
	voyageId: number,
	startTime: Moment,
	endTime: Moment,
	preferDuration: boolean,
	bunkers,
	hirePerDay: number,
	percentageForOwnersAccount: number,
	note: string,
	invoiceNote: string,
	type: string | null,
) => sendRequest(
	`/voyages/${voyageId}/off-hire`,
	'post',
	{
		startTime,
		endTime,
		preferDuration,
		bunkers,
		hirePerDay,
		percentageForOwnersAccount,
		note,
		invoiceNote,
		type,
	},
);

// 'extra' is stuff like 'hireRate'
export const getHireInvoicePreview = (
	voyageId: number,
	hireInvoiceItems: HireInvoiceItem[],
	extra,
): Promise<string> => sendRequest(
	'/invoices/hire-invoices/preview',
	'post',
	{
		voyageId,
		items: hireInvoiceItems,
		...(extra || {}),
	},
);

export const deleteHireInvoice = (id: number) => sendRequest(`/invoices/hire-invoices/${id}`, 'delete');

export const createVoyageExpense = (params: {
	voyageId: number;
	attachments: any; // TODO !
	itemDescription: string;
	customInvoiceId?: string | null;
	invoiceDate: Moment | null;
	dueDate: Moment | null;
	amount: number | null;
	note: string;
	currency: Values<typeof Currencies>;
	exchangeRate: number;
	supplierId: number;
	documentId?: number;
	isPortDa?: boolean;
	portCallId?: number;
	charterer?: number;
	estimated?: boolean;
	type?: 'payable' | 'payable_and_receivable';
}) => {
	const data = new FormData();

	if (params.attachments != null) {
		params.attachments.forEach((a) => data.append('attachments', a));
	}

	const parameters = {
		...params,
		invoiceDate: params.invoiceDate && formatDate(params.invoiceDate),
		dueDate: params.dueDate && formatDate(params.dueDate),
	};

	// Add all not-null values to form data
	Object.entries(parameters).forEach(([key, value]) => {
		if (value != null) {
			data.append(key, value);
		}
	});

	return sendRequest('/invoices/voyage-expenses', 'post', data);
};

export const updateOrganizationLogo = (orgId: number, file) => {
	const data = new FormData();
	data.append('logo', file);

	return sendRequest(
		`admin/organizations/${orgId}/logo`,
		'post',
		data,
	);
};

export const updateVesselLogo = (id: number, file) => {
	const data = new FormData();
	data.append('logo', file);

	return sendRequest(
		`/vessels/${id}/logo`,
		'post',
		data,
	);
};

export const submitExpense = (expenseId) => sendRequest(
	`/invoices/voyage-expenses/${expenseId}/submit`,
	'post',
);
export const unsubmitExpense = (expenseId) => sendRequest(
	`/invoices/voyage-expenses/${expenseId}/unsubmit`,
	'post',
);
export const markExpensePaid = (expenseId) => sendRequest(
	`/invoices/voyage-expenses/${expenseId}/mark-paid`,
	'post',
);
export const markExpenseUnpaid = (expenseId) => sendRequest(
	`/invoices/voyage-expenses/${expenseId}/mark-unpaid`,
	'post',
);

export const getExchangeRates = (baseCurrency) => sendRequest(`/financials/exchange-rates/${baseCurrency}`);

export const updateDifference = (voyageId, differenceId, attributes) => sendRequest(
	`/voyages/${voyageId}/differences/${differenceId}`,
	'patch',
	{ attributes },
);

export const markFixtureAsFixed = (id) => sendRequest(`/fixtures/${id}/fix`, 'post', { useOwnershipPeriod: true });

export const markFixtureAsCompleted = (id) => sendRequest(`/fixtures/${id}/complete`, 'post');

export const resumeFixture = (id) => sendRequest(`/fixtures/${id}/resume`, 'post');

export const autofillFixture = (id, otherFixtureId) => sendRequest(
	`/fixtures/${id}/autofill`,
	'post',
	{ otherFixtureId },
);

export const createFixture = (
	vesselId,
	counterpartyId,
	identifier,
	fixtureType,
) => sendRequest(
	'/fixtures',
	'post',
	{
		vesselId,
		counterpartyId,
		identifier,
		fixtureType,
	},
);

export const createExpenseSubjectToHireDays = (fixtureId, { name, amount, interval }) => sendRequest(
	`/fixtures/${fixtureId}/expenses-subject-to-hire-days`,
	'post',
	{ name, amount, interval },
);

export const updateExpenseSubjectToHireDays = (fixtureId, expenseId, attributes) => sendRequest(
	`/fixtures/${fixtureId}/expenses-subject-to-hire-days/${expenseId}`,
	'patch',
	{ attributes },
);

export const deleteExpenseSubjectToHireDays = (fixtureId, expenseId) => sendRequest(
	`/fixtures/${fixtureId}/expenses-subject-to-hire-days/${expenseId}`,
	'delete',
);

export const addFixtureBroker = (fixtureId, { brokerId, commission, paidBy }) => sendRequest(
	`/fixtures/${fixtureId}/brokers`,
	'post',
	{ brokerId, commission, paidBy },
);

export const updateFixtureBroker = (fixtureId, brokerId, attributes) => sendRequest(
	`/fixtures/${fixtureId}/brokers/${brokerId}`,
	'patch',
	{ attributes },
);

export const removeFixtureBroker = (fixtureId, brokerId) => sendRequest(
	`/fixtures/${fixtureId}/brokers/${brokerId}`,
	'delete',
);

