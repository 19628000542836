import React from 'react';
import {
	Card,
	Col,
	Empty,
	Row,
	Typography,
} from 'antd';
import {
	Bar,
	BarChart,
	LabelList,
	Tooltip,
	XAxis,
	YAxis,
	LabelProps,
} from 'recharts';
import { CartesianViewBox } from 'recharts/types/util/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { YearQuarterDashboardPeriods } from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { round } from '@shared/utils/math';
import { useAuth } from '@client/lib/auth';
import { DashboardComponent } from '@client/screens/general/HomeScreen/helpers/dashboardInfo';
import { getOffHireData } from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import TableWidget from '../components/TableWidget';
import ChartWidget from '../components/ChartWidget';
import styles from './OffHireDashboard.module.css';

const OffHireDashboard: DashboardComponent<YearQuarterDashboardPeriods> = ({ period }) => {
	const { userInfo } = useAuth();

	const [offhireData] = useFetchedState(
		() => getOffHireData(period),
		[period],
	);

	if (offhireData == null || offhireData?.length === 0) {
		return (
			<div className={styles.emptyWrapper}>
				<Card>
					<Empty
						image={(
							<FontAwesomeIcon
								color="crimson"
								size="4x"
								icon={faChartPie as IconProp}
							/>
						)}
						description={(
							<>
								<Typography.Title level={3}>
									This dashboard needs more data
								</Typography.Title>
								<Typography.Text type="secondary">
									Raise a hire invoice with off-hire to get starting using this dashboard
								</Typography.Text>
							</>
						)}
					/>
				</Card>
			</div>
		);
	}

	return (
		<Row gutter={[16, 16]}>
			<Col xs={24} xl={12}>
				<ChartWidget
					header="Total Off-hire Cost By Vessel"
					fetchData={getOffHireData}
					fetchDataParams={[period]}
					renderChart={(data) => {
						const renderCustomizedLabel = (props: LabelProps) => {
							const { x, y, width, value } = props;

							const viewBox = props.viewBox as CartesianViewBox;

							if (
								value == null ||
								viewBox == null ||
								viewBox?.height == null
							) {
								return (<text />);
							}

							return (
								<g>
									<text
										x={Number(x) + Number(width) + 10}
										y={Number(y) + 5 + (Number(viewBox.height) / 2)}
										fill="#275171"
										textAnchor="start"
										style={{ fontWeight: 'bold', fontSize: 16 }}
										dominantBaseline="end"
									>
										{
											value < 1_000_000 ?
												`${formatCurrency(Math.round(Number(value) / 1000), userInfo.baseCurrency)}K` :
												`${formatCurrency(round(Number(value) / 1_000_000, 2), userInfo.baseCurrency)}M`
										}
									</text>
								</g>
							);
						};

						return (
							<BarChart
								width={600}
								height={300}
								data={data}
								layout="vertical"
								margin={{ top: 5, right: 45, left: 20, bottom: 5 }}
							>
								<XAxis type="number" />
								<YAxis type="category" dataKey="name" />
								<Tooltip
									formatter={(value: number) => formatCurrency(value, userInfo.baseCurrency)}
									cursor={false}
								/>
								<Bar name="Total off hire cost" dataKey="totalCost" fill="#275171">
									<LabelList dataKey="totalCost" content={renderCustomizedLabel} />
								</Bar>
							</BarChart>
						);
					}}
				/>
			</Col>
			<Col xs={24} xl={12}>
				<ChartWidget
					header="Total Off-hire Days By Vessel"
					fetchData={getOffHireData}
					fetchDataParams={[period]}
					renderChart={(data) => {
						const renderCustomizedLabel = (props: LabelProps) => {
							const { x, y, width, value } = props;
							const viewBox = props.viewBox as CartesianViewBox;

							if (value == null || viewBox == null || viewBox?.height == null) {
								return (<text />);
							}

							return (
								<g>
									<text
										x={Number(x) + Number(width) + 10}
										y={Number(y) + 5 + (Number(viewBox.height) / 2)}
										fill="#275171"
										textAnchor="start"
										style={{ fontWeight: 'bold', fontSize: 16 }}
										dominantBaseline="end"
									>
										{`${round(Number(value), 2)} days`}
									</text>
								</g>
							);
						};

						return (
							<BarChart
								width={600}
								height={300}
								data={data}
								layout="vertical"
								margin={{ top: 5, right: 60, left: 20, bottom: 5 }}
							>
								<XAxis type="number" />
								<YAxis type="category" dataKey="name" />
								<Tooltip formatter={(value: number) => round(value, 3)} />
								<Bar name="Total off hire days" dataKey="totalDuration" fill="#275171">
									<LabelList dataKey="totalDuration" content={renderCustomizedLabel} />
								</Bar>
							</BarChart>
						);
					}}
				/>
			</Col>
			<Col span={24}>
				<TableWidget
					data-cy="voyageEventsTableWidget"
					header="Off-hire data"
					fetchData={getOffHireData}
					rowKey="key"
					fetchDataParams={[period]}
					transformData={(data) => {
						const newData = data.map((vessel) => {
							const offHirePeriods = vessel.voyageOffhirePeriods;

							return {
								...vessel,
								key: `vessel-${vessel.id}`,
								children: offHirePeriods.map((offhire) => ({
									key: `entry-${offhire.id}`,
									totalDuration: offhire.duration,
									totalCost: offhire.cost,
									name: offhire.description,
									children: offhire?.children.map((c) => ({
										key: `child-${c.id}`,
										name: c.name,
										totalCost: c.cost,
									})),
								})),
							};
						});

						return newData.sort((a, b) => b.totalCost - a.totalCost);
					}}
					columns={[{
						title: 'Vessel',
						dataIndex: 'name',
						key: 'name',
					}, {
						title: 'Total cost',
						dataIndex: 'totalCost',
						key: 'totalCost',
						render: (v) => (v == null ? null : formatCurrency(v, userInfo.baseCurrency)),
					},
					{
						title: 'Total days',
						dataIndex: 'totalDuration',
						key: 'totalDuration',
						render: (v) => (v == null ? null : round(v, 2)),
					}]}
					tableProps={{
						pagination: {
							hideOnSinglePage: true,
							pageSize: 5,
						},
						emptyText: 'No upcoming events',
						size: 'small',
					}}
				/>
			</Col>
		</Row>
	);
};

export default OffHireDashboard;
