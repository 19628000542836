import {
	HIIFieldTypes,
	VoyageBunkerTypes,
	FuelTypes,
	PnlGroups,
	InvoiceTemplates,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import getBunkerDescription from '@shared/utils/getBunkerDescription';
import TemplateItem from '@shared/TemplateItem';
import getFirstNotNull from '../utils/getFirstNotNull';
import HireInvoiceItem from './HireInvoiceItem';

class HIIBunker extends HireInvoiceItem {
	static get itemType() {
		return 'bunker';
	}

	constructor(id, isOriginal, params = {}) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });

		this.bunkerId = params.bunkerId;
		this.quantity = params.quantity;
		this.pricePerTon = params.pricePerTon;
		this.baseCurrency = params.currency;
		this.exchangeRate = params.exchangeRate;
		this.fuelGrade = params.fuelGrade;
		this.type = params.type;
		this.note = params.note;

		this._showTotal = true;
		this.addManually = true;

		let label;

		switch (this.type) {
			case VoyageBunkerTypes.DELIVERY:
				label = TemplateItem.Sections.BUNKERS_DELIVERY;
				this.pnlGroup = PnlGroups.CHARTERERS_BUNKERS;
				break;
			case VoyageBunkerTypes.REDELIVERY:
				label = TemplateItem.Sections.BUNKERS_REDELIVERY;
				this.pnlGroup = PnlGroups.OWNERS_BUNKERS;
				break;
			case VoyageBunkerTypes.OWNERS_ACCOUNT:
				label = TemplateItem.Sections.BUNKERS_OWNERS_ACCOUNT;
				this.pnlGroup = PnlGroups.OWNERS_BUNKERS;
				break;
			case VoyageBunkerTypes.CHARTERERS_ACCOUNT:
				label = TemplateItem.Sections.BUNKERS_CHARTERERS_ACCOUNT;
				this.pnlGroup = PnlGroups.CHARTERERS_BUNKERS;
				break;

			default:
				label = 'Bunkers';
		}

		this.itemTypeLabel = label;
		this.templateSection = label;

		this._fields = {
			quantity: {
				type: HIIFieldTypes.NUMBER,
				label: 'Quantity',
			},
			pricePerTon: {
				type: HIIFieldTypes.CURRENCY,
				label: 'Price per ton',
			},
			fuelGrade: {
				type: HIIFieldTypes.SELECT,
				options: Object.entries(FuelTypes).map(([key, value]) => ({
					value,
					key,
					label: value,
				})),
				label: 'Fuel grade',
			},
		};
	}

	_calculateTotal(_invoiceItems, convertCurrency) {
		let total;

		if (convertCurrency && this.fixtureCurrency !== this.currency) {
			total = this.pricePerTon / (this.exchangeRate ?? 1);
		} else {
			total = this.pricePerTon;
		}

		total *= this.quantity;

		if (
			this.type === VoyageBunkerTypes.REDELIVERY ||
			this.type === VoyageBunkerTypes.OWNERS_ACCOUNT
		) {
			return -1 * total;
		}

		return total;
	}

	getDescription() {
		const pricePerTon = (this.currency !== this.fixtureCurrency && this.exchangeRate != null) ?
			this.pricePerTon / this.exchangeRate :
			this.pricePerTon;

		return getBunkerDescription({
			quantity: this.quantity,
			fuelGrade: this.fuelGrade,
			pricePerTon,
		}, this.fixtureCurrency);
	}

	static async _getClassParams(model, _parentModel) {
		const voyageBunker = await model.getVoyageBunker();
		const bunker = await voyageBunker.getBunker();

		return {
			bunkerId: voyageBunker.id,
			quantity: getFirstNotNull(model.quantityOverride, bunker.quantity),
			fuelGrade: getFirstNotNull(model.fuelGradeOverride, bunker.fuelGrade),
			pricePerTon: getFirstNotNull(model.pricePerTonOverride, bunker.pricePerTon),
			currency: getFirstNotNull(model.currencyOverride, bunker.currency),
			exchangeRate: getFirstNotNull(model.exchangeRateOverride, bunker.exchangeRate),
			type: voyageBunker.type,
			note: voyageBunker.note,
			fixtureCurrency: model.fixtureCurrency,
		};
	}

	async saveModel(model) {
		await super.saveModel(model);

		model.voyageBunkerId = this.bunkerId;
		model.fixtureCurrency = this.fixtureCurrency;

		await model.save();

		if (this.isOriginal) {
			const voyageBunker = await model.getVoyageBunker();
			const bunker = await voyageBunker.getBunker();

			bunker.quantity = this.quantity;
			bunker.fuelGrade = this.fuelGrade;
			bunker.pricePerTon = this.pricePerTon;
			bunker.currency = this.currency;
			bunker.exchangeRate = this.exchangeRate;

			await bunker.save();
		} else {
			model.quantityOverride = this.quantity;
			model.fuelGradeOverride = this.fuelGrade;
			model.pricePerTonOverride = this.pricePerTon;
			model.currencyOverride = this.currency;
			model.exchangeRateOverride = this.exchangeRate;

			await model.save();
		}
	}

	_getTemplateItemParams() {
		const price = (this.currency !== this.fixtureCurrency) ?
			this.pricePerTon / this.exchangeRate :
			this.pricePerTon;

		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					this.fuelGrade,
					`${this.quantity} MT`,
					`${formatCurrency(
						price,
						this.fixtureCurrency,
					)} / MT`,
				],
				children: [
					[this.note],
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					`${this.fuelGrade} ${this.itemTypeLabel}`,
					`${this.quantity}`,
					'MT',
					`${formatCurrency(
						price,
						this.fixtureCurrency,
						{ hideSymbol: true },
					)}`,
				],
				children: this.note != null ? [
					[{
						content: this.note,
						small: true,
						italic: true,
					}],
				] : undefined,
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIBunker);

export default HIIBunker;
