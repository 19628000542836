import React, { ReactNode } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
	Breadcrumb,
	Space,
} from 'antd';
import {
	Link,
	useHistory,
} from 'react-router-dom';
import classNames from 'classnames';
import ErrorPage from '../ErrorPage';
import LoadingIndicator from '../LoadingIndicator';
import styles from './styles/SimpleScreen.module.css';
import RootScreen, { RootScreenProps } from './RootScreen';

type SimpleScreenProps = {
	title: string | ReactNode;
	headerOverride?: string | ReactNode;
	breadcrumbs?: [string, string | undefined][];
	headerActions?: ReactNode[];
	canGoBack?: boolean;
	goBackToUrl?: string;
	loading?: boolean;
	className?: string;
	reducedPadding?: boolean;
	error?: Error | null;
	centerContent?: boolean;
	titleExtra?: ReactNode;
	children: React.ReactElement | (React.ReactElement | null)[];
} & Omit<RootScreenProps, 'className' | 'error'>;

const SimpleScreen: React.FC<SimpleScreenProps> = ({
	breadcrumbs = [],
	title,
	headerActions,
	headerOverride,
	children,
	canGoBack,
	goBackToUrl,
	loading,
	className,
	reducedPadding = false,
	error,
	centerContent = false,
	titleExtra,
	...props
}) => {
	const history = useHistory();

	if (error != null) {
		return (<ErrorPage error={error} />);
	}

	return (
		<RootScreen
			className={classNames({ [styles.reducedPadding]: reducedPadding })}
			{...props}
		>
			<div className={styles.container}>
				<div>
					{headerOverride == null ? (
						<PageHeader
							title={(
								<Space align="center">
									<Breadcrumb className={styles.titleText}>
										{breadcrumbs.map(([text, path]) => (
											<Breadcrumb.Item>
												{path != null ? (
													<Link to={path}>{text}</Link>
												) : text}
											</Breadcrumb.Item>
										))}
										<Breadcrumb.Item>
											{title}
										</Breadcrumb.Item>
									</Breadcrumb>
									{titleExtra}
								</Space>
							)}
							onBack={
								canGoBack ? (
									goBackToUrl ? () => history.push(goBackToUrl) : () => history.goBack()
								) : undefined
							}
							className={styles.header}
							extra={loading ? null : headerActions}
						/>
					) : headerOverride}
				</div>
				{loading ? (
					<LoadingIndicator fullHeight />
				) : (
					<div className={classNames(
						styles.content,
						className,
						{ [styles.centerContent]: centerContent },
					)}
					>
						{children}
					</div>
				)}
			</div>
		</RootScreen>
	);
};

export default SimpleScreen;
