import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	Card,
	Row,
	Col,
	notification,
} from 'antd';
import {
	useParams,
	useHistory,
} from 'react-router-dom';
import getFixtureTableColumns from '@client/utils/getFixtureTableColumns';
import Table from '@client/components/Table/Table';
import {
	getBrokerDetails,
	updateBroker,
	deleteBroker,
} from '@client/lib/api';
import Button from '@client/components/Button';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import showErrorNotification from '@client/utils/showErrorNotification';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import getPointOfContactItems from '@client/utils/getPointOfContactItems';
import { Links } from '@client/utils/links';
import getItems from './helpers/getItems';

const BrokerDetailsScreen = () => {
	const { id } = useParams();
	const history = useHistory();

	const [broker, refreshBroker, error, loading] = useFetchedState(
		() => getBrokerDetails(id),
		[],
		{ showNotification: false },
	);

	const fixtureColumns = useMemo(() => getFixtureTableColumns(), []);

	const items = broker != null ? getItems(broker) : [];
	const pointOfContactItems = broker != null ? getPointOfContactItems(broker) : [];

	const saveDetails = useCallback(async (values) => {
		await updateBroker(id, values);
		await refreshBroker();
	}, [id, refreshBroker]);

	const onDeleteBroker = useCallback(async () => {
		try {
			await deleteBroker(id);

			notification.success({
				message: 'Broker was deleted',
				description: `${broker?.name} has successfully been deleted.`,
			});

			history.goBack();
		} catch (e) {
			showErrorNotification('Could not delete broker', e);
		}
	}, [id, history, broker?.name]);

	return (
		<SimpleScreen
			error={error}
			canGoBack
			breadcrumbs={[['Brokers', Links.Brokers.get()]]}
			title={broker?.name}
			rootPageTitle="Brokers"
			loading={loading}
			headerActions={[(
				<Button
					confirmTitle={`Are you sure you want to delete ${broker?.name}?`}
					onClick={onDeleteBroker}
					key="delete"
					type="primary"
					danger
				>
					Delete broker
				</Button>
			)]}
		>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Card bordered>
								<EditableDetails
									items={items}
									onSave={saveDetails}
									data-cy="details"
									saveButtonProps={{ 'data-cy': 'detailsSave' }}
									cancelButtonProps={{ 'data-cy': 'detailsCancel' }}
									editButtonProps={{ 'data-cy': 'detailsEdit' }}
								/>
							</Card>
						</Col>
						<Col span={24}>
							<Card bordered>
								<EditableDetails
									data-cy="pointOfContact"
									items={pointOfContactItems}
									onSave={saveDetails}
									title="Point of contact"
								/>
							</Card>
						</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Card title="Contracts">
						<Table
							data-cy="fixtures"
							dataSource={broker?.contracts ?? []}
							rowKey="id"
							columns={fixtureColumns}
						/>
					</Card>
				</Col>
			</Row>
		</SimpleScreen>
	);
};

export default BrokerDetailsScreen;
