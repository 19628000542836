import moment, { Moment } from 'moment';
import {
	toMoment,
	formatDate,
} from '@shared/utils/date';
import getPnlAmountAsEarnedCalculator from '@shared/utils/pnlAmountCalculators/getPnlAmountAsEarnedCalculator';
import { Values } from '@shared/utils/objectEnums';
import {
	HIIFieldTypes,
	DATE_AND_TIME,
	MillisecondsPer,
	PnlGroups,
	HireTypes,
	HireUnit,
	InvoiceTemplates,
} from '../utils/constants';
import { formatCurrency } from '../utils/currency';
import { round } from '../utils/math';
import TemplateItem from '../TemplateItem';
import HireInvoiceItem, { HIIConstructorParams } from './HireInvoiceItem';
import HireInvoiceDifference from './HireInvoiceDifference';

export type HIIHirePeriodType = HIIHirePeriod;

class HIIHirePeriod extends HireInvoiceItem {
	static get itemType() {
		return 'hirePeriod';
	}

	from: Moment;
	to: Moment;
	useUTC: boolean;
	hireRate: number;
	hireDescription: string | undefined;
	hireType: Values<typeof HireTypes>;
	hireDaysDecimals: number;
	hireUnit: HireUnit;

	constructor(
		id: number,
		isOriginal: boolean,
		params: HIIConstructorParams & {
			from: string | Moment;
			to: string | Moment;
			hireRate: number;
			hireDescription?: string;
			hireType: Values<typeof HireTypes>;
			useUTC?: boolean;
			hireDaysDecimals?: number;
			hireUnit: HireUnit;
		},
	) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });

		this.from = toMoment(params.from);
		this.to = toMoment(params.to);
		this.hireDaysDecimals = params?.hireDaysDecimals ?? 6;
		this.useUTC = params.useUTC ?? true;
		this.hireRate = Number(params.hireRate);
		this.hireDescription = params.hireDescription;
		this.hireType = params.hireType;
		this.pnlGroup = PnlGroups.HIRE;
		this.pnlAmountCalculator = getPnlAmountAsEarnedCalculator(this);

		this._showTotal = true;
		this.itemTypeLabel = 'Hire';
		this.addManually = false;
		this.templateSection = TemplateItem.Sections.HIRE;

		this.hireUnit = params.hireUnit;

		this._fields = {
			from: {
				label: 'From',
				type: HIIFieldTypes.DATE,
			},
			to: {
				label: 'To',
				type: HIIFieldTypes.DATE,
			},
			hireRate: {
				label: 'Hire rate',
				type: HIIFieldTypes.CURRENCY,
			},
			hireDescription: {
				label: 'Hire Description',
				type: HIIFieldTypes.TEXT,
			},
		};
	}

	_calculateTotal() {
		if (this.hireUnit === HireUnit.MONTHS) {
			let total = 0;
			let current = toMoment(this.from);
			const end = toMoment(this.to);

			// Handle the first partial month
			if (!current.isSame(current.clone().startOf('month'), 'day')) {
				const endOfMonth = current.clone().endOf('month');
				const daysInMonth = current.daysInMonth();
				const periodEnd = moment.min(endOfMonth, end);
				const fractionalDays = periodEnd.diff(current, 'hours', true) / 24;

				total += (this.hireRate / daysInMonth) * fractionalDays;
				current = periodEnd.add(1, 'second'); // Move to the next second after the end of the period
			}

			// Handle the full months in between
			while (current.isBefore(end, 'month') && current.isBefore(end)) {
				total += this.hireRate;
				current = current.add(1, 'month').startOf('month');
			}

			// Handle the last partial month
			if (current.isBefore(end)) {
				const daysInMonth = current.daysInMonth();
				const fractionalDays = end.diff(current, 'hours', true) / 24;
				total += (this.hireRate / daysInMonth) * fractionalDays;
			}

			return total;
		}

		return this.hireRate * this.getDays();
	}

	getDays() {
		return round(toMoment(this.to).diff(this.from) / MillisecondsPer.DAY, this.hireDaysDecimals ?? 6);
	}

	getDuration() {
		return `
			${formatDate(this.from, 'MMM Do YYYY HH:mm')} - 
			${formatDate(this.to, 'MMM Do YYYY HH:mm')}
		`;
	}

	getDescription() {
		return `
			${this.getDays()} days at 
			${formatCurrency(this.hireRate, this.fixtureCurrency)}\n
			${this.getDuration()}
		`;
	}

	static _getClassParams(model: any, _parentModel: any) {
		return {
			to: model.to,
			from: model.from,
			hireRate: model.hireRate,
			hireDescription: model.hireDescription,
			hireDaysDecimals: model.hireDaysDecimals,
			hireType: model.hireType,
			useUTC: model.useUTC,
			fixtureCurrency: model.fixtureCurrency,
			hireUnit: model.hireUnit,
		};
	}

	async saveModel(model: any) {
		await super.saveModel(model);

		model.to = this.to;
		model.from = this.from;
		model.hireRate = this.hireRate;
		model.hireDescription = this.hireDescription;
		model.hireDaysDecimals = this.hireDaysDecimals;
		model.hireType = this.hireType;
		model.useUTC = this.useUTC;
		model.hireUnit = this.hireUnit;

		await model.save();
	}

	_getTemplateItemParams() {
		const utcSuffix = {
			content: 'UTC',
			small: true,
		};

		const ltSuffix = {
			content: 'LT',
			small: true,
		};

		return {
			[InvoiceTemplates.NEO]: {
				columns: [
					`Hire ${this.getDuration()}`,
					`${this.getDays()}`,
					'days',
					// eslint-disable-next-line max-len
					`${formatCurrency(this.hireRate, this.fixtureCurrency, { hideSymbol: true })} ${this.hireUnit === HireUnit.MONTHS ? 'pcm' : ''}`,
				],
				children: this.hireDescription != null && this.hireDescription !== '' ? [
					[{
						content: this.hireDescription,
						small: true,
						italic: true,
					}],
				] : undefined,
			},
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					[
						[
							'From:',
							formatDate(this.from, DATE_AND_TIME, false, this.useUTC ? 'utc' : 'localTime') || '',
							this.useUTC ? utcSuffix : ltSuffix,
						],
						[
							'To:',
							formatDate(this.to, DATE_AND_TIME, false, this.useUTC ? 'utc' : 'localTime') || '',
							this.useUTC ? utcSuffix : ltSuffix,
						],
					],
					`${this.getDays()} days`,
					[
						[`@ ${formatCurrency(this.hireRate, this.fixtureCurrency)} 
					${this.hireUnit === HireUnit.MONTHS ? 'pcm' : ''}`],
						[
							{
								content: (this.hireDescription ?? ''),
								small: true,
								italic: true,
							},
						],
					],
				],
			},
		};
	}

	static groupItems(items: HireInvoiceItem[], differences: HireInvoiceDifference[]) {
		if (items == null || items.length === 0) {
			return { items, differences };
		}

		const groupRecursively = (
			groupedItems: HireInvoiceItem[],
			groupedDifferences: HireInvoiceDifference[],
		): {
			items: HireInvoiceItem[];
			differences: HireInvoiceDifference[];
		} => {
			let newGroupedDifferences = groupedDifferences;

			const updateGroupedDifferencesForItem = (
				item1: HireInvoiceItem,
				item2: HireInvoiceItem,
				newItem: HireInvoiceItem,
			) => {
				const changedItem1 = newGroupedDifferences.find((d) => d.original.id === item1.id)?.changed;
				const changedItem2 = newGroupedDifferences.find((d) => d.original.id === item2.id)?.changed;

				if (
					changedItem1 != null ||
					changedItem2 != null ||
					!item1.accepted ||
					!item2.accepted
				) {
					const changedItems = groupedItems.map((i) => (
						HireInvoiceDifference.getChangedInvoiceItem(i, newGroupedDifferences)
					));

					const changedItem1Total = !item1.accepted ? 0 : (
						changedItem1 == null ?
							item1.getTotal(items, false) :
							changedItem1.getTotal(changedItems, false)
					);
					const changedItem2Total = !item2.accepted ? 0 : (
						changedItem2 == null ?
							item2.getTotal(items, false) :
							changedItem2.getTotal(changedItems, false)
					);

					const changedNewItem = newItem.copy();
					changedNewItem.id -= 0.5;

					changedNewItem.totalOverride = round(changedItem1Total + changedItem2Total);
					changedNewItem.accepted = true;

					newGroupedDifferences = [
						...newGroupedDifferences.filter((d) => (
							d.original.id !== item1.id &&
							d.original.id !== item2.id
						)),
						new HireInvoiceDifference(newItem, changedNewItem),
					];
				}
			};

			for (let i1 = 0; i1 < groupedItems.length; i1++) {
				const item1 = groupedItems[i1];
				for (let i2 = 0; i2 < groupedItems.length; i2++) {
					const item2 = groupedItems[i2];

					if (
						item1 !== item2 &&
						item1 instanceof HIIHirePeriod &&
						item2 instanceof HIIHirePeriod &&
						item1.canBeGroupedWith(item2, differences)
					) {
						const newItem = new HIIHirePeriod(
							Math.min(item1.id, -item1.id),
							item1.isOriginal && item2.isOriginal,
							{
								from: item1.from,
								to: item2.to,
								hireDaysDecimals: item1.hireDaysDecimals,
								hireRate: item1.hireRate,
								hireDescription: '',
								fixtureCurrency: item1.fixtureCurrency,
								hireType: item1.hireType,
								useUTC: item1.useUTC,
								hireUnit: item1.hireUnit,
							},
						);

						const item1Total = item1.getTotal(items, false);
						const item2Total = item2.getTotal(items, false);

						newItem.totalOverride = round(item1Total + item2Total);
						newItem.isGrouped = true;
						newItem.accepted = true;
						newItem.groupedItems = [
							...(item1.isGrouped ? (item1.groupedItems ?? [item1]) : [item1]),
							...(item2.isGrouped ? (item2.groupedItems ?? [item2]) : [item2]),
						];

						updateGroupedDifferencesForItem(item1, item2, newItem);

						newItem.children = item1.children.map((child1, index) => {
							// This should be safe all current scenarios
							const child2 = item2.children[index];

							const newChild = child1.copy();

							newChild.totalOverride = round(
								child1.getTotal(items, false) +
								child2.getTotal(items, false),
							);

							updateGroupedDifferencesForItem(child1, child2, newChild);

							newChild.id = Math.min(newChild.id, -newChild.id);
							newChild.parentId = newItem.id;
							newChild.isGrouped = true;
							newChild.accepted = true;
							newChild.groupedItems = [
								...(child1.isGrouped ? (child1.groupedItems ?? [child1]) : [child1]),
								...(child2.isGrouped ? (child2.groupedItems ?? [child2]) : [child2]),
							];

							return newChild;
						});

						let newGroupedItems = groupedItems.filter((i) => i !== item1 && i !== item2);
						const insertIndex = Math.min(i1, i2);
						newGroupedItems = [
							...newGroupedItems.slice(0, insertIndex),
							newItem,
							...newGroupedItems.slice(insertIndex),
						];

						return groupRecursively(newGroupedItems, newGroupedDifferences);
					}
				}
			}

			return {
				items: groupedItems,
				differences: groupedDifferences,
			};
		};

		return groupRecursively(items, differences);
	}

	// @ts-ignore
	_canBeGroupedWith(other: any) {
		return (
			(this.hireRate === other.hireRate) &&
			(toMoment(this.to).isSame(toMoment(other.from)))
		);
	}
}

HireInvoiceItem.addItemClass(HIIHirePeriod);

export default HIIHirePeriod;
